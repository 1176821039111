import { CategoriesSliceInitialState } from 'components/Resources/Materials/NestedPages/Categories/Categories.types'
import { categoryFormHeaderInitialState, categoryFormBodyInitialState } from 'components/Resources/Materials/NestedPages/Categories/Categories.utils'
import { createAppSlice } from 'utils/createAppSlice'
import { defaultInitialState } from 'utils/defaultInitialState.ts'

const categoriesInitialState: CategoriesSliceInitialState = {
    ...defaultInitialState,
    editableFormHeader: categoryFormHeaderInitialState,
    editableFormBody: categoryFormBodyInitialState,
}

export const categoriesSlice = createAppSlice<CategoriesSliceInitialState>('categories',  categoriesInitialState)

export default categoriesSlice.reducer
