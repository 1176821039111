import { JobMaterialsSliceInitialState } from 'components/Resources/Jobs/PageBody/Materials/JobMaterials.types'
import { jobMaterialFormHeaderInitialState, jobMaterialsFormBodyInitialState } from 'components/Resources/Jobs/PageBody/Materials/JobMaterials.utils'
import { createAppSlice } from 'utils/createAppSlice'
import { defaultInitialState } from 'utils/defaultInitialState.ts'

const jobsInitialState: JobMaterialsSliceInitialState = {
    ...defaultInitialState,
    editableFormHeader: jobMaterialFormHeaderInitialState,
    editableFormBody: jobMaterialsFormBodyInitialState,
}

export const jobBodyAddMaterialsSlice = createAppSlice<JobMaterialsSliceInitialState>('jobBodyAddMaterials',  jobsInitialState)

export default jobBodyAddMaterialsSlice.reducer
