import {
    PerformerFindOutdatedApiResponse,
    useLazyPerformerFindOutdatedQuery,
} from 'store/api/performer.tsx';
import { range } from '@mantine/hooks';
import { useEffect, useState } from 'react';
import { Loader } from '@mantine/core';
import { WorkList } from '../Work/WorkList.tsx';

export const OverdueLayout = () => {
    
    const [findApiCall, { isLoading }] = useLazyPerformerFindOutdatedQuery()
    
    const [ outdatedWorks, setOutdatedWorks  ] =
        useState<PerformerFindOutdatedApiResponse['data']>()
    
    useEffect(() => {
        findApiCall()
            .unwrap()
            .then(({ data }) => setOutdatedWorks(data))
    }, [findApiCall, JSON.stringify(range)])
    
    return isLoading ?
        <Loader /> :
        <WorkList works={outdatedWorks} variant={'overdue'} />
}
