import { isRejectedWithValue, Middleware } from '@reduxjs/toolkit'
import { errorSlice } from 'store/slices/errorSlice.ts'

export const errorMiddleware: Middleware = (store) => (next) => (action: unknown) => {
    if (isRejectedWithValue(action) && (action.payload as { data: { status: number } }).data.status !== 401) {
        const payload = action.payload as { data: { message: string[], status: number } }

        store.dispatch(errorSlice.actions.Clear())

        store.dispatch(errorSlice.actions.Error({
            title: action.error.message,
            message: payload.data.message,
            status: payload.data.status,
        }))
    }
    return next(action as any)
}