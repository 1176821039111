import { FormBasedSliceInitialState } from 'types/redux.ts'
import { AutocompleteField } from 'types/inputs.ts'
import {
    TableProps,
    TablePropsAbstract,
} from 'components/AbstractComponents/TableReusable/TableReusable.types'


import {
    FindMaterialReportResponseDto,
    MaterialsReportApiArg,
    MaterialsReportApiResponse
} from 'store/api/materials.tsx';
import { autocompleteFieldDefault } from 'utils/inputs.tsx';


// *
// REUSABLE TABLE
// *

// export type AdditionalTableTypes = {
//     enableExpanding?: boolean,
//     enableExpandAll?: boolean,
// }

export type ControllerPropsMaterialUsage = TableProps<
    MaterialsReportApiResponse,
    MaterialsReportApiArg,
    FindMaterialReportResponseDto
>
export type TablePropsMaterialUsage = TablePropsAbstract &
    ControllerPropsMaterialUsage

// *
// FORM FIELDS
// *

type MaterialUsageAutocompleteFields = {
    code: AutocompleteField
    store: AutocompleteField
}
type MaterialUsageAdditionalFields = {
    // imageUrl?: string
}
type CategoriesCustomFields = MaterialUsageAutocompleteFields &
    MaterialUsageAdditionalFields


export type MaterialUsageAutocompleteKey = keyof MaterialUsageAutocompleteFields


// export type CategoriesFormGlobal = CategoriesCustomFields &
//     CategoriesGlobalImmutableFields

export type MaterialUsageFormGlobal = CategoriesCustomFields
export type MaterialUsageFormHeader = Pick<FindMaterialReportResponseDto, 'name'>
export type MaterialUsageFormBody = Omit<
    MaterialUsageFormGlobal,
    MaterialUsageFormHeader
>

export type MaterialUsageFormGlobalKey = keyof MaterialUsageFormGlobal
export type MaterialUsageFormHeaderKey = keyof MaterialUsageFormHeader
export type MaterialUsageFormBodyKey = keyof MaterialUsageFormBody

// *
// STORE
// *

export const materialUsageFormHeaderInitialState: MaterialUsageFormHeader = {
    name: '',
}
export const materialUsageFormBodyInitialState: MaterialUsageFormBody = {
    code: autocompleteFieldDefault,
    store: autocompleteFieldDefault,
    // imageUrl: '',
}


export class MaterialUsageSliceInitialState extends FormBasedSliceInitialState {
    editableFormHeader: MaterialUsageFormHeader = materialUsageFormHeaderInitialState;
    editableFormBody: MaterialUsageFormBody = materialUsageFormBodyInitialState;
}
