import { StoreFormBody, StoreFormHeader, StoresFormGlobal } from './Stores.types'
import { FindStoreResponseDto, UpdateStoreRequestDto } from 'store/api/stores'


// *
// DATA CONVERT FUNCS
// *

export const convertStoreDtoToFormBody = (store: FindStoreResponseDto): StoreFormBody => ({
    url: store?.url || storeFormBodyInitialState.url,
})
export const convertStoreDtoToFormHeader = (store: FindStoreResponseDto): StoreFormHeader => ({
    name: store?.name || storeFormHeaderInitialState.name,
})
export const convertStoreFormToRequestDto = (
    data: Partial<StoreFormBody & StoreFormHeader>
): UpdateStoreRequestDto => ({
    name: data?.name || storeFormHeaderInitialState.name,
    url: data?.url || storeFormBodyInitialState.url,
})


// *
// STORE
// *

export const storesFormGlobalDefaultValues: StoresFormGlobal = {
    name: '',
    url: '',
    // imageUrl: '',
}
export const storeFormHeaderInitialState: StoreFormHeader = {
    name: '',
}
export const storeFormBodyInitialState: StoreFormBody = {
    url: '',
    // imageUrl: '',
}


// *
// INPUT PROCESSING FUNCS
// *
