import { DateValue } from '@mantine/dates'
import dayjs from 'utils/dayjs.ts'

export const dateFormat = (d: string) => dayjs(d).format('YYYY-MM-DD HH:mm')
export const dateFormatUtc = (d: string) => dayjs(d).utc().format('YYYY-MM-DD HH:mm')


export const getISODayDate = (date: DateValue) => {
    const d = date?.toLocaleDateString()
    if (d) {
        const splitSeparator = d.split('')[2]
        return d.split(`${splitSeparator}`).reverse().join('-')
    }
}

export const calculateLag = (lag: number) => {
    const dayjsLag = dayjs.duration(lag, 'minutes')
    const lagInDays = Math.abs(dayjsLag.days()) ? `${Math.abs(dayjsLag.days())}д.` : ''
    const lagInHours = Math.abs(dayjsLag.hours()) ? `${Math.abs(dayjsLag.hours())}ч.` : ''
    const lagInMinutes = Math.abs(dayjsLag.minutes()) ? `${Math.abs(dayjsLag.minutes())}м.` : ''
    
    return [lagInDays, lagInHours, lagInMinutes].join(' ').trim()
}

const dayDateRegExp =
    /^(0?[1-9]|[12][0-9]|3[01])[\\/\\-\\.\\ ](0?[1-9]|1[012])[\\/\\-\\.\\ ]\d{0,4}$/

export const dateInputParserDay = (input: string) => {
    const match = input.match(dayDateRegExp)
    const dividers = /[\\/\\-\\.\\ ]/g
    if (match) {
        const date = match[0].trim().replace(dividers, ' ').split(' ').reverse()
        const year = +date[0]
        const month = +date[1] - 1
        const day = +date[2]
        return new Date(year, month, day)
    }
    return null
}

const day = 1000 * 60 * 60 * 24
const leapYearsFrequency = 4
const adultAge = 18
const adultAgeInMilliseconds = day * 365 * adultAge
const adultLeapYearDaysAmountInMilliseconds = day * Math.min(adultAge / leapYearsFrequency)

export const minAdultBirthDate = new Date(Date.now() - (adultAgeInMilliseconds + adultLeapYearDaysAmountInMilliseconds))
export const minDateInputDate = new Date(1900, 0)

export const getWeekDayReducedNameByIndex = (index: number) => index === 0 ? 'пн' : index === 1 ? 'вт' : index === 2 ? 'ср' : index === 3 ? 'чт' : index === 4 ? 'пт' :  index === 5 ? 'сб' : 'вс'

