import { DefaultSliceState } from 'types/defaultSliceState'

export const defaultInitialState: DefaultSliceState = {
    filterParamsArray: [],
    permanentFilterParamsArray: [],
    sortParamsArray: [],
    isEditableFormValid: false,
    shouldResetGlobalFilterParams: false,
    pagination: {
        limit: 100,
        offset: 0,
        total: 0,
        lastPageIndex: 0,
    }
}
