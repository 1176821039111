import { roomFormHeaderInitialState, roomFormBodyInitialState } from 'components/Clients/NestedPages/Rooms/Rooms.utils'
import { ProjectRoomsSliceInitialState } from 'components/Projects/PageBody/Rooms/RoomParts/ProjectRooms.types'
import { createAppSlice } from 'utils/createAppSlice'
import { defaultInitialState } from 'utils/defaultInitialState.ts'

const projectRoomsSliceInitialState: ProjectRoomsSliceInitialState = {
    ...defaultInitialState,
    editableFormHeader: roomFormHeaderInitialState,
    editableFormBody: roomFormBodyInitialState,
}

export const projectBodyAddRoomsSlice = createAppSlice<ProjectRoomsSliceInitialState>('projectBodyAddRooms', projectRoomsSliceInitialState)

export default projectBodyAddRoomsSlice.reducer
