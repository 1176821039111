import { UserRolesSliceInitialState } from 'components/Organizations/NestedPages/UserRoles/UserRoles.types'
import {
    userRoleFormHeaderInitialState,
    userRoleFormBodyInitialState,
} from 'components/Organizations/NestedPages/UserRoles/UserRoles.utils'
import { createAppSlice } from 'utils/createAppSlice'
import { defaultInitialState } from 'utils/defaultInitialState.ts'

const userRolesSliceInitialState: UserRolesSliceInitialState = {
    ...defaultInitialState,
    editableFormHeader: userRoleFormHeaderInitialState,
    editableFormBody: userRoleFormBodyInitialState,
}

export const userRolesSlice = createAppSlice<UserRolesSliceInitialState>(
    'userRoles',
    userRolesSliceInitialState
)

export default userRolesSlice.reducer
