import BreadcrumbItem from 'components/AbstractComponents/BreadCrumbs/BreadcrumbItem'
import { createElement } from 'react'
import { Navigate, Outlet, RouteObject } from 'react-router-dom'
import { RootStateType } from 'store/store'
import { Alert, Flex } from '@mantine/core'
import { IconAlertCircle } from '@tabler/icons-react'
import { UnderConstruction } from 'components/AbstractComponents/UnderConstruction/UnderConstruction.tsx'
import { JournalTable } from 'components/Projects/PageBody/Journals/JournalsParts/JournalTable.tsx'

export const PROJECTS_ROUTES: Array<RouteObject> = [
    {
        index: true,
        element: <Navigate to={ 'project' } />,
    },
    {
        id: 'projectList',
        path: 'project',
        element: <Outlet />,
        loader: ({ params }) => ( {
            id: params.id,
            name: 'Проекты',
            path: 'projects/project',
        } ),
        children: [
            {
                index: true,
                async lazy() {
                    const { ProjectsTable } = await import('components/Projects/ProjectsTable')
                    return { element: <ProjectsTable /> }
                },
            },
            {
                id: 'projectCreate',
                path: 'new',
                async lazy() {
                    const { ProjectPage } = await import('components/Projects/ProjectPage')
                    return { element: <ProjectPage /> }
                },
                loader: () => ( {
                    name: 'Новый проект',
                    path: 'projects/project/new',
                } ),
                children: [
                    {
                        index: true,
                        element: <Navigate to={ 'about' } replace />,
                    },
                    {
                        id: 'projectCreateInfo',
                        path: 'about',
                        async lazy() {
                            const { ProjectBodyEditInfo } = await import('components/Projects/PageBody/Info/ProjectBodyEditInfo')
                            return { element: <ProjectBodyEditInfo /> }
                        },
                    },
                    {
                        id: 'projectCreateReports',
                        path: 'reports',
                        element: <Flex pt={ 20 }>
                            <Alert
                                icon={ <IconAlertCircle size="1rem" /> }
                                title={ ' Создайте проект для создания отчёта' }
                            >
                                <p />
                            </Alert>
                        </Flex>,
                    },
                    {
                        id: 'projectCreateOutlays',
                        path: 'outlays',
                        async lazy() {
                            const { OutlayTable } = await import('components/Projects/NestedPages/Outlays/OutlayTable')
                            return { element: <OutlayTable /> }
                        },
                        
                    },
                    {
                        id: 'projectCreateCorrectives',
                        path: 'correctives',
                        async lazy() {
                            const { ProjectCorrectives } = await import('pages/tenant/client/ProjectCorrectives')
                            return { element: <ProjectCorrectives /> }
                        },
                    },
                    {
                        id: 'projectCreateJournal',
                        path: 'journal',
                        async lazy() {
                            const { ProjectCustomers } = await import('pages/tenant/client/ProjectCustomers')
                            
                            return { element: <ProjectCustomers /> }
                        },
                        children: [
                            {
                                id: 'projectCreateCustomerById',
                                path: ':customerId',
                                async lazy() {
                                    const { ProjectCustomerById } = await import('pages/tenant/client/ProjectCustomerById')
                                    return { element: <ProjectCustomerById /> }
                                },
                                loader: ({ params }) => ( {
                                    id: params.id,
                                    name: 'Имя заказчика',
                                } ),
                            },
                        ],
                    },
                    {
                        id: 'projectCreateRooms',
                        path: 'rooms',
                        async lazy() {
                            const { ProjectBodyEditRooms } = await import('components/Projects/PageBody/Rooms/ProjectBodyEditRooms')
                            return { element: <ProjectBodyEditRooms /> }
                        },
                        children: [
                            {
                                id: 'projectCreateRoomById',
                                path: ':roomId',
                                async lazy() {
                                    const { ProjectRoomById } = await import('pages/tenant/client/ProjectRoomById')
                                    return { element: <ProjectRoomById /> }
                                },
                                loader: ({ params }) => ( {
                                    id: params.id,
                                    name: 'Имя комнаты',
                                } ),
                                children: [
                                    {
                                        id: 'projectCreateWorkById',
                                        path: ':workId',
                                        async lazy() {
                                            const { ProjectRoomWork } = await import('pages/tenant/client/ProjectRoomWork')
                                            return { element: <ProjectRoomWork /> }
                                        },
                                        loader: ({ params }) => ( {
                                            id: params.id,
                                            name: 'Имя работы',
                                        } ),
                                        children: [
                                            {
                                                id: 'projectCreateMaterialById',
                                                path: ':materialId',
                                                async lazy() {
                                                    const { ProjectWorkMaterial } = await import('pages/tenant/client/ProjectWorkMaterial')
                                                    return { element: <ProjectWorkMaterial /> }
                                                },
                                                loader: ({ params }) => ( {
                                                    id: params.id,
                                                    name: 'Имя материала',
                                                } ),
                                                children: [
                                                    {
                                                        id: 'projectCreateMaterialByIdInfo',
                                                        path: 'info',
                                                        async lazy() {
                                                            const { WorkMaterialInfo } = await import('pages/tenant/client/ProjectMaterialInfo')
                                                            return { element: <WorkMaterialInfo /> }
                                                        },
                                                        loader: ({ params }) => ( {
                                                            id: params.id,
                                                            name: 'Инфо',
                                                        } ),
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        id: 'projectCreatePlanning',
                        path: 'planning',
                        async lazy() {
                            const { PlanningPageBody } = await import('components/Projects/PageBody/Planning/PlanningPageBody.tsx')
                            return { element: <PlanningPageBody /> }
                        },
                        children: [
                            {
                                index: true,
                                element: <Navigate to={ 'calendar' } replace />,
                            },
                            {
                                id: 'projectCreatePlanningCalendar',
                                path: 'calendar',
                                element: <>Вкладка "Календарь" будет доступна после создания
                                    проекта</>,
                            },
                            {
                                id: 'projectCreatePlanningStages',
                                path: 'stages',
                                element: <>Вкладка "Этапы" будет доступна после создания проекта</>,
                            },
                            {
                                id: 'projectCreatePlanningRooms',
                                path: 'rooms',
                                element: <>Вкладка "Помещения" будет доступна после создания
                                    проекта</>,
                            },
                        ],
                    },
                    {
                        id: 'projectCreateWarehouses',
                        path: 'warehouses',
                        element: <>Вкладка "Склады" будет доступна после создания
                            проекта</>,
                    },
                ],
            },
            {
                id: 'projectById',
                path: ':projectId',
                async lazy() {
                    const { ProjectPage } = await import('components/Projects/ProjectPage')
                    return { element: <ProjectPage /> }
                },
                loader: ({ params }) => ( {
                    id: params.projectId,
                    name: createElement(BreadcrumbItem((state: RootStateType) => ( {
                        name: state.projectsReducer.editableFormHeader.name || '',
                    } ))),
                    path: `/projects/project/${ params.projectId }/about`,
                } ),
                children: [
                    {
                        index: true,
                        element: <Navigate to={ 'about' } replace />,
                    },
                    {
                        id: 'projectInfo',
                        path: 'about',
                        async lazy() {
                            const { ProjectBodyViewInfo } = await import('components/Projects/PageBody/Info/ProjectBodyViewInfo')
                            return { element: <ProjectBodyViewInfo /> }
                        },
                    },
                    {
                        id: 'projectDocuments',
                        element: <Outlet />,
                        path: 'documents',
                        children: [
                            {
                                path: '',
                                async lazy() {
                                    const { DocumentsPage } = await import('components/Projects/PageBody/Documents/DocumentsPage.tsx')
                                    return { element: <DocumentsPage /> }
                                },
                            },
                            {
                                id: 'projectDocumentsPage',
                                path: ':documentId',
                                async lazy() {
                                    const { ProjectDocumentPage } = await import('components/Projects/PageBody/Documents/NestedPages/ProjectDocumentPage.tsx')
                                    return { element: <ProjectDocumentPage /> }
                                },
                                children: [
                                    {
                                        id: 'projectDocumentsPageById',
                                        path: '',
                                        async lazy() {
                                            const { DocumentFolder } = await import('components/Projects/PageBody/Documents/NestedPages/DocumentFolder.tsx')
                                            return { element: <DocumentFolder /> }
                                        }
                                    },
                                ]


                            }
                        ]
                    },
                    {
                        id: 'projectOutlays',
                        path: 'outlays',
                        children: [
                            {
                                index: true,
                                async lazy() {
                                    const { ProjectBodyViewOutlays } = await import('components/Projects/PageBody/Outlays/ProjectBodyViewOutlays')
                                    return { element: <ProjectBodyViewOutlays /> }
                                },
                            },
                            {
                                id: 'projectOutlayById',
                                path: ':outlayId',
                                async lazy() {
                                    const { OutlayPage } = await import('components/Projects/PageBody/Outlays/OutlayPage.tsx')
                                    return { element: <OutlayPage /> }
                                },
                                children: [
                                    {
                                        index: true,
                                        element: <Navigate to={ 'master_outlay' } replace />,
                                    },
                                    {
                                        id: 'projectMasterOutlay',
                                        path: 'master_outlay',
                                        async lazy() {
                                            const { MasterOutlay } = await import('components/Projects/PageBody/Outlays/OutlayParts/MasterOutlay.tsx')
                                            return { element: <MasterOutlay /> }
                                        },
                                    },
                                    {
                                        id: 'projectStagesOutlay',
                                        path: 'stages_outlay',
                                        async lazy() {
                                            const { StagesOutlay } = await import('components/Projects/PageBody/Outlays/OutlayParts/StagesOutlay.tsx')
                                            return { element: <StagesOutlay /> }
                                        },
                                    },
                                    {
                                        id: 'projectWorksOutlay',
                                        path: 'works_outlay',
                                        async lazy() {
                                            const { WorksOutlay } = await import('components/Projects/PageBody/Outlays/OutlayParts/WorksOutlay.tsx')
                                            return { element: <WorksOutlay /> }
                                        },
                                    },
                                    {
                                        id: 'projectSelfCostOutlay',
                                        path: 'selfCost_outlay',
                                        async lazy() {
                                            const { SelfCostOutlay } = await import('components/Projects/PageBody/Outlays/OutlayParts/SelfCostOutlay.tsx')
                                            return { element: <SelfCostOutlay /> }
                                        },
                                    },
                                    {
                                        id: 'projectMutualOutlay',
                                        path: 'mutual',
                                        async lazy() {
                                            const { MutualOutlay } = await import('components/Projects/PageBody/Outlays/OutlayParts/MutualOutlay.tsx')
                                            return { element: <MutualOutlay /> }
                                        },
                                    },
                                    {
                                        id: 'projectCommercialOutlay',
                                        path: 'commercial',
                                        async lazy() {
                                            const { CommercialOutlay } = await import('components/Projects/PageBody/Outlays/OutlayParts/CommercialOutlay.tsx')
                                            return { element: <CommercialOutlay /> }
                                        },
                                    },
                                    {
                                        id: 'projectWorkPrice',
                                        path: 'work_price',
                                        async lazy() {
                                            const { WorkPriceOutlay } = await import('components/Projects/PageBody/Outlays/OutlayParts/WorkPriceOutlay.tsx')
                                            return { element: <WorkPriceOutlay /> }
                                        },
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        id: 'projectReports',
                        path: 'reports',
                        children: [
                            {
                                id: 'projectReportsList',
                                path: '',
                                async lazy() {
                                    const { ReportsPageBodyView } = await import('components/Projects/PageBody/Reports/ReportsPageBodyView.tsx')
                                    return { element: <ReportsPageBodyView /> }
                                },
                            },
                            {
                                id: 'projectReportById',
                                path: ':projectReport',
                                async lazy() {
                                    const { GeneratedReportPage } = await import('components/Projects/PageBody/Reports/GeneratedReportPage.tsx')
                                    return { element: <GeneratedReportPage /> }
                                },
                                loader: ({ params }) => ( {
                                    id: params.id,
                                    name: 'Отчёт',
                                } ),
                            },
                            {
                                id: 'newProjectReportById',
                                path: 'new',
                                async lazy() {
                                    const { ReportsPageBodyCreate } = await import('components/Projects/PageBody/Reports/ReportsPageBodyCreate.tsx')
                                    return { element: <ReportsPageBodyCreate /> }
                                },
                                loader: () => ( {
                                    id: 'new',
                                    name: 'Новый отчёт',
                                } ),
                            },
                        ],
                        
                    },
                    {
                        id: 'projectCorrectives',
                        path: 'correctives',
                        children: [
                            {
                                id: 'correctivesList',
                                path: '',
                                async lazy() {
                                    const { CorrectivePage } = await import('components/Projects/PageBody/Correctives/CorrectivePage.tsx')
                                    return { element: <CorrectivePage /> }
                                },
                            },
                            {
                                id: 'correctiveById',
                                path: '',
                                async lazy() {
                                    const { CorrectiveByIdViewPage } = await import('components/Projects/PageBody/Correctives/CorrectiveByIdViewPage.tsx')
                                    return { element: < CorrectiveByIdViewPage /> }
                                },
                                //  element: <CorrectiveByIdView />,
                                loader: ({ params }) => ( { correctiveId: params.id } ),
                                children: [
                                    {
                                        id: 'correctiveByIdView',
                                        path: ':correctiveId',
                                        async lazy() {
                                            const { CorrectiveByIdView } = await import('components/Projects/PageBody/Correctives/CorrectivesParts/CorrectiveByIdView.tsx')
                                            return { element: < CorrectiveByIdView /> }
                                        },
                                        //  element: <CorrectiveByIdView />,
                                        loader: ({ params }) => ( { correctiveId: params.id } ),
                                    },
                                ],
                            },
                            
                            {
                                id: 'projectCorrectiveWorksScope',
                                path: 'work_scope',
                                async lazy() {
                                    const { WorkScopeTable } = await import('components/Projects/PageBody/Correctives/NestedPage/WorkScopeTable.tsx')
                                    return { element: < WorkScopeTable /> }
                                },
                            },
                        ],
                        
                    },
                    {
                        id: 'projectJournal',
                        path: 'journal',
                        async lazy() {
                            const { ProjectBodyViewJournals } = await import('components/Projects/PageBody/Journals/ProjectBodyViewJournals.tsx')
                            
                            return { element: <ProjectBodyViewJournals /> }
                        },
                        children: [
                            {
                                id: 'customerById',
                                path: ':customerId',
                                async lazy() {
                                    const { ProjectCustomerById } = await import('pages/tenant/client/ProjectCustomerById')
                                    return { element: <ProjectCustomerById /> }
                                },
                                loader: ({ params }) => ( {
                                    id: params.id,
                                    name: 'Имя заказчика',
                                } ),
                            },
                        ],
                    },
                    {
                        id: 'projectRooms',
                        path: 'rooms',
                        async lazy() {
                            const { ProjectBodyViewRooms } = await import('components/Projects/PageBody/Rooms/ProjectBodyViewRooms')
                            
                            return { element: <ProjectBodyViewRooms /> }
                        },
                        children: [
                            {
                                index: true,
                                element: <Navigate to={ 'works_by_room' } replace />,
                            },
                            {
                                id: 'projectWorksByRoom',
                                path: 'works_by_room',
                                async lazy() {
                                    const { ProjectBodyViewWorks } = await import('components/Projects/PageBody/Rooms/ProjectBodyViewWorks.tsx')
                                    return { element: <ProjectBodyViewWorks /> }
                                },
                            },
                            {
                                id: 'projectWorksByStage',
                                path: 'works_by_stage',
                                async lazy() {
                                    const { ProjectBodyViewWorks } = await import('components/Projects/PageBody/Rooms/ProjectBodyViewWorks.tsx')
                                    return { element: <ProjectBodyViewWorks /> }
                                },
                            },
                            {
                                id: 'projectWorksByGroup',
                                path: 'works_by_group',
                                async lazy() {
                                    const { ProjectBodyViewWorks } = await import('components/Projects/PageBody/Rooms/ProjectBodyViewWorks.tsx')
                                    return { element: <ProjectBodyViewWorks /> }
                                },
                            },

                            {
                                id: 'roomById',
                                path: ':roomId',
                                async lazy() {
                                    const { ProjectRoomById } = await import('pages/tenant/client/ProjectRoomById')
                                    return { element: <ProjectRoomById /> }
                                },
                                loader: ({ params }) => ( {
                                    id: params.id,
                                    name: 'Имя комнаты',
                                } ),
                                children: [
                                    {
                                        id: 'workId',
                                        path: ':workId',
                                        async lazy() {
                                            const { ProjectRoomWork } = await import('pages/tenant/client/ProjectRoomWork')
                                            return { element: <ProjectRoomWork /> }
                                        },
                                        loader: ({ params }) => ( {
                                            id: params.id,
                                            name: 'Имя работы',
                                        } ),
                                        children: [
                                            {
                                                id: 'materialId',
                                                path: ':materialId',
                                                async lazy() {
                                                    const { ProjectWorkMaterial } = await import('pages/tenant/client/ProjectWorkMaterial')
                                                    return { element: <ProjectWorkMaterial /> }
                                                },
                                                loader: ({ params }) => ( {
                                                    id: params.id,
                                                    name: 'Имя материала',
                                                } ),
                                                children: [
                                                    {
                                                        id: 'info',
                                                        path: 'info',
                                                        async lazy() {
                                                            const { WorkMaterialInfo } = await import('pages/tenant/client/ProjectMaterialInfo')
                                                            return { element: <WorkMaterialInfo /> }
                                                        },
                                                        loader: ({ params }) => ( {
                                                            id: params.id,
                                                            name: 'Инфо',
                                                        } ),
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        id: 'projectPlanning',
                        path: 'planning',
                        async lazy() {
                            const { PlanningPageBody } = await import('components/Projects/PageBody/Planning/PlanningPageBody.tsx')
                            return { element: <PlanningPageBody /> }
                        },
                        children: [
                            {
                                index: true,
                                element: <Navigate to={ 'calendar' } replace />,
                            },
                            {
                                id: 'projectPlanningCalendar',
                                path: 'calendar',
                                element: <Outlet />,
                                children: [
                                    {
                                        index: true,
                                        async lazy() {
                                            const { ProjectCalendarLayout } = await import('components/Projects/PageBody/Planning/PlanningParts/ProjectCalendarLayout.tsx')
                                            return { element: <ProjectCalendarLayout isEdit={ false } /> }
                                        },
                                    },
                                    {
                                        id: 'projectCalendarWorkTaskById',
                                        path: ':taskId',
                                        async lazy() {
                                            const { WorkerTaskPage } = await import('components/Worker/Task/WorkerTaskPage')
                                            return {
                                                element: <WorkerTaskPage isForemanUi />,
                                            }
                                        },
                                        loader: ({ params }) => ( {
                                            id: params.taskId,
                                            name: createElement(BreadcrumbItem((state: RootStateType) => ( {
                                                name: state.workerTaskReducer.editableFormHeader.name
                                                    || 'Test work name',
                                            } ))),
                                            path: `projects/project/${ params.projectId }/edit/planning/task/${ params.taskId }`,
                                        } ),
                                        children: [
                                            {
                                                index: true,
                                                element: <Navigate to={ 'steps' } replace />,
                                            },
                                            {
                                                id: 'projectCalendarWorkTaskSteps',
                                                path: 'steps',
                                                loader: () => ( { active: 'steps' } ),
                                                async lazy() {
                                                    const { WorkerStepsTab } = await import('components/Worker/Task/PageBody/Steps/WorkerStepsTab')
                                                    return { element: <WorkerStepsTab isForemanUi /> }
                                                },
                                                children: [
                                                    // {
                                                    //     id: 'step',
                                                    //     path: ':stepId',
                                                    //     async lazy() {
                                                    //         const { StepTab } = await import('components/Worker/Task/PageBody/Steps/StepTab')
                                                    //         return { element: <StepTab/> }
                                                    //     },
                                                    // }
                                                ],
                                            },
                                            {
                                                id: 'projectCalendarWorkTaskComments',
                                                path: 'comments',
                                                loader: () => ( { active: 'comments' } ),
                                                async lazy() {
                                                    const { CommentsTab } = await import('components/Worker/Task/PageBody/Comments/CommentsTab')
                                                    return { element: <CommentsTab isForemanUi /> }
                                                },
                                            },
                                            {
                                                id: 'projectCalendarWorkTaskInfo',
                                                path: 'info',
                                                loader: () => ( { active: 'comments' } ),
                                                async lazy() {
                                                    const { InformationTab } = await import('components/Worker/Task/PageBody/Information/InformationTab')
                                                    return { element: <InformationTab isForemanUi /> }
                                                },
                                            },
                                            {
                                                id: 'projectCalendarWorkTaskMaterials',
                                                path: 'materials',
                                                async lazy() {
                                                    const { MaterialsTab } = await import('components/Worker/Task/PageBody/Materials/MaterialsTab')
                                                    return { element: <MaterialsTab isForemanUi /> }
                                                },
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                id: 'projectPlanningWorks',
                                path: 'works',
                                element: <Outlet />,
                                children: [
                                    {
                                        index: true,
                                        async lazy() {
                                            const { ProjectPlanningWorks } = await import('components/Projects/PageBody/Planning/PlanningParts/Works/ProjectPlanningWorks.tsx')
                                            return {
                                                element: <ProjectPlanningWorks isEdit={ false } />,
                                            }
                                        },
                                    },
                                    {
                                        id: 'projectWorkTaskById',
                                        path: ':taskId',
                                        async lazy() {
                                            const { WorkerTaskPage } = await import('components/Worker/Task/WorkerTaskPage')
                                            return {
                                                element: <WorkerTaskPage isForemanUi />,
                                            }
                                        },
                                        loader: ({ params }) => ( {
                                            id: params.taskId,
                                            name: createElement(BreadcrumbItem((state: RootStateType) => ( {
                                                name: state.workerTaskReducer.editableFormHeader.name
                                                    || 'Test work name',
                                            } ))),
                                            path: `projects/project/${ params.projectId }/edit/planning/task/${ params.taskId }`,
                                        } ),
                                        children: [
                                            {
                                                index: true,
                                                element: <Navigate to={ 'steps' } replace />,
                                            },
                                            {
                                                id: 'projectPlanningWorkTaskSteps',
                                                path: 'steps',
                                                loader: () => ( { active: 'steps' } ),
                                                async lazy() {
                                                    const { WorkerStepsTab } = await import('components/Worker/Task/PageBody/Steps/WorkerStepsTab')
                                                    return { element: <WorkerStepsTab isForemanUi /> }
                                                },
                                                children: [
                                                    // {
                                                    //     id: 'step',
                                                    //     path: ':stepId',
                                                    //     async lazy() {
                                                    //         const { StepTab } = await import('components/Worker/Task/PageBody/Steps/StepTab')
                                                    //         return { element: <StepTab/> }
                                                    //     },
                                                    // }
                                                ],
                                            },
                                            {
                                                id: 'projectWorkTaskComments',
                                                path: 'comments',
                                                loader: () => ( { active: 'comments' } ),
                                                async lazy() {
                                                    const { CommentsTab } = await import('components/Worker/Task/PageBody/Comments/CommentsTab')
                                                    return { element: <CommentsTab isForemanUi /> }
                                                },
                                            },
                                            {
                                                id: 'projectWorkTaskInfo',
                                                path: 'info',
                                                loader: () => ( { active: 'comments' } ),
                                                async lazy() {
                                                    const { InformationTab } = await import('components/Worker/Task/PageBody/Information/InformationTab')
                                                    return { element: <InformationTab isForemanUi /> }
                                                },
                                            },
                                            {
                                                id: 'projectWorkTaskMaterials',
                                                path: 'materials',
                                                async lazy() {
                                                    const { MaterialsTab } = await import('components/Worker/Task/PageBody/Materials/MaterialsTab')
                                                    return { element: <MaterialsTab isForemanUi /> }
                                                },
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                id: 'projectPlanningStages',
                                path: 'stages',
                                element: <>Этапы</>,
                            },
                            {
                                id: 'projectPlanningRooms',
                                path: 'rooms',
                                element: <>Помещения</>,
                            },
                        ],
                    },
                    {
                        id: 'projectResourceStatement',
                        path: 'resourceStatement',
                        children: [
                            {
                                index: true,
                                async lazy() {
                                    const { ResourceStatementPage } = await import('components/Projects/PageBody/ResourceStatement/ResourceStatementPage.tsx')
                                    return { element: <ResourceStatementPage /> }
                                },
                            },
                            {
                                path: 'new-supply-request',
                                id: 'supplyRequestNew',
                                async lazy() {
                                    const { SupplyRequestPage } = await import('components/Supply/SupplyRequests/SupplyRequestPage.tsx')
                                    return { element: <SupplyRequestPage/> }
                                },
                                children: [
                                    {
                                        index: true,
                                        async lazy() {
                                            const { ListTab } = await import('components/Supply/SupplyRequests/PageBody/List/ListTab.tsx')
                                            return { element: <ListTab /> }
                                        },
                                    },
                                ],

                            },
                        ]
                    },
                    {
                        id: 'projectWarehouses',
                        path: 'warehouses',
                        element: <Outlet />,
                        children: [
                            {
                                index: true,
                                async lazy() {
                                    const { ProjectWarehouses } = await import('components/Projects/PageBody/Warehouses/ProjectWarehouses.tsx')
                                    return { element: <ProjectWarehouses /> }
                                },
                            },
                            {
                                id: 'projectWarehouseCreate',
                                path: 'new',
                                async lazy() {
                                    const { ProjectWarehousePage } = await import('components/Projects/PageBody/Warehouses/ProjectWarehousePage.tsx')
                                    return { element: <ProjectWarehousePage /> }
                                },
                                children: [
                                    {
                                        index: true,
                                        async lazy() {
                                            const { ProjectWarehousePageBodyEdit } = await import('components/Projects/PageBody/Warehouses/PageBody/ProjectWarehousePageBodyEdit.tsx')
                                            return {
                                                element: <ProjectWarehousePageBodyEdit />,
                                            }
                                        },
                                    },
                                ],
                            },
                            {
                                id: 'projectWarehouseById',
                                path: ':warehouseId',
                                async lazy() {
                                    const { ProjectWarehousePage } = await import('components/Projects/PageBody/Warehouses/ProjectWarehousePage.tsx')
                                    return { element: <ProjectWarehousePage /> }
                                },
                                children: [
                                    {
                                        index: true,
                                        async lazy() {
                                            const { ProjectWarehousePageBodyView } = await import('components/Projects/PageBody/Warehouses/PageBody/ProjectWarehousePageBodyView.tsx')
                                            return {
                                                element: <ProjectWarehousePageBodyView />,
                                            }
                                        },
                                    },
                                    {
                                        id: 'projectWarehouseEdit',
                                        path: 'edit',
                                        async lazy() {
                                            const { ProjectWarehousePageBodyEdit } = await import('components/Projects/PageBody/Warehouses/PageBody/ProjectWarehousePageBodyEdit.tsx')
                                            return {
                                                element: <ProjectWarehousePageBodyEdit />,
                                            }
                                        },
                                    },
                                ],
                            },
                        ],
                    },
                    
                    {
                        id: 'projectEdit',
                        path: 'edit',
                        element: <Outlet />,
                        children: [
                            {
                                index: true,
                                element: <Navigate to={ 'about' } replace />,
                            },
                            {
                                id: 'projectEditInfo',
                                path: 'about',
                                async lazy() {
                                    const { ProjectBodyEditInfo } = await import('components/Projects/PageBody/Info/ProjectBodyEditInfo')
                                    return { element: <ProjectBodyEditInfo /> }
                                },
                            },
                            {
                                id: 'projectEditReports',
                                path: 'reports',
                                children: [
                                    {
                                        id: 'projectEditReportsList',
                                        path: '',
                                        async lazy() {
                                            const { ReportsPageBodyView } = await import('components/Projects/PageBody/Reports/ReportsPageBodyView.tsx')
                                            return { element: <ReportsPageBodyView /> }
                                        },
                                    },
                                    {
                                        id: 'projectEditReportsById',
                                        path: ':reportId',
                                        async lazy() {
                                            const { GeneratedReportPage } = await import('components/Projects/PageBody/Reports/GeneratedReportPage.tsx')
                                            return { element: <GeneratedReportPage /> }
                                        },
                                        loader: ({ params }) => ( {
                                            id: params.id,
                                            name: 'Отчёт',
                                        } ),
                                    },
                                ],
                            },
                            {
                                id: 'projectEditOutlays',
                                path: 'outlays',
                                children: [
                                    {
                                        index: true,
                                        async lazy() {
                                            const { ProjectBodyViewOutlays } = await import('components/Projects/PageBody/Outlays/ProjectBodyViewOutlays')
                                            return { element: <ProjectBodyViewOutlays /> }
                                        },
                                    },
                                    {
                                        id: 'projectEditOutlayById',
                                        path: ':outlayId',
                                        async lazy() {
                                            const { OutlayPage } = await import('components/Projects/PageBody/Outlays/OutlayPage.tsx')
                                            return { element: <OutlayPage /> }
                                        },
                                        children: [
                                            {
                                                index: true,
                                                element: <Navigate to={ 'master_outlay' } replace />,
                                            },
                                            {
                                                id: 'projectEditMasterOutlay',
                                                path: 'master_outlay',
                                                async lazy() {
                                                    const { MasterOutlay } = await import('components/Projects/PageBody/Outlays/OutlayParts/MasterOutlay.tsx')
                                                    return { element: <MasterOutlay /> }
                                                },
                                            },
                                            {
                                                id: 'projectEditStagesOutlay',
                                                path: 'stages_outlay',
                                                async lazy() {
                                                    const { StagesOutlay } = await import('components/Projects/PageBody/Outlays/OutlayParts/StagesOutlay.tsx')
                                                    return { element: <StagesOutlay /> }
                                                },
                                            },
                                            {
                                                id: 'projectEditWorksOutlay',
                                                path: 'works_outlay',
                                                async lazy() {
                                                    const { WorksOutlay } = await import('components/Projects/PageBody/Outlays/OutlayParts/WorksOutlay.tsx')
                                                    return { element: <WorksOutlay /> }
                                                },
                                            },
                                            {
                                                id: 'projectEditSelfCostOutlay',
                                                path: 'selfCost_outlay',
                                                async lazy() {
                                                    const { SelfCostOutlay } = await import('components/Projects/PageBody/Outlays/OutlayParts/SelfCostOutlay.tsx')
                                                    return { element: <SelfCostOutlay /> }
                                                },
                                            },
                                            {
                                                id: 'projectEditMutualOutlay',
                                                path: 'mutual',
                                                async lazy() {
                                                    const { MutualOutlay } = await import('components/Projects/PageBody/Outlays/OutlayParts/MutualOutlay.tsx')
                                                    return { element: <MutualOutlay /> }
                                                },
                                            },
                                            {
                                                id: 'projectEditCommercialOutlay',
                                                path: 'commercial',
                                                async lazy() {
                                                    const { CommercialOutlay } = await import('components/Projects/PageBody/Outlays/OutlayParts/CommercialOutlay.tsx')
                                                    return { element: <CommercialOutlay /> }
                                                },
                                            },
                                            {
                                                id: 'projectEditWorkPrice',
                                                path: 'work_price',
                                                async lazy() {
                                                    const { WorkPriceOutlay } = await import('components/Projects/PageBody/Outlays/OutlayParts/WorkPriceOutlay.tsx')
                                                    return { element: <WorkPriceOutlay /> }
                                                },
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                id: 'projectEditDocuments',
                                element: <Outlet />,
                                path: 'documents',
                                children: [
                                    {
                                        path: '',
                                        async lazy() {
                                            const { DocumentsPage } = await import('components/Projects/PageBody/Documents/DocumentsPage.tsx')
                                            return { element: <DocumentsPage /> }
                                        },
                                    },
                                    {
                                        id: 'projectEditDocumentsPage',
                                        path: ':documentId',
                                        async lazy() {
                                            const { ProjectDocumentPage } = await import('components/Projects/PageBody/Documents/NestedPages/ProjectDocumentPage.tsx')
                                            return { element: <ProjectDocumentPage /> }
                                        },
                                        children: [
                                            {
                                                id: 'projectDocumentsEditPageById',
                                                path: '',
                                                async lazy() {
                                                    const { DocumentFolder } = await import('components/Projects/PageBody/Documents/NestedPages/DocumentFolder.tsx')
                                                    return { element: <DocumentFolder /> }
                                                }
                                            },
                                        ]


                                    },
                                ]
                            },
                            {
                                id: 'projectEditCorrectives',
                                path: 'correctives',
                                children: [
                                    {
                                        id: 'projectEditCorrectiveList',
                                        path: '',
                                        async lazy() {
                                            const { CorrectivePage } = await import('components/Projects/PageBody/Correctives/CorrectivePage.tsx')
                                            return { element: <CorrectivePage /> }
                                        },
                                    },
                                    {
                                        id: 'correctiveByIdEditPage',
                                        path: '',
                                        async lazy() {
                                            const { CorrectiveByIdEditPage } = await import('components/Projects/PageBody/Correctives/CorrectiveByIdEditPage.tsx')
                                            return { element: < CorrectiveByIdEditPage /> }
                                        },
                                        loader: ({ params }) => ( { correctiveId: params.id } ),
                                        children: [
                                            {
                                                id: 'correctiveByIdEdit',
                                                path: ':correctiveId',
                                                async lazy() {
                                                    const { PageDivisionByCorrectionStatus } = await import('components/Projects/PageBody/Correctives/CorrectivesParts/PageDivisionByCorrectionStatus.tsx')
                                                    return { element: < PageDivisionByCorrectionStatus /> }
                                                },
                                                loader: ({ params }) => ( { correctiveId: params.id } ),
                                            },
                                        ],
                                    },
                                    {
                                        id: 'projectCorrectiveWorksScopeEdit',
                                        path: 'work_scope',
                                        async lazy() {
                                            const { WorkScopeTable } = await import('components/Projects/PageBody/Correctives/NestedPage/WorkScopeTable.tsx')
                                            return { element: < WorkScopeTable /> }
                                        },
                                    },
                                
                                ],
                            },
                            {
                                id: 'projectEditJournal',
                                path: 'journal',
                                async lazy() {
                                    const { ProjectBodyViewJournals } = await import('components/Projects/PageBody/Journals/ProjectBodyViewJournals.tsx')
                                    
                                    return { element: <ProjectBodyViewJournals /> }
                                },
                                children: [
                                    {
                                        id: 'projectEditCustomerById',
                                        path: ':customerId',
                                        async lazy() {
                                            const { ProjectCustomerById } = await import('pages/tenant/client/ProjectCustomerById')
                                            return { element: <ProjectCustomerById /> }
                                        },
                                        loader: ({ params }) => ( {
                                            id: params.id,
                                            name: 'Имя заказчика',
                                        } ),
                                    },
                                ],
                            },
                            {
                                id: 'projectEditRooms',
                                path: 'rooms',
                                async lazy() {
                                    const { ProjectBodyEditRooms } = await import('components/Projects/PageBody/Rooms/ProjectBodyEditRooms')
                                    
                                    return { element: <ProjectBodyEditRooms /> }
                                },
                                children: [
                                    {
                                        id: 'projectEditRoomById',
                                        path: ':roomId',
                                        async lazy() {
                                            const { ProjectRoomById } = await import('pages/tenant/client/ProjectRoomById')
                                            return { element: <ProjectRoomById /> }
                                        },
                                        loader: ({ params }) => ( {
                                            id: params.id,
                                            name: 'Имя комнаты',
                                        } ),
                                        children: [
                                            {
                                                id: 'projectEditWorkById',
                                                path: ':workId',
                                                async lazy() {
                                                    const { ProjectRoomWork } = await import('pages/tenant/client/ProjectRoomWork')
                                                    return { element: <ProjectRoomWork /> }
                                                },
                                                loader: ({ params }) => ( {
                                                    id: params.id,
                                                    name: 'Имя работы',
                                                } ),
                                                children: [
                                                    {
                                                        id: 'projectEditWorkMaterialById',
                                                        path: ':materialId',
                                                        async lazy() {
                                                            const { ProjectWorkMaterial } = await import('pages/tenant/client/ProjectWorkMaterial')
                                                            return {
                                                                element: <ProjectWorkMaterial />,
                                                            }
                                                        },
                                                        loader: ({ params }) => ( {
                                                            id: params.id,
                                                            name: 'Имя материала',
                                                        } ),
                                                        children: [
                                                            {
                                                                id: 'projectEditWorkMaterialInfo',
                                                                path: 'info',
                                                                async lazy() {
                                                                    const { WorkMaterialInfo } = await import('pages/tenant/client/ProjectMaterialInfo')
                                                                    return {
                                                                        element:
                                                                            <WorkMaterialInfo />,
                                                                    }
                                                                },
                                                                loader: ({ params }) => ( {
                                                                    id: params.id,
                                                                    name: 'Инфо',
                                                                } ),
                                                            },
                                                        ],
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                id: 'projectEditPlanning',
                                path: 'planning',
                                async lazy() {
                                    const { PlanningPageBody } = await import('components/Projects/PageBody/Planning/PlanningPageBody.tsx')
                                    return { element: <PlanningPageBody /> }
                                },
                                children: [
                                    {
                                        index: true,
                                        element: <Navigate to={ 'calendar' } replace />,
                                    },
                                    {
                                        id: 'projectPlanningCalendarEdit',
                                        path: 'calendar',
                                        element: <Outlet />,
                                        children: [
                                            {
                                                index: true,
                                                async lazy() {
                                                    const { ProjectCalendarLayout } = await import('components/Projects/PageBody/Planning/PlanningParts/ProjectCalendarLayout.tsx')
                                                    return {
                                                        element:
                                                            <ProjectCalendarLayout isEdit={ true } />,
                                                    }
                                                },
                                            },
                                            {
                                                id: 'projectEditPlanningWorkTaskById',
                                                path: ':taskId',
                                                async lazy() {
                                                    const { WorkerTaskPage } = await import('components/Worker/Task/WorkerTaskPage')
                                                    return {
                                                        element: <WorkerTaskPage isForemanUi />,
                                                    }
                                                },
                                                loader: ({ params }) => ( {
                                                    id: params.taskId,
                                                    name: createElement(BreadcrumbItem((state: RootStateType) => ( {
                                                        name: state.workerTaskReducer.editableFormHeader.name
                                                            || 'Test work name',
                                                    } ))),
                                                    path: `projects/project/${ params.projectId }/edit/planning/task/${ params.taskId }`,
                                                } ),
                                                children: [
                                                    {
                                                        index: true,
                                                        element: <Navigate
                                                            to={ 'steps' }
                                                            replace
                                                        />,
                                                    },
                                                    {
                                                        id: 'projectEditPlanningWorkTaskSteps',
                                                        path: 'steps',
                                                        loader: () => ( { active: 'steps' } ),
                                                        async lazy() {
                                                            const { WorkerStepsTab } = await import('components/Worker/Task/PageBody/Steps/WorkerStepsTab')
                                                            return {
                                                                element: <WorkerStepsTab
                                                                    isForemanUi
                                                                />,
                                                            }
                                                        },
                                                        children: [
                                                            // {
                                                            //     id: 'step',
                                                            //     path: ':stepId',
                                                            //     async lazy() {
                                                            //         const { StepTab } = await import('components/Worker/Task/PageBody/Steps/StepTab')
                                                            //         return { element: <StepTab/> }
                                                            //     },
                                                            // }
                                                        ],
                                                    },
                                                    {
                                                        id: 'projectEditPlanningWorkTaskComments',
                                                        path: 'comments',
                                                        loader: () => ( { active: 'comments' } ),
                                                        async lazy() {
                                                            const { CommentsTab } = await import('components/Worker/Task/PageBody/Comments/CommentsTab')
                                                            return {
                                                                element: <CommentsTab
                                                                    isForemanUi
                                                                />,
                                                            }
                                                        },
                                                    },
                                                    {
                                                        id: 'projectEditPlanningWorkTaskInfo',
                                                        path: 'info',
                                                        loader: () => ( { active: 'comments' } ),
                                                        async lazy() {
                                                            const { InformationTab } = await import('components/Worker/Task/PageBody/Information/InformationTab')
                                                            return {
                                                                element: <InformationTab
                                                                    isForemanUi
                                                                />,
                                                            }
                                                        },
                                                    },
                                                    {
                                                        id: 'projectEditPlanningWorkTaskMaterials',
                                                        path: 'materials',
                                                        async lazy() {
                                                            const { MaterialsTab } = await import('components/Worker/Task/PageBody/Materials/MaterialsTab')
                                                            return {
                                                                element: <MaterialsTab
                                                                    isForemanUi
                                                                />,
                                                            }
                                                        },
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                    {
                                        id: 'projectEditPlanningWorks',
                                        path: 'works',
                                        element: <Outlet />,
                                        children: [
                                            {
                                                index: true,
                                                async lazy() {
                                                    const { ProjectPlanningWorks } = await import('components/Projects/PageBody/Planning/PlanningParts/Works/ProjectPlanningWorks.tsx')
                                                    return {
                                                        element:
                                                            <ProjectPlanningWorks isEdit={ true } />,
                                                    }
                                                },
                                            },
                                            {
                                                id: 'projectEditWorkTaskById',
                                                path: ':taskId',
                                                async lazy() {
                                                    const { WorkerTaskPage } = await import('components/Worker/Task/WorkerTaskPage')
                                                    return {
                                                        element: <WorkerTaskPage isForemanUi />,
                                                    }
                                                },
                                                loader: ({ params }) => ( {
                                                    id: params.taskId,
                                                    name: createElement(BreadcrumbItem((state: RootStateType) => ( {
                                                        name: state.workerTaskReducer.editableFormHeader.name
                                                            || 'Test work name',
                                                    } ))),
                                                    path: `projects/project/${ params.projectId }/edit/planning/task/${ params.taskId }`,
                                                } ),
                                                children: [
                                                    {
                                                        index: true,
                                                        element: <Navigate
                                                            to={ 'steps' }
                                                            replace
                                                        />,
                                                    },
                                                    {
                                                        id: 'projectEditWorkTaskSteps',
                                                        path: 'steps',
                                                        loader: () => ( { active: 'steps' } ),
                                                        async lazy() {
                                                            const { WorkerStepsTab } = await import('components/Worker/Task/PageBody/Steps/WorkerStepsTab')
                                                            return {
                                                                element: <WorkerStepsTab
                                                                    isForemanUi
                                                                />,
                                                            }
                                                        },
                                                        children: [
                                                            // {
                                                            //     id: 'step',
                                                            //     path: ':stepId',
                                                            //     async lazy() {
                                                            //         const { StepTab } = await import('components/Worker/Task/PageBody/Steps/StepTab')
                                                            //         return { element: <StepTab/> }
                                                            //     },
                                                            // }
                                                        ],
                                                    },
                                                    {
                                                        id: 'projectEditWorkTaskComments',
                                                        path: 'comments',
                                                        loader: () => ( { active: 'comments' } ),
                                                        async lazy() {
                                                            const { CommentsTab } = await import('components/Worker/Task/PageBody/Comments/CommentsTab')
                                                            return {
                                                                element: <CommentsTab
                                                                    isForemanUi
                                                                />,
                                                            }
                                                        },
                                                    },
                                                    {
                                                        id: 'projectEditWorkTaskInfo',
                                                        path: 'info',
                                                        loader: () => ( { active: 'comments' } ),
                                                        async lazy() {
                                                            const { InformationTab } = await import('components/Worker/Task/PageBody/Information/InformationTab')
                                                            return {
                                                                element: <InformationTab
                                                                    isForemanUi
                                                                />,
                                                            }
                                                        },
                                                    },
                                                    {
                                                        id: 'projectEditWorkTaskMaterials',
                                                        path: 'materials',
                                                        async lazy() {
                                                            const { MaterialsTab } = await import('components/Worker/Task/PageBody/Materials/MaterialsTab')
                                                            return {
                                                                element: <MaterialsTab
                                                                    isForemanUi
                                                                />,
                                                            }
                                                        },
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                    {
                                        id: 'projectPlanningStagesEdit',
                                        path: 'stages',
                                        element: <>Этапы</>,
                                    },
                                    {
                                        id: 'projectPlanningRoomsEdit',
                                        path: 'rooms',
                                        element: <>Помещения</>,
                                    },
                                ],
                            },
                            {
                                id: 'projectEditResourceStatement',
                                path: 'resourceStatement',
                                async lazy() {
                                    const { ResourceStatementPage } = await import('components/Projects/PageBody/ResourceStatement/ResourceStatementPage.tsx')
                                    return { element: <ResourceStatementPage /> }
                                },
                            },
                            {
                                id: 'projectEditWarehouses',
                                path: 'warehouses',
                                element: <Outlet />,
                                children: [
                                    {
                                        index: true,
                                        async lazy() {
                                            const { ProjectWarehouses } = await import('components/Projects/PageBody/Warehouses/ProjectWarehouses.tsx')
                                            return { element: <ProjectWarehouses /> }
                                        },
                                    },
                                    {
                                        id: 'projectEditWarehouseCreate',
                                        path: 'new',
                                        async lazy() {
                                            const { ProjectWarehousePage } = await import('components/Projects/PageBody/Warehouses/ProjectWarehousePage.tsx')
                                            return { element: <ProjectWarehousePage /> }
                                        },
                                        children: [
                                            {
                                                index: true,
                                                async lazy() {
                                                    const { ProjectWarehousePageBodyEdit } = await import('components/Projects/PageBody/Warehouses/PageBody/ProjectWarehousePageBodyEdit.tsx')
                                                    return {
                                                        element:
                                                            <ProjectWarehousePageBodyEdit />,
                                                    }
                                                },
                                            },
                                        ],
                                    },
                                    {
                                        id: 'projectEditWarehouseById',
                                        path: ':warehouseId',
                                        async lazy() {
                                            const { ProjectWarehousePage } = await import('components/Projects/PageBody/Warehouses/ProjectWarehousePage.tsx')
                                            return { element: <ProjectWarehousePage /> }
                                        },
                                        children: [
                                            {
                                                index: true,
                                                async lazy() {
                                                    const { ProjectWarehousePageBodyView } = await import('components/Projects/PageBody/Warehouses/PageBody/ProjectWarehousePageBodyView.tsx')
                                                    return {
                                                        element:
                                                            <ProjectWarehousePageBodyView />,
                                                    }
                                                },
                                            },
                                            {
                                                id: 'projectEditWarehouseEdit',
                                                path: 'edit',
                                                async lazy() {
                                                    const { ProjectWarehousePageBodyEdit } = await import('components/Projects/PageBody/Warehouses/PageBody/ProjectWarehousePageBodyEdit.tsx')
                                                    return {
                                                        element:
                                                            <ProjectWarehousePageBodyEdit />,
                                                    }
                                                },
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        id: 'templates',
        path: 'templates',
        async lazy() {
            // const { Templates } = await import('pages/tenant/client/Templates')
            return Promise.resolve({ element: <UnderConstruction /> })
        },
        loader: ({ params }) => ( {
            id: params.id,
            name: 'Шаблоны проектов',
            path: 'projects/templates',
        } ),
        children: [
            {
                id: 'templateList',
                path: '',
                async lazy() {
                    // const { TemplateList } = await import('pages/tenant/client/TemplateList')
                    return Promise.resolve({ element: <UnderConstruction /> })
                },
            },
            {
                id: 'templateById',
                path: ':templateId',
                async lazy() {
                    const { TemplateById } = await import('pages/tenant/client/TemplateById')
                    return { element: <TemplateById /> }
                },
                loader: ({ params }) => ( {
                    id: params.id,
                    name: 'Имя шаблона',
                } ),
            },
        ],
    },
    {
        id: 'outlays',
        path: 'outlays',
        element: <Outlet />,
        loader: ({ params }) => ( {
            id: params.id,
            name: 'Сметы',
            path: 'projects/outlays',
        } ),
        children: [
            {
                index: true,
                async lazy() {
                    const { OutlayTable } = await import('components/Projects/NestedPages/Outlays/OutlayTable.tsx')
                    return { element: <OutlayTable /> }
                },
            },
            {
                id: 'outlayById',
                path: ':outlayId',
                async lazy() {
                    const { OutlayPage } = await import('components/Projects/PageBody/Outlays/OutlayPage.tsx')
                    return { element: <OutlayPage /> }
                },
                children: [
                    {
                        index: true,
                        element: <Navigate to={ 'master_outlay' } replace />,
                    },
                    {
                        id: 'masterOutlay',
                        path: 'master_outlay',
                        async lazy() {
                            const { MasterOutlay } = await import('components/Projects/PageBody/Outlays/OutlayParts/MasterOutlay.tsx')
                            return { element: <MasterOutlay /> }
                        },
                    },
                    {
                        id: 'stagesOutlay',
                        path: 'stages_outlay',
                        async lazy() {
                            const { StagesOutlay } = await import('components/Projects/PageBody/Outlays/OutlayParts/StagesOutlay.tsx')
                            return { element: <StagesOutlay /> }
                        },
                    },
                    {
                        id: 'worksOutlay',
                        path: 'works_outlay',
                        async lazy() {
                            const { WorksOutlay } = await import('components/Projects/PageBody/Outlays/OutlayParts/WorksOutlay.tsx')
                            return { element: <WorksOutlay /> }
                        },
                    },
                    {
                        id: 'selfCostOutlay',
                        path: 'selfCost_outlay',
                        async lazy() {
                            const { SelfCostOutlay } = await import('components/Projects/PageBody/Outlays/OutlayParts/SelfCostOutlay.tsx')
                            return { element: <SelfCostOutlay /> }
                        },
                    },
                    {
                        id: 'mutualOutlay',
                        path: 'mutual',
                        async lazy() {
                            const { MutualOutlay } = await import('components/Projects/PageBody/Outlays/OutlayParts/MutualOutlay.tsx')
                            return { element: <MutualOutlay /> }
                        },
                    },
                    {
                        id: 'commercialOutlay',
                        path: 'commercial',
                        async lazy() {
                            const { CommercialOutlay } = await import('components/Projects/PageBody/Outlays/OutlayParts/CommercialOutlay.tsx')
                            return { element: <CommercialOutlay /> }
                        },
                    },
                ],
            },
        ],
    },
    {
        id: 'journal',
        path: 'journal',
        element: <JournalTable />,
        loader: ({ params }) => ( {
            id: params.id,
            name: 'Журнал',
        } ),
    },
    {
        id: 'correctives',
        path: 'correctives',
        loader: ({ params }) => ( {
            id: params.id,
            name: 'Корректировки',
            path: 'projects/correctives',
        } ),
        children: [
            {
                id: 'correctivesTable',
                path: '',
                async lazy() {
                    const { CorrectivesTable } = await import('components/Projects/PageBody/Correctives/CorrectivesTable.tsx')
                    return { element: <CorrectivesTable /> }
                },
            },
        ],
    },
    {
        id: 'reports',
        path: 'reports',
        loader: ({ params }) => ( {
            id: params.id,
            name: 'Отчёты',
            path: 'projects/reports',
        } ),
        children: [
            {
                id: 'reportsList',
                path: '',
                async lazy() {
                    const { ReportsPage } = await import('components/Projects/PageBody/Reports/ReportsPage.tsx')
                    return { element: <ReportsPage /> }
                },
            },
            {
                id: 'reportById',
                path: ':reportId',
                async lazy() {
                    const { GeneratedReportPage } = await import('components/Projects/PageBody/Reports/GeneratedReportPage.tsx')
                    return { element: <GeneratedReportPage /> }
                },
                loader: ({ params }) => ( {
                    reportId: params.id,
                    name: 'Название отчёта',
                } ),
            },
        ],
    },
]
