import { Text } from '@mantine/core'
import React from 'react'
import AlertCircle from 'assets/icons/fi_alert-circle.svg'
import CheckCircle from 'assets/icons/fi_check-circle.svg'
import PlayCircle from 'assets/icons/fi_play-circle.svg'
import ZeroPoint from 'assets/icons/fi_zero-point.svg'
import { WorkWithMoneyShow } from '../Calendar/Types/WorkCardProps'
import styles from 'components/Worker/Dashboard.module.css'
import classNames from 'classnames';

type WorkProgressProps = {
    work: WorkWithMoneyShow | null
    progress: number
}

export const WorkProgress: React.FC<WorkProgressProps> = ({ work, progress }) => {

    const formattedProgress = `${Math.round(progress + Number.EPSILON).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}%`

    if( work?.status === 'PROBLEM' || work?.status === 'CONTROL') return <Text color='red.6' className={classNames(styles.progress)}>
        <AlertCircle/>
        {formattedProgress}
    </Text>

    if(progress === 100 && work?.status === 'DONE') return <Text color='success.5' className={classNames(styles.progress)}>
        <CheckCircle/>
        {formattedProgress}
    </Text>

    if(progress === 0) return <Text color='natural.6' className={classNames(styles.progress)}>
        <ZeroPoint/>
        {formattedProgress}
    </Text>

    if(progress > 0) return <Text color='info.5' className={classNames(styles.progress)}>
        <PlayCircle/>
        {formattedProgress}
    </Text>
}