import { defaultInitialState } from 'utils/defaultInitialState.ts.ts';
import { createAppSlice } from 'utils/createAppSlice.ts';
import { OrdersSliceInitialState } from 'components/Supply/Orders/Orders.types';

const ordersSliceInitialState: OrdersSliceInitialState = {
    ...defaultInitialState,
    sortParamsArray: [
        {
            param: 'order.createdAt',
            rule: 'desc',
        },
    ],
}


export const ordersSlice = createAppSlice<OrdersSliceInitialState>(
    'orders',
    ordersSliceInitialState
)

export default ordersSlice.reducer
