import { BrandsSliceInitialState } from 'components/Resources/Materials/NestedPages/Brands/Brands.types'
import { brandFormHeaderInitialState, brandFormBodyInitialState } from 'components/Resources/Materials/NestedPages/Brands/Brands.utils'
import { createAppSlice } from 'utils/createAppSlice'
import { defaultInitialState } from 'utils/defaultInitialState.ts'


const brandsInitialState: BrandsSliceInitialState = {
    ...defaultInitialState,
    editableFormHeader: brandFormHeaderInitialState,
    editableFormBody: brandFormBodyInitialState,
}

export const brandsSlice = createAppSlice<BrandsSliceInitialState>('brands',  brandsInitialState)

export default brandsSlice.reducer
