import {
    UserOrganizationFormBody,
    UserOrganizationFormHeader,
    UserOrganizationsFormGlobal
} from './UserOrganizations.types'
import { FindOrganizationResponseDto, UpdateOrganizationRequestDto } from 'store/api/userOrganization'


// *
// DATA CONVERT FUNCS
// *

export const convertUserOrganizationDtoToFormBody = (userOrganization: FindOrganizationResponseDto): UserOrganizationFormBody => {
    console.log('userOrganization: ', userOrganization)
    return {
        tenantId: '', // temp as no fields yet except for name
    }
}
export const convertUserOrganizationDtoToFormHeader = (userOrganization: FindOrganizationResponseDto): UserOrganizationFormHeader => ({
    name: userOrganization?.name || userOrganizationFormHeaderInitialState.name,
})
export const convertUserOrganizationFormToRequestDto = (
    data: Partial<UserOrganizationFormBody & UserOrganizationFormHeader>
): UpdateOrganizationRequestDto => ({
    name: data?.name || userOrganizationFormHeaderInitialState.name,
    tenantId: data?.tenantId || 'dNkiFh0oyEk', // temp (not needed actually)
})


// *
// STORE
// *

export const userOrganizationsFormGlobalDefaultValues: UserOrganizationsFormGlobal = {
    name: '',
}
export const userOrganizationFormHeaderInitialState: UserOrganizationFormHeader = {
    name: '',
}
export const userOrganizationFormBodyInitialState: UserOrganizationFormBody = {
    tenantId: '',
}


// *
// INPUT PROCESSING FUNCS
// *
