import { multiselectFieldDefault } from 'utils/inputs'
import { ProjectPlanningWorkFormBody, ProjectPlanningWorkFormHeader, ProjectPlanningWorksFormGlobal, ProjectPlanningWorksMultiselectKey } from 'components/Projects/PageBody/Planning/PlanningParts/Works/ProjectPlanningWorks.types.ts'
import { InputChangeArg, InputSubmitArg } from 'types/inputs.ts'


// *
// STORE
// *

export const projectPlanningWorksFormGlobalDefaultValues: ProjectPlanningWorksFormGlobal = {
    name: '',
    status: multiselectFieldDefault,
}

export const projectPlanningWorkFormHeaderInitialState: ProjectPlanningWorkFormHeader = {
    name: '',
}

export const projectPlanningWorksFormBodyInitialState: ProjectPlanningWorkFormBody = {
    status: multiselectFieldDefault,
}


// *
// INPUT PROCESSING FUNCS
// *

export const onProjectPlanningWorkMultiselectChange = ({ formData, key, setFormValue }: InputChangeArg<ProjectPlanningWorksMultiselectKey, ProjectPlanningWorksFormGlobal>) => (label: string) => {
    if (formData[key]?.name !== label) {
        setFormValue(`${key}.name`, label)
    }
}
export const onProjectPlanningWorkMultiselectSubmit = ({ key, setFormValue }: InputSubmitArg<ProjectPlanningWorksMultiselectKey, ProjectPlanningWorksFormGlobal>) => (valueIds: Array<string>) => {
    setFormValue(`${key}.ids`, valueIds)
}