import { createAppSlice } from 'utils/createAppSlice'
import { defaultInitialState } from 'utils/defaultInitialState.ts'
import { FormBasedSliceInitialState } from 'types/redux.ts'

export class OutlaysSliceInitialState extends FormBasedSliceInitialState {
    editableFormHeader = {}
    editableFormBody = {}
}

const outlaysInitialState: OutlaysSliceInitialState = {
    ...defaultInitialState,
    editableFormBody: {},
    editableFormHeader: {},
}

export const outlaysSlice = createAppSlice<OutlaysSliceInitialState>('outlays', outlaysInitialState)

export default outlaysSlice.reducer
