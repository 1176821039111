import React, { useState } from 'react'
import { TimePointProps } from './WorkingHoursTimetable.types'
import {
    SECONDS_PER_DAY,
    MIN_OUTER_DIAPASON_IN_MINUTES,
    MIN_INNER_DIAPASON_IN_MINUTES,
    getPointStyles,
    getLeftCoordinate,
    getFormattedTime,
    getRoundedTime,
} from './WorkingHoursTimetable.utils'
import styles from 'components/Projects/PageBody/Info/WorkingHoursTimetable/WorkingHoursTimetable.module.css'
import { Box } from '@mantine/core'

export const TimePoint: React.FC<TimePointProps> = ({
    index,
    intervalIndex,
    position,
    parentWidth,
    timeTable,
    onStop: onParentStop,
    onChange: onParentChange,
}) => {
    const [posX, setX] = useState(0)
    const [down, setDown] = useState<0 | 1>(0)
    const interval = timeTable[intervalIndex]

    const onStop = () => {
        if (down) {
            setDown(0)
            onParentStop()
        }
    }

    const onMove = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (parentWidth) {
            const dX = posX - event.clientX
            const progressInPercent = dX / parentWidth
            const newTime = interval[index] - SECONDS_PER_DAY * progressInPercent

            if (intervalIndex === 0 && index === 1 || intervalIndex === 1 && index === 0) {
                const differenceBetweenOuterPoints = Math.abs(intervalIndex === 0 ? timeTable[1][0] - newTime : newTime - timeTable[0][1])
                if (differenceBetweenOuterPoints < MIN_OUTER_DIAPASON_IN_MINUTES * 60) return
            }
            const differenceBetweenInnerPoints = Math.abs(newTime - (index === 1 ? interval[0] : interval[1]))
            if (differenceBetweenInnerPoints < MIN_INNER_DIAPASON_IN_MINUTES * 60) return

            setX(event.clientX)
            if (!(newTime < 0) && !(newTime > SECONDS_PER_DAY)) {
                onParentChange(Math.round(newTime))
            }
        }
    }

    return (
        <Box
            sx={getPointStyles(`${getLeftCoordinate(interval[index])}%`, down)}
            onMouseDown={(event) => {
                setDown(1)
                setX(event.clientX)
            }}
            onMouseUp={onStop}
            onMouseLeave={onStop}
            onMouseMove={(event) => down && onMove(event)}
        >
            <Box className={`${styles.edgeLabel} ${styles.unfilled} `} sx={
                down ? {
                    height: '100%',
                    padding: '52px 40px',
                    margin: '-36px -40px',
                } : {
                    height: '100%',
                    padding: '52px 0px',
                    margin: '-36px 0px',
                }}>
                <Box
                    className={`${styles.labelValue} ${position === 'top' ? styles.topLabel : styles.bottomLabel
                    }`}
                >
                    {getFormattedTime(getRoundedTime(interval[index]))}
                </Box>
            </Box>
        </Box>
    )
}
