import { jobsSlice } from 'store/slices/jobsSlice'
import { materialsSlice } from 'store/slices/materialsSlice'
import { tasksSlice } from 'store/slices/tasksSlice'
import { objectsSlice } from 'store/slices/objectsSlice'
import { roomsSlice } from 'store/slices/roomsSlice'
import { brandsSlice } from 'store/slices/brandsSlice'
import { storesSlice } from 'store/slices/storesSlice'
import { categoriesSlice } from 'store/slices/categoriesSlice'
import { stagesSlice } from 'store/slices/stagesSlice'
import { viewsSlice } from 'store/slices/viewsSlice'
import { packagesSlice } from 'store/slices/packagesSlice'
import { jobTypesSlice } from 'store/slices/jobTypesSlice'
import { stepsSlice } from 'store/slices/stepsSlice'
import { userOrganizationsSlice } from 'store/slices/userOrganizationsSlice'
import { userRolesSlice } from 'store/slices/userRolesSlice'
import { usersSlice } from 'store/slices/usersSlice'
import { warehousesSlice } from 'store/slices/warehousesSlice'
import { warehouseInventoriesSlice } from 'store/slices/warehouseInventoriesSlice'
import { clientsSlice } from 'store/slices/clientsSlice'
import { jobBodyViewMaterialsSlice } from 'store/slices/jobBodyViewMaterialsSlice'
import { jobBodyAddMaterialsSlice } from 'store/slices/jobBodyAddMaterialsSlice'
import { taskBodyViewMaterialsSlice } from 'store/slices/taskBodyViewMaterialsSlice'
import { taskBodyAddMaterialsSlice } from 'store/slices/taskBodyAddMaterialsSlice'
import { specializationsSlice } from 'store/slices/specializationsSlice'
import { projectsSlice } from 'store/slices/projectsSlice'
import { jobBodyFormulaSlice } from 'store/slices/jobBodyFormulaSlice'
import { projectBodyAddWorksSlice } from 'store/slices/projectBodyAddWorksSlice'
import { projectBodyAddRoomsSlice } from 'store/slices/projectBodyAddRoomsSlice'
import { workerTaskSlice } from 'store/slices/workerTaskSlice'
import { correctivesTableSlice } from 'store/slices/correctivesTable.ts'
import { projectPlanningWorksSlice } from 'store/slices/projectPlanningWorksSlice'

import { FilterParam, FormBasedSliceName, GlobalFiltersSearchArg, LocalFiltersSearchArg, SortParam } from 'types/inputs'
import { useAppDispatch } from './redux'
import { getFilterParamString } from 'utils/inputs'
import { useLazyDownloadQuery, useUploadMutation } from 'store/api/files'
import { outlaysSlice } from 'store/slices/outlaysSlice'
import { FindFileResponseDto } from 'store/api/materials'
import { journalSlice } from 'store/slices/journalSlice.ts'
import { minStroyMaterialsSlice } from 'store/slices/minStroyMaterialsSlice'
import { minstroyMaterialsKSRSlice } from 'store/slices/minstroyMaterialsKSRSlice.ts'
import { projectReportsSlice } from 'store/slices/projectReports'
import { minStroyJobsSlice } from 'store/slices/minstroyJobsSlice'
import { replaceCorrectionMaterialSlice } from 'store/slices/replaceCorrectionMaterial.ts'
import { projectWarehousesSlice } from 'store/slices/projectWarehousesSlice.ts'
import { projectWarehouseInventoriesSlice } from 'store/slices/projectWarehouseInventoriesSlice.ts'
import { materialUsageSlice } from 'store/slices/materialUsageSlice.ts';
import { formulasSlice } from 'store/slices/formulasSlice.ts';
import { groupsSlice } from 'store/slices/groupsSlice.ts';
import { jobsGroupsSlice } from 'store/slices/jobsGroups.ts';
import { groupsAddJobsSlice } from 'store/slices/groupsAddJobs.ts';
import { projectBodyAddGroupsSlice } from 'store/slices/projectBodyAddGroupsSlice.ts';
import { inventoryMaterialsSlice } from 'store/slices/inventoryMaterials.ts';
import { inventoriesTableSlice } from 'store/slices/inventoriesTableSlice.ts';
import { inventoryAddMaterialsSlice } from 'store/slices/inventoryAddMaterialsSlice.ts';
import { documentMovementsSlice } from 'store/slices/documentMovementsSlice'
import { documentMovementMaterialsSlice } from 'store/slices/documentMovementMaterialsSlice.ts';
import { warehouseInventoriesTableSlice } from 'store/slices/warehouseInventoriesTable.ts';
import { ordersSlice } from 'store/slices/ordersSlice.ts';
import { orderMaterialsSlice } from 'store/slices/orderMaterialsSlice.ts';
import { resourceStatementSlice } from 'store/slices/resourceStatement.ts';
import { supplyRequestsSlice } from 'store/slices/supplyRequestsSlice.ts';
import { supplyRequestMaterialsSlice } from 'store/slices/supplyRequestMaterialsSlice';
import { replaceSupplyRequestMaterialSlice } from 'store/slices/replaceSupplyRequestMaterialSlice.ts';

const defineSlice = (type: FormBasedSliceName) => {
    switch (type) {
        case 'materials':
            return materialsSlice
        case 'jobs':
            return jobsSlice
        case 'jobBodyAddMaterials':
            return jobBodyAddMaterialsSlice
        case 'jobBodyViewMaterials':
            return jobBodyViewMaterialsSlice
        case 'journal':
            return journalSlice
        case 'taskBodyAddMaterials':
            return taskBodyAddMaterialsSlice
        case 'taskBodyViewMaterials':
            return taskBodyViewMaterialsSlice
        case 'tasks':
            return tasksSlice
        case 'objects':
            return objectsSlice
        case 'rooms':
            return roomsSlice
        case 'brands':
            return brandsSlice
        case 'stores':
            return storesSlice
        case 'categories':
            return categoriesSlice
        case 'minStroyMaterials':
            return minStroyMaterialsSlice
        case 'minstroyMaterialsKSR':
            return minstroyMaterialsKSRSlice
        case 'minstroyJobs':
            return minStroyJobsSlice
        case 'stages':
            return stagesSlice
        case 'views':
            return viewsSlice
        case 'packages':
            return packagesSlice
        case 'jobTypes':
            return jobTypesSlice
        case 'steps':
            return stepsSlice
        case 'userOrganizations':
            return userOrganizationsSlice
        case 'userRoles':
            return userRolesSlice
        case 'users':
            return usersSlice
        case 'warehouses':
            return warehousesSlice
        case 'warehouseInventories':
            return warehouseInventoriesSlice
        case 'projectWarehouses':
            return projectWarehousesSlice
        case 'projectWarehouseInventories':
            return projectWarehouseInventoriesSlice
        case 'clients':
            return clientsSlice
        case 'specializations':
            return specializationsSlice
        case 'projects':
            return projectsSlice
        case 'jobBodyFormula':
            return jobBodyFormulaSlice
        case 'projectBodyAddWorks':
            return projectBodyAddWorksSlice
        case 'projectBodyAddGroups':
            return projectBodyAddGroupsSlice
        case 'projectBodyAddRooms':
            return projectBodyAddRoomsSlice
        case 'outlays':
            return outlaysSlice
        case 'workerTask':
            return workerTaskSlice
        case 'projectReports':
            return projectReportsSlice
        case 'correctivesTable':
            return correctivesTableSlice
        case 'replaceCorrectionMaterial':
            return replaceCorrectionMaterialSlice
        case 'materialUsage':
            return materialUsageSlice
        case 'formulas':
            return formulasSlice
        case 'projectPlanningWorks':
            return projectPlanningWorksSlice
        case 'groups':
            return groupsSlice
        case 'jobsGroups':
            return jobsGroupsSlice
        case 'groupsAddJobs':
            return groupsAddJobsSlice
        case 'inventoryMaterials':
            return inventoryMaterialsSlice
        case 'inventoriesTable':
            return inventoriesTableSlice
        case 'inventoryAddMaterials':
            return inventoryAddMaterialsSlice
        case 'documentMovements':
            return documentMovementsSlice
        case 'documentMovementMaterials':
            return documentMovementMaterialsSlice
        case 'warehouseInventoriesTable':
            return warehouseInventoriesTableSlice
        case 'orders':
            return ordersSlice
        case 'orderMaterials':
            return orderMaterialsSlice
        case 'resourceStatement':
            return resourceStatementSlice
        case 'supplyRequests':
            return supplyRequestsSlice
        case 'supplyRequestMaterials':
            return supplyRequestMaterialsSlice
        case 'replaceSupplyRequestMaterial':
            return replaceSupplyRequestMaterialSlice
        default:
            throw new Error('Unprocessed filters manager type!')
    }
}

export const useInputsSearch = (sliceName: FormBasedSliceName) => {
    const dispatch = useAppDispatch()
    const {
        setGlobalFilterParam,
        deleteGlobalFilterParam,
    } = defineSlice(sliceName).actions
    
    const initInputSearch = ({
        callback,
        formError,
        // formValue,
        // globalSearchParam,
        // localSearchParam,
        globalFilter,
        localFilter,
    }: GlobalFiltersSearchArg) => {
        if (globalFilter?.value === '') return dispatch(deleteGlobalFilterParam(globalFilter.param))
        if (( localFilter as unknown as FilterParam )?.value === '' && callback) return callback({})
        if (!globalFilter) {
            if (!formError || ( localFilter as unknown as FilterParam )?.value
                || ( localFilter as unknown as FilterParam[] )?.length
                && ( localFilter as unknown as FilterParam[] )?.findIndex(el => Boolean(el.value))
                > -1) {
                //dispatch(setGlobalFilterParam(globalFilter))
                
                if (localFilter && callback) {
                    const filter = Array.isArray(localFilter)
                        ? localFilter.filter(f => Boolean(f.value)).map(
                            getFilterParamString).join('&filter=')
                        : getFilterParamString(localFilter)
                    callback({ filter })
                }
            }
            return
        }
        if (typeof globalFilter.value !== 'boolean' && !globalFilter.value) {
            return dispatch(
                deleteGlobalFilterParam(globalFilter.param))
        }
        if (!formError || ( localFilter as unknown as FilterParam )?.value
            || ( localFilter as unknown as FilterParam[] )?.length
            && ( localFilter as unknown as FilterParam[] )?.findIndex(el => Boolean(el.value))
            > -1) {
            dispatch(setGlobalFilterParam(globalFilter))
            
            if (localFilter && callback) {
                const filter = Array.isArray(localFilter)
                    ? localFilter.filter(f => Boolean(f.value))
                        .map(getFilterParamString)
                        .join('&filter=')
                    : getFilterParamString(localFilter)
                callback({ filter })
            }
        }
        
        // if (formValue && !formError) {
        //     const localFilterData: FilterParam = {
        //         param: localSearchParam || globalSearchParam,
        //         rule: 'like',
        //         value: formValue,
        //     }
        
        //     localSearchParam && callback && callback({ filter: getFilterParamString(localFilterData) })
        //     dispatch(setGlobalFilterParam({ ...localFilterData, param: globalSearchParam }))
        // } else {
        //     dispatch(deleteGlobalFilterParam(globalSearchParam))
        // }
    }
    
    return [initInputSearch]
}

export const useInnerInputSearch = () => {
    const innerInputSearch = ({
        callback,
        formError,
        formValue,
        localSearchParam,
    }: LocalFiltersSearchArg) => {
        if (formValue && !formError) {
            const localFilterData: FilterParam = {
                param: localSearchParam,
                rule: 'like',
                value: formValue,
            }
            callback({ filter: getFilterParamString(localFilterData) })
        }
    }
    
    return [innerInputSearch]
}

export const useSortProcessing = (type: FormBasedSliceName) => {
    const dispatch = useAppDispatch()
    const { storeSortParams } = defineSlice(type).actions
    
    const processSort = (sortParams: SortParam[]) => {
        dispatch(storeSortParams(sortParams))
    }
    
    return [processSort]
}

export const useFilesUpload = () => {
    const [uploadFile] = useUploadMutation()
    
    const uploadNewImages = async(urls: string[]) => {
        const result: FindFileResponseDto[] = []
        
        for (const item of urls) {
            const urlSplit = item.split('/')
            const name = urlSplit[urlSplit.length - 1]
            const blob = await fetch(item).then(response => response.blob())
            
            await uploadFile({
                name,
                file: blob,
            }).unwrap().then(data => {
                const file: FindFileResponseDto = {
                    id: data[0].id,
                    name: data[0].name,
                    size: data[0].size,
                    type: data[0].type,
                    bucket: data[0].bucket,
                }
                result.push(file)
            }).catch(console.log)
        }
        
        return result
    }
    
    return [uploadNewImages]
}


export const useFilesUploadWithName = () => {
    const [uploadFile] = useUploadMutation()
    
    const uploadNewImagesWithName = async(files: {
        url: string,
        name: string,
        id?: string | undefined
    } []) => {
        const result: FindFileResponseDto[] = []
        
        for (const item of files) {
            const url = item.url
            const name = item.name
            const blob = await fetch(url).then(response => response.blob())
            
            await uploadFile({
                name,
                file: blob,
            }).unwrap().then(data => {
                const file: FindFileResponseDto = {
                    id: data[0].id,
                    name: data[0].name,
                    size: data[0].size,
                    type: data[0].type,
                    bucket: data[0].bucket,
                }
                result.push(file)
            }).catch(console.log)
        }
        
        return result
    }
    
    return [uploadNewImagesWithName]
}

export const useFilesUploadNew = () => {
    const [uploadFile] = useUploadMutation()
    
    const upload = async(files: File[]) => {
        const result: FindFileResponseDto[] = []
        
        for (const item of files) {
            const objectURL: string = URL.createObjectURL(item)
            const name = item.name
            const blob = await fetch(objectURL).then(response => response.blob())
            
            await uploadFile({
                name,
                file: blob,
            }).unwrap().then(data => {
                const file: FindFileResponseDto = {
                    id: data[0].id,
                    name: data[0].name,
                    size: data[0].size,
                    type: data[0].type,
                    bucket: data[0].bucket,
                }
                result.push(file)
            }).catch(console.log)
        }
        
        return result
    }
    
    return [upload]
}

export const useFileDownload = ({
    createFileDefinition,
    preDownload,
    postDownload,
}: {
    createFileDefinition: (format?: 'PDF' | 'WORD') => any,
    preDownload: () => void,
    postDownload: () => void
}) => {
    const [downloadFile] = useLazyDownloadQuery()

    
    const download = async(format?: 'PDF' | 'WORD') => {
        preDownload()
        
        let file
        if (format) {
            const { data } = await createFileDefinition(format).unwrap()
            file = data
        } else {
            const { data } = await createFileDefinition().unwrap()
            file = data
        }
        
        
        const { data } = await downloadFile(file.id)
        
        if (!data) {
            return
        }
        
        const link = document.createElement('a')
        
        link.download = file.name
        link.href = data
        link.click()
        
        postDownload()
        
        URL.revokeObjectURL(data)
    }
    
    return { download }
}
