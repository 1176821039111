import { Box, Button, Flex, Grid } from '@mantine/core'
import { TimeTable } from 'components/Projects/PageBody/Info/WorkingHoursTimetable/WorkingHoursTimetable.types.ts';
import { WorkingHoursTimetable } from 'components/Projects/PageBody/Info/WorkingHoursTimetable/WorkingHoursTimetable.tsx'
import React, { useEffect, useState } from 'react'
import { IconPlus } from '@tabler/icons-react';
import { getWeekDayReducedNameByIndex } from 'utils/date.ts';
import { getFormattedTime, getRoundedTime, transformWorkingTimeFromMinutesToSeconds } from 'components/Projects/PageBody/Info/WorkingHoursTimetable/WorkingHoursTimetable.utils.ts'
import { MEDIA_MAX } from 'styles/constants.ts'
import { useMediaQuery } from '@mantine/hooks'


type WorkTimetable = Array<TimeTable | null>

type UserWorkScheduleProps = {
    initialData: WorkTimetable
    updateWorkTimetable?: (workTimetable: WorkTimetable) => void
    isOnlyView?: boolean
}

export const UserWorkSchedule: React.FC<UserWorkScheduleProps> = ({ initialData, updateWorkTimetable, isOnlyView }) => {
    
    const [data, setData] = useState(initialData)
    
    const isLgScreen = useMediaQuery(MEDIA_MAX.LG)
    
    const updateParentFormOnTimetableChange = (index: number) => (timetable: TimeTable) => {
        if (updateWorkTimetable) {
            const workScheduleCopy = [...initialData]
            workScheduleCopy.splice(index, 1, timetable)
            
            updateWorkTimetable(workScheduleCopy)
        }
    }
    
    const handleAddTimetable = (index: number) => () => {
        if (updateWorkTimetable) {
            const standardTimetable = transformWorkingTimeFromMinutesToSeconds([[540, 780], [900, 1080]])
            const workScheduleCopy = [...initialData]
            workScheduleCopy.splice(index, 1, standardTimetable)
            
            updateWorkTimetable(workScheduleCopy)
        }
    }
    
    const handleClearTimetable = (index: number) => () => {
        if (updateWorkTimetable) {
            const workScheduleCopy = [...initialData]
            workScheduleCopy.splice(index, 1, null)
            
            updateWorkTimetable(workScheduleCopy)
        }
    }
    
    const arrayOfWeekDayIndexes = [...new Array(7).keys()]
    
    const groupedWorkTimetableObject: { [key: number]: WorkTimetable } = {}
    
    arrayOfWeekDayIndexes.forEach(index => {
        if (index > 0) {
            const lastResultIndex = Object.keys(groupedWorkTimetableObject).length - 1
            
            const areTimetablesEqual = JSON.stringify(initialData[index]) === JSON.stringify(groupedWorkTimetableObject[lastResultIndex][0])
            
            if (areTimetablesEqual) {
                groupedWorkTimetableObject[lastResultIndex] = [...groupedWorkTimetableObject[lastResultIndex], initialData[index]]
            } else {
                groupedWorkTimetableObject[lastResultIndex + 1] = [initialData[index]]
            }
        } else {
            groupedWorkTimetableObject[0] = [initialData[index]]
        }
    })
    
    useEffect(() => {
        if (!data.length && !initialData.length) {
            setData(() => [null, null, null, null, null, null, null])
        }
    }, [data, initialData])
    
    const isLoading = !data.length
    
    return (
        isLoading ?
            <></> :
            isOnlyView ?
                <Box>
                    <Flex direction={'column'} gap={4}>
                        <Grid gutter={4} columns={7}>
                            {Object.keys(groupedWorkTimetableObject).map((key) =>
                                <Grid.Col
                                    span={groupedWorkTimetableObject[+key].length}
                                    key={+key}
                                >
                                    <Flex
                                        justify={'space-around'}
                                        align={'center'}
                                        sx={(theme) => ({
                                            width: '100% !important',
                                            height: '100%',
                                            borderRadius: '4px',
                                            paddingTop: '6px',
                                            paddingBottom: '6px',
                                            backgroundColor: theme.colors.custom[0],
                                            fontSize: '14px',
                                            lineHeight: '20px',
                                            fontWeight: 500,
                                            color: theme.colors.natural[8],
                                        })}
                                    >
                                        { groupedWorkTimetableObject[+key].map((_, i) => {
                                            let prevDaysAmount = i
                                            let currentGroupedTimetableIndex = 0
                                        
                                            while (currentGroupedTimetableIndex < +key) {
                                                prevDaysAmount += groupedWorkTimetableObject[currentGroupedTimetableIndex]?.length
                                                currentGroupedTimetableIndex += 1
                                            }
                                        
                                            return (
                                                <Box key={i}>
                                                    {getWeekDayReducedNameByIndex(prevDaysAmount).toUpperCase()}
                                                </Box>
                                            )
                                        }
                                        ) }
                                    </Flex>
                                </Grid.Col>
                            )}
                        </Grid>
                
                        <Grid gutter={4} columns={7}>
                            {Object.keys(groupedWorkTimetableObject).map((key) =>
                                <Grid.Col
                                    span={groupedWorkTimetableObject[+key].length}
                                    key={+key}
                                >
                                    <Flex
                                        justify={'center'}
                                        align={'center'}
                                        sx={() => ({
                                            width: '100% !important',
                                            height: '100%',
                                        })}
                                    >
                                        {
                                            groupedWorkTimetableObject[+key][0] ?
                                                <Flex
                                                    direction={'column'}
                                                    justify={'center'}
                                                    align={'center'}
                                                    gap={4}
                                                    sx={(theme) => ({
                                                        width: '100% !important',
                                                        height: '100%',
                                                        borderRadius: '4px',
                                                        paddingTop: '4px',
                                                        paddingBottom: '4px',
                                                        backgroundColor: theme.fn.rgba(theme.colors.success[5], 0.2),
                                                        fontSize: '12px',
                                                        lineHeight: '16px',
                                                        fontWeight: 500,
                                                        color: theme.colors.natural[9],
                                                    })}
                                                >
                                                    <Box>
                                                        { groupedWorkTimetableObject[+key][0]?.[0]?.map(point => getFormattedTime(getRoundedTime(point))).join(' - ') || '' }
                                                    </Box>
                                        
                                                    <Box>
                                                        { groupedWorkTimetableObject[+key][0]?.[1]?.map(point => getFormattedTime(getRoundedTime(point))).join(' - ') || '' }
                                                    </Box>
                                                </Flex> :
                                                <Flex
                                                    justify={'center'}
                                                    align={'center'}
                                                    sx={(theme) => ({
                                                        width: '100% !important',
                                                        height: '100%',
                                                        borderRadius: '4px',
                                                        backgroundColor: theme.fn.rgba(theme.colors.primary[5], 0.2),
                                                        fontSize: '12px',
                                                        lineHeight: '16px',
                                                        fontWeight: 500,
                                                        color: theme.colors.natural[9],
                                                    })}
                                                >
                                                    Вых
                                                </Flex>
                                        }
                                    </Flex>
                                </Grid.Col>
                            )}
                        </Grid>
                    </Flex>
                </Box> :
        
                <Flex
                    direction={'column'}
                    justify={'center'}
                    gap={12}
                    sx={{
                        fontSize: isLgScreen ? '10px' : '16px',
                    }}
                >
                    { arrayOfWeekDayIndexes.map(index =>
                        <Flex
                            key={index}
                            justify={'space-between'}
                            align={'center'}
                            gap={isLgScreen ? 8 : 32}
                            h={'76px'}
                        >
                            <Box>{getWeekDayReducedNameByIndex(index).toUpperCase()}</Box>
                    
                            { initialData[index] ?
                                <WorkingHoursTimetable
                                    initialData={initialData[index]}
                                    updateParentForm={updateParentFormOnTimetableChange(index)}
                                /> :
                                <Flex
                                    justify={'center'}
                                    align={'center'}
                                    h={isLgScreen ? '48px' : '64px'}
                                    w={'100%'}
                                >
                                    <Flex
                                        justify={'center'}
                                        align={'center'}
                                        sx={(theme) => ({
                                            width: '100% !important',
                                            height: isLgScreen ? '28px' : '32px',
                                            borderRadius: '4px',
                                            paddingTop: '20px',
                                            paddingBottom: '20px',
                                            backgroundColor: theme.colors.warning[1],
                                            fontSize: '14px',
                                            lineHeight: '20px',
                                            fontWeight: 400,
                                            color: theme.colors.natural[9],
                                        })}
                                    >
                                        Выходной
                                    </Flex>
                                </Flex>
                            }
                    
                            { initialData[index] ?
                                <Button
                                    size={'xs'}
                                    p={10}
                                    h={'40px'}
                                    // h={'100%'}
                                    // p={isLgScreen ? '14px' : 'inherit'}
                                    variant={'secondary'}
                                    onClick={handleClearTimetable(index)}
                                >
                                    <IconPlus style={{ transform: 'rotate(45deg)' }} />
                                </Button>
                                :
                                <Button
                                    size={'xs'}
                                    p={10}
                                    h={'40px'}
                                    // h={'100%'}
                                    // p={isLgScreen ? '14px' : 'inherit'}
                                    variant={'primary'}
                                    onClick={handleAddTimetable(index)}
                                >
                                    <IconPlus />
                                </Button>}
                
                        </Flex>) }
                </Flex>
    )
}
