import { defaultInitialState } from 'utils/defaultInitialState.ts'
import { createAppSlice } from 'utils/createAppSlice'
import { ProjectPlanningWorksSliceInitialState } from 'components/Projects/PageBody/Planning/PlanningParts/Works/ProjectPlanningWorks.types.ts'
import { projectPlanningWorkFormHeaderInitialState, projectPlanningWorksFormBodyInitialState } from 'components/Projects/PageBody/Planning/PlanningParts/Works/ProjectPlanningWorks.utils.ts'

const projectPlanningWorksInitialState: ProjectPlanningWorksSliceInitialState = {
    ...defaultInitialState,
    editableFormHeader: projectPlanningWorkFormHeaderInitialState,
    editableFormBody: projectPlanningWorksFormBodyInitialState,
}

export const projectPlanningWorksSlice = createAppSlice<ProjectPlanningWorksSliceInitialState>('projectPlanningWorks', projectPlanningWorksInitialState)

export default projectPlanningWorksSlice.reducer
