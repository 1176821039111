import { Box, Flex } from '@mantine/core'
import { IconChevronLeft } from '@tabler/icons-react'
import { useNavigate, To } from 'react-router-dom'
import styles from './BackLinkComponent.module.css'
import { ReactNode } from 'react'

type BackLinkComponentProps = {
    children: ReactNode,
    route: To
}

export const BackLinkComponent = ({ children, route }: BackLinkComponentProps) => {

    const navigate = useNavigate()

    return <Box className={ styles.backLinkWrapper }>
        <Flex align={ 'start' }>
            <Flex onClick={ () => navigate(route) }>
                <IconChevronLeft className={ styles.backLinkIcon }/>
            </Flex>
            { children }
        </Flex>
    </Box>

}