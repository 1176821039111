import {
    InventoriesAutocompleteKey,
    InventoriesTableFormBody, InventoriesTableFormGlobal,
    InventoriesTableFormHeader
} from 'components/Supply/Inventories/inventoriesTable.types.ts';
import { autocompleteChangeArg, autocompleteFieldDefault, autocompleteSubmitArg } from 'utils/inputs.tsx';
import { AutocompleteSubmitData, InputChangeArg, InputSubmitArg } from 'types/inputs.ts';
import {
    InventoryMaterialsAutocompleteKey,
    InventoryMaterialsFormGlobal
} from 'components/Supply/Inventories/PageBody/InventoryMaterilas.types.ts';
export const inventoriesTableFormHeaderInitialState: InventoriesTableFormHeader = {
    name: ''
}

export const inventoriesTableFormBodyInitialState: InventoriesTableFormBody = {
    status: autocompleteFieldDefault,
    organisation: autocompleteFieldDefault,
    warehouse: autocompleteFieldDefault
}

export const inventoriesTableFormGlobalDefaultValues: InventoriesTableFormGlobal = {
    name: '',
    status: autocompleteFieldDefault,
    organization: autocompleteFieldDefault,
    warehouse: autocompleteFieldDefault
}

export const onInventoryGlobalAutocompleteSubmit = ({
    key,
    setFormValue,
}: InputSubmitArg<InventoriesAutocompleteKey, InventoriesTableFormGlobal>) => (data: AutocompleteSubmitData) => {
    setFormValue(key, autocompleteSubmitArg(data))
}
export const onInventoryGlobalAutocompleteChange = ({
    formData,
    key,
    setFormValue,
}: InputChangeArg<InventoriesAutocompleteKey, InventoriesTableFormGlobal>) => (label: string) => {
    if (formData[key]?.name !== label) {
        setFormValue(key, autocompleteChangeArg(label))
    }
}
