import React, { useEffect, useState } from 'react';
import { Box, Loader, Text } from '@mantine/core';
import { useParams } from 'react-router-dom';
import { MobileTopAccordion } from 'components/Worker/Work/MobileTopAccordion.tsx';
import { BackLinkComponent } from 'components/Worker/Task/Components/BackLinkComponent.tsx';
import { FindProjectTaskResponseDto, useLazyProjectTasksTreeFindByJobIdQuery } from 'store/api/projectTasks.tsx';
import { EmptyCalendarDate } from 'components/Worker/Calendar/Nested/EmptyCalendarDate.tsx';
import { WorkTaskTree } from 'components/Worker/Work/WorkTaskTree.tsx';
import dayjs from 'utils/dayjs.ts';
import { getWeekdayName } from 'components/Worker/Dashboard.utils.ts';


export const WorkPageLayout: React.FC<{ variant: 'overdue' | 'calendar' }> = ({ variant }) => {

    const { workId } = useParams()

    const  [findTaskApiCall, { isLoading: isTaskLoading }]  = useLazyProjectTasksTreeFindByJobIdQuery()
    const [tasksTree, setTasksTree] = useState<FindProjectTaskResponseDto[][] | undefined>(undefined)

    useEffect(() => {
        if (!workId) return
        findTaskApiCall(workId)
            .unwrap()
            .then(({ data }) => setTasksTree(data))

    }, [findTaskApiCall, workId]);


    const workDate =  JSON.parse(String(localStorage.getItem('workDate')))

    return isTaskLoading && !tasksTree ?
        <Loader /> :
        <>
            <BackLinkComponent route={variant === 'overdue' ? '/overdue' : '/'}>
                <Text c={'#FFFFFF'} fw={500} style={{ fontSize: '16px' }}>{`${getWeekdayName(workDate)}, ${dayjs(workDate).format('DD MMM')}`}</Text>
            </BackLinkComponent>
            { tasksTree && <MobileTopAccordion tasksTree={tasksTree} />}
            <Box h={80} />
            {tasksTree && tasksTree.length ?
                <WorkTaskTree tasksTree={tasksTree} /> :
                <EmptyCalendarDate string={'Нет задач'}/>}
        </>
}
