import { createSlice, Draft } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { FindWorkResponseDto } from 'store/api/projectTasks.tsx';

type ActiveWorkInitialState = { storeActiveWork: FindWorkResponseDto | null }

const initialState: ActiveWorkInitialState = {
    storeActiveWork: null
}

export const activeWorkSlice = createSlice({
    name: 'activeWork',
    initialState,
    reducers: {
        setActiveWorkToStore: (state: Draft<ActiveWorkInitialState>, action: PayloadAction<FindWorkResponseDto>) => {
            state.storeActiveWork = action.payload
        } }
})

export default activeWorkSlice.reducer
