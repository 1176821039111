import { defaultInitialState } from 'utils/defaultInitialState.ts.ts';
import { createFormBasedSliceReducers } from 'utils/redux.ts';
import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import {
    SupplyRequestChangedMaterial, SupplyRequestMaterialForEdit,
    SupplyRequestMaterialListForEdit,
    SupplyRequestMaterialsSliceInitialState
} from 'components/Supply/SupplyRequests/PageBody/List/SupplyRequestListEdit.types.ts';
import {
    supplyRequestEditFormBodyInitialState
} from 'components/Supply/SupplyRequests/PageBody/List/SupplyRequestListEdit.utils.ts';
import {
    FindSupplyRequestMaterialListResponseDto,
} from 'store/api/supplyRequestMaterial.tsx';
import { FindSupplyRequestStatusResponseDto } from 'store/api/supplyRequestStatus.tsx';
import { FindSupplyRequestResponseDto } from 'store/api/supplyRequest.tsx';

const supplyRequestMaterialsSliceInitialState: SupplyRequestMaterialsSliceInitialState = {
    ...defaultInitialState,
    editableFormBody: supplyRequestEditFormBodyInitialState,
    materialsSort: '',
    materialsFilter: '',
    materialsForEdit: [],
    originalMaterialsForEdit: [],
    supplyRequestStatuses:  undefined,
    supplyRequestData: undefined,
    selectedMaterial: undefined,
    changedMaterials: []
}

const reducers = createFormBasedSliceReducers<any>()

export const supplyRequestMaterialsSlice = createSlice({
    name: 'supplyRequestMaterials',
    initialState: supplyRequestMaterialsSliceInitialState,
    reducers: {
        ...reducers,
        setMaterialsFilter: (state: Draft<SupplyRequestMaterialsSliceInitialState>, action: PayloadAction<string>) => {
            state.materialsFilter = action.payload
        },
        setMaterialsSort: (state: Draft<SupplyRequestMaterialsSliceInitialState>, action: PayloadAction<string>) => {
            state.materialsSort = action.payload
        },
        setMaterialsForEdit: (state: Draft<SupplyRequestMaterialsSliceInitialState>, action: PayloadAction<SupplyRequestMaterialListForEdit[]>) => {
            state.materialsForEdit = action.payload
        },
        setOriginalMaterialsForEdit: (state: Draft<SupplyRequestMaterialsSliceInitialState>, action: PayloadAction<FindSupplyRequestMaterialListResponseDto[]>) => {
            state.originalMaterialsForEdit = action.payload
        },

        setSupplyRequestStatuses: (state: Draft<SupplyRequestMaterialsSliceInitialState>, action: PayloadAction<FindSupplyRequestStatusResponseDto[]>) => {
            state.supplyRequestStatuses = action.payload
        },
        setSupplyRequestData:  (state: Draft<SupplyRequestMaterialsSliceInitialState>, action: PayloadAction<FindSupplyRequestResponseDto>) => {
            state.supplyRequestData = action.payload
        },
        setSelectedMaterial:  (state: Draft<SupplyRequestMaterialsSliceInitialState>, action: PayloadAction<SupplyRequestMaterialForEdit | undefined>) => {
            state.selectedMaterial = action.payload
        },
        setChangedMaterials: (state: Draft<SupplyRequestMaterialsSliceInitialState>, action: PayloadAction<SupplyRequestChangedMaterial[]>) => {
            state.changedMaterials = action.payload
        },
    }
}
)


export default supplyRequestMaterialsSlice.reducer