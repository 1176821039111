import { createSlice, Draft } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { FindOutlayResponseDto } from 'store/api/outlays.tsx';

type OutlayInitialState = { outlay: FindOutlayResponseDto | null }

const initialState: OutlayInitialState = {
    outlay: null
}

export const selectedOutlaySlice = createSlice({
    name: 'selectedOutlaySlice',
    initialState,
    reducers: {
        setOutlayToStore: (state: Draft<OutlayInitialState>, action: PayloadAction<FindOutlayResponseDto>) => {
            state.outlay = action.payload
        } }
})

export default selectedOutlaySlice.reducer
