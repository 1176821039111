import { UserAccount } from 'components/Worker/Profile/Account/UserAccount.tsx';
import { useParams } from 'react-router-dom';
import {
    FindUserResponseDto,
    useLazyUsersFindOneQuery,
    UsersFindOneApiResponse
} from 'store/api/user.tsx';
import { useEffect, useState } from 'react';
import { Box, Loader } from '@mantine/core';
import { LoginResponseDto } from 'store/api/authentication.tsx';

export const UserProfileLayout = () => {

    const { userId } = useParams()

    const [
        findOne,
        {
            data: findOneResponse,
            //  isSuccess: isFindSuccess,
            isLoading: isFindLoading,
            /// isFetching: isFindFetching,
        },
    ] = useLazyUsersFindOneQuery()

    useEffect(() => {
        if (!userId) return
        findOne(userId)

    }, []);

    const [user, setUser] = useState<LoginResponseDto | null>(null)

    useEffect(() => {
        if (!findOneResponse?.data) return
        const user = convertUser(findOneResponse)
        setUser(user)

    }, [findOneResponse]);

    const convertUser = (findOneResponse: UsersFindOneApiResponse ): LoginResponseDto => {
        const userdata: FindUserResponseDto = findOneResponse.data
        return { ...userdata, phone: userdata.phone ? userdata.phone :'', job: userdata.job ? userdata.job : '', createdAt: userdata.createdAt, updatedAt: userdata.updatedAt, organizations: userdata.organizations.length ? userdata.organizations : [], permissions: [], type: userdata.type || 'INDIVIDUAL' }
    }


    return isFindLoading || !user  ? <Loader /> : <Box mt={20}>
        <UserAccount user={ user } />
    </Box>
}