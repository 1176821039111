import { ViewFormBody, ViewFormHeader, ViewsFormGlobal } from './Views.types'
import { FindJobViewResponseDto, UpdateJobViewRequestDto } from 'store/api/jobViews'


// *
// DATA CONVERT FUNCS
// *

export const convertViewDtoToFormBody = (view: FindJobViewResponseDto): ViewFormBody => ({
    description: view?.description || viewFormBodyInitialState.description,
})
export const convertViewDtoToFormHeader = (view: FindJobViewResponseDto): ViewFormHeader => ({
    name: view?.name || viewFormHeaderInitialState.name,
})
export const convertViewFormToRequestDto = (data: Partial<ViewFormBody & ViewFormHeader>): UpdateJobViewRequestDto => ({
    name: data?.name || viewFormHeaderInitialState.name,
    description: data?.description || viewFormBodyInitialState.description,
})


// *
// STORE
// *

export const viewsFormGlobalDefaultValues: ViewsFormGlobal = {
    name: '',
    description: '',
}
export const viewFormHeaderInitialState: ViewFormHeader = {
    name: '',
}
export const viewFormBodyInitialState: ViewFormBody = {
    description: '',
}


// *
// INPUT PROCESSING FUNCS
// *
