import { ReactNode, useEffect, useMemo } from 'react'
import { useAppSelector } from 'hooks/redux.ts'
import { notifications } from '@mantine/notifications'
import { IconCircleCheck, IconCircleX, IconInfoCircle } from '@tabler/icons-react'
import { notificationsSlice } from 'store/slices/notificationsSlice.ts'
import { useDispatch } from 'react-redux'
import { NotificationReportsStatuses } from 'components/AbstractComponents/Notifications/Notifications.type.ts';

type Props = {
    children: ReactNode
}
export const NotificationsProvider = ({ children }: Props) => {

    const dispatch = useDispatch()

    const { notifyError, clearNotifications } = notificationsSlice.actions

    useEffect(() => {
        dispatch(clearNotifications())
    }, [])

    useEffect(() => {
        if (window.EventSource) {
            if (window.SharedWorker) {
                const worker = new SharedWorker('/workers/worker.js', 'notification-worker')

                worker.addEventListener('error', (event) => {
                    console.log('event error')
                    console.error(event)
                })

                worker.port.onmessageerror = () => {
                    console.log('onmessageerror')
                    console.error('Unable to deserialize message')
                }

                worker.port.onmessage = (e) => {
                    dispatch(notifyError(e.data))
                    /////// dispatch(notifyError(e.data))
                }

                worker.port.postMessage({
                    type: 'start',
                })

                return () => worker.port.postMessage({
                    type: 'close',
                })
            }
        }




    }, [])

    const receivedNotifications = useAppSelector((state) => state.notificationsReducer.receivedNotifications)

    // const receivedNotifications: FindNotificationResponseDto = {
    //     id: '123',
    //     createdAt: '10.06.2004 10:24:23' ,
    //     value: {
    //         text: 'Hello',
    //         link: 'http://localhost:',
    //         url: 'http://localhost:',
    //         name: 'Hello',
    //         address: 'Hello',
    //
    //         type:'CHANGE_STATUS',
    //         //     | 'PROBLEM'
    //         // | 'PROBLEM_SOLVED'
    //         // | 'REPORT_NOT_ACCEPTED'
    //         // | 'REPORT_ACCEPTED'
    //         // | 'REPORT_WAIT_CONTROL'
    //         // | 'NEW_COMMENT'
    //         // | 'CHANGE_STATUS';
    //     },
    //     isRead: true
    // }
    const apiError = useAppSelector((state) => state.notificationsReducer.apiError)
    //const apiSuccessNotification = useAppSelector((state) => state.notificationsReducer.apiSuccessNotification)

    const notificationReportsStatuses: NotificationReportsStatuses =  useMemo(() => ({
        REPORT_ACCEPTED: {
            color: '#118F3F',
            icon: <IconCircleCheck  style={{ fontSize: 20 }} color='#118F3F'/>,
            text: 'Отчёт принят'
        },
        REPORT_WAIT_CONTROL: {
            color: '#3656be',
            icon: <IconInfoCircle  style={{ fontSize: 20 }} color='#3656be'/>,
            text: 'Отчёт ожидает контроля'
        },
        REPORT_NOT_ACCEPTED: {
            color: '#ec9827',
            icon: <IconCircleX  style={{ fontSize: 20 }}  color='#ec9827'/>,
            text: 'Отчёт не принят'
        },
        NEW_COMMENT: {
            color: '#2c313d',
            icon: <IconInfoCircle  style={{ fontSize: 20 }} color='#2c313d'/>,
            text: 'Новый комментарий'

        },
        PROBLEM: {
            color: '#d44332',
            icon: <IconInfoCircle  style={{ fontSize: 20 }}  color='#d44332' />,
            text: 'Инцидент в задаче'

        },
        PROBLEM_SOLVED: {
            color: '#2c313d',
            icon: <IconInfoCircle  style={{ fontSize: 20 }} color='#2c313d'/>,
            text: 'Инцидент решён'

        },
        CHANGE_STATUS: {
            color: '#3656be',
            icon: <IconInfoCircle  style={{ fontSize: 20 }} color='#3656be'/>,
            text: 'Изменён статус проекта'
        },
    }), [])

    useEffect(function (): () => any {
        if (receivedNotifications) {
            notifications.show({
                withBorder: true,
                title:  notificationReportsStatuses[receivedNotifications.value.type].text,
                message:receivedNotifications.value.text,
                color: notificationReportsStatuses[receivedNotifications.value.type].color,
                icon: notificationReportsStatuses[receivedNotifications.value.type].icon,
                radius: 'xs',
                autoClose: 5000,
                styles: (theme) => ({
                    root: {
                        height: 90,
                        borderRadius: 8,
                        border: '1px solid #e0e3e7',
                        borderLeftColor: `${notificationReportsStatuses[receivedNotifications.value.type].color} !important`,
                        borderLeftWidth: '8px !important',
                        alignItems: 'flex-start',
                        paddingBottom: '16px',
                        paddingTop: '16px',
                    },
                    description:{
                        color: '#2c313d'
                    },
                    icon: {
                        backgroundColor:'white',
                        marginRight: '5px',
                        width: 20,
                        height: 20
                    },
                    title: { color: notificationReportsStatuses[receivedNotifications.value.type].color },
                    closeButton: {
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        '&:hover': { backgroundColor: theme.colors.natural[1] },
                    },
                }),
            })
        }

        return () => dispatch(clearNotifications())
    }, [receivedNotifications])


    useEffect(function () {
        if (apiError) {
            const message = apiError.message.join(', ')
            const color = String(apiError.status)[0] == '4' || String(apiError.status)[0] == '5' ? 'red' : 'info'
            notifications.show({
                title:  String(apiError.status)[0] == '4' ? 'Ошибка' : 'Новое уведомление',
                message: message,
                color,
                radius: 'xs',
                icon: String(apiError.status)[0] == '4' ? <IconInfoCircle style={{ fontSize: 20 }} color={color}/> : <IconInfoCircle  style={{ fontSize: 20 }}  color={color}/>,
                styles: (theme) => ({
                    root: {
                        height: 'fit-content',
                        minHeight: 90,
                        borderRadius: 8,
                        border: '1px solid #e0e3e7',
                        borderLeftColor: `${color} !important`,
                        borderLeftWidth: '8px !important',
                        alignItems: 'flex-start',
                        paddingBottom: '16px',
                        paddingTop: '16px',
                    },
                    description:{
                        color: '#2c313d'
                    },
                    icon: {
                        backgroundColor:'white',
                        marginRight: '5px',
                        width: 20,
                        height: 20
                    },
                    title: { color },
                    closeButton: {
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        '&:hover': { backgroundColor: theme.colors.natural[1] },
                    },
                }),
                autoClose: 3000,
            })
        }

        return () => {
            notifications.cleanQueue()
            notifications.clean()
        }
    }, [apiError])


    return children

}
