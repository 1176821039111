import { ProjectWorksSliceInitialState } from 'components/Projects/PageBody/Rooms/WorkParts/ProjectWorks.types'
import { JobsSliceInitialState } from 'components/Resources/Jobs/Jobs.types'
import { jobFormHeaderInitialState, jobFormBodyInitialState } from 'components/Resources/Jobs/Jobs.utils'
import { createAppSlice } from 'utils/createAppSlice'
import { defaultInitialState } from 'utils/defaultInitialState.ts'

const projectWorksSliceInitialState: ProjectWorksSliceInitialState = {
    ...defaultInitialState,
    editableFormHeader: jobFormHeaderInitialState,
    editableFormBody: jobFormBodyInitialState,
}

export const projectBodyAddWorksSlice = createAppSlice<JobsSliceInitialState>('projectBodyAddWorks', projectWorksSliceInitialState)

export default projectBodyAddWorksSlice.reducer
