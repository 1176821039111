import { autocompleteChangeArg, autocompleteFieldDefault, autocompleteSubmitArg } from 'utils/inputs'
import { AutocompleteSubmitData, InputChangeArg, InputSubmitArg } from 'types/inputs'
import {
    MinstroyMaterialsKSRAutocompleteKey, MinstroyMaterialsKSRFormBody,
    MinstroyMaterialsKSRFormGlobal, MinstroyMaterialsKSRFormHeader
} from 'components/Resources/Materials/NestedPages/MinstroyMaterialsKSRMaterials/MinstroyMaterialsKSR.types.ts'

export const MinstroyMaterialsKSRFormGlobalDefaultValues: MinstroyMaterialsKSRFormGlobal = {
    text: '',
    category: autocompleteFieldDefault
}

export const onMinstroyMaterialsKSRAutocompleteSubmit = ({ key, setFormValue }: InputSubmitArg<MinstroyMaterialsKSRAutocompleteKey, MinstroyMaterialsKSRFormGlobal>) => (data: AutocompleteSubmitData) => {
    setFormValue(key, autocompleteSubmitArg(data))
}

export const onMinstroyMaterialsKSRFormGlobalAutocompleteChange = ({ formData, key, setFormValue }: InputChangeArg<MinstroyMaterialsKSRAutocompleteKey, MinstroyMaterialsKSRFormGlobal>) => (label: string) => {
    if (formData[key]?.name!==label) {
        setFormValue(key, autocompleteChangeArg(label))
    }
}

export const minstroyMaterialsKSRFormHeaderInitialState: MinstroyMaterialsKSRFormHeader = {
    text: '',
}

export const minstroyMaterialsKSRFormBodyInitialState: MinstroyMaterialsKSRFormBody = {
    category: autocompleteFieldDefault,
}
