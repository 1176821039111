import { Box } from '@mantine/core'
import React, { DetailedHTMLProps, HTMLAttributes } from 'react'

export const BoxHeading: React.FC<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>> = (props) => <Box style={props.style} sx={(theme) => ({
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.white,
    margin: '-20px -30px 0 -40px;',
    padding: '20px 40px 0 40px',
})}>
    {props.children}
</Box>
