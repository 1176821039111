import React from 'react'
import styles from 'components/Worker/Dashboard.module.css'
import { Badge, Box, Flex, Text } from '@mantine/core'
import classNames from 'classnames'
import { WorkCardProps } from 'components/Worker/Calendar/Types/WorkCardProps.ts'
import { WorkProgress } from 'components/Worker/Work/WorkProgress.tsx'
import { IconCurrencyRubel } from '@tabler/icons-react';

export const WorkCardDrawer: React.FC<WorkCardProps> = ({ date, work, progress }: WorkCardProps) =>
    <Box className={classNames(styles.drawerWork)}>
        <Flex direction={'row'} justify={'space-between'} align={'center'}>

            <Box className={ styles.workTime }>
                { date }
            </Box>

            <Flex justify="flex-end" align="center" gap={8}>
                <WorkProgress work={work} progress={progress ?? 0}/>

                { work?.isShowMoney &&
                    <Badge className={classNames(styles.drawerMoney)} sx={(theme) => ({ background:theme.fn.rgba(theme.colors.success[5], 0.1) })}>
                        <Text className={classNames(styles.drawerMoneyText)} color='success.5'>
                            {work.money ? (Math.round(work.money * 100 + Number.EPSILON)/100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : 0}
                            <IconCurrencyRubel size={16}/>
                        </Text>
                    </Badge>
                }
            </Flex>

        </Flex>

        <Box className={ styles.workName }>
            { work?.name }
        </Box>

    </Box>
