import { autocompleteChangeArg, autocompleteFieldDefault, autocompleteSubmitArg, multiselectFieldDefault } from 'utils/inputs'
import {
    UserFormBody,
    UserFormHeader,
    UsersAutocompleteKey,
    UsersFormGlobal,
    UsersMultiselectFilterParamKey, UserTabs, UserTabsPath
} from './Users.types'
import { AutocompleteSubmitData, InputChangeArg, InputSubmitArg } from 'types/inputs'
import { FindUserResponseDto, UpdateUserRequestDto } from 'store/api/user'
import { transformWorkingTimeFromMinutesToSeconds } from 'components/Projects/PageBody/Info/WorkingHoursTimetable/WorkingHoursTimetable.utils.ts'

// *
// TABS FUNCS
// *

export const userTabs: UserTabs = {
    about: 'Данные пользователя',
    scheduler: 'Календарь',
    money: 'Финансы',

}

export const getUserActiveTab = (pathname: string): UserTabsPath =>
    pathname.includes('/about') ? 'about' :
        pathname.includes('/scheduler') ? 'scheduler' :
            pathname.includes('/money') ? 'money' :
                'about'

// *
// DATA CONVERT FUNCS
// *

export const userType: {
    [key: string]: string
} = {
    STAFF: 'Штатный',
    INDIVIDUAL: 'ИП',
    CASH: 'Самозанятый',

}
export const userFormHeaderInitialState: UserFormHeader = {
    name: '',
}
export const userFormBodyInitialState: UserFormBody = {
    email: '',
    password: '',
    repeatPassword: '',
    phone: '',
    job: '',
    department: autocompleteFieldDefault,
    type: autocompleteFieldDefault,
    types: [],
    organizations: multiselectFieldDefault,
    specializations: multiselectFieldDefault,
    roles: multiselectFieldDefault,
    groups: multiselectFieldDefault,
    avatarUrl: '',
    avatar: {
        id: '',
        name: '',
        size: 0,
        type: '',
        bucket: {
            id: '',
            name: '',
            path: '',
        },
    },
    
    workingDays: [],
    deleted: false,
    createdAt: '',
    updatedAt: '',
}

export const convertUserDtoToFormBody = (user: FindUserResponseDto): UserFormBody => ({
    email: user.email || userFormBodyInitialState.email,
    password: userFormBodyInitialState.password,
    repeatPassword: userFormBodyInitialState.repeatPassword,
    workingDays: user.workingDays || userFormBodyInitialState.workingDays,
    phone: user.phone || userFormBodyInitialState.phone,
    job: user.job || userFormBodyInitialState.job,
    department: user?.department && {
        name: user.department.name,
        data: { id: user.department.id, name: user.department.name },
    } || userFormBodyInitialState.department,
    createdAt: user?.createdAt || userFormBodyInitialState.createdAt,
    updatedAt: user?.updatedAt || userFormBodyInitialState.updatedAt,
    createdUser: user.createdUser,
    updatedUser: user.updatedUser,
    type: user?.type ? 
        { name: userType[user?.type], data: { id: user?.type, name: userType[user?.type] } } : 
        userFormBodyInitialState.type,
    specializations: { // temp
        name: '',
        ids: user?.specializations?.map(r => r.id) || [],
        alreadySelected: user?.specializations?.map(s => ({ label: s.name, value: s.id })) || [],
    },
    organizations: { // temp
        name: '',
        ids: user?.organizations?.map(r => r.id) || [],
        alreadySelected: user?.organizations?.map(s => ({ label: s.name, value: s.id })) || [],
    },
    roles: { // temp
        name: '',
        ids: user?.roles?.map(r => r.id) || [],
        alreadySelected: user?.roles?.map(s => ({
            type: s.type,
            label: s.name,
            description: s.organization?.name,
            value: s.id,
        })) || [],
    },
    types: user?.roles?.map(s => ({
        type: s.type
    })) || [],
    groups: { // temp
        name: '',
        ids: [], // user?.groups?.map(g => g.id) || []
        alreadySelected: [], // user?.groups?.map(s => ({label: s.name, value: s.id})) || [],
    },
    avatar: user.avatar || userFormBodyInitialState.avatar,
    deleted: user.deleted
})
export const convertUserDtoToFormHeader = (user: FindUserResponseDto): UserFormHeader => ({
    name: user?.name || userFormHeaderInitialState.name,
})
export const convertUserFormToRequestDto = (data: Partial<UserFormBody & UserFormHeader>): UpdateUserRequestDto => ({
    name: data?.name || userFormHeaderInitialState.name,
    email: data?.email || userFormBodyInitialState.email,
    password: data?.password || userFormBodyInitialState.password,
    phone: data?.phone || userFormBodyInitialState.phone,
    job: data?.job || userFormBodyInitialState.job,

    departmentId: data?.department?.data?.id || data?.department?.name || userFormBodyInitialState.department.data.id,
    type: data?.type?.name === userType.STAFF ? 'STAFF' : data?.type?.name === userType.INDIVIDUAL ? 'INDIVIDUAL' : data?.type?.name === userType.CASH ? 'CASH' : null,
    specializationsIds:
        data?.specializations?.ids?.length ?
            data?.specializations?.ids :
            data?.specializations?.name?.length ?
                [data.specializations.name] :
                userFormBodyInitialState.specializations.ids,
    organizationsIds: data?.organizations?.ids?.length ?
        data?.organizations?.ids :
        data?.organizations?.name?.length ?
            [data.organizations.name] :
            userFormBodyInitialState.organizations.ids,
    rolesIds:
        data?.roles?.ids?.length ?
            data?.roles?.ids :
            data?.roles?.name?.length ?
                [data.roles.name] :
                userFormBodyInitialState.roles.ids,
    groupsIds:
        data?.groups?.ids?.length ?
            data?.groups?.ids :
            data?.groups?.name?.length ?
                [data.groups.name] :
                userFormBodyInitialState.groups.ids,

    avatarId: data?.avatar?.id || userFormBodyInitialState.avatar.id,
    
    deleted: data?.deleted || userFormBodyInitialState.deleted,
    workingDays: data?.workingDays || userFormBodyInitialState.workingDays,
})

export const formatWorkingDays = (workingDays: FindUserResponseDto['workingDays']) => {
    const arrayOfWeekDayIndexes = [...new Array(7).keys()]
    
    return arrayOfWeekDayIndexes.map((i) => {
        const workingHours = workingDays[i]
        
        if (Array.isArray(workingHours) && workingHours?.length) {
            return workingHours
        } else {
            return null
        }
    }).map((timetable) => timetable ? transformWorkingTimeFromMinutesToSeconds(timetable) : timetable)
}

// *
// STORE
// *

export const usersFormGlobalDefaultValues: UsersFormGlobal = {
    name: '',
    password: '',
    email: '',
    phone: '',
    job: '',
    createdAt: '',
    updatedAt: '',
    deleted: false,

    department: autocompleteFieldDefault,
    type: autocompleteFieldDefault,
    organizations: multiselectFieldDefault,
    specializations: multiselectFieldDefault,
    roles: multiselectFieldDefault,
    groups: multiselectFieldDefault,
    avatar: {
        id: '',
        name: '',
        size: 0,
        type: '',
        bucket: {
            id: '',
            name: '',
            path: '',
        },
    },
}


// *
// INPUT PROCESSING FUNCS
// *

// global
export const onUsersGlobalAutocompleteSubmit = ({
    key,
    setFormValue,
}: InputSubmitArg<UsersAutocompleteKey, UsersFormGlobal>) =>
    (data: AutocompleteSubmitData) => {
        setFormValue(key, autocompleteSubmitArg(data))
    }
export const onUsersGlobalAutocompleteChange = ({
    formData,
    key,
    setFormValue,
}: InputChangeArg<UsersAutocompleteKey, UsersFormGlobal>) =>
    (label: string) => {
        if (formData[key]?.name !== label) {
            setFormValue(key, autocompleteChangeArg(label))
        }
    }

export const onUsersGlobalMultiselectChange = ({
    formData,
    key,
    setFormValue,
}: InputChangeArg<UsersMultiselectFilterParamKey, UsersFormGlobal>) => (label: string) => {
    if (formData[key]?.name !== label) {
        setFormValue(`${ key }.name`, label)
    }
}
export const onUsersGlobalMultiselectSubmit = ({
    key,
    setFormValue,
}: InputSubmitArg<UsersMultiselectFilterParamKey, UsersFormGlobal>) => (valueIds: Array<string>) => {
    setFormValue(`${ key }.ids`, valueIds)
}

// body
export const onUserBodyAutocompleteSubmit = ({
    key,
    setFormValue,
}: InputSubmitArg<UsersAutocompleteKey, UserFormBody>) =>
    (data: AutocompleteSubmitData) => {
        setFormValue(key, autocompleteSubmitArg(data))
    }
export const onUserBodyAutocompleteChange = ({
    formData,
    key,
    setFormValue,
}: InputChangeArg<UsersAutocompleteKey, UserFormBody>) =>
    (label: string) => {
        if (formData[key]?.name !== label) {
            setFormValue(key, autocompleteChangeArg(label))
        }
    }

export const onUserBodyMultiselectChange = ({
    formData,
    key,
    setFormValue,
}: InputChangeArg<UsersMultiselectFilterParamKey, UserFormBody>) => (label: string) => {
    if (formData[key]?.name !== label) {
        setFormValue(`${ key }.name`, label)
    }
}
export const onUserBodyMultiselectSubmitOld = ({
    key,
    setFormValue,
}: InputSubmitArg<UsersMultiselectFilterParamKey, UserFormBody>) => (valueIds: Array<string>) => {
    setFormValue(`${ key }.ids`, valueIds)
}
export const onUserBodyMultiselectSubmit = ({
    key,
    setFormValue,
}: InputSubmitArg<UsersMultiselectFilterParamKey, UserFormBody>) => (valueIds: Array<string>) => {
    setFormValue(`${ key }.ids`, valueIds)

    // if ( formData[key]?.alreadySelected && formData[key]!.alreadySelected.length > valueIds.length) {
    //     // delete case
    //     // const newData = formData[key]?.data.filter(d => valueIds.includes(d.value)) || []

    //     // setFormValue(`${key}.data`, newData || [])
    //     // data = newData
    // } else {
    //     // add case
    //     // const newId = valueIds.filter(id => !formData[key]?.id.includes(id))[0]
    //     // const newFieldIdx = data.findIndex(d => d.value === newId)
    //     // const newField = newFieldIdx > -1 ? data[newFieldIdx] : undefined
    //     // const newData = newField ? [...formData[key]?.data || [], newField] : [...formData[key]?.data || []]

    //     // setFormValue(`${key}.data`, newData)
    //     // data = newData
    //     setFormValue(`${key}.ids`, valueIds)
    // }
}
