import { JobFormulaSliceInitialState } from 'components/Resources/Jobs/PageBody/Formulas/Formulas.types'
import { jobFormulaFormHeaderInitialState, jobFormulaFormBodyInitialState } from 'components/Resources/Jobs/PageBody/Formulas/Formulas.utils'
import { createAppSlice } from 'utils/createAppSlice'
import { defaultInitialState } from 'utils/defaultInitialState.ts'

const jobFormulaInitialState: JobFormulaSliceInitialState = {
    ...defaultInitialState,
    editableFormHeader: jobFormulaFormHeaderInitialState,
    editableFormBody: jobFormulaFormBodyInitialState,
}

export const jobBodyFormulaSlice = createAppSlice<JobFormulaSliceInitialState>('jobBodyFormula',  jobFormulaInitialState)

export default jobBodyFormulaSlice.reducer
