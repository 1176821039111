import { Navigate, RouteObject } from 'react-router-dom'

export const EMPLOYEE_ROUTES: Array<RouteObject> = [
    {
        index: true,
        element: <Navigate to={'employees'} />,
    },
    {
        id: 'employees',
        path: 'employees',
        async lazy() {
            const { Employees } = await import('pages/tenant/employee/Employees')
            return { element: <Employees /> }
        },
        loader: ({ params }) => ({ id: params.id, name: 'Сотрудники', path: 'employees' }),
        children: [
            {
                id: 'employeeList',
                path: '',
                async lazy() {
                    const { EmployeeList } = await import('pages/tenant/employee/EmployeeList')
                    return { element: <EmployeeList /> }
                },
            },
            {
                id: 'employeeById',
                path: ':employeeId',
                async lazy() {
                    const { EmployeeById } = await import('pages/tenant/employee/EmployeeById')
                    return { element: <EmployeeById /> }
                },
                loader: ({ params }) => ({ id: params.id, name: 'Имя сотрудника (редактировать)' }),
            },
        ],
    },
    {
        id: 'groups',
        path: 'groups',
        async lazy() {
            const { Groups } = await import('pages/tenant/employee/Groups')
            return { element: <Groups /> }
        },
        loader: ({ params }) => ({ id: params.id, name: 'Группы и бригады' }),
        children: [
            {
                id: 'groupList',
                path: '',
                async lazy() {
                    const { GroupList } = await import('pages/tenant/employee/GroupList')
                    return { element: <GroupList /> }
                },
            },
            {
                id: 'groupById',
                path: ':groupId',
                async lazy() {
                    const { GroupById } = await import('pages/tenant/employee/GroupById')
                    return { element: <GroupById /> }
                },
                loader: ({ params }) => ({ id: params.id, name: 'Имя группы (редактировать' }),
            },
        ],
    },
]
