import { createSlice } from '@reduxjs/toolkit'

interface ErrorState {
    errors: {
        title: string
        message: string[]
        status: number
    }[]
}

const initialState: ErrorState = {
    errors: [],
}

export const errorSlice = createSlice({
    name: 'errors',
    initialState,
    reducers: {
        Error: (state = initialState, action) => ({
            errors: [...state.errors, action.payload],
        }),
        Clear: () => initialState,
    },
})

export default errorSlice.reducer
