import { MRT_Icons, MRT_TableOptions } from 'mantine-react-table'
import { useStylesMantineReactTable } from './useStylesMantineReactTable'
import { useStylesCommon } from './useStylesCommon'
import { IconDotsVertical } from '@tabler/icons-react'
import ShowHideColumnsIcon from 'assets/icons/show_hide_columns.svg'
import SorterNeutralIcon from 'assets/icons/sorter_neutral.svg'
import SorterAscendingIcon from 'assets/icons/sorter_ascending.svg'
import SorterDescendingIcon from 'assets/icons/sorter_descending.svg'
import { MRT_Localization_RU } from 'mantine-react-table/locales/ru'

export function useCommonTableOptions<T extends Record<string, any>>() {
    const { classes } = useStylesMantineReactTable()
    const { classes: classesCommon } = useStylesCommon()

    const icons: Partial<MRT_Icons> = {
        IconDots: IconDotsVertical,
        IconColumns: ShowHideColumnsIcon,
        IconArrowsSort: SorterNeutralIcon,
        IconSortAscending: SorterAscendingIcon,
        IconSortDescending: SorterDescendingIcon,
    }

    const commonTableOptions: Partial<MRT_TableOptions<T>> = {
        // enableTopToolbar: false,
        // enableGlobalFilterRankedResults: false,
        // enableFilterMatchHighlighting: false,
        icons,
        localization: MRT_Localization_RU,

        enableToolbarInternalActions: true,
        manualFiltering: true,
        manualSorting: true,
        enableStickyHeader: true,
        enableTableFooter: false,
        // enableRowNumbers: true,
        enableRowActions: true,
        positionActionsColumn: 'last',
        displayColumnDefOptions: {
            'mrt-row-actions': {
                header: 'Действия',
                Header: '',
                maxSize: 10,
                enableHiding: true,
            },
            'mrt-row-expand': {
                header: 'Раскрыть',
                Header: '',
                maxSize: 10,
                enableHiding: true,
            },
            // 'mrt-row-numbers': {
            //     header: '№',
            //     Header: '№',
            //     maxSize: 10,
            //     enableHiding: true,
            //     // enableColumnActions: false,
            // },
        },

        enableBottomToolbar: true,
        rowNumberMode: 'original',
        // enableFilterMatchHighlighting: false,
        // paginateExpandedRows: false,
        positionPagination: 'both',
        paginationDisplayMode: 'pages',
        manualPagination: true,
        mantineToolbarAlertBannerProps: {
            color: 'red',
            children: 'Error loading data',
        },
        mantinePaperProps: {
            className: `${classes.mantinePaper} ${classesCommon.containerUnderTab}`,
        },
        mantineSelectCheckboxProps: {
            size: 'xs',
        },
        mantineSelectAllCheckboxProps: {
            size: 'xs',
        },
        mantineTopToolbarProps: {
            className: classes.mantineToolbar,
        },
        mantineBottomToolbarProps: {
            className: classes.mantineToolbar,
        },
        mantineTableHeadProps: {
            className: classes.mantineTableHead,
        },
        // mantineTableHeadRowProps: {
        //     sx: {
        //         position: 'static',
        //         boxShadow: 'none !important',
        //     },
        // },
        mantineTableHeadCellProps: {
            className: classes.mantineTableHeadCell,
            sx: {
                fontWeight: 'normal',
                // flex: '0 0 auto',
            },
        },
        mantineTableProps: {
            className: classes.mantineTable,
            sx: {
                tableLayout: 'fixed',
            },
        },
        mantineTableBodyProps: {
            className: classes.mantineTableBody,
            sx: {
                // flex: '0 0 auto',
            },
        },
        mantineTableBodyCellProps: {
            className: classes.mantineTableBodyCell,
        },
        enableColumnActions: false,
        enableColumnFilters: false,
        enableFullScreenToggle: false,
        enableGlobalFilter: false,
        enableDensityToggle: false,
        enableSorting: true,
        // enableHiding: true,

        initialState: { density: 'xs' },
        mantineProgressProps: ({ isTopToolbar }) => ({
            color: 'info.1',
            sx: { display: isTopToolbar ? 'block' : 'none' },
        }),
    }

    return commonTableOptions
}
