import { createAppSlice } from 'utils/createAppSlice'
import { defaultInitialState } from 'utils/defaultInitialState.ts'
import {
    WarehouseInventoriesSliceInitialState
} from 'components/Supply/Warehouses/PageBody/Inventories/WarehouseInventories.types.ts';
import {
    warehouseInventoriesFormBodyInitialState,
    warehouseInventoriesFormHeaderInitialState
} from 'components/Supply/Warehouses/PageBody/Inventories/WarehouseInantories.utils.ts';

const warehouseInventoriesTableSliceInitialState: WarehouseInventoriesSliceInitialState = {
    ...defaultInitialState,
    editableFormHeader: warehouseInventoriesFormHeaderInitialState,
    editableFormBody: warehouseInventoriesFormBodyInitialState,
}
export const warehouseInventoriesTableSlice = createAppSlice<WarehouseInventoriesSliceInitialState>('warehouseInventoriesTable', warehouseInventoriesTableSliceInitialState )

export default warehouseInventoriesTableSlice.reducer
