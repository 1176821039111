import { createSlice, PayloadAction } from '@reduxjs/toolkit'

class InitialState {
    pageScrollOffset: number = 0
    firstFixedElementOffsetTop: number = 0
    headerHeight: number = 0
    boxHeadingButtonsGroupHeight: number = 0
    drawerInfoHeaderHeight: number = 0
    topToolbarHeight: number = 0
    sortBlockHeight: number = 40
    fixedPositions = {
        boxHeadingButtonsGroup: 0,
        topToolbar: 0,
        sortBlock: 0,
    }
}

const initialState: InitialState = {
    pageScrollOffset: 0,
    firstFixedElementOffsetTop: 0,

    headerHeight: 0,
    boxHeadingButtonsGroupHeight: 0,
    drawerInfoHeaderHeight: 0,
    topToolbarHeight: 0,
    sortBlockHeight: 40,

    fixedPositions: {
        boxHeadingButtonsGroup: 0,
        topToolbar: 0,
        sortBlock: 0,
    }
}


export const layoutSlice = createSlice({
    name: 'layout',
    initialState,
    reducers: {
        setPageScrollOffset: (state, action: PayloadAction<InitialState['pageScrollOffset']>) => {
            state.pageScrollOffset = action.payload
        },
        setFirstFixedElementOffsetTop: (state, action: PayloadAction<InitialState['firstFixedElementOffsetTop']>) => {
            state.firstFixedElementOffsetTop = action.payload
        },

        setBoxHeadingButtonsGroupHeight: (state, action: PayloadAction<InitialState['boxHeadingButtonsGroupHeight']>) => {
            state.boxHeadingButtonsGroupHeight = action.payload
        },
        setHeaderHeight: (state, action: PayloadAction<InitialState['headerHeight']>) => {
            state.headerHeight = action.payload
        },
        setDrawerInfoHeaderHeight: (state, action: PayloadAction<InitialState['drawerInfoHeaderHeight']>) => {
            state.drawerInfoHeaderHeight = action.payload
        },
        setTopToolbarHeight: (state, action: PayloadAction<InitialState['topToolbarHeight']>) => {
            state.topToolbarHeight = action.payload
        },
        setSortBlockHeight: (state, action: PayloadAction<InitialState['sortBlockHeight']>) => {
            state.sortBlockHeight = action.payload
        },

        setFixedPositions: (state, action: PayloadAction<Partial<InitialState['fixedPositions']>>) => {
            state.fixedPositions = { ...state.fixedPositions, ...action.payload }
        },
    },
})

export default layoutSlice.reducer
