import { MantineThemeOverride } from '@mantine/core'

export const mantineColors: MantineThemeOverride['colors'] = {
    custom: [
        '#E0E3E7',
        '#F1F2F4',
    ],
    natural: [
    // use another pallette as in Flow module
        '#F5F7FA',
        '#EBEFF5',
        '#DDE3ED',
        '#C8D1E0',
        '#AFBACC',
        '#8E99AB',
        '#707A8A',
        '#58606E',
        '#333840',
        '#22252B',
    ],
    gray: [
        '#F5F7FA',
        '#EBEFF5',
        '#DDE3ED',
        '#C8D1E0',
        '#AFBACC',
        '#8E99AB',
        '#545F77',
        '#58606E',
        '#333840',
        '#22252B',
    ],
    yellow: [
        '#FFFFDD',
        '#FFFB9E',
        '#FFF15F',
        '#FFE120',
        '#FFCB10',
        '#FFAD00',
        '#E38200',
        '#BB5D00',
        '#934000',
        '#6A2600',
    ],
    blue: [
        '#EEFAFF',
        '#C1EBFF',
        '#88D2FF',
        '#57B7FF',
        '#1083FF',
        '#3656be',
        '#0049CE',
        '#00329D',
        '#001F6D',
        '#000F3D',
    ],
    red: [
        '#FFEFED',
        '#FFD0CB',
        '#FF978E',
        '#FF675F',
        '#FF3933',
        '#ED0000',
        '#D44332',
        '#9E0000',
        '#760000',
        '#4E0000',
    ],
    primary: [
        '#FFFFDD',
        '#FFFB9E',
        '#FFF15F',
        '#FDD13A',
        '#FFCB10',
        '#EC9827',
        '#E38200',
        '#BB5D00',
        '#934000',
        '#6A2600',
    ],
    secondary: ['#EBEFF5'],
    success: [
        '#EDFFF5',
        '#BEFFDA',
        '#7BFFB1',
        '#4EF38D',
        '#21CC5E',
        '#118F3F',
        '#00882A',
        '#006B1E',
        '#004F14',
        '#00330C',
    ],
    info: [
        '#EEFAFF',
        '#C1EBFF',
        '#88D2FF',
        '#57B7FF',
        '#1083FF',
        '#436CEE',
        '#0049CE',
        '#00329D',
        '#001F6D',
        '#000F3D',
    ],
    warning: [
        '#FFF7EB',
        '#F8DEB8',
        '#FFC483',
        '#FEA957',
        '#FE8B2B',
        '#EC9827',
        '#C44B00',
        '#9D3600',
        '#752400',
        '#4E1500',
    ],
    danger: ['#FF675F', '#F5222D'],
}
