import { createAppSlice } from 'utils/createAppSlice'
import { defaultInitialState } from 'utils/defaultInitialState.ts'
import {
    materialUsageFormBodyInitialState,
    materialUsageFormHeaderInitialState, MaterialUsageSliceInitialState
} from 'components/Resources/Materials/NestedPages/MaterialUsage/MaterialUsage.types.ts';

const materialUsageInitialState: MaterialUsageSliceInitialState = {
    ...defaultInitialState,
    editableFormHeader: materialUsageFormHeaderInitialState,
    editableFormBody: materialUsageFormBodyInitialState,
}

export const materialUsageSlice = createAppSlice<MaterialUsageSliceInitialState>('materialUsage',  materialUsageInitialState)

export default materialUsageSlice.reducer
