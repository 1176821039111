import { Suspense } from 'react'
import { Navigate, RouteObject } from 'react-router-dom'

export const AUTH_ROUTES: Array<RouteObject> = [
    {
        id: 'auth',
        path: '/',
        async lazy() {
            const { LayoutAuth } = await import('components/AbstractComponents/LayoutAuth/LayoutAuthorize')

            return { element: <Suspense fallback=""><LayoutAuth /></Suspense> }
        },
        children: [
            {
                index: true,
                element: <Navigate to={'login'} />,
            },
            {
                id: 'login',
                path: 'login',
                async lazy() {
                    const { Login } = await import('components/Auth/Login.tsx')

                    return { element: <Login /> }
                },
            },
            {
                id: 'register',
                path: 'register',
                async lazy() {
                    const { Register } = await import('components/Auth/Register.tsx')
                    return { element: <Register /> }
                },
            },
            {
                id: 'reset',
                path: 'reset',
                async lazy() {
                    const { Reset } = await import('components/Auth/Reset.tsx')
                    return { element: <Reset /> }
                },
            },
            {
                id: '403',
                path: '/403',
                async lazy() {
                    const { Page403 } = await import('components/Error/403.tsx')
                    return { element: <Page403 /> }
                },
            },
            {
                id: 'notFound',
                path: '*',
                element: <Navigate to={'login'} />,
            },
        ],
    },
    {
        id: 'callback',
        path: 'oauth/callback',
        async lazy() {
            const { OAuthCallback } = await import('components/Auth/OAuthCallback.tsx')
            return { element: <OAuthCallback /> }
        },
    },
]
