import { UserOrganizationsSliceInitialState } from 'components/Organizations/UserOrganizations.types'
import { userOrganizationFormHeaderInitialState, userOrganizationFormBodyInitialState } from 'components/Organizations/UserOrganizations.utils'
import { createAppSlice } from 'utils/createAppSlice'
import { defaultInitialState } from 'utils/defaultInitialState.ts'

const userOrganizationsSliceInitialState: UserOrganizationsSliceInitialState = {
    ...defaultInitialState,
    editableFormHeader: userOrganizationFormHeaderInitialState,
    editableFormBody: userOrganizationFormBodyInitialState,
}

export const userOrganizationsSlice = createAppSlice<UserOrganizationsSliceInitialState>('userOrganizations', userOrganizationsSliceInitialState)


export default userOrganizationsSlice.reducer
