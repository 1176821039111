import { TaskMaterialsSliceInitialState } from 'components/Resources/Jobs/NestedPages/Tasks/PageBody/Materials/TaskMaterials.types'
import { taskMaterialFormHeaderInitialState, taskMaterialsFormBodyInitialState } from 'components/Resources/Jobs/NestedPages/Tasks/PageBody/Materials/TaskMaterials.utils'
import { createAppSlice } from 'utils/createAppSlice'
import { defaultInitialState } from 'utils/defaultInitialState.ts'

const taskMaterialsSliceInitialState: TaskMaterialsSliceInitialState = {
    ...defaultInitialState,
    editableFormHeader: taskMaterialFormHeaderInitialState,
    editableFormBody: taskMaterialsFormBodyInitialState,
}

export const taskBodyAddMaterialsSlice = createAppSlice<TaskMaterialsSliceInitialState>('taskBodyAddMaterials', taskMaterialsSliceInitialState)


export default taskBodyAddMaterialsSlice.reducer
