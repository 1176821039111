import { JobsSliceInitialState } from 'components/Resources/Jobs/Jobs.types'
import {
    jobFormBodyInitialState,
    jobFormHeaderInitialState,
} from 'components/Resources/Jobs/Jobs.utils'
import { createAppSlice } from 'utils/createAppSlice'
import { defaultInitialState } from 'utils/defaultInitialState.ts'

const jobsInitialState: JobsSliceInitialState = {
    ...defaultInitialState,
    editableFormHeader: jobFormHeaderInitialState,
    editableFormBody: jobFormBodyInitialState,
}

export const jobsSlice = createAppSlice<JobsSliceInitialState>('jobs', jobsInitialState)


export default jobsSlice.reducer
