import React, { ReactNode, useMemo } from 'react'
import { createBrowserRouter, RouteObject, RouterProvider } from 'react-router-dom'
import { AUTH_ROUTES } from 'routes/auth/routes'
import { TENANT_ROUTES } from 'routes/tenant/routes'
import { WORKER_ROUTES } from 'routes/worker/routes'
import { RoleType } from 'types/auth'
import { getRoleType } from 'utils/auth.ts'
import { LoginResponseDto } from 'store/api/authentication.tsx'
import { NotificationsProvider } from 'providers/NotificationsProvider/NotificationsProvider.tsx'
import { usePageUiStateProjectPlanningWorksLocalStorage } from 'hooks/usePageUiStateLocalStorage.ts'

type Props = {
    user: LoginResponseDto | undefined
}

export type CrumbData = {
    id: string
    name: string | ReactNode,
    path: string
}

const handleCrumb = {
    crumb: (data: CrumbData) => data,
}

const makeRouteCrumbed = (route: RouteObject): RouteObject => Object.assign(
    route,
    { handle: handleCrumb },
)

const crumbRoutes = (routes: RouteObject[]): RouteObject[] => routes.map(route => {
    if (route.children?.length) crumbRoutes(route.children)
    return makeRouteCrumbed(route)
})

export const ProviderRouter: React.FC<Props> = ({ user }): React.JSX.Element => {
    
    const router = useMemo(() => {
        let router = createBrowserRouter(AUTH_ROUTES)
        
        const role = getRoleType(user?.roles)
        
        if (role) {
            if (role === RoleType.MANAGER) {
                router = createBrowserRouter(crumbRoutes(TENANT_ROUTES))
            } else if (role === RoleType.WORKER) {
                router = createBrowserRouter(crumbRoutes(WORKER_ROUTES))
            } else if (role === RoleType.UNKNOWN) {
                console.error(
                    '☝ Mixing role types for user is not allowed, cannot determine role type, temporarily forcing manager layout')
                router = createBrowserRouter(crumbRoutes(TENANT_ROUTES))
            }
            
        }
        
        return router
    }, [user])
    
    const [, setPageUiStateProjectPlanningWorks] = usePageUiStateProjectPlanningWorksLocalStorage()
    
    router.subscribe(state => {
        if (state.historyAction === 'PUSH') {
            const taskId = state.matches[state.matches.length - 1].params.taskId
            const projectId = state.matches[state.matches.length - 1].params.projectId
            const pathname = state.matches[state.matches.length - 1].pathname
            
            if (taskId && projectId && pathname.includes('/planning/works')) {
                setPageUiStateProjectPlanningWorks({ returnLastUiState: true })
            }
            
        }
    })
    
    return <NotificationsProvider>
        <RouterProvider router={ router } />
    </NotificationsProvider>
    
}
