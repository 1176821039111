// ** MEDIA

export const MEDIA_PX = {
    XS: '320px',
    SM: '768px',
    MD: '992px',
    LG: '1440px',
    XL: '1920px',
}

export const MEDIA_EM = {
    XS: '20em',
    SM: '48em',
    MD: '62em',
    LG: '90em',
    XL: '120em',
}

export const MEDIA_MAX = {
    XS: `(max-width: ${ MEDIA_EM.XS })`,
    SM: `(max-width: ${ MEDIA_EM.SM })`,
    MD: `(max-width: ${ MEDIA_EM.MD })`,
    LG: `(max-width: ${ MEDIA_EM.LG })`,
    XL: `(max-width: ${ MEDIA_EM.XL })`,
}

export const MEDIA_MIN = {
    XS: `(min-width: ${ MEDIA_EM.XS })`,
    SM: `(min-width: ${ MEDIA_EM.SM })`,
    MD: `(min-width: ${ MEDIA_EM.MD })`,
    LG: `(min-width: ${ MEDIA_EM.LG })`,
    XL: `(min-width: ${ MEDIA_EM.XL })`,
}

// ** TYPOGRAPHY

export const STEP_4 = 4

// ** COLORS

export const COLOR_WHITE = '#FFFFFF'
