import { useLocalStorage } from '@mantine/hooks'
import { useParams } from 'react-router-dom'

export const usePageUiStateLocalStorage = (path: string) => useLocalStorage<object | null>({
    key: path,
    serialize(value: object): string {
        return JSON.stringify(value)
    },
    deserialize(value: string): object {
        return JSON.parse(value)
    },
})

export const usePageUiStateProjectPlanningWorksLocalStorage = () => {
    const { projectId } = useParams()
    const [pageUiState, setPageUiState] = usePageUiStateLocalStorage(`/projects/project/${ projectId }/planning/works`)
    
    return [pageUiState, setPageUiState] as [typeof pageUiState, typeof setPageUiState]
}
