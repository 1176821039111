import { CreateClientContactRequestDto, FindClientContactResponseDto } from 'store/api/clientContacts'
import { ClientContactFormGlobal } from './ClientContactForms.types'
/*import { AutocompleteField, AutocompleteSubmitData, InputChangeArg, InputSubmitArg } from 'types/inputs'
import {
    autocompleteChangeArg, autocompleteFieldDefault,
    autocompleteSubmitArg
} from 'utils/inputs'*/


// *
// DATA CONVERT FUNCS
// *

export const convertClientContactDtoToFormGlobal = (contact: FindClientContactResponseDto): ClientContactFormGlobal => ({
    company: contact?.company || clientContactFormGlobalInitialState.company,
    email: contact?.email || clientContactFormGlobalInitialState.email,
    firstName: contact?.firstName || clientContactFormGlobalInitialState.firstName,
    lastName: contact?.lastName || clientContactFormGlobalInitialState.lastName,
    middleName: contact?.middleName || clientContactFormGlobalInitialState.middleName,
    note: contact?.note || clientContactFormGlobalInitialState.note,
    phone: contact?.phone || clientContactFormGlobalInitialState.phone,
    position: contact?.position || clientContactFormGlobalInitialState.position,
    website: contact?.website || clientContactFormGlobalInitialState.website,
    clientId: '', // dummy (clientId is passes additionally)
    id: contact?.id || clientContactFormGlobalInitialState.id,
})

export const convertClientContactFormToRequestDto = (data: Partial<ClientContactFormGlobal>): CreateClientContactRequestDto & { id?: string } => ({
    firstName: data?.firstName || clientContactFormGlobalInitialState.firstName,
    clientId: data.clientId || clientContactFormGlobalInitialState.clientId,
    lastName: data.lastName || clientContactFormGlobalInitialState.lastName,
    position: data.position || clientContactFormGlobalInitialState.position,
    company: data.company || clientContactFormGlobalInitialState.company,
    email: data.email || clientContactFormGlobalInitialState.email,
    middleName: data.middleName || clientContactFormGlobalInitialState.middleName,
    note: data.note || clientContactFormGlobalInitialState.note,
    phone: data.phone || clientContactFormGlobalInitialState.phone,
    website: data.website || clientContactFormGlobalInitialState.website,
    id: data.id,
    // id: data?.id || clientContactFormGlobalInitialState.id,
})


// *
// STORE
// *

/*export const onClientCompanyGlobalAutocompleteSubmit = ({ key, setFormValue }: InputSubmitArg<ClientCompanyAutocompleteKey, ClientContactFormGlobal>) =>
    (data: AutocompleteSubmitData) => {
        setFormValue(key, autocompleteSubmitArg(data))
    }

export const onClientCompanyGlobalAutocompleteChange = ({ formData, key, setFormValue }: InputChangeArg<ClientCompanyAutocompleteKey, ClientContactFormGlobal>) =>
    (label: string) => {
        if (formData[key]?.name!==label) {
            setFormValue(key, autocompleteChangeArg(label))
        }
    }*/

export const clientContactFormGlobalInitialState: ClientContactFormGlobal = {
    company: '',
    email: null,
    firstName: '',
    lastName: '',
    middleName: null,
    note: null,
    phone: null,
    position: '',
    website: null,
    clientId: '',
}


// *
// REUSABLE
// *

export const clientContactDefault: FindClientContactResponseDto = {
    company: '',
    email: '',
    firstName: '',
    id: '',
    lastName: '',
    middleName: '',
    note: '',
    phone: '',
    position: '',
    website: '',
}
