import { createSlice, Draft } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { ApiError } from 'store/api/projects.tsx';
import { FindNotificationResponseDto } from 'store/api/notifications.tsx';


type NotificationsInitialState = {
    notifications: FindNotificationResponseDto[] ,
    receivedNotifications: null | FindNotificationResponseDto,
    apiError: ApiError | null,
}

const initialState: NotificationsInitialState = {
    notifications: [],
    receivedNotifications: null,
    apiError: null,
}

export const notificationsSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        setNotificationsToStore: (state: Draft<NotificationsInitialState>, action: PayloadAction<FindNotificationResponseDto[]>) => {
            state.notifications = action.payload
        } ,
        notifyError: (state = initialState, action: PayloadAction<FindNotificationResponseDto>) => ({
            ...state,
            receivedNotifications:  action.payload,
            notifications: [...state.notifications, action.payload]
            
        }),
        clearNotifications: (state) => ({ ...state, receivedNotifications: null }),
        setApiError: (state: Draft<NotificationsInitialState>, action: PayloadAction<ApiError>) => {
            state.apiError = action.payload
        }
    } }
)

export default notificationsSlice.reducer
