import React from 'react'
import styles from 'components/Worker/Dashboard.module.css'
import { Box, Flex } from '@mantine/core'
import classNames from 'classnames'
import { WorkCardProps } from 'components/Worker/Calendar/Types/WorkCardProps'
import { IconAlertTriangle } from '@tabler/icons-react'
import { WorkStatusComponent } from 'components/Worker/Work/WorkStatus.tsx'
import { calculateLag } from 'utils/date.ts'
import dayjs from 'utils/dayjs.ts'
import { WorkMoneyComponent } from '../Work/WorkMoney.tsx'


export const WorkCardOverdue: React.FC<WorkCardProps> = ({ work, isActive }: WorkCardProps) => 
    <Flex
        className={ classNames(styles.overdueWork, { [styles.calendarWorkActive]: isActive }) }
        direction={'column'}
        gap={8}
    >
        <Flex direction={'row'} gap={12} align={'center'} justify={'stretch'}>
            <Box
                sx={(theme) => ({
                    color: theme.colors.red[5],
                    fontSize: '12px',
                    fontWeight: 600,
                })}
            >
                { `До ${dayjs(work?.to).format('YYYY.MM.DD HH:mm')}` }
            </Box>
            <Flex direction={'row'} gap={4} align={'center'}>
                <IconAlertTriangle color={'#EC9827'} size={14} />

                <Box
                    sx={(theme) => ({
                        color: theme.colors.warning[5],
                        fontSize: '12px',
                        fontWeight: 400,
                    })}
                >
                    { `Опоздание: ${work?.lag ? calculateLag(work?.lag) : ''}` }
                </Box>
            </Flex>
        </Flex>
            
        <Flex direction={'row'} gap={8} align={'center'}>
            { work?.status && <WorkStatusComponent workStatus={work.status} /> }
                
            { work?.isShowMoney &&
                    <WorkMoneyComponent workStatus={work.status} workMoney={work.money} />
            }
        </Flex>
            
        <Flex direction={'column'}>
            <Box className={ styles.overdueWorkName }>
                { work?.name }
            </Box>
                
            <Box className={ styles.workAddress }>
                { work?.address }
            </Box>
        </Flex>
    </Flex>
