import { Global } from '@mantine/core'

// fonts below are imported this way because not found other way
import InterBold from 'assets/fonts/inter/Inter-Bold.woff'
import InterBold2 from 'assets/fonts/inter/Inter-Bold.woff2'
import InterMedium from 'assets/fonts/inter/Inter-Medium.woff'
import InterMedium2 from 'assets/fonts/inter/Inter-Medium.woff2'
import InterRegular from 'assets/fonts/inter/Inter-Regular.woff'
import InterRegular2 from 'assets/fonts/inter/Inter-Regular.woff2'
import InterLightItalic from 'assets/fonts/inter/Inter-LightItalic.woff'
import InterLightItalic2 from 'assets/fonts/inter/Inter-LightItalic.woff2'
import InterItalic from 'assets/fonts/inter/Inter-Italic.woff'
import InterItalic2 from 'assets/fonts/inter/Inter-Italic.woff2'


import InterSemiBold from 'assets/fonts/inter/Inter-SemiBold.woff'
import InterSemiBold2 from 'assets/fonts/inter/Inter-SemiBold.woff2'


export const InterFont = () => 
    <Global
        styles={[
            {
                '@font-face': {
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: 100,
                    fontDisplay: 'swap',
                    src: 'url(\'assets/fonts/inter/Inter-Thin.woff2\') format(\'woff2\'), url(\'assets/fonts/inter/Inter-Thin.woff\') format(\'woff\')',
                },
            },
            {
                '@font-face': {
                    fontFamily: 'Inter',
                    fontStyle: 'italic',
                    fontWeight: 100,
                    fontDisplay: 'swap',
                    src: 'url(\'assets/fonts/inter/Inter-ThinItalic.woff2\') format(\'woff2\'), url(\'assets/fonts/inter/Inter-ThinItalic.woff\') format(\'woff\')',
                },
            },

            {
                '@font-face': {
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: 200,
                    fontDisplay: 'swap',
                    src: 'url(\'assets/fonts/inter/Inter-ExtraLight.woff2\') format(\'woff2\'), url(\'assets/fonts/inter/Inter-ExtraLight.woff\') format(\'woff\')',
                },
            },
            {
                '@font-face': {
                    fontFamily: 'Inter',
                    fontStyle: 'italic',
                    fontWeight: 200,
                    fontDisplay: 'swap',
                    src: 'url(\'assets/fonts/inter/Inter-ExtraLightItalic.woff2\') format(\'woff2\'), url(\'assets/fonts/inter/Inter-ExtraLightItalic.woff\') format(\'woff\')',
                },
            },

            {
                '@font-face': {
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: 300,
                    fontDisplay: 'swap',
                    src: 'url(\'assets/fonts/inter/Inter-Light.woff2\') format(\'woff2\'), url(\'assets/fonts/inter/Inter-Light.woff\') format(\'woff\')',
                },
            },
            {
                '@font-face': {
                    fontFamily: 'Inter',
                    fontStyle: 'italic',
                    fontWeight: 300,
                    fontDisplay: 'swap',
                    src: `url('${InterLightItalic2}') format('woff2'), url('${InterLightItalic}') format('woff')`,
                },
            },

            {
                '@font-face': {
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontDisplay: 'swap',
                    src: `url('${InterRegular2}') format('woff2'), url('${InterRegular}') format('woff')`,
                },
            },
            {
                '@font-face': {
                    fontFamily: 'Inter',
                    fontStyle: 'italic',
                    fontWeight: 400,
                    fontDisplay: 'swap',
                    src: `url('${InterItalic2}') format('woff2'), url('${InterItalic}') format('woff')`,
                },
            },

            {
                '@font-face': {
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    fontDisplay: 'swap',
                    src: `url('${InterMedium2}') format('woff2'), url('${InterMedium}') format('woff')`,
                },
            },
            {
                '@font-face': {
                    fontFamily: 'Inter',
                    fontStyle: 'italic',
                    fontWeight: 500,
                    fontDisplay: 'swap',
                    src: 'url(\'assets/fonts/inter/Inter-MediumItalic.woff2\') format(\'woff2\'), url(\'assets/fonts/inter/Inter-MediumItalic.woff\') format(\'woff\')',
                },
            },

            {
                '@font-face': {
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontDisplay: 'swap',
                    src: `url('${InterSemiBold2}') format('woff2'), url('${InterSemiBold}') format('woff')`,
                },
            },
            {
                '@font-face': {
                    fontFamily: 'Inter',
                    fontStyle: 'italic',
                    fontWeight: 600,
                    fontDisplay: 'swap',
                    src: 'url(\'assets/fonts/inter/Inter-SemiBoldItalic.woff2\') format(\'woff2\'), url(\'assets/fonts/inter/Inter-SemiBoldItalic.woff\') format(\'woff\')',
                },
            },

            {
                '@font-face': {
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: 700,
                    fontDisplay: 'swap',
                    src: `url('${InterBold2}') format('woff2'), url('${InterBold}') format('woff')`,
                },
            },
            {
                '@font-face': {
                    fontFamily: 'Inter',
                    fontStyle: 'italic',
                    fontWeight: 700,
                    fontDisplay: 'swap',
                    src: 'url(\'assets/fonts/inter/Inter-BoldItalic.woff2\') format(\'woff2\'), url(\'assets/fonts/inter/Inter-BoldItalic.woff\') format(\'woff\')',
                },
            },

            {
                '@font-face': {
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: 800,
                    fontDisplay: 'swap',
                    src: 'url(\'assets/fonts/inter/Inter-ExtraBold.woff2\') format(\'woff2\'), url(\'assets/fonts/inter/Inter-ExtraBold.woff\') format(\'woff\')',
                },
            },
            {
                '@font-face': {
                    fontFamily: 'Inter',
                    fontStyle: 'italic',
                    fontWeight: 800,
                    fontDisplay: 'swap',
                    src: 'url(\'assets/fonts/inter/Inter-ExtraBoldItalic.woff2\') format(\'woff2\'), url(\'assets/fonts/inter/Inter-ExtraBoldItalic.woff\') format(\'woff\')',
                },
            },

            {
                '@font-face': {
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: 900,
                    fontDisplay: 'swap',
                    src: 'url(\'assets/fonts/inter/Inter-Black.woff2\') format(\'woff2\'), url(\'assets/fonts/inter/Inter-Black.woff\') format(\'woff\')',
                },
            },
            {
                '@font-face': {
                    fontFamily: 'Inter',
                    fontStyle: 'italic',
                    fontWeight: 900,
                    fontDisplay: 'swap',
                    src: 'url(\'assets/fonts/inter/Inter-BlackItalic.woff2\') format(\'woff2\'), url(\'assets/fonts/inter/Inter-BlackItalic.woff\') format(\'woff\')',
                },
            },
        ]}
    />

