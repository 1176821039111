import {
    groupFormBodyInitialState, groupFormHeaderInitialState,
    GroupsSliceInitialState
} from 'components/Resources/Jobs/NestedPages/Groups/Groups.types.ts'
import { createAppSlice } from 'utils/createAppSlice'
import { defaultInitialState } from 'utils/defaultInitialState.ts'

const groupsSliceInitialState: GroupsSliceInitialState = {
    ...defaultInitialState,
    editableFormHeader: groupFormHeaderInitialState,
    editableFormBody: groupFormBodyInitialState,
}
export const groupsSlice = createAppSlice<GroupsSliceInitialState>('groups', groupsSliceInitialState )

export default groupsSlice.reducer
