import { getFilterParamString, getSortParamString } from 'utils/inputs'
import { useAppSelector } from './redux'
import { FilterParam, FormBasedSliceName } from 'types/inputs'
import { useEffect, useReducer } from 'react'

export const useFilterParamsString = (reducerPath: FormBasedSliceName) => {
    const [filterParamsString, setFilterParamsString] = useReducer((
        prev: string,
        arg: string,
    ) => prev !== arg ? arg : prev, '')
    const reducer = `${ reducerPath }Reducer` as const
    const filterParamsArray = useAppSelector((state) => state[reducer].filterParamsArray)
    const permanentFilterParamsArray = useAppSelector((state) => state[reducer].permanentFilterParamsArray)
    
    const getFiltersString = (array: FilterParam[] | undefined) => array && array?.length
        ? array
            .map((f, i) => {
                if (i === 0) {
                    return getFilterParamString(
                        f)
                } else {
                    return `filter=${ getFilterParamString(
                        f) }`
                }
            })
            .join('&')
            .trim()
        : ''
    
    const permanentFiltersString = getFiltersString(permanentFilterParamsArray)
    
    useEffect(() => {
        const casualFiltersString = getFiltersString(filterParamsArray)
        
        if (permanentFiltersString) {
            if (casualFiltersString) {
                setFilterParamsString([
                    permanentFiltersString,
                    casualFiltersString,
                ].join('&filter='))
            } else {
                setFilterParamsString(permanentFiltersString)
            }
        } else {
            setFilterParamsString(casualFiltersString)
        }
        
    }, [JSON.stringify(filterParamsArray), permanentFiltersString])
    
    return [filterParamsString]
}

export const useSortParamsString = (reducerPath: FormBasedSliceName) => {
    const [sortParamsString, setSortParamsString] = useReducer(
        (prev: string, arg: string) => prev !== arg
            ? arg
            : prev,
        '',
    )
    const reducer = `${ reducerPath }Reducer` as const
    const sortParamsArray = useAppSelector((state) => state[reducer].sortParamsArray)
    
    useEffect(() => {
        const result = sortParamsArray.length
            ? sortParamsArray.map(getSortParamString).join('&').trim()
            : ''
        
        setSortParamsString(result)
    }, [JSON.stringify(sortParamsArray)])
    
    return [sortParamsString, sortParamsArray] as [string, typeof sortParamsArray]
}
