import { emptyApi as api } from './emptyApi';
export const addTagTypes = ['Authentication'] as const;
const injectedRtkApi = api
    .enhanceEndpoints({
        addTagTypes,
    })
    .injectEndpoints({
        endpoints: (build) => ({
            login: build.mutation<LoginApiResponse, LoginApiArg>({
                query: (queryArg) => ({
                    url: '/api/v1/auth/login',
                    method: 'POST',
                    body: queryArg,
                }),
                invalidatesTags: ['Authentication'],
            }),
            register: build.mutation<RegisterApiResponse, RegisterApiArg>({
                query: (queryArg) => ({
                    url: '/api/v1/auth/register',
                    method: 'POST',
                    body: queryArg,
                }),
                invalidatesTags: ['Authentication'],
            }),
            logout: build.query<LogoutApiResponse, LogoutApiArg>({
                query: () => ({ url: '/api/v1/auth/logout' }),
                providesTags: ['Authentication'],
            }),
            validate: build.query<ValidateApiResponse, ValidateApiArg>({
                query: () => ({ url: '/api/v1/auth/validate' }),
                providesTags: ['Authentication'],
            }),
            refresh: build.mutation<RefreshApiResponse, RefreshApiArg>({
                query: (queryArg) => ({
                    url: '/api/v1/auth/refresh',
                    method: 'POST',
                    body: queryArg,
                }),
                invalidatesTags: ['Authentication'],
            }),
            reset: build.mutation<ResetApiResponse, ResetApiArg>({
                query: (queryArg) => ({
                    url: '/api/v1/auth/reset',
                    method: 'POST',
                    body: queryArg,
                }),
                invalidatesTags: ['Authentication'],
            }),
            changePassword: build.mutation<
                ChangePasswordApiResponse,
                ChangePasswordApiArg
            >({
                query: (queryArg) => ({
                    url: '/api/v1/auth/change-password',
                    method: 'POST',
                    body: queryArg,
                }),
                invalidatesTags: ['Authentication'],
            }),
            oauthLink: build.query<OauthLinkApiResponse, OauthLinkApiArg>({
                query: (queryArg) => ({ url: `/api/v1/auth/oauth/link/${queryArg}` }),
                providesTags: ['Authentication'],
            }),
            oauthAuthorize: build.mutation<
                OauthAuthorizeApiResponse,
                OauthAuthorizeApiArg
            >({
                query: (queryArg) => ({
                    url: '/api/v1/auth/oauth/authorize',
                    method: 'POST',
                    body: queryArg,
                }),
                invalidatesTags: ['Authentication'],
            }),
        }),
        overrideExisting: false,
    });
export { injectedRtkApi as authenticationApi };
export type LoginApiResponse =
  /** status 200 Пользователь авторизован */ LoginResponseDto;
export type LoginApiArg = LoginRequestDto;
export type RegisterApiResponse =
  /** status 200 Пользователь зарегистрирован */ LoginResponseDto;
export type RegisterApiArg = RegisterRequestDto;
export type LogoutApiResponse =
  /** status 200 Пользователь деавторизован */ void;
export type LogoutApiArg = void;
export type ValidateApiResponse =
  /** status 200 Пользователь авторизован */ LoginResponseDto;
export type ValidateApiArg = void;
export type RefreshApiResponse = /** status 200 Токен обновлен */ UserEntity;
export type RefreshApiArg = RefreshRequestDto;
export type ResetApiResponse =
  /** status 200 Пароль отправлен пользователю */ void;
export type ResetApiArg = ResetRequestDto;
export type ChangePasswordApiResponse = /** status 200 Пароль изменен */ void;
export type ChangePasswordApiArg = ChangePasswordRequestDto;
export type OauthLinkApiResponse =
  /** status 200 Ссылка на авторизацию в стороннем провайдере */ OAuthLinkResponse;
export type OauthLinkApiArg =
  /** Тип провайдера (google, mail, vk, yandex) */ any;
export type OauthAuthorizeApiResponse =
  /** status 200 Авторизация выполнена успешно */ LoginResponseDto;
export type OauthAuthorizeApiArg = OAuthAuthorizeRequest;
export type ShortEntity = {
    /** ID */
    id: string;
    /** Name */
    name: string;
};
export type FindOrganizationResponseDto = {
    id: string;
    name: string;
};
export type FindRolePermissionDto = {
    id: string;
    name: string;
    description: string;
    allow: boolean;
};
export type FindRolePermissionGroupDto = {
    name: string;
    permissions: FindRolePermissionDto[];
};
export type FindRoleResponseDto = {
    id: string;
    name: string;
    enabled: boolean;
    visible: boolean;
    type: 'MANAGER' | 'WORKER';
    organization: FindOrganizationResponseDto;
    permissions: FindRolePermissionGroupDto[];
};
export type FindSpecializationResponseDto = {
    id: string;
    name: string;
};
export type LoginResponseDto = {
    /** User ID */
    id: string;
    /** User created datetime */
    createdAt: string;
    /** User updated datetime */
    updatedAt: string;
    /** User name */
    name: string;
    /** User email */
    email: string;
    /** User phone */
    phone?: string;
    /** User job name */
    job?: string;
    /** User avatar */
    avatar?: ShortEntity;
    /** User department */
    department?: ShortEntity;
    /** User roles */
    roles: FindRoleResponseDto[];
    /** User organizations */
    organizations: ShortEntity[];
    /** User permissions */
    permissions: string[];
    specializations: FindSpecializationResponseDto[];
    /** User type */
    type: 'STAFF' | 'INDIVIDUAL' | 'CASH';
};
export type ApiError = {
    status: number;
    message: string[];
    error: string;
};
export type LoginRequestDto = {
    /** User email */
    email: string;
    /** User password */
    password: string;
};
export type RegisterRequestDto = {
    name: string;
    email: string;
    password: string;
    roles: string[];
};
export type SpecializationEntity = {
    /** Created date */
    createdAt: string;
    /** Updated date */
    updatedAt: string;
    /** ID */
    id: string;
    /** specialization name */
    name: string;
    /** specialization users */
    users: object;
    /** specialization jobs */
    jobs: object;
};
export type OrganizationEntity = {
    /** Created date */
    createdAt: string;
    /** Updated date */
    updatedAt: string;
    /** ID */
    id: string;
};
export type RoleEntity = {
    /** Created date */
    createdAt: string;
    /** Updated date */
    updatedAt: string;
    /** ID */
    id: string;
    /** Role name */
    name: string;
    organization: OrganizationEntity;
};
export type GroupEntity = {
    /** Created date */
    createdAt: string;
    /** Updated date */
    updatedAt: string;
    /** ID */
    id: string;
};
export type UserEntity = {
    /** Created date */
    createdAt: string;
    /** Updated date */
    updatedAt: string;
    /** ID */
    id: string;
    /** User name */
    name: string;
    /** User email */
    email: string;
    /** User phone number */
    phone: string;
    /** User job name */
    job: string;
    /** User work type enum */
    type: string;
    /** User chat id in Telegram */
    chatId: string;
    /** User specializations */
    specializations: SpecializationEntity[];
    /** User roles */
    roles: RoleEntity[];
    /** User groups */
    groups: GroupEntity[];
};
export type RefreshRequestDto = {
    /** Refresh toke */
    refreshToken: string;
};
export type ResetRequestDto = {
    /** Адрес электронной почты для восстановления пароля */
    email: string;
};
export type ChangePasswordRequestDto = {
    /** Hash сброса пароля */
    hash: string;
    /** Новый пароль */
    password: string;
};
export type OAuthLinkResponse = {
    /** OAuth link */
    link: string;
};
export type OAuthAuthorizeRequest = {
    /** Authorization code */
    code: string;
    /** Cached local state */
    state: string;
};
export const {
    useLoginMutation,
    useRegisterMutation,
    useLogoutQuery,
    useLazyLogoutQuery,
    useValidateQuery,
    useLazyValidateQuery,
    useRefreshMutation,
    useResetMutation,
    useChangePasswordMutation,
    useOauthLinkQuery,
    useLazyOauthLinkQuery,
    useOauthAuthorizeMutation,
} = injectedRtkApi;
