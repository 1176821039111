import {
    bankAccountFormGlobalInitialState
} from 'components/Clients/PageBody/ClientFormParts/BankAccountForms/BankAccountForms.utils'
import { clientContactDefault } from 'components/Clients/PageBody/ClientFormParts/ContactForms/ClientContactForms.utils'
import { FindClientResponseDto, UpdateClientRequestDto } from 'store/api/clients'
import { AutocompleteSubmitData, InputChangeArg, InputSubmitArg } from 'types/inputs'
import { autocompleteChangeArg, autocompleteFieldDefault, autocompleteSubmitArg } from 'utils/inputs'
import {
    ClientFormBody,
    ClientFormHeader,
    ClientsAutocompleteKey,
    ClientsFormGlobal,
    FindClientResponseDtoCustom
} from './Clients.types'


// *
// DATA CONVERT FUNCS
// *

export const convertClientDtoToFormBody = (client: FindClientResponseDtoCustom): ClientFormBody => ({
    birthDate: client?.birthDate || null,
    firstName: client?.firstName || clientFormBodyInitialState.firstName,
    lastName: client?.lastName || clientFormBodyInitialState.lastName,
    middleName: client?.middleName || clientFormBodyInitialState.middleName,
    fullName: client?.fullName || clientFormBodyInitialState.fullName,
    abbreviatedName: client?.abbreviatedName || clientFormHeaderInitialState.name, // types difference (abbreviatedName = name) is made specially
    passportNumber: client?.passportNumber || clientFormBodyInitialState.passportNumber,
    passportIssuer: client?.passportIssuer || clientFormBodyInitialState.passportIssuer,
    passportIssueDate: client?.passportIssueDate || null,
    passportIssuingUnitCode: client?.passportIssuingUnitCode || clientFormBodyInitialState.passportIssuingUnitCode,
    registrationAddress: client?.registrationAddress || clientFormBodyInitialState.registrationAddress,
    legalAddress: client?.legalAddress || clientFormBodyInitialState.legalAddress,
    inn: client?.inn || clientFormBodyInitialState.inn,
    ogrn: client?.ogrn || clientFormBodyInitialState.ogrn,
    kpp: client?.kpp || clientFormBodyInitialState.kpp,
    okato: client?.okato || clientFormBodyInitialState.okato,
    okpo: client?.okpo || clientFormBodyInitialState.okpo,
    okved: client?.okved || clientFormBodyInitialState.okved,
    deleted: client?.deleted || clientFormBodyInitialState.deleted,

    kind: client?.kind ? { name: client.kind.name, data: { id: client.kind.id, name: client.kind.name } }:autocompleteFieldDefault,
    taxationSystem: client?.taxationSystem ? { name: client.taxationSystem.name, data: { id: client.taxationSystem.id, name: client.taxationSystem.name } }:autocompleteFieldDefault,
    organization: client?.organization ? { name: client.organization?.name, data: { id: client.organization?.id, name: client.organization?.name } }:autocompleteFieldDefault,

    type: {
        name: client?.types?.map(t => t.name)[0] || '',
        data: {
            id: client?.types?.map(t => t.id)[0] || '', // temp
            name: client?.types?.map(t => t.name)[0] || '', // temp
        }
    },

    accounts: client?.accounts?.length ? client.accounts:clientFormBodyInitialState.accounts,
    contacts: client?.contacts?.length ? client.contacts:clientFormBodyInitialState.contacts,
})
export const convertClientDtoToFormHeader = (client: FindClientResponseDto): ClientFormHeader => ({
    name: client?.abbreviatedName || clientFormHeaderInitialState.name, // types difference (abbreviatedName = name) is made specially
})
export const convertClientToRequestDto = (data: Partial<ClientFormBody & ClientFormHeader>): UpdateClientRequestDto => ({
    birthDate: data?.birthDate || null,
    firstName: data?.firstName || clientFormBodyInitialState.firstName,
    lastName: data?.lastName || clientFormBodyInitialState.lastName,
    middleName: data?.middleName || clientFormBodyInitialState.middleName,
    fullName: data?.fullName || clientFormBodyInitialState.fullName,
    abbreviatedName: data?.abbreviatedName || clientFormHeaderInitialState.name, // types difference (abbreviatedName = name) is made specially
    passportNumber: data?.passportNumber || undefined,
    passportIssuer: data?.passportIssuer || clientFormBodyInitialState.passportIssuer,
    passportIssueDate: data?.passportIssueDate || undefined,
    passportIssuingUnitCode: data?.passportIssuingUnitCode || undefined,
    registrationAddress: data?.registrationAddress || clientFormBodyInitialState.registrationAddress,
    legalAddress: data?.legalAddress || clientFormBodyInitialState.legalAddress,
    inn: data?.inn || clientFormBodyInitialState.inn,
    ogrn: data?.ogrn || clientFormBodyInitialState.ogrn,
    kpp: data?.kpp || clientFormBodyInitialState.kpp,
    okato: data?.okato || clientFormBodyInitialState.okato,
    okpo: data?.okpo || clientFormBodyInitialState.okpo,
    okved: data?.okved || clientFormBodyInitialState.okved,
    // deleted: data?.deleted || clientFormBodyInitialState.deleted,

    kindId: data?.kind && (data?.kind?.data.id || data?.kind?.name) || clientFormBodyInitialState.kind.name,
    taxationSystemId: data?.taxationSystem && (data?.taxationSystem?.data.id || data?.taxationSystem?.name) || null,
    organizationId: data?.organization && (data?.organization.data.id || data?.organization?.name) || clientFormBodyInitialState.organization.name,
    typesIds:
            data?.type?.data?.id?.length ?
                [data?.type.data?.id]:
                data?.type?.name?.length ?
                    [data.type.name]:
                    [clientFormBodyInitialState.type.data.id],
})


// *
// STORE
// *

export const clientsFormGlobalDefaultValues: ClientsFormGlobal = {
    name: '',
    birthDate: null,
    firstName: '',
    lastName: '',
    middleName: '',
    fullName: '',
    abbreviatedName: '', // types difference (abbreviatedName = name) is made specially'',
    passportNumber: 0,
    passportIssuer: '',
    passportIssueDate: '',
    passportIssuingUnitCode: '',
    registrationAddress: '',
    legalAddress: '',
    inn: '',
    ogrn: '',
    kpp: '',
    okato: '',
    okpo: '',
    okved: '',
    deleted: false,

    kind: autocompleteFieldDefault,
    taxationSystem: autocompleteFieldDefault,
    type: autocompleteFieldDefault,
    organization: autocompleteFieldDefault,

    accounts: [bankAccountFormGlobalInitialState],
    contacts: [clientContactDefault],
}
export const clientFormHeaderInitialState: ClientFormHeader = {
    name: '',
}

export const deletedAutocompleteFieldDefault: { value: string } = {
    value: '',
}
export const clientFormBodyInitialState: ClientFormBody = {
    birthDate: null,
    firstName: '',
    lastName: '',
    middleName: '',
    fullName: '',
    abbreviatedName: '', // types difference (abbreviatedName = name) is made specially'',
    passportNumber: 0,
    passportIssuer: '',
    passportIssueDate: '',
    passportIssuingUnitCode: '',
    registrationAddress: '',
    legalAddress: '',
    inn: '',
    ogrn: '',
    kpp: '',
    okato: '',
    okpo: '',
    okved: '',
    //organizationId: '',
    deleted: false,

    kind: autocompleteFieldDefault,
    taxationSystem: autocompleteFieldDefault,
    type: autocompleteFieldDefault,
    organization: autocompleteFieldDefault,

    accounts: [bankAccountFormGlobalInitialState],
    contacts: [clientContactDefault],
}

// *
// INPUT PROCESSING FUNCS
// *

// global
export const onClientsGlobalAutocompleteSubmit = ({ key, setFormValue }: InputSubmitArg<ClientsAutocompleteKey, ClientsFormGlobal>) =>
    (data: AutocompleteSubmitData) => {
        setFormValue(key, autocompleteSubmitArg(data))
    }
export const onClientsGlobalAutocompleteChange = ({ formData, key, setFormValue }: InputChangeArg<ClientsAutocompleteKey, ClientsFormGlobal>) =>
    (label: string) => {
        if (formData[key]?.name!==label) {
            setFormValue(key, autocompleteChangeArg(label))
        }
    }
// export const onClientGlobalMultiselectChange = ({ formData, key, setFormValue }: InputChangeArg<ClientsMultiselectFilterParamKey, ClientsFormGlobal>) => (label: string) => {
//     if (formData[key]?.name !== label) {
//         setFormValue(`${key}.name`, label)
//     }
// }
// export const onClientGlobalMultiselectSubmit = ({ key, setFormValue }: InputSubmitArg<ClientsMultiselectFilterParamKey, ClientsFormGlobal>) => (valueIds: Array<string>) => {
//     setFormValue(`${key}.ids`, valueIds)
// }

// body
export const onClientBodyAutocompleteSubmit = ({ key, setFormValue }: InputSubmitArg<ClientsAutocompleteKey, ClientFormBody>) =>
    (data: AutocompleteSubmitData) => {
        setFormValue(key, autocompleteSubmitArg(data))
    }
export const onClientBodyAutocompleteChange = ({ formData, key, setFormValue }: InputChangeArg<ClientsAutocompleteKey, ClientFormBody>) =>
    (label: string) => {
        if (formData[key]?.name!==label) {
            setFormValue(key, autocompleteChangeArg(label))
        }
    }

// export const onClientBodyMultiselectChange = ({ formData, key, setFormValue }: InputChangeArg<ClientsMultiselectFilterParamKey, ClientFormBody>) => (label: string) => {
//     if (formData[key]?.name !== label) {
//         setFormValue(`${key}.name`, label)
//     }
// }
// export const onClientBodyMultiselectSubmit = ({ key, setFormValue }: InputSubmitArg<ClientsMultiselectFilterParamKey, ClientFormBody>) => (valueIds: Array<string>) => {
//     setFormValue(`${key}.ids`, valueIds)
// }
