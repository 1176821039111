import { createAppSlice } from 'utils/createAppSlice'
import { defaultInitialState } from 'utils/defaultInitialState.ts'
import {
    replaceMaterialBodyInitialState,
    replaceMaterialHeaderInitialState
} from 'components/Projects/PageBody/Correctives/CorrectivesParts/Filters/replaceMaterial.utils.ts';
import {
    ReplaceCorrectionMaterialState
} from 'components/Projects/PageBody/Correctives/CorrectivesParts/Filters/replaceMaterial.types.ts';

const replaceCorrectionMaterialInitialState: ReplaceCorrectionMaterialState = {
    ...defaultInitialState,
    editableFormHeader: replaceMaterialHeaderInitialState,
    editableFormBody: replaceMaterialBodyInitialState,
}

export const replaceCorrectionMaterialSlice = createAppSlice<ReplaceCorrectionMaterialState>('replaceCorrectionMaterial',  replaceCorrectionMaterialInitialState)

export default replaceCorrectionMaterialSlice.reducer
