import { FindPackageResponseDto, UpdatePackageRequestDto } from 'store/api/resourcePackages'
import { PackageFormBody, PackageFormHeader, PackagesFormGlobal } from './Packages.types'


// *
// DATA CONVERT FUNCS
// *

export const convertPackageDtoToFormBody = (pack: FindPackageResponseDto): PackageFormBody => ({
    description: pack?.description || packageFormBodyInitialState.description,
})
export const convertPackageDtoToFormHeader = (pack: FindPackageResponseDto): PackageFormHeader => ({
    name: pack?.name || packageFormHeaderInitialState.name,
})
export const convertPackageFormToRequestDto = (data: Partial<PackageFormBody & PackageFormHeader>): UpdatePackageRequestDto => ({
    name: data?.name || packageFormHeaderInitialState.name,
    description: data?.description || packageFormBodyInitialState.description,
})


// *
// STORE
// *

export const packagesFormGlobalDefaultValues: PackagesFormGlobal = {
    name: '',
    description: '',
}
export const packageFormHeaderInitialState: PackageFormHeader = {
    name: '',
}
export const packageFormBodyInitialState: PackageFormBody = {
    description: '',
}


// *
// INPUT PROCESSING FUNCS
// *
