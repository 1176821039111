import React from 'react'
import styles from 'components/Worker/Dashboard.module.css'
import { Box, Drawer } from '@mantine/core'
import { WorkDrawerProps } from 'components/Worker/Drawers/Types/WorkDrawerProps'

export const WorkDrawer: React.FC<WorkDrawerProps> = ({
    drawerRef,
    isDrawerOpened,
    close,
    children,
}: WorkDrawerProps) =>
    <Drawer.Root
        closeOnClickOutside={ false }
        closeOnEscape={ false }
        withinPortal={ false }
        position={ 'right' }
        shadow={ 'none' }
        lockScroll={ false }
        target={ drawerRef?.current ?? undefined }
        opened={ isDrawerOpened }
        size={ 'lg' }
        onClose={ close }
        styles={{
            inner: {
                right: 0,
            },
        }}
    >
        <Drawer.Content
            radius={ 'sm' }
            className={ styles.drawerContent }
        >
            <Drawer.Header
                className={ styles.drawerHeader }>
                <Drawer.CloseButton
                    iconSize={ 20 }
                    size={ 'md' }
                    className={ styles.drawerCloseButton }/>
            </Drawer.Header>
            <Drawer.Body p={0}>
                <Box className={ styles.drawer }>
                    { children }
                </Box>
            </Drawer.Body>
        </Drawer.Content>
    </Drawer.Root>