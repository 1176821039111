import './wdyr.ts'

import { ProviderAuth } from 'providers/ProviderAuth/ProviderAuth'
import { ProviderMantineGlobal } from 'providers/ProviderMantineGlobal/ProviderMantineGlobal'
import { ProviderRedux } from 'providers/ProviderRedux/ProviderRedux'
import ReactDOM from 'react-dom/client'
import 'types/number'
import { SentryInit } from './sentry.tsx'
// import '@mantine/carousel/styles.css'

// Add Sentry bug capture
SentryInit()

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <ProviderMantineGlobal>
        <ProviderRedux>
            <ProviderAuth/>
        </ProviderRedux>
    </ProviderMantineGlobal>,
)
