
import { autocompleteChangeArg, autocompleteFieldDefault, autocompleteSubmitArg } from 'utils/inputs.tsx';
import { AutocompleteSubmitData, InputChangeArg, InputSubmitArg } from 'types/inputs.ts';
import {
    WarehouseInventoriesAutocompleteKey,
    WarehouseInventoriesFormBody, WarehouseInventoriesFormGlobal,
    WarehouseInventoriesFormHeader,
} from 'components/Supply/Warehouses/PageBody/Inventories/WarehouseInventories.types.ts';
export const warehouseInventoriesFormHeaderInitialState: WarehouseInventoriesFormHeader = {
    name: ''
}

export const warehouseInventoriesFormBodyInitialState:  WarehouseInventoriesFormBody = {
    status: autocompleteFieldDefault,
    organisation: autocompleteFieldDefault,
}

export const warehouseInventoriesFormGlobalDefaultValues:  WarehouseInventoriesFormGlobal = {
    name: '',
    status: autocompleteFieldDefault,
    organization: autocompleteFieldDefault,
}

export const onWarehouseInventoriesGlobalAutocompleteSubmit = ({
    key,
    setFormValue,
}: InputSubmitArg<WarehouseInventoriesAutocompleteKey, WarehouseInventoriesFormGlobal>) => (data: AutocompleteSubmitData) => {
    setFormValue(key, autocompleteSubmitArg(data))
}
export const onWarehouseInventoriesGlobalAutocompleteChange = ({
    formData,
    key,
    setFormValue,
}: InputChangeArg<WarehouseInventoriesAutocompleteKey, WarehouseInventoriesFormGlobal>) => (label: string) => {
    if (formData[key]?.name !== label) {
        setFormValue(key, autocompleteChangeArg(label))
    }
}
