import { createStyles } from '@mantine/core'

export const useStylesMantineReactTable = createStyles(theme => ( {
    mantinePaper: {
        border: 'none !important',
        boxShadow: 'none !important',
        backgroundColor: theme.colorScheme === 'dark'
            ? `${ theme.colors.dark[5] } !important`
            : `${ theme.colors.natural[0] } !important`,
        overflow: 'visible !important',
        padding: '0 !important',
        '.mantine-Select-wrapper': {
            input: {
                backgroundColor: `${ theme.colors.natural[0] } !important`,
                fontWeight: 600,
                marginRight: '10px',
            },
        },
    },
    mantineToolbar: {
        display: 'block !important',
        backgroundColor: theme.colorScheme === 'dark'
            ? `${ theme.colors.dark[5] } !important`
            : `${ theme.colors.natural[0] } !important`,
        margin: '0 !important',
    },
    stickyTopToolbar: {
        position: 'sticky',
        top: '60px',
        paddingTop: '32px',
        backgroundColor: theme.colors.natural[0],
        zIndex: 3,
    },
    stickyTopToolbarWithoutTopGap: {
        position: 'sticky',
        top: 0,
        backgroundColor: theme.colors.natural[0],
        zIndex: 3,
    },
    mantineTable: {
        border: 'none !important',
        boxShadow: 'none !important',
        overflow: 'scroll !important',
    },
    mantinePagination: {
        backgroundColor: theme.colorScheme === 'dark'
            ? `${ theme.colors.dark[5] } !important`
            : `${ theme.colors.natural[0] } !important`,
        gap: 4,
        button: {
            border: 'none',
            backgroundColor: 'transparent',
            fontSize: '14px',
            fontWeight: 600,
            lineHeight: '20px',
        },
        '& button[data-active]': {
            borderRadius: '4px',
            gap: '10px',
            backgroundColor: theme.colorScheme === 'dark'
                ? `${ theme.colors.dark[5] } !important`
                : `${ theme.colors.natural[1] } !important`,
            color: theme.colors.info[5],
            
            '&:hover': {
                ':not([data-disabled])': {
                    backgroundColor: theme.colorScheme === 'dark'
                        ? theme.colors.dark[5]
                        : theme.colors.natural[1],
                },
            },
        },
    },
    mantineTableHead: {
        backgroundColor: theme.colorScheme === 'dark'
            ? `${ theme.colors.dark[5] } !important`
            : `${ theme.colors.natural[0] } !important`,
        position: 'sticky',
        top: '0px !important',
        zIndex: 1,
        boxShadow: 'none !important',
        tr: {
            border: 'none !important',
            boxShadow: 'none !important',
            
            th: {
                border: 'none !important',
                boxShadow: 'none !important',
            },
        },
    },
    mantineTableHeadCell: {
        backgroundColor: theme.colorScheme === 'dark'
            ? `${ theme.colors.dark[5] } !important`
            : `${ theme.colors.natural[1] } !important`,
        fontSize: '13px !important',
        fontWeight: 500,
        lineHeight: '16px !important',
        wordBreak: 'keep-all',
        color: `${ theme.colors.natural[6] } !important`,
    },
    mantineTableBody: {
        backgroundColor: theme.colorScheme === 'dark'
            ? `${ theme.colors.dark[6] } !important`
            : 'white !important',
        borderWidth: '0px !important',
        borderStyle: 'solid !important',
        borderColor: `${ theme.colors.natural[2] } !important`,
        borderRadius: '8px',
        // overflow: 'hidden', // to use border radius correctly (may cause bugs when table is wide)
        paddingTop: '12px !important',
        paddingBottom: '12px !important',
    },
    mantineTableBodyRow: {
        cursor: 'pointer',
        borderRadius: '8px',
        td: {
            paddingTop: '10px !important',
            paddingBottom: '10px !important',
            display: 'flex',
            alignItems: 'center',
            lineHeight: '20px !important',
            justifyContent: 'left',
            color: `${ theme.colors.natural[9] } !important`,
            backgroundColor: theme.colorScheme === 'dark'
                ? `${ theme.colors.dark[6] } !important`
                : 'white !important',
        },
        '&:hover': {
            td: {
                backgroundColor: theme.colorScheme === 'dark'
                    ? `${ theme.colors.dark[3] } !important`
                    : `${ theme.colors.primary[0] } !important`,
            },
        },
    },
    mantineTableBodyCell: {
        borderTop: 'none !important',
        borderBottom: 'none !important',
    },
} ))
