import { Dayjs } from 'dayjs'
import dayjs from 'utils/dayjs.ts'

export const dateLabel = (day: string | null): string | null => {
    if (!day) {
        return null
    }
    const formatted: Dayjs = dayjs(day, 'YYYY-MM-DD')
    if (dayjs().isSame(formatted, 'day')) {
        return 'Сегодня'
    } else if (dayjs().add(1, 'day').isSame(formatted, 'day')) {
        return 'Завтра'
    } else if (dayjs().subtract(1, 'day').isSame(formatted, 'day')) {
        return 'Вчера'
    }
    return null
}