import { ReactNode, useEffect, useState } from 'react';
import { useLazyProjectWorksFindOneQuery } from 'store/api/projectWorks.tsx';
import { FindWorkResponseDto } from 'store/api/projectTasks.tsx';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from 'hooks/redux.ts';
import { activeWorkSlice } from 'store/slices/activeWorkSlice.ts';
import { Loader } from '@mantine/core';

type WorkLoadWrapperProps = { children: ReactNode }
export const WorkLoadWrapper = ({ children }: WorkLoadWrapperProps) => {

    const { workId } = useParams()

    const dispatch = useAppDispatch()

    const [findWorkApiCall, { isLoading: isWorkLoading }]  = useLazyProjectWorksFindOneQuery()
    const [selectedWork, setSelectedWork] = useState<FindWorkResponseDto | null>(null)
    const { setActiveWorkToStore } = activeWorkSlice.actions

    useEffect(() =>{
        if (!selectedWork) return

        dispatch(setActiveWorkToStore(selectedWork))

    }, [selectedWork])

    useEffect(() => {
        workId && findWorkApiCall(workId)
            .unwrap()
            .then(({ data }) => setSelectedWork(data))

    }, [workId]);

    return isWorkLoading ? <Loader /> : children
}
