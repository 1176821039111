import { defaultInitialState } from 'utils/defaultInitialState.ts.ts';
import { createAppSlice } from 'utils/createAppSlice.ts';
import {
    ResourceStatementSliceInitialState
} from 'components/Projects/PageBody/ResourceStatement/ResourceStatement.types.ts';
import {
    resourceStatementFormBodyInitialState,
    resourceStatementFormHeaderInitialState
} from 'components/Projects/PageBody/ResourceStatement/ResourceStatement.utils.ts';

const resourceStatementInitialState: ResourceStatementSliceInitialState = {
    ...defaultInitialState,
    editableFormHeader: resourceStatementFormHeaderInitialState,
    editableFormBody: resourceStatementFormBodyInitialState,
}

export const resourceStatementSlice = createAppSlice<ResourceStatementSliceInitialState>('resourceStatement', resourceStatementInitialState)

export default resourceStatementSlice.reducer

