import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Box, Flex, Grid, Title } from '@mantine/core'
import { WorkDrawer } from 'components/Worker/Drawers/WorkDrawer.tsx'
import { useDisclosure, useMediaQuery } from '@mantine/hooks'
import { MEDIA_MAX } from 'styles/constants.ts'
import { WorkSummary } from 'components/Worker/Drawers/Nested/WorkSummary.tsx'
import { PerformerFindApiResponse, PerformerFindOutdatedApiResponse } from 'store/api/performer.tsx'
import styles from 'components/Worker/Dashboard.module.css'
import dayjs from 'utils/dayjs.ts'
import { IconCircleFilled } from '@tabler/icons-react'
import { EmptyCalendarDate } from 'components/Worker/Calendar/Nested/EmptyCalendarDate.tsx'
import { dateLabel } from 'components/Worker/Utils/date.label.ts'
import { useNavigate } from 'react-router-dom'
import { WorkWithStartWorkTime, WorkWithMoneyShow, CombinedWork } from 'components/Worker/Calendar/Types/WorkCardProps.ts'
import { WorkListProps } from '../Calendar/Types/CalendarProps.ts'
import { WorkCard } from 'components/Worker/Work/WorkCard.tsx'


export const WorkList: React.FC<WorkListProps> = ({ works, variant }) => {
    
    const isSmScreen = useMediaQuery(MEDIA_MAX.SM)
    
    const [activeWork, setActiveWork] = useState<CombinedWork | null>(null)
    
    const drawerRef = useRef(null)
    
    const [ formattedWorks, setFormattedWorks ]  = useState<{ [key: string]: WorkWithMoneyShow[] | number } | null>( null)
    
    const [activeDate, setActiveDate] = useState<string | null>(null)
    
    const [isDrawerOpened, { open, close }] = useDisclosure(false)
    
    const navigate = useNavigate()
    
    const openWork = (date: string, work: CombinedWork) => {
        setActiveDate(date)
        setActiveWork(work)
        localStorage.setItem('workDate', JSON.stringify(date))
        
        if (!isSmScreen) {
            open()
        } else {
            if (variant === 'overdue') {
                navigate(`/overdue/work/${ work.id }`)
            } else if (variant === 'calendar') {
                navigate(`/work/${ work.id }`)
            }
        }
    }
    
    const getFormattedWorks= useMemo(() => {
        
        const typedWorks = works as PerformerFindApiResponse['data']
        
        const formattedWorks: { [key: string]: WorkWithMoneyShow[] | number } = { ...typedWorks }
        
        const allWorksWithTime: any[][] = []
        
        typedWorks && Object.keys(typedWorks).filter(key => key !== 'outdated').forEach((date) => {
            
            if (typedWorks[date] && Array.isArray(typedWorks[date])) {
                
                const worksInDay = typedWorks[date]
                
                const worksWithStartTime: WorkWithStartWorkTime[] = worksInDay.map((workItem) => {
                    const part = workItem?.parts.find((part) => dayjs(date, 'YYYY-MM-DD').isSame(dayjs(part.from, 'YYYY-MM-DD HH:mm'), 'day'))
                    const workTime = part ? dayjs(part?.from, 'YYYY-MM-DD HH:mm').format('HH:mm') : undefined
                    
                    return {
                        ...workItem,
                        startWorkTime : workTime
                    }
                })
                
                const sortedWorks = worksWithStartTime.sort(function (a, b) {
                    if (dayjs(`${date} ${a.startWorkTime}`).isAfter(`${date} ${b.startWorkTime}`, 'minutes')) { ///is after
                        return 1;
                    }
                    if (dayjs(`${date} ${a.startWorkTime}`).isBefore(`${date} ${b.startWorkTime}`, 'minutes')) { ///is before
                        return -1;
                    }
                    return 0;
                })
                
                const worksWithDateForMoneyCheck = sortedWorks.map(({ id, startWorkTime }) => ({ id, startWorkTime: `${date} ${startWorkTime}` }))
                const uniqueWorksWithDateForMoneyCheck = [...new Map(worksWithDateForMoneyCheck.flat().map(item => [item['id'], item])).values()];
                
                allWorksWithTime.push(uniqueWorksWithDateForMoneyCheck)
                
                formattedWorks[date] = sortedWorks
            } else {
                formattedWorks[date] = []
            }
        })
        
        const worksUniqueById: { id: string, startWorkTime: string }[] = allWorksWithTime.flat().reduce((acc: { id: string, startWorkTime: string }[], curr) => {
            const workWithCurrId = acc.find((el) => el.id === curr.id)
            if (workWithCurrId) {
                if (dayjs(workWithCurrId.startWorkTime).isBefore(dayjs(curr.startWorkTime), 'minutes')) {
                    return acc
                } else {
                    const workWithCurrIdIdx = acc.findIndex((el) => el.id === curr.id)
                    return [
                        ...acc.slice(0, workWithCurrIdIdx),
                        curr,
                        ...acc.slice(workWithCurrIdIdx + 1)
                    ]
                }
            } else return [...acc, curr]
        }, [])
        
        worksUniqueById.forEach((uniqueWork) => {
            
            const workWithMoneyFromFormattedDate = Object.keys(formattedWorks).filter(key => key !== 'outdated')
                .find((date) => {
                    const works: WorkWithStartWorkTime[] =  Array.isArray(formattedWorks[date]) ? formattedWorks[date] : []
                    return  Array.isArray(works) && works.find((work) => {
                    
                        if (work && uniqueWork) {
                        
                            const uniqueWorkTime = uniqueWork.startWorkTime.split(' ')[1]
                            return work.id == uniqueWork.id && work?.startWorkTime == uniqueWorkTime
                        }
                    
                    })
                
                }) as string
            
            const worksForCheckMoney = formattedWorks[workWithMoneyFromFormattedDate]  as WorkWithStartWorkTime[]
            
            formattedWorks[workWithMoneyFromFormattedDate] = worksForCheckMoney.map((work) => {
                const uniqueWorkTime = uniqueWork.startWorkTime.split(' ')[1]
                if (work.id == uniqueWork.id && work?.startWorkTime == uniqueWorkTime) {
                    return {
                        ...work,
                        isShowMoney: true
                    }
                } else {
                    return work
                }
            })
            
        })
        
        return formattedWorks
        
    }, [works])
    
    const getFormattedWorksOverdue= useMemo(() => {
        
        const typedWorks = works as PerformerFindOutdatedApiResponse['data']
        
        const entries = typedWorks?.length ? 
            typedWorks?.map((group) => [group.name, group.works.map(w => ({
                ...w,
                isShowMoney: true,
                project: {
                    responsibles: group?.responsibles,
                    emergencyPhoneNumber: group?.emergencyPhoneNumber,
                    name: group?.name,
                }
            }))]) :
            []
        
        const formattedWorks: { [key: string]: WorkWithMoneyShow[] | number } = entries ? Object.fromEntries(entries) : {  }
        
        return formattedWorks
        
    }, [works])
    
    useEffect(() => {
        if (works) {
            if (variant === 'calendar') {
                setFormattedWorks(getFormattedWorks)
            } else if (variant === 'overdue') {
                setFormattedWorks(getFormattedWorksOverdue)
            }
        }
    }, [works, formattedWorks])
    
    return variant === 'calendar' ? 
        <Box key="workListCalendar" className={ styles.calendarWrapper }>
            { formattedWorks && Object.keys(formattedWorks).filter(key => key !== 'outdated').map((date) =>
                <Grid grow key={ 'grid_' + date } className={ styles.calendarRow }>
                    
                    <Grid.Col sm={ 12 } md={ 3 } lg={ 2 }>
                        <Box className={ styles.calendarDay }>
                            { dayjs(date, 'YYYY-MM-DD').format('dddd') }
                        </Box>
                        
                        <Flex
                            className={ styles.calendarDate }
                            align="center"
                            gap={ 8 }
                        >
                            { dayjs(date, 'YYYY-MM-DD').format('D MMMM') }
                            { dateLabel(date) &&
                                [
                                    <IconCircleFilled key={ 'icon_' + date } size={ '4px' } color={ '#AFBACC' }
                                        stroke={ 0 }/>,
                                    dateLabel(date),
                                ]
                            }
                        </Flex>
                    </Grid.Col>
                    
                    <Grid.Col key="works" sm={ 8 } md={ 9 } lg={ 10 }>
                        <Grid>
                            { formattedWorks[date] && Array.isArray(formattedWorks[date]) && formattedWorks[date]?.length ?
                                formattedWorks[date].map((work, idx) =>
                                    <Grid.Col
                                        key={ 'work_' + idx }
                                        className={ styles.workContainer }
                                        onClick={ () => openWork(date, work) }
                                        sm={ 6 }
                                        md={ 6 }
                                        lg={ 4 }
                                    >
                                        <WorkCard
                                            variant={'calendar'}
                                            isActive={ activeWork ? work.id === activeWork.id : false }
                                            work={ work }
                                            date={ date }
                                        />
                                    </Grid.Col>,
                                ) : <Grid.Col span={ 12 } className={ styles.workContainer }>
                                    <EmptyCalendarDate string={ 'Нет работ' }/>
                                </Grid.Col>
                            }
                        </Grid>
                    </Grid.Col>
                    
                </Grid>,
            ) }
            
            <WorkDrawer
                drawerRef={ drawerRef }
                isDrawerOpened={ isDrawerOpened }
                close={ close }
            >
                <WorkSummary variant={'calendar'} date={ activeDate } work={ activeWork } />
            </WorkDrawer>
        </Box>
        : 
        <Box key="workListOverdue" className={ styles.calendarWrapper }>
            <Title
                order={2}
                sx={theme => ({
                    color: theme.colors.natural[8],
                    padding: isSmScreen ? 8 : 'inherit',
                })}
            >
                Список задолжностей
            </Title>
            { formattedWorks && Object.keys(formattedWorks).filter(key => key !== 'outdated').map((projectName) =>
                <Grid grow key={ 'grid_' + projectName } className={ styles.calendarRowOverdue }>
                    
                    <Grid.Col span={ 12 }>
                        <Title order={3} sx={theme => ({ color: theme.colors.natural[8] })}>
                            { projectName }
                        </Title>
                    </Grid.Col>
                    
                    <Grid.Col key="works" span={ 12 }>
                        <Grid gutter={8}>
                            { formattedWorks[projectName] && Array.isArray(formattedWorks[projectName]) ?
                                formattedWorks[projectName].map((work, idx) =>
                                    <Grid.Col
                                        key={ 'work_' + idx }
                                        onClick={ () => openWork(work?.from ? dayjs(work?.from).format('YYYY-MM-DD'): '', work) }
                                        sm={ 6 }
                                        md={ 6 }
                                        lg={ 4 }
                                    >
                                        <WorkCard
                                            variant={'overdue'}
                                            isActive={ activeWork ? work.id === activeWork.id : false }
                                            work={ work }
                                            date={ projectName }
                                        />
                                    </Grid.Col>,
                                ) : <Grid.Col span={ 12 }>
                                    <EmptyCalendarDate string={ 'Нет задач' }/>
                                </Grid.Col>
                            }
                        </Grid>
                    </Grid.Col>
                    
                </Grid>,
            ) }
            
            <WorkDrawer
                drawerRef={ drawerRef }
                isDrawerOpened={ isDrawerOpened }
                close={ close }
            >
                <WorkSummary variant={'overdue'} date={ activeDate } work={ activeWork } />
            </WorkDrawer>
        </Box>
}
