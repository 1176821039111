import { createSlice, Draft } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import {
    FindProjectAdjustmentResponseDto,
    ProjectAdjustmentResourceMaterial,
} from 'store/api/projectAdjustments.tsx';
import {
    CreateProjectAdjustmentResourceMaterialRequestDto,
    FindProjectAdjustmentListResponseDto
} from 'store/api/projectAdjustmentResources.tsx';
import { ChangedResourceWithId } from 'components/Projects/PageBody/Correctives/Corrective.types.ts';

interface MaterialsDataForReplace extends ProjectAdjustmentResourceMaterial {
    isDeleted: boolean,
    workId: string,
    isFromClient: boolean,
    replacementId: undefined | string
}

type AdjustmentInitialState = {
    adjustments: FindProjectAdjustmentResponseDto[] | null,
    adjustment: FindProjectAdjustmentListResponseDto | null,
    adjustmentView:  FindProjectAdjustmentListResponseDto | null,
    originalAdjustment: FindProjectAdjustmentListResponseDto | null,
    changedResources: ChangedResourceWithId[],
    materialsDataForReplace: MaterialsDataForReplace | undefined,
    addedMaterials: CreateProjectAdjustmentResourceMaterialRequestDto[],

}

const initialState: AdjustmentInitialState = {
    adjustments: null,
    adjustment: null,
    adjustmentView: null,
    originalAdjustment: null,
    changedResources: [],
    materialsDataForReplace: undefined,
    addedMaterials: [],

}

export const adjustmentSlice = createSlice({
    name: 'adjustment',
    initialState,
    reducers: {
        setAdjustmentsToStore: (state: Draft<AdjustmentInitialState>, action: PayloadAction<FindProjectAdjustmentResponseDto[]>) => {
            state.adjustments = action.payload
        },
        setAdjustmentToStore: (state: Draft<AdjustmentInitialState>, action: PayloadAction<FindProjectAdjustmentListResponseDto>) => {
            state.adjustment = action.payload
        },
        setAdjustmentViewDataToStore: (state: Draft<AdjustmentInitialState>, action: PayloadAction<FindProjectAdjustmentListResponseDto>) => {
            state.adjustmentView = action.payload
        },
        setOriginalAdjustmentToStore: (state: Draft<AdjustmentInitialState>, action: PayloadAction<FindProjectAdjustmentListResponseDto>) => {
            state.originalAdjustment = action.payload
        },
        setChangedResources:(state: Draft<AdjustmentInitialState>, action: PayloadAction<ChangedResourceWithId[]>) => {
            state.changedResources = action.payload
        },

        setMaterialsDataForReplace: (state: Draft<AdjustmentInitialState>, action: PayloadAction<MaterialsDataForReplace | undefined>) => {
            state.materialsDataForReplace= action.payload
        },
        setAddedMaterials: (state: Draft<AdjustmentInitialState>, action: PayloadAction<CreateProjectAdjustmentResourceMaterialRequestDto[]>) => {
            state.addedMaterials = action.payload
        },

    }
})

export default adjustmentSlice.reducer
