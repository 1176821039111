import { Navigate, RouteObject } from 'react-router-dom'
import { UnderConstruction } from 'components/AbstractComponents/UnderConstruction/UnderConstruction.tsx'

export const ADMIN_ROUTES: Array<RouteObject> = [
    {
        index: true,
        element: <Navigate to={ 'tenants' }/>,
    },
    {
        id: 'adminTenants',
        path: 'tenants',
        element: <UnderConstruction/>,
        loader: () => ({ name: 'Тенанты', path: '/admin/tenants' }),
    },
    {
        id: 'adminOrganizations',
        path: 'organizations',
        element: <UnderConstruction/>,
        loader: () => ({ name: 'Организации', path: '/admin/organizations' }),
    },
    {
        id: 'adminRoles',
        path: 'roles',
        element: <UnderConstruction/>,
        loader: () => ({ name: 'Роли', path: '/admin/roles' }),
    },
    {
        id: 'adminUsers',
        path: 'users',
        element: <UnderConstruction/>,
        loader: () => ({ name: 'Пользователи', path: '/admin/users' }),
    },
    {
        id: 'adminDepartments',
        path: 'departments',
        element: <UnderConstruction/>,
        loader: () => ({ name: 'Подразделения', path: '/admin/departments' }),
    },
]
