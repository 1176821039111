import { ProjectBodyInfoAutocompleteKey, ProjectFormBody, ProjectFormBodyInfo, ProjectFormHeader, ProjectsAutocompleteKey, ProjectsFormGlobal, ProjectsPercentKey, ProjectTabs, ProjectTabsPath } from 'components/Projects/Projects.types'
import { useLazyWorkFormulasFindQuery } from 'store/api/projectWorkFormulas'
import { CreateProjectRequestDto, CreateProjectResponsibleRequestDto, FindProjectResponseDto } from 'store/api/projects'
import { AutocompleteSubmitData, InputChangeArg, InputSubmitArg } from 'types/inputs'
import { autocompleteChangeArg, autocompleteFieldDefault, autocompleteSubmitArg, multiselectFieldDefault } from 'utils/inputs'
import { convertRoomToStoreFormat } from './PageBody/Rooms/ProjectRooms.utils.ts'
import { ProjectRoom, RoomWithWorks } from './PageBody/Rooms/ProjectRooms.types'
import { useEffect, useRef } from 'react'


// *
// DATA CONVERT FUNCS
// *

export const convertProjectDtoToFormHeader = (project: FindProjectResponseDto): ProjectFormHeader => ( {
    name: project?.name || projectFormHeaderInitialState.name,
} )
export const convertProjectDtoToFormBody = (project: FindProjectResponseDto): ProjectFormBody => {
    const clientName = ( project?.client?.abbreviatedName || project?.client?.fullName
        || `${ project?.client?.lastName || '' } ${ project?.client?.firstName
        || '' } ${ project?.client?.middleName || '' }` ).trim()
    const manager = project.responsibles.filter(r => r.type === 'MANAGER')[0]
    const supervisor = project.responsibles.filter(r => r.type === 'FOREMAN')[0]
    
    return {
        object: {
            name: project?.object?.name,
            data: {
                id: project?.object?.id,
                name: project?.object?.name,
            },
        },
        organization: {
            name: project?.organization?.name,
            data: {
                id: project?.organization?.id,
                name: project?.organization?.name,
            },
        },
        
        manager: {
            name: manager?.user?.name || '',
            data: {
                id: manager?.user?.id || '',
                name: manager?.user?.name || '',
            },
        },
        supervisor: {
            name: supervisor?.user?.name || '',
            data: {
                id: supervisor?.user?.id || '',
                name: supervisor?.user?.name || '',
            },
        },
        
        objectData: project.object,
        status: {
            name: project?.status?.name,
            data: {
                id: project?.status?.id,
                name: project?.status?.name,
            },
        },
        client: {
            name: clientName,
            data: {
                id: project?.client?.id,
                name: clientName,
            },
        },
        
        description: project.description,
        from: project.from,
        to: project.to,
        workingHours: project.workingHours,
        overhead: project.overhead,
        transportCosts: project.transportCosts,
        emergencyPhoneNumber: project.emergencyPhoneNumber,
        images: project.images,
        
        // TODO implement for next sub-pages
        rooms: [],
        correctives: [],
        outlays: [],
        documents: [],
    }
}
export const convertProjectFormToRequestDto = (data: Partial<ProjectsFormGlobal>): CreateProjectRequestDto & {
    images?: string[]
} => {
    const manager: CreateProjectResponsibleRequestDto = {
        type: 'MANAGER',
        userId: data.manager?.data.id || '',
        userGroupId: '',
    } // temp userGroupId
    const foreman: CreateProjectResponsibleRequestDto = {
        type: 'FOREMAN',
        userId: data.supervisor?.data.id || '',
        userGroupId: '',
    } // temp userGroupId
    
    return {
        name: data.name || projectFormHeaderInitialState.name,
        description: data.description || projectsFormBodyInitialState.description,
        from: data.from || projectsFormBodyInitialState.from || '',
        to: data.to || projectsFormBodyInitialState.to || '',
        workingHours: data.workingHours || projectsFormBodyInitialState.workingHours || [],
        overhead: data.overhead || projectsFormBodyInitialState.overhead,
        imagesIds: data.imagesUrls || projectsFormBodyInitialState.imagesUrls || [], // temp
        
        transportCosts: data.transportCosts || projectsFormBodyInitialState.transportCosts,
        emergencyPhoneNumber: data.emergencyPhoneNumber
            || projectsFormBodyInitialState.emergencyPhoneNumber,
        objectId: data.object?.data?.id ? data.object?.data?.id : data.object?.name || '',
        statusId: data.status?.data?.id ? data.status?.data?.id : data.status?.name || '',
        clientId: data.client?.data?.id ? data.client?.data?.id : data.client?.name || '',
        organizationId: data.organization?.data?.id
            ? data.organization?.data?.id
            : data.organization?.name || '',
        
        responsibles: [manager, foreman],
    }
}

// *
// TABS FUNCS
// *

export const projectTabs: ProjectTabs = {
    about: 'О проекте',
    rooms: 'Состав проекта',
    documents: 'Документы',
    reports: 'Отчёты',
    correctives: 'Корректировки',
    outlays: 'Сметы',
    journal: 'Журнал',
    warehouses: 'Склады',
    planning: 'Планирование',
    resourceStatement: 'Ресурсная ведомость',
}

export const getProjectActiveTab = (pathname: string): ProjectTabsPath =>
    pathname.includes('/about') ? 'about' :
        pathname.includes('/rooms') ? 'rooms' :
            pathname.includes('/documents') ? 'documents' :
                pathname.includes('/outlays') ? 'outlays' :
                    pathname.includes('/planning') ? 'planning' :
                        pathname.includes('/journal') ? 'journal' :
                            pathname.includes('/correctives') ? 'correctives' :
                                pathname.includes('/reports') ? 'reports' :
                                    pathname.includes('/warehouses') ? 'warehouses' :
                                        pathname.includes('/resourceStatement') ? 'resourceStatement' :
                                            'about'


// *
// INPUT PROCESSING FUNCS
// *

export const onProjectAutocompleteSubmit = ({
    key,
    setFormValue,
}: InputSubmitArg<ProjectsAutocompleteKey, ProjectsFormGlobal>) => (data: AutocompleteSubmitData) => {
    setFormValue(key, autocompleteSubmitArg(data))
}
export const onProjectAutocompleteChange = ({
    formData,
    key,
    setFormValue,
}: InputChangeArg<ProjectsAutocompleteKey, ProjectsFormGlobal>) => (label: string) => {
    if (formData[key]?.name !== label) {
        setFormValue(key, autocompleteChangeArg(label))
    }
}

export const onProjectBodyInfoAutocompleteSubmit = ({
    key,
    setFormValue,
}: InputSubmitArg<ProjectBodyInfoAutocompleteKey, ProjectFormBodyInfo>) => (data: AutocompleteSubmitData) => {
    setFormValue(key, autocompleteSubmitArg(data))
}
export const onProjectBodyInfoAutocompleteChange = ({
    formData,
    key,
    setFormValue,
}: InputChangeArg<ProjectBodyInfoAutocompleteKey, ProjectFormBodyInfo>) => (label: string) => {
    if (formData[key]?.name !== label) {
        setFormValue(key, autocompleteChangeArg(label))
    }
}

export const onProjectPercentInputChange = ({
    formData,
    key,
    setFormValue,
}: InputChangeArg<ProjectsPercentKey, ProjectFormBody>) => (label: number) => {
    if (formData[key] !== label) {
        setFormValue(key, label)
    }
}


// *
// STORE
// *

export const projectsFormGlobalDefaultValues: ProjectsFormGlobal = {
    name: '',
    description: '',
    from: '',
    to: '',
    workingHours: [],
    overhead: 0,
    transportCosts: 0,
    emergencyPhoneNumber: '',
    
    imagesUrls: [],
    images: [],
    
    rooms: [],
    correctives: [],
    outlays: [],
    documents: [],
    
    object: autocompleteFieldDefault,
    status: autocompleteFieldDefault,
    client: autocompleteFieldDefault,
    organization: autocompleteFieldDefault,
    manager: autocompleteFieldDefault,
    supervisor: autocompleteFieldDefault,
    
    responsibles: multiselectFieldDefault,
}

export const projectFormHeaderInitialState: ProjectFormHeader = {
    name: '',
}

export const projectsFormBodyInitialState: ProjectFormBody = {
    description: '',
    from: '',
    to: '',
    workingHours: [],
    overhead: 0,
    transportCosts: 0,
    emergencyPhoneNumber: '',
    
    imagesUrls: [],
    images: [],
    
    rooms: [],
    correctives: [],
    outlays: [],
    documents: [],
    
    object: autocompleteFieldDefault,
    status: autocompleteFieldDefault,
    client: autocompleteFieldDefault,
    organization: autocompleteFieldDefault,
    manager: autocompleteFieldDefault,
    supervisor: autocompleteFieldDefault,
    
    responsibles: multiselectFieldDefault,
}


// *
// OTHER
// *

export const useConvertRoomsToStoreFormat = () => {
    // const [findProjectWorks] = useLazyProjectWorksFindQuery()
    const [findWorkFormulas] = useLazyWorkFormulasFindQuery()
    
    const convertRoomsToStoreFormat = async(roomsWithWorks: RoomWithWorks[]) => { // convertRoomFormulasToStoreFormat ???
        
        // const roomsWithWorks: RoomWithWorks[] = roomsResponse.slice().map(r => ({ ...r, works: [] })) || []
        
        for (const [roomIndex, room] of roomsWithWorks.entries()) {
            const worksCopy = room.works?.slice()
            
            if (worksCopy?.length) {
                for (const [workIndex, work] of worksCopy.entries()) {
                    await findWorkFormulas(work.id)
                        .unwrap()
                        .then((res) => {
                            worksCopy.splice(
                                workIndex,
                                1,
                                {
                                    ...worksCopy[workIndex],
                                    formulas: res.data,
                                },
                            )
                        })
                        .catch(console.error)
                }
            }
            
            roomsWithWorks.splice(
                roomIndex,
                1,
                {
                    ...roomsWithWorks[roomIndex],
                    works: worksCopy,
                },
            )
        }
        
        const rooms: ProjectRoom[] = roomsWithWorks.map(room => convertRoomToStoreFormat(room))
        
        return rooms
    }
    
    return [convertRoomsToStoreFormat]
}


export const usePreviousValue = (value: string) => {
    
    const ref = useRef<string>()
    
    useEffect(() => {
        ref.current = value
    })
    return ref.current
}