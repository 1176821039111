import { emptyApi as api } from './emptyApi';
export const addTagTypes = ['ProjectTaskSnippets'] as const;
const injectedRtkApi = api
    .enhanceEndpoints({
        addTagTypes,
    })
    .injectEndpoints({
        endpoints: (build) => ({
            projectTaskSnippetsFindByStepId: build.query<
                ProjectTaskSnippetsFindByStepIdApiResponse,
                ProjectTaskSnippetsFindByStepIdApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/projects/task-snippets/step/${queryArg.stepId}`,
                    params: {
                        limit: queryArg.limit,
                        offset: queryArg.offset,
                        filter: queryArg.filter,
                        sort: queryArg.sort,
                    },
                }),
                providesTags: ['ProjectTaskSnippets'],
            }),
            projectTaskSnippetsFindOne: build.query<
                ProjectTaskSnippetsFindOneApiResponse,
                ProjectTaskSnippetsFindOneApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/projects/task-snippets/${queryArg}`,
                }),
                providesTags: ['ProjectTaskSnippets'],
            }),
            projectTaskSnippetRemove: build.mutation<
                ProjectTaskSnippetRemoveApiResponse,
                ProjectTaskSnippetRemoveApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/projects/task-snippets/${queryArg}`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['ProjectTaskSnippets'],
            }),
            projectTaskSnippetCreateMany: build.mutation<
                ProjectTaskSnippetCreateManyApiResponse,
                ProjectTaskSnippetCreateManyApiArg
            >({
                query: (queryArg) => ({
                    url: '/api/v1/projects/task-snippets',
                    method: 'POST',
                    body: queryArg,
                }),
                invalidatesTags: ['ProjectTaskSnippets'],
            }),
            projectTaskSnippetUpdateMany: build.mutation<
                ProjectTaskSnippetUpdateManyApiResponse,
                ProjectTaskSnippetUpdateManyApiArg
            >({
                query: (queryArg) => ({
                    url: '/api/v1/projects/task-snippets',
                    method: 'PUT',
                    body: queryArg,
                }),
                invalidatesTags: ['ProjectTaskSnippets'],
            }),
        }),
        overrideExisting: false,
    });
export { injectedRtkApi as projectTaskSnippetsApi };
export type ProjectTaskSnippetsFindByStepIdApiResponse = /** status 200 ОК */ {
    metadata: {
        id: string;
        permissions: string[];
    }[];
    data: FindProjectTaskSnippetResponseDto[];
    pagination: {
        offset: number;
        limit: number;
        total: number;
    };
};
export type ProjectTaskSnippetsFindByStepIdApiArg = {
    stepId: string;
    /** Кол-во возвращаемых элементов */
    limit?: number;
    /** Сдвиг значений */
    offset?: number;
    /** Фильтры */
    filter?: string;
    /** Сортировка */
    sort?: string;
};
export type ProjectTaskSnippetsFindOneApiResponse = /** status 200  */ {
    metadata: {
        id: string;
        permissions: string[];
    }[];
    data: FindProjectTaskSnippetResponseDto;
};
export type ProjectTaskSnippetsFindOneApiArg = string;
export type ProjectTaskSnippetRemoveApiResponse = /** status 200  */ void;
export type ProjectTaskSnippetRemoveApiArg = string;
export type ProjectTaskSnippetCreateManyApiResponse =
  /** status 201  */ FindProjectTaskSnippetResponseDto[];
export type ProjectTaskSnippetCreateManyApiArg =
  CreateProjectTaskSnippetRequestDto[];
export type ProjectTaskSnippetUpdateManyApiResponse =
  /** status 200  */ FindProjectTaskSnippetResponseDto[];
export type ProjectTaskSnippetUpdateManyApiArg =
  UpdateProjectTaskSnippetRequestDto[];
export type FindProjectTaskSnippetVariantResponseDto = {
    id: string;
    label: string;
    sort: number;
    isRequired: boolean;
};
export type FindBucketResponseDto = {
    id: string;
    /** Bucket name */
    name: string;
    /** Bucket path */
    path: string;
};
export type FindFileResponseDto = {
    id: string;
    /** File name */
    name: string;
    /** File mime type */
    type: string;
    /** File size in bytes */
    size: number;
    /** File bucket */
    bucket: FindBucketResponseDto;
};
export type FindProjectTaskSnippetResponseDto = {
    id: string;
    type:
    | 'PROJECT_TEXT'
    | 'PROJECT_IMAGES'
    | 'PROJECT_CHECKBOX'
    | 'PROJECT_RADIO_BUTTON'
    | 'PROJECT_INPUT'
    | 'PROJECT_FILES'
    | 'PROJECT_MATERIAL_REPORT'
    | 'PROJECT_JOB_FILES'
    | 'PROJECT_JOB_PARAMS';
    sort: number;
    label: string | null;
    variants?: FindProjectTaskSnippetVariantResponseDto[] | null;
    header: string | null;
    placeholder: string | null;
    text: string | null;
    isRequired: boolean | null;
    isAny: boolean | null;
    minCount: number | null;
    format: string[] | null;
    images: FindFileResponseDto[] | null;
};
export type ApiError = {
    status: number;
    message: string[];
    error: string;
};
export type CreateProjectTaskSnippetVariantRequestDto = {
    label: string;
    sort: number;
    isRequired: boolean;
};
export type CreateProjectTaskSnippetRequestDto = {
    stepId: string;
    sort: number;
    type:
    | 'PROJECT_TEXT'
    | 'PROJECT_IMAGES'
    | 'PROJECT_CHECKBOX'
    | 'PROJECT_RADIO_BUTTON'
    | 'PROJECT_INPUT'
    | 'PROJECT_FILES'
    | 'PROJECT_MATERIAL_REPORT'
    | 'PROJECT_JOB_FILES'
    | 'PROJECT_JOB_PARAMS';
    label?: string | null;
    variants?: CreateProjectTaskSnippetVariantRequestDto[] | null;
    header?: string | null;
    placeholder?: string | null;
    text?: string | null;
    isRequired?: boolean | null;
    isAny?: boolean | null;
    minCount: number | null;
    format?: string[] | null;
    imagesIds?: string[] | null;
    workParameterId?: string | null;
    projectFileTypeId?: string | null;
};
export type UpdateProjectTaskSnippetRequestDto = {
    id: string;
    snippet: CreateProjectTaskSnippetRequestDto;
};
export const {
    useProjectTaskSnippetsFindByStepIdQuery,
    useLazyProjectTaskSnippetsFindByStepIdQuery,
    useProjectTaskSnippetsFindOneQuery,
    useLazyProjectTaskSnippetsFindOneQuery,
    useProjectTaskSnippetRemoveMutation,
    useProjectTaskSnippetCreateManyMutation,
    useProjectTaskSnippetUpdateManyMutation,
} = injectedRtkApi;
