import { AutocompleteSubmitData, InputChangeArg, InputSubmitArg } from 'types/inputs.ts';

import {
    autocompleteChangeArg, autocompleteFieldDefault,
    autocompleteSubmitArg,
} from 'utils/inputs.tsx';

import {
    InventoryChangedMaterials, InventoryMaterialCountFactField,
    InventoryMaterialsAutocompleteKey,
    InventoryMaterialsFormBody, InventoryMaterialsFormGlobal,
    InventoryMaterialsFormHeader, MaterialsAutocompleteFields, MaterialsAutocompleteKey
} from 'components/Supply/Inventories/PageBody/InventoryMaterilas.types.ts';
import {
    CreateDocumentInventoryMaterialRequestDto,
    FindDocumentInventoryMaterialResponseDto,
    UpdateDocumentInventoryMaterialRequestDto
} from 'store/api/documentInventoryMaterial.tsx';
import { FindMaterialResponseDto } from 'store/api/materials.tsx';
import dayjs from 'dayjs';

export const onInventoryMaterialsGlobalAutocompleteSubmit = ({
    key,
    setFormValue,
}: InputSubmitArg<InventoryMaterialsAutocompleteKey, InventoryMaterialsFormGlobal>) => (data: AutocompleteSubmitData) => {
    setFormValue(key, autocompleteSubmitArg(data))
}
export const onInventoryMaterialsGlobalAutocompleteChange = ({
    formData,
    key,
    setFormValue,
}: InputChangeArg<InventoryMaterialsAutocompleteKey, InventoryMaterialsFormGlobal>) => (label: string) => {
    if (formData[key]?.name !== label) {
        setFormValue(key, autocompleteChangeArg(label))
    }
}


export const inventoryMaterialsFormHeaderInitialState: InventoryMaterialsFormHeader = {
    name: '',
}

export const inventoryMaterialsFormBodyInitialState: InventoryMaterialsFormBody = {
    type: autocompleteFieldDefault,
    inventoryType: autocompleteFieldDefault,
}
export const inventoryMaterialsFormGlobalInitialState: InventoryMaterialsFormGlobal = {
    name: '',
    type: autocompleteFieldDefault,
    inventoryType: autocompleteFieldDefault,
}

export const convertSelectedRowsToInventoryMaterials = (selectedMaterials: FindMaterialResponseDto[]): FindDocumentInventoryMaterialResponseDto[] => selectedMaterials.map(material => {

    const convertedMaterial: FindDocumentInventoryMaterialResponseDto = {
        id: `new_${Math.random()}`,
        createdAt: dayjs().format('DD-MM-YYYY HH:mm'),
        updatedAt: dayjs().format('DD-MM-YYYY HH:mm'),
        countAccounting: 0,
        countFact: 0,
        material,
        file: null
    }
    return convertedMaterial
})

export const convertMaterialForStore = (material: FindDocumentInventoryMaterialResponseDto, editPath: InventoryChangedMaterials['editPath']): InventoryChangedMaterials => ({
    ...material, editPath
})

export const convertMaterialsForUpdate = (materials: FindDocumentInventoryMaterialResponseDto[]): UpdateDocumentInventoryMaterialRequestDto[] => materials.map(material => ({
    id: material.id,
    countFact: material.countFact,
    fileId: material.file?.id ? material.file?.id : null,
    unitsId: material.units?.id ? material.units?.id : null
}))

export const convertMaterialsForCreate = (materials:  FindDocumentInventoryMaterialResponseDto[], inventoryId: string): CreateDocumentInventoryMaterialRequestDto[] => materials.map((material) => ({
    countFact: material.countFact,
    fileId: material.file?.id ? material.file?.id : null,
    inventoryId,
    materialId: material.material.id,
    unitsId: material.units?.id ? material.units?.id : null
}))

//inputs in edit materials table//////////

export const materialsAutocompleteFieldsInitialState: MaterialsAutocompleteFields = {
    units: autocompleteFieldDefault,
}

export const onMaterialUnitAutocompleteSubmit = ({ key, setFormValue }: InputSubmitArg<MaterialsAutocompleteKey, MaterialsAutocompleteFields>) => (data: AutocompleteSubmitData) => {
    setFormValue(key, autocompleteSubmitArg(data))
}
export const onMaterialUnitAutocompleteChange = ({ formData, key, setFormValue }: InputChangeArg<MaterialsAutocompleteKey, MaterialsAutocompleteFields>) => (label: string) => {
    if (formData[key]?.name !== label) {
        setFormValue(key, autocompleteChangeArg(label))
    }
}

export const inventoryMaterialCountInitialState: InventoryMaterialCountFactField = {
    countFact: 0,
}

export const nameSortASC = (a: FindDocumentInventoryMaterialResponseDto, b: FindDocumentInventoryMaterialResponseDto) => {
    const nameA = a.material.name.toUpperCase()
    const nameB = b.material.name.toUpperCase()
    if (nameA < nameB) {
        return -1;
    }
    if (nameA > nameB) {
        return 1;
    }
    return 0;

}


export const nameSortDESC = (a: FindDocumentInventoryMaterialResponseDto, b: FindDocumentInventoryMaterialResponseDto) => {
    const nameA = a.material.name.toUpperCase()
    const nameB = b.material.name.toUpperCase()
    if (nameA < nameB) {
        return 1;
    }
    if (nameA > nameB) {
        return -1;
    }
    return 0;
}

export const getPaginatedFilteredData = (activePage: number, rowsPerPage: number, filteredTableData: FindDocumentInventoryMaterialResponseDto[]) => {
    const startIdx = (activePage - 1) * rowsPerPage
    const endIdx = (activePage - 1) * rowsPerPage + rowsPerPage
    const copiedFilteredData = [...filteredTableData]
    return copiedFilteredData.slice(startIdx, endIdx )
}