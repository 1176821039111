import { PackagesSliceInitialState } from 'components/Resources/Jobs/NestedPages/Packages/Packages.types'
import { packageFormHeaderInitialState, packageFormBodyInitialState } from 'components/Resources/Jobs/NestedPages/Packages/Packages.utils'
import { createAppSlice } from 'utils/createAppSlice'
import { defaultInitialState } from 'utils/defaultInitialState.ts'

const packagesSliceInitialState: PackagesSliceInitialState = {
    ...defaultInitialState,
    editableFormHeader: packageFormHeaderInitialState,
    editableFormBody: packageFormBodyInitialState,
}

export const packagesSlice = createAppSlice<PackagesSliceInitialState>('packages', packagesSliceInitialState )

export default packagesSlice.reducer
