import { emptyApi as api } from './emptyApi';
export const addTagTypes = ['Files'] as const;
const formData = (queryArg: UploadApiArg) => {
    const form = new FormData()
    form.append(queryArg.name, queryArg.file)
    return form
}
const injectedRtkApi = api
    .enhanceEndpoints({
        addTagTypes,
    })
    .injectEndpoints({
        endpoints: (build) => ({
            download: build.query<DownloadApiResponse, DownloadApiArg>({
                query: (queryArg) => ({
                    url: `/api/v1/file/${queryArg}`,
                    responseType: 'blob',
                    responseEncoding: 'binary',
                    responseHandler: async (response) => URL.createObjectURL(await response.blob()),
                }),
            }),
            upload: build.mutation<UploadApiResponse, UploadApiArg>({
                query: (queryArg) => ({
                    url: '/api/v1/file',
                    method: 'POST',
                    body: formData(queryArg),
                    prepareHeaders: (headers: Headers) => {
                        headers.set('Content-Type', 'multipart/form-data')
                        return headers
                    },
                }),
            }),
        }),
        overrideExisting: false,
    });
export { injectedRtkApi as filesApi };
export type DownloadApiResponse = /** status 200 Файл отправлен */ string;
export type DownloadApiArg = string;
export type UploadApiResponse = /** status 200 Файл загружен */ UploadApiResponseDto[];
export type UploadApiResponseDto = {
    bucket: Bucket;
    id: string;
    name: string;
    path: string;
    sid: string;
    size: number;
    type: string;
};
export type Bucket = {
    sid: string;
    id: string;
    name: string;
    path: string;
};
export type UploadApiArg = {
    name: string,
    file: Blob
};
export type ApiError = {
    status: number;
    message: string[];
    error: string;
};
export const { useDownloadQuery, useLazyDownloadQuery, useUploadMutation } =
  injectedRtkApi;
