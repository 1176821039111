import {
    JobAdditionalFields,
    JobFormBody,
    JobFormHeader,
    JobsAutocompleteKey,
    JobsFormGlobal,
    JobsMultiselectFilterParamKey,
    JobTabs,
    JobTabsPath,
} from 'components/Resources/Jobs/Jobs.types'
import { FindFormulaResponseDto, FormulasFindApiResponse } from 'store/api/formulas'
import { FindJobResponseDto, UpdateJobRequestDto } from 'store/api/jobs'
import { AutocompleteSubmitData, InputChangeArg, InputSubmitArg } from 'types/inputs'
import {
    autocompleteChangeArg,
    autocompleteFieldDefault,
    autocompleteSubmitArg,
    multiselectFieldDefault
} from 'utils/inputs.tsx'


// *
// DATA CONVERT FUNCS
// *

export const convertJobDtoToFormBody = (job: FindJobResponseDto & JobAdditionalFields): JobFormBody => ({
    clientDescription: job?.clientDescription || '',
    insideDescription: job?.insideDescription || '',

    stage: job?.stage ? { name: job.stage.name, data: { id: job.stage.id, name: job.stage.name } } : autocompleteFieldDefault,
    type: job?.type ? { name: job.type.name, data: { id: job.type.id, name: job.type.name } } : autocompleteFieldDefault,
    view: job?.view ? { name: job.view.name, data: { id: job.view.id, name: job.view.name } } : autocompleteFieldDefault,
    organization: job?.organization ? { name: job.organization.name, data: { id: job.organization.id, name: job.organization.name } } : autocompleteFieldDefault,
    package: job?.package ? { name: job.package.name, data: { id: job.package.id, name: job.package.name } } : { name: '', data: { id: '', name: '' } },

    surfaces: {
        name: '',
        ids: job?.surfaces?.map(s => s.id) || [],
        alreadySelected: job?.surfaces?.map(s => ({ label: s.name, value: s.id })) || [],
    },
    groups: {
        name: '',
        ids: job?.groups?.map(s => s.id) || [],
        alreadySelected: job?.groups?.map(s => ({ label: s.name, value: s.id })) || [],
    },
    specializations: {
        name: '',
        ids: job?.specializations?.map(s => s.id) || [],
        alreadySelected: job?.specializations?.map(s => ({ label: s.name, value: s.id })) || [],
    },

    isNoisiness: job?.isNoisiness,
    isInvisible: job?.isInvisible,
})

export const convertJobDtoToFormHeader = (job: FindJobResponseDto): JobFormHeader => ({
    name: job?.name || '',
})

export const convertJobFormToRequestDto = (data: Partial<JobsFormGlobal>): UpdateJobRequestDto => ({
    clientDescription: data?.clientDescription || jobFormBodyInitialState.clientDescription,
    insideDescription: data?.insideDescription || jobFormBodyInitialState.insideDescription,
    name: data?.name || jobFormHeaderInitialState.name,

    organizationId: data?.organization?.data?.id || data?.organization?.name || jobFormBodyInitialState.organization.name,
    stageId: data?.stage?.data?.id || data?.stage?.name || jobFormBodyInitialState.stage.name,
    typeId: data?.type?.data?.id || data?.type?.name || jobFormBodyInitialState.type.name,
    viewId: data?.view?.data?.id || data?.view?.name || jobFormBodyInitialState.view.name,
    packageId: data?.package?.data?.id || data?.package?.name || jobFormBodyInitialState.package.name,

    surfacesIds:
        data?.surfaces?.ids?.length ?
            data?.surfaces.ids :
            data?.surfaces?.name?.length ?
                [data.surfaces.name] :
                jobFormBodyInitialState.surfaces.ids,
    groupsIds:
        data?.groups?.ids?.length ?
            data?.groups.ids :
            data?.groups?.name?.length ?
                [data.groups.name] :
                jobFormBodyInitialState.groups.ids,

    specializationsIds:
        data?.specializations?.ids?.length ?
            data?.specializations.ids :
            data?.specializations?.name?.length ?
                [data.specializations.name] :
                jobFormBodyInitialState.specializations.ids,

    isNoisiness: data?.isNoisiness || jobFormBodyInitialState.isNoisiness,
    isInvisible: data?.isInvisible || jobFormBodyInitialState.isInvisible,
})

export const convertFormulasDtoToFormBody = (formulas: FormulasFindApiResponse['data']) => {
    const CONSTANT: FindFormulaResponseDto[] = []
    const LEGKOREM: FindFormulaResponseDto[] = []
    const MATERIAL_COUNT: FindFormulaResponseDto[] = []
    const MATERIAL_PARAMETER: FindFormulaResponseDto[] = []
    const OPERATION: FindFormulaResponseDto[] = []
    const SPECIAL: FindFormulaResponseDto[] = []

    formulas.forEach(f => {
        const type = f.type.type
        switch (type) {
            case 'CONSTANT':
                CONSTANT.push(f)
                return
            case 'LEGKOREM':
                LEGKOREM.push(f)
                return
            case 'MATERIAL_COUNT':
                MATERIAL_COUNT.push(f)
                return
            case 'MATERIAL_PARAMETER':
                MATERIAL_PARAMETER.push(f)
                return
            case 'OPERATION':
                OPERATION.push(f)
                return
            case 'SPECIAL':
                SPECIAL.push(f)
                return
            default:
                throw new Error('unprocessed formula type ocurred')
        }
    })

    return {
        CONSTANT,
        LEGKOREM,
        MATERIAL_COUNT,
        MATERIAL_PARAMETER,
        OPERATION,
        SPECIAL,
    }
}


// *
// TABS FUNCS
// *

export const jobTabs: JobTabs = {
    info: 'Общая информация',
    materials: 'Материалы',
    formulas: 'Параметры и формулы',
    tasks: 'Задачи',
}
export const getJobActiveTab = (pathname: string): JobTabsPath => pathname.includes('/info') ? 'info' :
    pathname.includes('/materials') ? 'materials' :
        pathname.includes('/formulas') ? 'formulas' :
            pathname.includes('/tasks') ? 'tasks' :
                'info'


// *
// INPUT PROCESSING FUNCS
// now only 2 input types processed (autocomplete, multiselect)
// text/boolean and other immutable fields are changes by RHF control
// funcs differ generally in types
// *


// global filter funcs
export const onJobsGlobalAutocompleteSubmit = ({ key, setFormValue }: InputSubmitArg<JobsAutocompleteKey, JobsFormGlobal>) => (data: AutocompleteSubmitData) => {
    setFormValue(key, autocompleteSubmitArg(data))
}
export const onJobsGlobalAutocompleteChange = ({ formData, key, setFormValue }: InputChangeArg<JobsAutocompleteKey, JobsFormGlobal>) => (label: string) => {
    if (formData[key]?.name !== label) {
        setFormValue(key, autocompleteChangeArg(label))
    }
}
export const onJobsGlobalMultiselectChange = ({ formData, key, setFormValue }: InputChangeArg<JobsMultiselectFilterParamKey, JobsFormGlobal>) => (label: string) => {
    if (formData[key]?.name !== label) {
        setFormValue(`${key}.name`, label)
    }
}
export const onJobsGlobalMultiselectSubmit = ({ key, setFormValue }: InputSubmitArg<JobsMultiselectFilterParamKey, JobsFormGlobal>) => (valueIds: Array<string>) => {
    setFormValue(`${key}.ids`, valueIds)
}

// edit form funcs
export const onJobBodyAutocompleteSubmit = ({ key, setFormValue }: InputSubmitArg<JobsAutocompleteKey, JobFormBody>) => (data: AutocompleteSubmitData) => {
    setFormValue(key, autocompleteSubmitArg(data))
}
export const onJobBodyAutocompleteChange = ({ formData, key, setFormValue }: InputChangeArg<JobsAutocompleteKey, JobFormBody>) => (label: string) => {
    if (formData[key]?.name !== label) {
        setFormValue(key, autocompleteChangeArg(label))
    }
}
export const onJobBodyMultiselectChange = ({ formData, key, setFormValue }: InputChangeArg<JobsMultiselectFilterParamKey, JobFormBody>) => (label: string) => {
    if (formData[key]?.name !== label) {
        setFormValue(`${key}.name`, label)
    }
}
export const onJobBodyMultiselectSubmit = ({ key, setFormValue }: InputSubmitArg<JobsMultiselectFilterParamKey, JobFormBody>) => (valueIds: Array<string>) => {
    setFormValue(`${key}.ids`, valueIds)
}


// *
// STORE
// *

export const jobsFormGlobalDefaultValues: JobsFormGlobal = {
    name: '',
    clientDescription: '',
    insideDescription: '',

    type: autocompleteFieldDefault,
    view: autocompleteFieldDefault,
    stage: autocompleteFieldDefault,
    organization: autocompleteFieldDefault,
    package: autocompleteFieldDefault,

    surfaces: multiselectFieldDefault,
    specializations: multiselectFieldDefault,
    groups: multiselectFieldDefault,

    isNoisiness: false,
    isInvisible: false,
    taskTree: [],
}
export const jobFormHeaderInitialState: JobFormHeader = {
    name: '',
}
export const jobFormBodyInitialState: JobFormBody = {
    insideDescription: '',
    clientDescription: '',

    type: autocompleteFieldDefault,
    view: autocompleteFieldDefault,
    stage: autocompleteFieldDefault,
    organization: autocompleteFieldDefault,
    package: autocompleteFieldDefault,

    surfaces: multiselectFieldDefault,
    specializations: multiselectFieldDefault,
    groups: multiselectFieldDefault,

    formulasList: {
        CONSTANT: [],
        LEGKOREM: [],
        MATERIAL_COUNT: [],
        MATERIAL_PARAMETER: [],
        OPERATION: [],
        SPECIAL: [],
    },

    isNoisiness: false,
    isInvisible: false,
    taskTree: [],
}

