import React from 'react'
import { Box, Center, Flex, Text, Title } from '@mantine/core'
import ConstructionImage from 'assets/images/painter.svg'
import { BoxHeading } from '../BoxHeading/BoxHeading'

export const UnderConstructionContent = () => <Flex justify="center"
    align="center"
    direction="column">
    <Center component="div" w={ '100%' }>
        <ConstructionImage/>
    </Center>
    <Center component="div" w={ '100%' }>
        <Title order={ 3 }>
            Раздел находится в разработке
        </Title>
    </Center>
    <Center>
        <Text>
            И в ближайшее время будет доступен
        </Text>
    </Center>
</Flex>

export const UnderConstruction: React.FC = () => <>
    <Box>
        <BoxHeading>
            <Title order={ 1 }>В разработке</Title>
        </BoxHeading>
    </Box>
    <UnderConstructionContent/>
</>