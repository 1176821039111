import { createSlice, Draft, type PayloadAction } from '@reduxjs/toolkit';

type Article = Record<string, string | Record<string, string>>

type ArticleInitialState = { article: Article | null }

const initialState: ArticleInitialState = {
    article: null
}

export const docsSlice = createSlice({
    name: 'docs',
    initialState,
    reducers: {
        setArticleToStore: (state: Draft<ArticleInitialState>, action: PayloadAction<Article>) => {
            state.article = action.payload
        } }
})

export default docsSlice.reducer
