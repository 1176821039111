import React from 'react'
import { useNavigate } from 'react-router-dom'

export const Page404: React.FC = () => {
    const navigate = useNavigate()
    console.log('Page404')

    return (
        <div>
            {/* can share state between routes, e.g. ErrorPage ---> PrevPage */}
            <a onClick={(e) => {
                e.preventDefault()
                navigate('/test', { state: { testErrorState: 'testErrorState' } })
            }}>
                to test
            </a>
            <div>404 error</div>
        </div>
    )
}
