import * as Sentry from '@sentry/react'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LoginResponseDto } from 'store/api/authentication.tsx'

class InitialState {
    user: LoginResponseDto | null = null
}

const initialState: InitialState = {
    user: null,
}

export const authSlice = createSlice({
    name: 'authentication',
    initialState,
    reducers: {
        logout: () => initialState,
        setUser: (state, action: PayloadAction<InitialState>) => {
            state.user = action.payload.user

            Sentry.setUser({ id: state.user?.id, username: state.user?.name, email: state.user?.email })
        },
    },
})

export default authSlice.reducer
