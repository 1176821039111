import { createAppSlice } from 'utils/createAppSlice'
import { defaultInitialState } from 'utils/defaultInitialState.ts'
import {
    GroupsAddJobsInitialState,
    newAddedJobsInitialState
} from 'components/Resources/Jobs/NestedPages/Groups/PageBody/groupsAddJobs.types.ts';

const groupsAddJobsInitialState: GroupsAddJobsInitialState = {
    ...defaultInitialState,
    editableFormBody: newAddedJobsInitialState,
}

export const groupsAddJobsSlice = createAppSlice<GroupsAddJobsInitialState>('groupsAddJobs', groupsAddJobsInitialState)


export default groupsAddJobsSlice.reducer
