import React from 'react'
import { Navigate, RouteObject } from 'react-router-dom'

const Moving = React.lazy(() => import('pages/tenant/store/Moving').then((module) => ({ default: module.Moving })))
const Orders = React.lazy(() => import('pages/tenant/store/Orders').then((module) => ({ default: module.Orders })))
const Resources = React.lazy(() => import('pages/tenant/store/Resources').then((module) => ({ default: module.Resources })))
const Stores = React.lazy(() => import('pages/tenant/store/Stores').then((module) => ({ default: module.Stores })))

export const STORE_ROUTES: Array<RouteObject> = [
    {
        index: true,
        element: <Navigate to={'orders'}/>,
    },
    {
        id: 'orders',
        path: 'orders',
        element: <Orders/>,
        loader: ({ params }) => ({ id: params.id, name: 'Заявки на снабжение' }),
    },
    {
        id: 'resources',
        path: 'resources',
        element: <Resources/>,
        loader: ({ params }) => ({ id: params.id, name: 'Ресурсная ведомость' }),
    },
    {
        id: 'stores',
        path: 'stores',
        element: <Stores/>,
        loader: ({ params }) => ({ id: params.id, name: 'Склад' }),
    },
    {
        id: 'moving',
        path: 'moving',
        element: <Moving/>,
        loader: ({ params }) => ({ id: params.id, name: 'Реестр перемещений ТМЦ' }),
    },
]
