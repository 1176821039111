import {
    DocumentMovementsSliceInitialState
} from 'components/Supply/DocumentMovements/DocumentMovements.types.ts';
import { defaultInitialState } from 'utils/defaultInitialState.ts.ts';
import { createAppSlice } from 'utils/createAppSlice.ts';

const documentMovementsSliceInitialState: DocumentMovementsSliceInitialState = {
    ...defaultInitialState,
    sortParamsArray: [
        {
            param: 'movement.createdAt',
            rule: 'desc',
        },
    ],
}


export const documentMovementsSlice = createAppSlice<DocumentMovementsSliceInitialState>(
    'documentMovements',
    documentMovementsSliceInitialState
)

export default documentMovementsSlice.reducer
