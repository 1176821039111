import { StepsSliceInitialState } from 'components/Resources/Jobs/NestedPages/Steps/Steps.types'
import { stepFormHeaderInitialState, stepFormBodyInitialState } from 'components/Resources/Jobs/NestedPages/Steps/Steps.utils'
import { createAppSlice } from 'utils/createAppSlice'
import { defaultInitialState } from 'utils/defaultInitialState.ts'

const stepsSliceInitialState: StepsSliceInitialState = {
    ...defaultInitialState,
    editableFormHeader: stepFormHeaderInitialState,
    editableFormBody: stepFormBodyInitialState,
}

export const stepsSlice = createAppSlice<StepsSliceInitialState>('steps', stepsSliceInitialState)

export default stepsSlice.reducer
