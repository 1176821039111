import { useLocalStorage } from '@mantine/hooks'

export const usePageSizeLocalStorage = () => useLocalStorage<number | null>({
    key: 'pageSize',
    serialize(value: number): string {
        return JSON.stringify(value)
    },
    deserialize(value: string): number {
        return JSON.parse(value) as number
    },
})
