import { Box, Button, Center, Flex, Menu, Modal, Text, Tooltip } from '@mantine/core'
import { useDidUpdate, useDisclosure } from '@mantine/hooks'
import { IconEdit, IconTrash, IconTrashOff } from '@tabler/icons-react'
import { useCommonTableOptions } from 'hooks/useCommonMRTOptions'
import { usePageSizeLocalStorage } from 'hooks/usePageSizeLocalStorage'
import { useStylesMantineReactTable } from 'hooks/useStylesMantineReactTable'

import {
    MantineReactTable,
    MRT_PaginationState,
    MRT_Row,
    MRT_RowSelectionState,
    MRT_ShowHideColumnsButton,
    type MRT_SortingState,
    MRT_TablePagination,
    useMantineReactTable
} from 'mantine-react-table'
import React, { useCallback, useEffect, useReducer, useRef, useState } from 'react'
import { TablePropsUnion } from './TableReusable.types'
import { ErpButtonAffixTopComponent } from 'components/AbstractComponents/AffixButton/button.affix.top.component'
import { useNavigate } from 'react-router-dom'
import { SortParam } from 'types/inputs.ts';

const defaultPageIndex = 0
const defaultPageSize = 10

export const TableReusable = React.memo(function TableReusable({
    findApiCall,
    findApiResult,
    columns,
    filterString,
    sortString,
    FiltersComponent,
    storeSortParams,
    handleEditClick,
    handleDeleteClick,
    headerComponentFn,
    updateSelectedRowsState,
    shouldResetRowsState,
    isParentMounted,
    deleteViaModal,
    initialState,
    tableStyles,
    isNeedOptions = true,
    modalText,
    deleteText = 'Удалить',
    /////enableMultiRowSelection = true everywhere except correction material table
    enableMultiRowSelection = true,
    additionalQueryArguments,
    getViewOnePath,
    defineIfMenuItemsDisabled =  (row: MRT_Row<Record<string, any>>) => ({
        isEditDisabled: false,
        isDeleteDisabled:  false,
    }) ,
    renderTopPagination = true,
    handleRestoreClick,
    restoreViaModal,
    restoreText,
    restoreModalText,
    topToolbarClassName = 'stickyTopToolbar',
    selectedRowsActionsComponentFn,
    selectedRowsParam,
    enableBottomToolbar = true,
}: TablePropsUnion) {
    const tableContainerRef = useRef<HTMLDivElement>(null) //we can get access to the underlying TableContainer element and react to its scroll events
    const commonTableOptions = useCommonTableOptions<Record<string, any>>()
    const { classes } = useStylesMantineReactTable()

    // NOT PERFECT, IDS WITH ONLY LOWER CASE LETTERS AND NUMBERS WILL STILL RETURN FALSE SO A UNIQUE KEY WILL BE CREATED BUT IT'S BETTER THAN NOTHING
    const idRegex = /^(?=.*[A-Z])[A-Za-z0-9_-]+$/

    const localStorageKey = window.location.pathname
        .split('/')
        .map((el, index, array) => {

            // SPECIAL CASE FOR TABLES WHERE A ROW CLICK LEADS TO ANOTHER TABLE (LIKE IN INVENTORIES)
            // FOR SUCH CASES localStorageKey CAN BE THE SAME, WHICH IS UNWANTED
            // SO INSTEAD OF ID 'INNER' WILL BE ATTACHED TO THE KEY AT THE END

            // SPECIAL CASE FOR ORDERS (DUE TO DIFFERENCE IN COLUMNS VISIBILITY, EACH ORDER TYPE NEED TO BE STORED UNDER ITS OWN TYPE)
            if(index === array.length - 1 && (el.includes('receipt_') || el.includes('expense_') || el.includes('return_'))) {
                if(el.includes('receipt_')) return 'receipt_inner'
                if(el.includes('expense_')) return 'expense_inner'
                if(el.includes('return_')) return 'return_inner'
            }

            if(index === array.length - 1 && idRegex.test(el)) {
                return 'inner'
            }

            return el
        })
        .filter(el => !idRegex.test(el)).join('')

    const localData = JSON.parse(localStorage.getItem(localStorageKey))
    const userState = localData ? localData : initialState

    const getInitialState = () => {
        if (localData && localData.sorting && localData.sorting.length !== 0) {
            const values = Object.values(localData.sorting[0])
            const param = values[0]
            const rule = values[1] === true ? 'desc' : 'asc'
            return {
                sortParamsArray: [
                    {
                        param,
                        rule
                    }
                ] as SortParam[] | undefined
            }
        }
        return initialState
    }

    const [sorting, setSorting] = useState<MRT_SortingState>(getInitialState()?.sortParamsArray?.map(p => ( {
        id: p.param,
        desc: p.rule === 'desc',
    } )) ?? [])

    const pageSizeFromStorage = Number(localStorage.getItem('pageSize')) || 0
    const pageSize = pageSizeFromStorage !== 0 ? pageSizeFromStorage : defaultPageSize
    const [, setPageSize] = usePageSizeLocalStorage()

    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [showOnlySelected, setShowOnlySelected] = React.useState(false)

    const [isInitialRender, setIsInitialRender] = useReducer(
        (state: boolean, payload: boolean): boolean => {
            if (state !== payload) {
                return payload
            } else {
                return state
            }
        },
        true,
    )

    const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({})
    const [pagination, setPagination] = useState<MRT_PaginationState>({
        pageIndex: defaultPageIndex,
        pageSize: pageSize && !isNaN(pageSize) ? pageSize : defaultPageSize,
    })

    const totalRowsCount = findApiResult?.data?.pagination?.total ?? 0 // change to actual total number
    const totalFetched = findApiResult?.data?.data?.length
        ? ( pagination.pageIndex + 1 ) * pagination.pageSize
        : 0

    const [
        isDeleteModalOpened, {
            open: openDeleteModal,
            close: closeDeleteModal,
        },
    ] = useDisclosure(false)
    const [
        isRestoreModalOpened, {
            open: openRestoreModal,
            close: closeRestoreModal,
        },
    ] = useDisclosure(false)
    const [deleteItemId, setDeleteItemId] = useState<string>()
    const [restoreItem, setRestoreItem] = useState<MRT_Row<Record<string, any>>>()

    const navigate = useNavigate()

    const fetchPageOnChanges = useCallback(() => {

        const showOnlySelectedFilterString: string = showOnlySelected && selectedRowsParam ? `${filterString?.trim() ? '&filter=' : ''}${selectedRowsParam}:in:${Object.keys(rowSelection).join(',')}` : ''

        if (!isInitialRender && isParentMounted) {
            const args = {
                limit: pagination.pageSize,
                offset: pagination.pageIndex * pagination.pageSize,
                filter: filterString?.trim() || showOnlySelectedFilterString ? filterString?.trim() + showOnlySelectedFilterString : undefined,
                sort: sortString?.trim() ? sortString?.trim() : undefined,
            }

            if (additionalQueryArguments) {
                findApiCall({ ...args, ...additionalQueryArguments })
                    .catch(console.error)
            } else {
                findApiCall(args)
                    .catch(console.error)
            }
        }
    }, [
        filterString,
        sortString,
        findApiCall,
        pagination.pageIndex,
        pagination.pageSize,
        isParentMounted,
        rowSelection,
        selectedRowsParam,
        showOnlySelected
    ])

    useEffect(function initialItemsFind() {
        if (isInitialRender && isParentMounted) {
            setIsInitialRender(false)
            const args = {
                limit: pagination.pageSize,
                offset: pagination.pageIndex * pagination.pageSize,
                filter: filterString?.trim() ? filterString?.trim() : undefined,
                sort: sortString?.trim() ? sortString?.trim() : undefined,
            }

            if (additionalQueryArguments?.id) {
                findApiCall(additionalQueryArguments.id)
                    .catch(console.error)
            } else if (!additionalQueryArguments?.id) {
                findApiCall({ ...args, ...additionalQueryArguments })
                    .catch(console.error)
            } else {
                findApiCall(args)
                    .catch(console.error)
            }
        }
    }, [pagination, isParentMounted])

    useDidUpdate(fetchPageOnChanges, [
        pagination.pageIndex,
        pagination.pageSize,
        filterString,
        sortString,
        showOnlySelected,
        selectedRowsParam,
        // totalRowsCount,
        // totalFetched,
        // isParentMounted,
    ])

    useEffect(
        function keepLoadFinish() {
            if (
                !findApiResult.isLoading &&
                !findApiResult.isFetching &&
                ( findApiResult.isSuccess || findApiResult.isError )
            ) {
                setIsLoading(false)
            }
            if (findApiResult.isLoading || findApiResult.isFetching) {
                setIsLoading(true)
            }
        },
        [
            findApiResult.isError,
            findApiResult.isFetching,
            findApiResult.isLoading,
            findApiResult.isSuccess,
        ],
    )

    useEffect(
        function saveSortParamsToStore() {
            storeSortParams(sorting)
        },
        [sorting],
    )

    useEffect(
        function keepActualPageSizeLS() {
            setPageSize(pagination.pageSize)
        },
        [pagination.pageSize, setPageSize],
    )

    const SelectedRowsActions = selectedRowsActionsComponentFn && selectedRowsActionsComponentFn(showOnlySelected, setShowOnlySelected)

    const table = useMantineReactTable({
        ...commonTableOptions,
        columns,
        data: findApiResult?.data?.data && !findApiResult?.isError ? findApiResult.data.data : [],
        defaultColumn: {
            minSize: 40,
            maxSize: 1000,
            size: 220,
        }, //units are in px (these are the default values)
        // enableColumnResizing: true,
        layoutMode: 'grid',
        memoMode: 'cells',
        // enableRowDragging: true,
        enableMultiRowSelection,
        enableRowSelection: !!updateSelectedRowsState,
        getRowId: (originalRow) => originalRow.id,

        onRowSelectionChange: setRowSelection,

        onSortingChange: setSorting,

        mantinePaginationProps: {
            rowsPerPageOptions: ['10', '20', '50', '100'],
            // showRowsPerPage: true,
            withEdges: false,
            siblings: 2,
            // boundaries: 2,
            className: classes.mantinePagination,
        },
        mantineTableContainerProps: {
            ref: tableContainerRef, //get access to the table container element
            sx: {
                maxHeight: 'none',
                overflow: 'hidden',
                // marginTop: fixedPositions.sortBlock,
            },
            // onScroll: (e) => console.log(e),
        },

        mantineTableBodyProps: {
            sx: {
                // marginTop: fixedPositions.sortBlock ? 80 : 0,
                ...tableStyles,
            },
        },
        mantineTableHeadProps: {
            // sx: sortBlockStyles,
        },
        mantineTableHeadRowProps: {
            sx: {
                border: 'none',
                outline: 'none',
                boxShadow: 'none !important',
            },
        },

        onPaginationChange: setPagination,
        mantineTableBodyRowProps: ({ row }) => ( {
            onClick: () => {
                if (getViewOnePath) {
                    const path = getViewOnePath(row)
                    navigate(path)
                }

            },
            className: classes.mantineTableBodyRow
        } ),
        renderTopToolbar: ({ table }) =>
            <Flex
                // ref={topToolbarRef}
                direction={ 'column' }
                className={ classes[topToolbarClassName] }
                // sx={topToolbarStyles}
            >
                {/* <Flex align='center' justify={'flex-end'} gap={16} >
                 <MRT_ShowHideColumnsButton table={table} />
                 </Flex> */ }

                <FiltersComponent />

                <Flex align="center" justify={ 'flex-end' }>
                    {/* <MRT_ToolbarInternalButtons table={table} /> */ }
                    { renderTopPagination && <MRT_TablePagination table={ table } /> }
                </Flex>
                { SelectedRowsActions }
            </Flex>
        ,
        enableBottomToolbar: enableBottomToolbar,
        renderBottomToolbar: ({ table }) =>
            <Flex align={ 'center' } justify={ 'space-between' } p={ 0 }>
                <Text align={ 'left' }>
                    { totalFetched > 0 &&
                        <>
                            <Text component="span" sx={ { fontWeight: 600 } }>
                                { pagination.pageIndex * pagination.pageSize + 1 }
                            </Text>
                            -
                            <Text component="span" sx={ { fontWeight: 600 } }>
                                { totalFetched < totalRowsCount ? totalFetched : totalRowsCount }
                            </Text>{ ' ' }
                            из{ ' ' }
                            <Text component="span" sx={ { fontWeight: 600 } }>
                                { totalRowsCount }
                            </Text>
                        </>
                    }
                </Text>
                <MRT_TablePagination table={ table } />
            </Flex>
        ,
        renderRowActionMenuItems: ({ row }) => !isNeedOptions || row.original?.status?.type == 'IN_PROJECT' ?
            null :
            <>
                {defineIfMenuItemsDisabled && !defineIfMenuItemsDisabled(row)?.isEditDisabled ? <Menu.Item
                    component="a"
                    href={handleEditClick ? `${handleEditClick(row)}` : ''}
                    target="_blank"
                    icon={<IconEdit/>}
                    // disabled={ defineIfMenuItemsDisabled ? defineIfMenuItemsDisabled(row).isEditDisabled : false }
                >
                    <Box>
                        Редактировать
                    </Box>
                </Menu.Item> : null}
                {/*RESTORE (IF RESTORE EXISTS)*/}
                {handleRestoreClick && row.original.deleted && <Menu.Item
                    icon={<IconTrashOff/>}
                    onClick={() => {
                        if (restoreViaModal) {
                            setRestoreItem(row)
                            openRestoreModal()
                        } else if (handleRestoreClick) {
                            handleRestoreClick(row)
                        }
                    }}
                >
                    {restoreText}
                </Menu.Item>}
                {/*DELETE (IF RESTORE EXISTS)*/}
                {handleRestoreClick && !row.original.deleted && <Menu.Item
                    icon={<IconTrash/>}
                    onClick={() => {
                        if (deleteViaModal) {
                            setDeleteItemId(() => row.original.id)
                            openDeleteModal()
                        } else if (handleDeleteClick) {
                            handleDeleteClick(row.original.id)
                        }
                    }}
                >
                    {deleteText}
                </Menu.Item>}

                {defineIfMenuItemsDisabled && defineIfMenuItemsDisabled(row)?.isDeleteDisabled ?
                    <Menu.Item
                        icon={<IconTrash/>}
                        data-disabled
                        sx={(theme) => ({
                            color: theme.colors.natural[4],
                        })}
                        onClick={(e) => {
                            e.stopPropagation()
                            e.preventDefault()
                        }}
                    >
                        <Tooltip
                            withArrow
                            // label="Склад содержит ТМЦ на хранении. Очистите склад, чтобы удалить его"
                            label={defineIfMenuItemsDisabled(row)?.deleteDisabledText ? defineIfMenuItemsDisabled(row)?.deleteDisabledText : 'Склад содержит ТМЦ на хранении. Очистите склад, чтобы удалить его'}
                        >
                            <Box>
                                {deleteText}
                            </Box>
                        </Tooltip>
                    </Menu.Item> : !handleRestoreClick ?
                        // DELETE (IF RESTORE DOES NOT EXIST)
                        <Menu.Item
                            icon={<IconTrash/>}
                            onClick={() => {
                                if (deleteViaModal) {
                                    setDeleteItemId(() => row.original.id)
                                    openDeleteModal()
                                } else if (handleDeleteClick) {
                                    handleDeleteClick(row.original.id)
                                }
                            }}
                        >
                            {deleteText}
                        </Menu.Item> : null
                }
            </>
        ,
        rowCount: totalRowsCount,
        renderEmptyRowsFallback: () => <Center>Данные не найдены</Center>,
        enableRowActions: isNeedOptions,
        state: {
            showAlertBanner: findApiResult?.isError,
            // showProgressBars: isLoading === null || isLoading,
            sorting,
            rowSelection,
            // showSkeletons: findApiResult?.isLoading,
            pagination,
            isLoading,
        },
        initialState: userState,
    })

    useEffect(
        function refreshSelectedRowsState() {
            localStorage.setItem(
                localStorageKey,
                JSON.stringify({
                    ...initialState,
                    columnVisibility: table.getState().columnVisibility,
                    sorting: table.getState().sorting
                }),
            )
            //console.log(JSON.stringify(table.getState().columnVisibility), localStorageKey)
        },
        [table.getState().columnVisibility, table.getState().sorting],
    )

    useEffect(
        function refreshSelectedRowsState() {
            if (updateSelectedRowsState) {
                const originalSelectedRows = Object.values(
                    table.getSelectedRowModel().rows,
                ).map((r) => r.original)

                updateSelectedRowsState(originalSelectedRows, rowSelection)
            }
        },
        [table.getSelectedRowModel().rows],
    )

    useEffect(
        function resetSelectedRowsState() {
            if (shouldResetRowsState) {
                setRowSelection({})
            }
        },
        [shouldResetRowsState],
    )

    const Header = headerComponentFn(<MRT_ShowHideColumnsButton table={ table } />)

    return (
        <Box>
            { Header }

            <MantineReactTable table={ table } />

            <ErpButtonAffixTopComponent />

            {/*<Affix position={{ bottom: rem(80), right: rem(40) }}>
             <Transition transition="slide-up" mounted={scroll.y > 0}>
             {(transitionStyles) =>
             <Button
             leftIcon={<IconArrowUp size="1rem" />}
             style={transitionStyles}
             variant="primary"
             onClick={() => scrollTo({ y: 0 })}
             >
             Наверх
             </Button>
             }
             </Transition>
             </Affix>*/ }

            {/*DELETE MODAL*/}
            <Modal.Root opened={ isDeleteModalOpened } onClose={ closeDeleteModal } centered>
                <Modal.Overlay />
                <Modal.Content>
                    <Modal.Body>
                        <Flex direction={ 'column' } gap={ 32 }>
                            { modalText }
                            {/*<Flex direction={'column'} gap={24}>
                             <Title order={3}>Удаление проекта</Title>
                             <Title order={5} sx={{ fontWeight: 400 }}>Вы действительно хотите удалить проект?</Title>
                             </Flex>*/ }
                            <Flex justify={ 'flex-end' } gap={ 24 }>
                                <Button
                                    variant={ 'secondaryGray' }
                                    radius={ 8 }
                                    onClick={ closeDeleteModal }
                                >Отменить</Button>
                                <Button
                                    variant={ 'yellow' } radius={ 8 } onClick={ () => {
                                        if (deleteItemId) {
                                            if (handleDeleteClick) handleDeleteClick(deleteItemId)
                                            closeDeleteModal()
                                        }
                                    } }
                                >Подтвердить</Button>
                            </Flex>
                        </Flex>
                    </Modal.Body>
                </Modal.Content>
            </Modal.Root>

            {/*RESTORE MODAL*/}
            <Modal.Root opened={ isRestoreModalOpened } onClose={ closeRestoreModal } centered>
                <Modal.Overlay />
                <Modal.Content>
                    <Modal.Body>
                        <Flex direction={ 'column' } gap={ 32 }>
                            { restoreModalText }
                            <Flex justify={ 'flex-end' } gap={ 24 }>
                                <Button
                                    variant={ 'secondaryGray' }
                                    radius={ 8 }
                                    onClick={ closeRestoreModal }
                                >Отменить</Button>
                                <Button
                                    variant={ 'yellow' } radius={ 8 } onClick={ () => {
                                        if (restoreItem) {
                                            if (handleRestoreClick) handleRestoreClick(restoreItem)
                                            closeRestoreModal()
                                        }
                                    } }
                                >Подтвердить</Button>
                            </Flex>
                        </Flex>
                    </Modal.Body>
                </Modal.Content>
            </Modal.Root>
        </Box>
    )
})
