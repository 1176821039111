import { Affix, Box, Flex, Transition } from '@mantine/core'
import { useWindowScroll } from '@mantine/hooks'
import { IconChevronUp } from '@tabler/icons-react'
import { ErpButtonComponent } from './button.component'

export const ErpButtonAffixTopComponent = () => {
    const [scroll, scrollTo] = useWindowScroll()
    
    return (
        <Affix
            position={ {
                bottom: 20,
                right: 20,
            } }
        >
            <Transition transition="slide-up" mounted={ scroll.y > 200 }>
                { (transitionStyles) =>
                    <ErpButtonComponent
                        style={ transitionStyles }
                        variant={ 'circle' }
                        onClick={ () => scrollTo({ y: 0 }) }
                        p={ 0 }
                    >
                        <Flex align={ 'center' } justify={ 'center' }>
                            <Flex direction={ 'column' } align={ 'center' } justify={ 'center' }>
                                <IconChevronUp size={ 20 } />
                                <Box
                                    style={ {
                                        fontSize: '10px',
                                        fontWeight: 600,
                                        lineHeight: '12px',
                                    } }
                                >
                                    НАВЕРХ
                                </Box>
                            </Flex>
                        </Flex>
                    </ErpButtonComponent>
                }
            </Transition>
        </Affix>
    )
}
