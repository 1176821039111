import { ErrorData } from 'types/error.ts'

export interface ApiErrorResponse {
    status: number
    data: ErrorData
}

export enum RoleType {
    MANAGER = 'MANAGER',
    WORKER = 'WORKER',
    UNKNOWN = 'UNKNOWN',
    UNAUTHORIZED = 'UNAUTHORIZED',
}