import { LoadingOverlay } from '@mantine/core'
// import {useAppDispatch, useAppSelector} from 'hooks/redux'
import React, { useEffect } from 'react'
import { authenticationApi } from 'store/api/authentication'
import { ProviderRouter } from '../ProviderRouter/ProviderRouter'
import { useAppDispatch } from 'hooks/redux.ts'
import { authSlice } from 'store/slices/authSlice.ts'
import { useDisclosure } from '@mantine/hooks'
// import { notifications } from '@mantine/notifications'
// import { IconX } from '@tabler/icons-react'
// import {authSlice} from 'store/slices/authSlice.ts'
// import {useUserLocalStorage} from 'hooks/useUserLocalStorage.ts'

export const ProviderAuth: React.FC = () => {
    const dispatch = useAppDispatch()

    // const errors = useAppSelector((state) => state.errorReducer.errors)

    // useEffect(() => {
    //     errors?.map((error) => {
    //         const message = error.message?.map(m => typeof m === 'string' ? m : 'Что-то пошло не так')

    //         notifications.show({
    //             title: error.title,
    //             message: message,
    //             color: 'red',
    //             radius: 'xs',
    //             icon: <IconX size="1.1rem"/>,
    //             autoClose: 5000,
    //         })
    //     })
    // }, [errors])

    const [visible, { close }] = useDisclosure(true)

    const { setUser } = authSlice.actions

    const useValidateResult = authenticationApi.endpoints.validate.useQuery()

    useEffect(() => {
        dispatch(setUser({
            user: useValidateResult.data || null,
        }))
        close()
    }, [dispatch, close, useValidateResult, setUser])

    return useValidateResult.isLoading ? <LoadingOverlay
        loaderProps={ { size: 'lg' } }
        overlayOpacity={ 0.3 }
        transitionDuration={ 500 }
        overlayBlur={ 1 }
        exitTransitionDuration={ 500 }
        overlayColor="#EDEDED"
        visible={ visible }
    /> : <ProviderRouter key="providerAuth" user={ useValidateResult.data }/>

}
