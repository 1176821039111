import { JobTypesSliceInitialState } from 'components/Resources/Jobs/NestedPages/Types/JobType.types'
import { jobTypeFormHeaderInitialState, jobTypeFormBodyInitialState } from 'components/Resources/Jobs/NestedPages/Types/JobType.utils'
import { createAppSlice } from 'utils/createAppSlice'
import { defaultInitialState } from 'utils/defaultInitialState.ts'

const jobTypesInitialState: JobTypesSliceInitialState = {
    ...defaultInitialState,
    editableFormHeader: jobTypeFormHeaderInitialState,
    editableFormBody: jobTypeFormBodyInitialState,
}

export const jobTypesSlice = createAppSlice<JobTypesSliceInitialState>('jobTypes', jobTypesInitialState)

export default jobTypesSlice.reducer
