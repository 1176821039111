import { BackgroundImage, Box, Button, Checkbox, Flex, Grid, Text } from '@mantine/core'
import { useStylesCommon } from 'hooks/useStylesCommon'
import UserIcon from 'assets/icons/user.svg'
import styles from 'components/AbstractComponents/UserProfile/UserProfile.module.css';
import { IconBrandTelegram, IconCheck, IconKey, IconLogout } from '@tabler/icons-react';
import { SpecializationBadge } from 'components/AbstractComponents/UserProfile/SpecializationBadge.tsx';
import { UserWorkSchedule } from 'components/Organizations/NestedPages/Users/PageBody/UserWorkSchedule.tsx';
import { useLazyUsersFindOneQuery, useUsersGetBotLinkQuery } from 'store/api/user.tsx';
import { useAppDispatch, useAppSelector } from 'hooks/redux.ts';
import { useLazyLogoutQuery } from 'store/api/authentication.tsx';
import { authSlice } from 'store/slices/authSlice.ts';
import { useEffect } from 'react';
import dayjs from 'utils/dayjs.ts';
import { userDataType } from 'components/AbstractComponents/UserProfile/UserProfile.types.ts';
import { formatWorkingDays, userType } from 'components/Organizations/NestedPages/Users/Users.utils';

interface UserProfileProps {
    userId?: string | null,
    isUserAccount?: boolean | null,
    userData: userDataType,
}

export const UserProfile = ({ userId, userData, isUserAccount }: UserProfileProps) => {
    const dispatch = useAppDispatch()
    
    const authUser = useAppSelector((state) => state.authReducer.user)
    const { logout: authLogout } = authSlice.actions
    
    const { classes: classesCommon } = useStylesCommon()

    const [findUser, { data: findUserResponse }] = useLazyUsersFindOneQuery()

    useEffect(() => {
        let intervalId;
        if (isUserAccount && userId && !findUserResponse) {
            findUser(userId)

            intervalId =  setInterval(() => {
                findUser(userId)
            }, 10000)
        }

        return clearInterval(intervalId)
    }, [userId])

    const [logout] = useLazyLogoutQuery()
    
    const tgBotAnswer = useUsersGetBotLinkQuery()

    const logoutUser = async () => {
        await logout().unwrap().then(() => {
            dispatch(authLogout())
            window.location.reload()
        }).catch(e => console.error(e))
    }

    const isWorker =  authUser ? !!authUser.roles.find((el) => el.type !== 'WORKER') : false
    const isRolesVisible = isWorker && !isUserAccount

    const handleTGBot = () => {
        // console.log(tgBotAnswer, 1)
        window.open(tgBotAnswer?.currentData?.data?.link)
    }

    const transformedWorkSchedule = formatWorkingDays(userData.workingDays ? userData.workingDays : findUserResponse?.data.workingDays? findUserResponse.data.workingDays : [])

    return (
        <Grid mt={0} sx={(theme) => ({ backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[0] })}>
            <Grid.Col sm={4} md={3} xl='content' span={12}>

                {/*AVATAR*/}
                {userData.avatarId ?
                    <Flex w={{ base: '100%', xl: 364 }} bg='custom.0' className={styles.userProfileAvatarContainer}>
                        <BackgroundImage radius={8} src={ `/api/v1/file/${ userData.avatarId  }` }  />
                    </Flex>
                    :
                    <Flex w={{ base: '100%', xl: 364 }} p={{ base: 20, sm: 20, lg: 40 }} bg='custom.0' className={styles.userProfileAvatarContainer}>
                        <UserIcon/>
                    </Flex>
                }

                {/*BUTTONS*/}
                <Flex direction='column' gap={8}>
                    { isUserAccount && tgBotAnswer?.isSuccess && findUserResponse ?
                        <Button
                            size='md'
                            variant="primary"
                            leftIcon={findUserResponse?.data?.chatId ? <IconCheck size={20}/> : <IconBrandTelegram size={20}/>}
                            disabled={!!findUserResponse?.data?.chatId}
                            onClick={handleTGBot}
                            styles={(theme) => ({
                                root: {
                                    borderRadius: 8,
                                    '&:disabled': {
                                        background: 'transparent',
                                        border: `1px solid ${theme.colors.custom[0]}`
                                    }
                                }
                            })}
                        >
                            <Text truncate>{findUserResponse?.data?.chatId ? 'Уведомления в Telegram подключены' : 'Подключить уведомления в Telegram'}</Text>
                        </Button>
                        :
                        null}
                    <Button
                        styles={{
                            root: {
                                borderRadius: 8
                            }
                        }}
                        size='md'
                        variant={'secondary'}
                        leftIcon={<IconKey size={20}/>}
                    >
                        Сменить пароль
                    </Button>
                    {isUserAccount && <Button
                        styles={{
                            root: {
                                borderRadius: 8
                            }
                        }}
                        size='md'
                        variant={'secondary'}
                        onClick={ logoutUser }
                        leftIcon={<IconLogout size={20}/>}
                    >
                        Выйти
                    </Button>}
                </Flex>

                {/*GENERAL DATA*/}
                {isUserAccount ? null : <Flex mt={32} direction={'column'} gap={16} align={{ base: 'center', sm: 'start' }}>
                    <Flex direction={'column'} gap={4} align={{ base: 'center', sm: 'start' }}>
                        <Text fw={500} c={'natural.5'} size={12}>Дата создания</Text>
                        <Text fw={400} c={'natural.9'}
                            size={16}>{userData.createdAt ? dayjs(userData.createdAt).utc().format('D MMM YYYY, HH:mm') : '-'}</Text>
                    </Flex>
                    <Flex direction={'column'} gap={4} align={{ base: 'center', sm: 'start' }}>
                        <Text fw={500} c={'natural.5'} size={12}>Кем создан</Text>
                        <Text fw={400} c={'natural.9'}
                            size={16}>{userData.createdBy || '-'}</Text>
                    </Flex>
                    <Flex direction={'column'} gap={4} align={{ base: 'center', sm: 'start' }}>
                        <Text fw={500} c={'natural.5'} size={12}>Дата изменения</Text>
                        <Text fw={400} c={'natural.9'}
                            size={16}>{userData.updatedAt ? dayjs(userData.updatedAt).utc().format('D MMM YYYY, HH:mm') : '-'}</Text>
                    </Flex>
                    <Flex direction={'column'} gap={4} align={{ base: 'center', sm: 'start' }}>
                        <Text fw={500} c={'natural.5'} size={12}>Кем изменён</Text>
                        <Text fw={400} c={'natural.9'}
                            size={16}>{userData.updatedBy || '-'}</Text>
                    </Flex>
                </Flex>}

            </Grid.Col>

            <Grid.Col sm={4} md={6} xl='auto' span={12}>

                <Flex direction={'column'} gap={8}>

                    {/*PERSONAL DATA*/}
                    <Box className={ classesCommon.islandLightBlock }>
                        <Box className={styles.userProfileSectionTitleContainer}>
                            <Text className={styles.userProfileSectionTitle}>Личные данные</Text>
                        </Box>
                        <Flex className={styles.userProfileSectionContentContainer} rowGap={12}>
                            <Text fw={400} size={20}>{userData.name}</Text>
                            <Flex direction='column' rowGap={2}>
                                <Text fw={500} size={14} c={'natural.6'}>{userData.phone}</Text>
                                <Text fw={500} size={14} c={'natural.6'}>{userData.email}</Text>
                            </Flex>
                        </Flex>
                    </Box>

                    {/*PROFESSION DATA*/}
                    <Box className={ classesCommon.islandLightBlock }>
                        <Box className={styles.userProfileSectionTitleContainer}>
                            <Text className={styles.userProfileSectionTitle}>Профессиональные данные</Text>
                        </Box>
                        <Flex className={styles.userProfileSectionContentContainer} gap={20}>
                            <Flex direction={'column'} gap={4}>
                                <Text fw={500} c={'natural.5'} size={12}>Организация</Text>
                                <Text fw={400} c={'natural.9'} size={16}>{userData.organizations ? userData.organizations.join('; ') : '-'}</Text>
                            </Flex>
                            <Flex direction={'column'} gap={4}>
                                <Text fw={500} c={'natural.5'} size={12}>Подразделение</Text>
                                <Text fw={400} c={'natural.9'} size={16}>{userData.department ? userData.department : '-'}</Text>
                            </Flex>
                            <Flex direction={'column'} gap={4}>
                                <Text fw={500} c={'natural.5'} size={12}>Специализация</Text>
                                <Flex gap={4} wrap='wrap'>
                                    {userData.specializations && userData.specializations.length > 0 ? userData.specializations.map((specialization, index) => <SpecializationBadge key={`specialization-${index}`} title={specialization} />) : '-'}
                                </Flex>
                            </Flex>
                        </Flex>

                        {/*WORK SCHEDULE*/}
                        <Flex className={styles.userProfileSectionContentContainer} gap={8}>
                            <Text fw={500} c={'natural.5'} size={12}>Рабочий график</Text>

                            <UserWorkSchedule
                                isOnlyView
                                initialData={transformedWorkSchedule}
                            />
                        </Flex>

                    </Box>

                </Flex>

            </Grid.Col>

            <Grid.Col sm={4} md={3} xl={3} span={12}>

                {/*RIGHTS AND ROLES*/}
                <Box className={ classesCommon.islandLightBlock }>
                    <Box className={styles.userProfileSectionTitleContainer}>
                        <Text className={styles.userProfileSectionTitle}>Права и роли</Text>
                    </Box>

                    <Flex className={styles.userProfileSectionContentContainer} gap={10}>
                        {/*RIGHTS*/}
                        <Flex direction={'column'} gap={4}>
                            <Text fw={500} c={'natural.5'} size={12}>Договорной статус</Text>
                            <Text fw={400} c={'natural.9'} size={16}>{userType[userData.type] || '-'}</Text>
                        </Flex>
                        {/*ROLES*/}
                        {isRolesVisible && userData.roles && userData.roles.length > 0 ? <Flex direction={'column'} gap={8}>
                            <Text c={'natural.9'} size={20}>Роли</Text>
                            <Flex direction='column' gap={12}>
                                {userData.roles.map((role, index) => <Checkbox
                                    key={`role - ${index}`}
                                    checked={role.value}
                                    disabled={true}
                                    label={role.title}
                                    styles={(theme) => ({
                                        input: {
                                            cursor: 'default !important',
                                            '&:hover': {
                                                borderColor: '#C8D1E0'
                                            },
                                            '&:checked': {
                                                backgroundColor: '#C3C7CF',
                                            },
                                        },
                                        label: {
                                            cursor: 'default !important',
                                            '&[data-disabled]': {
                                                color: `${role.value ? theme.colors.natural[8] : theme.colors.gray[6]} !important`
                                            }
                                        }
                                    })}
                                />)}
                            </Flex>
                        </Flex> : null}
                    </Flex>
                </Box>
            </Grid.Col>
        </Grid>
    )
}