import { emptyApi as api } from './emptyApi';
export const addTagTypes = ['User'] as const;
const injectedRtkApi = api
    .enhanceEndpoints({
        addTagTypes,
    })
    .injectEndpoints({
        endpoints: (build) => ({
            usersFind: build.query<UsersFindApiResponse, UsersFindApiArg>({
                query: (queryArg) => ({
                    url: '/api/v1/users',
                    params: {
                        limit: queryArg.limit,
                        offset: queryArg.offset,
                        filter: queryArg.filter,
                        sort: queryArg.sort,
                    },
                }),
                providesTags: ['User'],
            }),
            userCreate: build.mutation<UserCreateApiResponse, UserCreateApiArg>({
                query: (queryArg) => ({
                    url: '/api/v1/users',
                    method: 'POST',
                    body: queryArg,
                }),
                invalidatesTags: ['User'],
            }),
            userWorkersFind: build.query<
                UserWorkersFindApiResponse,
                UserWorkersFindApiArg
            >({
                query: (queryArg) => ({
                    url: '/api/v1/users/performers',
                    params: {
                        limit: queryArg.limit,
                        offset: queryArg.offset,
                        filter: queryArg.filter,
                        sort: queryArg.sort,
                    },
                }),
                providesTags: ['User'],
            }),
            usersFindByPermission: build.mutation<
                UsersFindByPermissionApiResponse,
                UsersFindByPermissionApiArg
            >({
                query: (queryArg) => ({
                    url: '/api/v1/users/bypermissions',
                    method: 'POST',
                    body: queryArg,
                }),
                invalidatesTags: ['User'],
            }),
            usersFindBySpecialization: build.mutation<
                UsersFindBySpecializationApiResponse,
                UsersFindBySpecializationApiArg
            >({
                query: (queryArg) => ({
                    url: '/api/v1/users/specialization',
                    method: 'POST',
                    body: queryArg,
                }),
                invalidatesTags: ['User'],
            }),
            usersFindOne: build.query<UsersFindOneApiResponse, UsersFindOneApiArg>({
                query: (queryArg) => ({ url: `/api/v1/users/${queryArg}` }),
                providesTags: ['User'],
            }),
            userUpdate: build.mutation<UserUpdateApiResponse, UserUpdateApiArg>({
                query: (queryArg) => ({
                    url: `/api/v1/users/${queryArg.id}`,
                    method: 'PUT',
                    body: queryArg.updateUserRequestDto,
                }),
                invalidatesTags: ['User'],
            }),
            userRemove: build.mutation<UserRemoveApiResponse, UserRemoveApiArg>({
                query: (queryArg) => ({
                    url: `/api/v1/users/${queryArg}`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['User'],
            }),
            usersGetBotLink: build.query<
                UsersGetBotLinkApiResponse,
                UsersGetBotLinkApiArg
            >({
                query: () => ({ url: '/api/v1/users/bot-link' }),
                providesTags: ['User'],
            }),
        }),
        overrideExisting: false,
    });
export { injectedRtkApi as userApi };
export type UsersFindApiResponse = /** status 200 ОК */ {
    metadata: {
        id: string;
        permissions: string[];
    }[];
    data: FindUserResponseDto[];
    pagination: {
        offset: number;
        limit: number;
        total: number;
    };
};
export type UsersFindApiArg = {
    /** Кол-во возвращаемых элементов */
    limit?: number;
    /** Сдвиг значений */
    offset?: number;
    /** Фильтры */
    filter?: string;
    /** Сортировка */
    sort?: string;
};
export type UserCreateApiResponse = /** status 201  */ FindUserResponseDto;
export type UserCreateApiArg = CreateUserRequestDto;
export type UserWorkersFindApiResponse = /** status 200 ОК */ {
    metadata: {
        id: string;
        permissions: string[];
    }[];
    data: FindUserResponseDto[];
    pagination: {
        offset: number;
        limit: number;
        total: number;
    };
};
export type UserWorkersFindApiArg = {
    /** Кол-во возвращаемых элементов */
    limit?: number;
    /** Сдвиг значений */
    offset?: number;
    /** Фильтры */
    filter?: string;
    /** Сортировка */
    sort?: string;
};
export type UsersFindByPermissionApiResponse = /** status 200  */ {
    metadata: {
        id: string;
        permissions: string[];
    }[];
    data: FindUserResponseDto[];
};
export type UsersFindByPermissionApiArg = string[];
export type UsersFindBySpecializationApiResponse = /** status 200  */ {
    metadata: {
        id: string;
        permissions: string[];
    }[];
    data: FindUserResponseDto[];
};
export type UsersFindBySpecializationApiArg = string[];
export type UsersFindOneApiResponse = /** status 200  */ {
    metadata: {
        id: string;
        permissions: string[];
    }[];
    data: FindUserResponseDto;
};
export type UsersFindOneApiArg = string;
export type UserUpdateApiResponse = /** status 200  */ FindUserResponseDto;
export type UserUpdateApiArg = {
    id: string;
    updateUserRequestDto: UpdateUserRequestDto;
};
export type UserRemoveApiResponse = /** status 200  */ void;
export type UserRemoveApiArg = string;
export type UsersGetBotLinkApiResponse = /** status 200  */ {
    metadata: {
        id: string;
        permissions: string[];
    }[];
    data: FindUserBotLinkResponseDto;
};
export type UsersGetBotLinkApiArg = void;
export type FindDepartmentResponseDto = {
    id: string;
    name: string;
};
export type FindSpecializationResponseDto = {
    id: string;
    name: string;
};
export type FindBucketResponseDto = {
    id: string;
    /** Bucket name */
    name: string;
    /** Bucket path */
    path: string;
};
export type FindFileResponseDto = {
    id: string;
    /** File name */
    name: string;
    /** File mime type */
    type: string;
    /** File size in bytes */
    size: number;
    /** File bucket */
    bucket: FindBucketResponseDto;
};
export type FindOrganizationResponseDto = {
    id: string;
    name: string;
};
export type FindRolePermissionDto = {
    id: string;
    name: string;
    description: string;
    allow: boolean;
};
export type FindRolePermissionGroupDto = {
    name: string;
    permissions: FindRolePermissionDto[];
};
export type FindRoleResponseDto = {
    id: string;
    name: string;
    enabled: boolean;
    visible: boolean;
    type: 'MANAGER' | 'WORKER';
    organization: FindOrganizationResponseDto;
    permissions: FindRolePermissionGroupDto[];
};
export type FindUserResponseDto = {
    id: string;
    createdAt: string;
    updatedAt: string;
    name: string;
    email: string;
    phone: string | null;
    job: string | null;
    chatId: number | null;
    deleted: boolean;
    type?: ('STAFF' | 'INDIVIDUAL' | 'CASH') | null;
    department: FindDepartmentResponseDto;
    specializations: FindSpecializationResponseDto[];
    avatar: FindFileResponseDto;
    roles: FindRoleResponseDto[];
    organizations: FindOrganizationResponseDto[];
    createdUser: FindUserResponseDto;
    updatedUser: FindUserResponseDto;
    workingDays: (number[][] | (string | null))[];
};
export type ApiError = {
    status: number;
    message: string[];
    error: string;
};
export type CreateUserRequestDto = {
    name: string;
    email: string;
    password: string;
    phone?: string | null;
    job?: string | null;
    type?: ('STAFF' | 'INDIVIDUAL' | 'CASH') | null;
    avatarId?: string | null;
    departmentId?: string | null;
    specializationsIds?: string[] | null;
    rolesIds: string[];
    groupsIds: string[];
    organizationsIds: string[];
    workingDays: (number[][] | (string | null))[];
};
export type UpdateUserRequestDto = {
    name: string;
    email: string;
    phone?: string | null;
    job?: string | null;
    deleted: boolean;
    type?: ('STAFF' | 'INDIVIDUAL' | 'CASH') | null;
    avatarId?: string | null;
    departmentId?: string | null;
    specializationsIds?: string[] | null;
    rolesIds: string[];
    groupsIds: string[];
    organizationsIds: string[];
    workingDays: (number[][] | (string | null))[];
};
export type FindUserBotLinkResponseDto = {
    link: string;
};
export const {
    useUsersFindQuery,
    useLazyUsersFindQuery,
    useUserCreateMutation,
    useUserWorkersFindQuery,
    useLazyUserWorkersFindQuery,
    useUsersFindByPermissionMutation,
    useUsersFindBySpecializationMutation,
    useUsersFindOneQuery,
    useLazyUsersFindOneQuery,
    useUserUpdateMutation,
    useUserRemoveMutation,
    useUsersGetBotLinkQuery,
    useLazyUsersGetBotLinkQuery,
} = injectedRtkApi;
