import { StoresSliceInitialState } from 'components/Resources/Materials/NestedPages/Stores/Stores.types'
import { storeFormHeaderInitialState, storeFormBodyInitialState } from 'components/Resources/Materials/NestedPages/Stores/Stores.utils'
import { createAppSlice } from 'utils/createAppSlice'
import { defaultInitialState } from 'utils/defaultInitialState.ts'

const storesSliceInitialState: StoresSliceInitialState = {
    ...defaultInitialState,
    editableFormHeader: storeFormHeaderInitialState,
    editableFormBody: storeFormBodyInitialState,
}


export const storesSlice = createAppSlice<StoresSliceInitialState>('stores', storesSliceInitialState)


export default storesSlice.reducer
