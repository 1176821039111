import {
    FindProjectJournalResponseDto,
    useLazyProjectJournalsQuery
} from 'store/api/projectJournals.tsx';
import {  useEffect, useMemo, useReducer } from 'react';
import { Box, Button, Flex,  Title } from '@mantine/core';
import dayjs from 'utils/dayjs.ts';
import { useNavigate } from 'react-router-dom';
import { useSortProcessing } from 'hooks/useInputManagers.ts';
import { MRT_ColumnDef, MRT_Row, MRT_SortingState } from 'mantine-react-table';
import { transformMRTSortDataToStorable } from 'utils/inputs.tsx';
import { TablePropsProjectJournal } from 'components/Projects/PageBody/Journals/ProjectJournal.types.ts';
import { useAppDispatch } from 'hooks/redux.ts';
import { journalSlice } from 'store/slices/journalSlice.ts';
import { useFilterParamsString, useSortParamsString } from 'hooks/useFormBasedSliceParams.ts';
import { BoxHeading } from 'components/AbstractComponents/BoxHeading/BoxHeading.tsx';
import { TableReusable } from 'components/AbstractComponents/TableReusable/TableReusable.tsx';

export const JournalTable = (  ) => {

    const [findJournal, findJournalResponse] = useLazyProjectJournalsQuery()

    const navigate = useNavigate()

    const [isInitialRender, setIsInitialRender] = useReducer(
        (state: boolean, payload: boolean): boolean => {
            if (state !== payload) {
                return payload
            } else {
                return state
            }
        },
        true
    )


    const dispatch = useAppDispatch()
    const { setShouldResetGlobalFilterParams } = journalSlice.actions
    const [filter] = useFilterParamsString(journalSlice.name)
    const [sort] = useSortParamsString(journalSlice.name)
    const [processSort] = useSortProcessing(journalSlice.name)

    const resetFilters = () => {
        dispatch(setShouldResetGlobalFilterParams(true))
    }

    const storeSortParams = (tableSortData: MRT_SortingState) => {
        const formattedSortParams = transformMRTSortDataToStorable(tableSortData)
        processSort(formattedSortParams)
    }

    const onRowItemClick = (id: string, projectId: string) => {
        navigate('/projects/project/' + projectId + '/journal/' + id)
    }

    const handleEditClick = (row: MRT_Row<Record<string, any>>) =>
        `/projects/project/${row.original?.project?.id}/journal/${row.original?.id}/edit`

    const handleDeleteClick = (id: string) => {
        console.log('handleDeleteClick', id)
    }

    const columns = useMemo<MRT_ColumnDef<FindProjectJournalResponseDto>[]>(
        () => [
            {
                accessorFn: (row) =>
                    dayjs(row?.createdAt).utc().format('DD-MM-YYYY HH:mm') ,
                id: 'journal.createdAt',
                header: 'Создан',
            },
            {
                accessorFn: (row) => row?.user?.name,
                id: 'user.name',
                header: 'Автор',
            },
            {
                accessorFn: (row) => <Box onClick={() => onRowItemClick(row.id, row?.project?.id)}>{
                    row?.project?.name
                }</Box>,
                id: 'project.name',
                header: 'Проект',
            },
            {
                accessorFn: (row) => row?.field,
                id: 'journal.field',
                header: 'Изменение',
            },
            {
                accessorFn: (row) => row?.oldValue?.value,
                id: 'journal.oldValue',
                header: 'Предыдущее значение',
            },
            {
                accessorFn: (row) => row?.newValue?.value,
                id: 'journal.newValue',
                header: 'Новое значение',
            },
            {
                accessorFn: (row) => row?.comment,
                id: 'journal.comment',
                header: 'Комментарий',
            },

        ],
        [],
    )

    const headerComponentFn = (showHideColumnsButton?: React.JSX.Element) =>
        <BoxHeading>
            <Title order={1}>Журнал</Title>
            <Flex justify={ 'flex-end' } p={ '0 0 16px 0' } gap={ 8 }>
                { showHideColumnsButton }
                <Button variant="secondary" onClick={ resetFilters }>
                    Сбросить фильтры
                </Button>
            </Flex>
        </BoxHeading>

    const tableProps = useMemo(
        () => {
            const props: TablePropsProjectJournal = {
                findApiCall: findJournal,
                findApiResult: findJournalResponse,
                handleDeleteClick,
                columns,
                sortString: sort,
                filterString: filter,
                FiltersComponent: () => <Box/>,
                storeSortParams,
                handleEditClick,
                headerComponentFn,
                isParentMounted: !isInitialRender,
                isNeedOptions: false
            }

            return props
        },
        [
            findJournal,
            findJournalResponse,
            sort,
            filter,
            isInitialRender,
        ]
    )

    useEffect(function initialPageLoad() {
        setIsInitialRender(false)
    }, [])


    return (
        <TableReusable { ...tableProps  } />
    )


}