import { WarehousesSliceInitialState } from 'components/Supply/Warehouses/Warehouses.types'
import { warehouseFormBodyInitialState, warehouseFormHeaderInitialState } from 'components/Supply/Warehouses/Warehouses.utils'
import { createAppSlice } from 'utils/createAppSlice'
import { defaultInitialState } from 'utils/defaultInitialState.ts'

const warehousesSliceInitialState: WarehousesSliceInitialState = {
    ...defaultInitialState,
    editableFormHeader: warehouseFormHeaderInitialState,
    editableFormBody: warehouseFormBodyInitialState,
    permanentFilterParamsArray: [
        {
            param: 'warehouse.deleted',
            rule: 'eq',
            value: false,
        },
    ],
    sortParamsArray: [
        {
            param: 'warehouse.updatedAt',
            rule: 'desc',
        },
    ],
}

export const warehousesSlice = createAppSlice<WarehousesSliceInitialState>(
    'warehouses',
    warehousesSliceInitialState,
)


export default warehousesSlice.reducer
