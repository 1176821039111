import { WorkCardPropsNew } from '../Calendar/Types/CalendarProps.ts';
import React from 'react'
import { WorkCardCalendar } from 'components/Worker/Calendar/Nested/WorkCardCalendar.tsx'
import { WorkCardOverdue } from 'components/Worker/Overdue/WorkCardOverdue.tsx'
import { WorkCardDrawer } from 'components/Worker/Drawers/Nested/WorkCardDrawer.tsx'

export const WorkCard: React.FC<WorkCardPropsNew> = ({ variant, work, isActive, date, progress }) => variant === 'calendar' ?
    <WorkCardCalendar work={work} isActive={isActive} date={date} /> :
    variant === 'overdue' ?
        <WorkCardOverdue work={work} isActive={isActive} date={date} /> :
        <WorkCardDrawer work={work} isActive={isActive} date={date} progress={progress} />
