import { CreateFormulaCalculateRequestDto, FindFormulaResponseDto, FormulaCalculateRequestDto, UpdateFormulaRequestDto } from 'store/api/formulas'
import { Operator, NotFinalValue, FinalValue, FlattenedFormulaItem, FormulaItem, JobFormulaFormBody, JobFormulaFormGlobal, JobFormulaFormHeader, JobFormulaAutocompleteKey } from './Formulas.types'
import { UniqueIdentifier } from '@dnd-kit/core'
import { arrayMove } from '@dnd-kit/sortable'
import { autocompleteChangeArg, autocompleteFieldDefault, autocompleteSubmitArg } from 'utils/inputs'
import { InputSubmitArg, AutocompleteSubmitData, InputChangeArg } from 'types/inputs'
import { FormulaTypesFindSnippetsByJobApiResponse } from 'store/api/formulaTypes'

export const isOperator = (formula: FormulaCalculateRequestDto): formula is Operator => !!formula?.operator
export const isNotFinalValue = (formula: FormulaCalculateRequestDto): formula is NotFinalValue => {
    const value = formula && formula?.value

    return !!(value &&
        typeof value !== 'number')
}
export const isFinalValue = (formula: FormulaCalculateRequestDto): formula is FinalValue => !isNotFinalValue(formula)


// *
// STORE
// *

export const jobFormulasFormGlobalDefaultValues: JobFormulaFormGlobal = {
    name: '',
    formulaItems: [],
    units: autocompleteFieldDefault,
}
export const jobFormulaFormHeaderInitialState: JobFormulaFormHeader = {
    name: '',
}
export const jobFormulaFormBodyInitialState: JobFormulaFormBody = {
    formulaItems: [],
    name: '',
    units: autocompleteFieldDefault,
    job: undefined,
    material: undefined,
    type: undefined,

    // type: FindFormulaTypeResponseDto;
    // job?: FindJobResponseDto | undefined;
    // material: FindMaterialResponseDto;
    // units: FindUnitsResponseDto;
}


export const getParenthesisArray = (formulaTypeSnippetsResponse: FormulaTypesFindSnippetsByJobApiResponse, children?: (number | FormulaCalculateRequestDto)[]): FormulaItem[] => {
    const data = formulaTypeSnippetsResponse.data[formulaTypeSnippetsResponse.data.findIndex(el => el.operations)]
    const operations = data.operations
    const LEFT_PARENTHESIS = operations[operations.findIndex(el => el.type === 'LEFT_PARENTHESIS')]
    const RIGHT_PARENTHESIS = operations[operations.findIndex(el => el.type === 'RIGHT_PARENTHESIS')]

    const LEFT_PARENTHESIS_ITEM: FormulaItem = {
        id: `${LEFT_PARENTHESIS?.id}_${0}_${Math.random()}`,
        type: 'vanillaOperator',
        color: data.colour,
        displayName: LEFT_PARENTHESIS.name,
        children: [],
        value: { ...LEFT_PARENTHESIS, colour: data.colour },
    }

    const RIGHT_PARENTHESIS_ITEM: FormulaItem = {
        id: `${RIGHT_PARENTHESIS?.id}_${1}_${Math.random()}`,
        type: 'vanillaOperator',
        color: data.colour,
        displayName: RIGHT_PARENTHESIS.name,
        children: [],
        value: { ...RIGHT_PARENTHESIS, colour: data.colour },
    }

    return children?.length ? [LEFT_PARENTHESIS_ITEM, ...convertFormulaValueToStoreFormat(formulaTypeSnippetsResponse, children), RIGHT_PARENTHESIS_ITEM] : [LEFT_PARENTHESIS_ITEM, RIGHT_PARENTHESIS_ITEM]
}

export const getOpenClosingBracketsArray = (formulaTypeSnippetsResponse: FormulaTypesFindSnippetsByJobApiResponse | undefined, children?: (number | FormulaCalculateRequestDto)[]): FormulaItem[] => formulaTypeSnippetsResponse ? getParenthesisArray(formulaTypeSnippetsResponse, children) : []
export const getConditionOperatorChildren = (formulaTypeSnippetsResponse: FormulaTypesFindSnippetsByJobApiResponse | undefined, children?: (number | FormulaCalculateRequestDto)[][]): FormulaItem[] => {
    const THEN: FormulaItem = {
        id: `$THEN_${Math.random()}`,
        children: null,
        color: 'Success',
        displayName: '?',
        type: 'conditionSpecialOperator',
        value: {
            colour: 'Success',
            id: `$THEN_${Math.random()}`,
            name: '?',
            operator: '?',
        },
    }
    const ELSE: FormulaItem = {
        id: `$ELSE_${Math.random()}`,
        children: null,
        color: 'Success',
        displayName: ':',
        type: 'conditionSpecialOperator',
        value: {
            colour: 'Success',
            id: `$ELSE_${Math.random()}`,
            name: ':',
            operator: ':',
        },
    }

    const result = children?.length ? [
        getOpenClosingBracketsArray(formulaTypeSnippetsResponse)[0],

        ...getOpenClosingBracketsArray(formulaTypeSnippetsResponse, children[0]),
        THEN,
        ...getOpenClosingBracketsArray(formulaTypeSnippetsResponse, children[1]),
        ELSE,
        ...getOpenClosingBracketsArray(formulaTypeSnippetsResponse, children[2]),

        getOpenClosingBracketsArray(formulaTypeSnippetsResponse)[1],
    ] : [
        getOpenClosingBracketsArray(formulaTypeSnippetsResponse)[0],

        ...getOpenClosingBracketsArray(formulaTypeSnippetsResponse),
        THEN,
        ...getOpenClosingBracketsArray(formulaTypeSnippetsResponse),
        ELSE,
        ...getOpenClosingBracketsArray(formulaTypeSnippetsResponse),

        getOpenClosingBracketsArray(formulaTypeSnippetsResponse)[1],
    ]

    return [...result].map((el, i) => ({ ...el, id: `${i}_${el?.id}_${Math.random()}`, type: 'conditionSpecialOperator' }))
}
export const getConditionOperatorValue = (formulaTypeSnippetsResponse: FormulaTypesFindSnippetsByJobApiResponse | undefined) => getConditionOperatorChildren(formulaTypeSnippetsResponse).map(el => el.value)


// *
// DATA CONVERT FUNCS
// *

export const convertFormulaToStoreFormat = (formula: FindFormulaResponseDto, formulaTypeSnippetsResponse: FormulaTypesFindSnippetsByJobApiResponse | undefined): JobFormulaFormBody => ({
    name: formula?.name,
    formulaItems: convertFormulaValueToStoreFormat(formulaTypeSnippetsResponse, formula?.value),
    job: formula?.job,
    material: formula?.material,
    type: formula?.type,
    units: formula?.units ? { name: formula.units.name, data: { id: formula.units.id, name: formula.units.name } } : autocompleteFieldDefault,
})
export const convertFormulaToRequestDto = (formula: JobFormulaFormBody & { value: FlattenedFormulaItem[], jobId: string | undefined, typeId: string }): UpdateFormulaRequestDto => ({
    name: formula.name,
    value: convertFormulaValueToRequestDto(formula?.value),
    jobId: formula.jobId,
    // jobId: formula?.job?.id,
    materialId: formula?.material?.id,
    typeId: formula.typeId,
    unitsId: formula.units.data?.id,
})

export const convertFormulaValueToStoreFormat = (formulaTypeSnippetsResponse: FormulaTypesFindSnippetsByJobApiResponse | undefined, formulaValue?: FindFormulaResponseDto['value']): FormulaItem[] => {
    const result: FormulaItem[] = []

    if (Array.isArray(formulaValue)) {
        formulaValue.forEach((v, i) => {
            if (typeof v === 'number') {
                const id = `${v}_${i}_${Math.random()}`
                const data: FormulaItem = {
                    id,
                    type: 'number',
                    color: 'number',
                    displayName: v,
                    children: null,
                    value: v,
                }
                result.push(data)
            } else if (!v) {
                const id = `${1}_${i}_${Math.random()}`
                const data: FormulaItem = {
                    id,
                    type: 'number',
                    color: 'number',
                    displayName: 1,
                    children: null,
                    value: 1,
                }
                result.push(data)
            } else if (isOperator(v)) {
                if (isNotFinalValue(v) && Array.isArray(v?.value)) {
                    const id = `${v?.id}_${i}_${Math.random()}`
                    if (v.type === 'CONDITION') {
                        const data: FormulaItem = {
                            id,
                            type: 'capaciousOperator',
                            color: v?.colour,
                            displayName: v.name,
                            children: getConditionOperatorChildren(formulaTypeSnippetsResponse, v?.value as (number | FormulaCalculateRequestDto)[][]),
                            value: v,
                        }
                        result.push(data)
                    } else {
                        const data: FormulaItem = {
                            id,
                            type: 'capaciousOperator',
                            color: v?.colour,
                            displayName: v.name,
                            children: convertFormulaValueToStoreFormat(formulaTypeSnippetsResponse, v?.value as (number | FormulaCalculateRequestDto)[]),
                            value: v,
                        }
                        result.push(data)
                    }

                } else {
                    const id = `${v?.id}_${i}_${Math.random()}`
                    const data: FormulaItem = {
                        id,
                        type: 'vanillaOperator',
                        color: v?.colour,
                        displayName: v.name,
                        children: [],
                        value: v,
                    }
                    result.push(data)
                }
            } else if (isNotFinalValue(v)) {
                if (Array.isArray(v?.value)) {
                    const id = `${v?.id}_${i}_${Math.random()}`
                    const data: FormulaItem = {
                        id,
                        type: 'capaciousFormula',
                        color: v?.colour,
                        displayName: v.name,
                        children: convertFormulaValueToStoreFormat(formulaTypeSnippetsResponse, v?.value),
                        value: v,
                    }

                    result.push(data)
                } else {
                    const id = `${v?.id}_${i}_${Math.random()}`
                    const data: FormulaItem = {
                        id,
                        type: 'materialParameter',
                        color: v?.colour,
                        displayName: `${v?.value?.name}  ${v?.value?.name == v.name ? '' : '(' + v.name + ')'}`,
                        children: [],
                        value: v,
                    }
                    result.push(data)
                    // drawCalculatedFormulaPart(v.value.value)
                }
            } else if (isFinalValue(v)) {
                const id = `${v?.id}_${i}_${Math.random()}`
                const data: FormulaItem = {
                    id,
                    type: 'final',
                    color: v?.colour,
                    displayName: v.name,
                    children: [],
                    value: v,
                }
                result.push(data)
            }
        })
    }

    return result
}
const convertFormulaPartToDtoFormat = (data: FlattenedFormulaItem | FormulaItem): number | FormulaCalculateRequestDto => {
    const type = data.type

    if (type === 'materialParameter') {
        const value = data?.value as NotFinalValue
        return {
            id: String(value?.id),
            name: value.name,
            colour: value?.colour,
            value: value?.value,
        }
    } else if (type === 'final') {
        const value = data?.value as FinalValue
        return {
            id: String(value?.id),
            name: value.name,
            colour: value?.colour,
            value: value?.value
        }
    } else if (type === 'vanillaOperator') {
        const value = data?.value as Operator
        return {
            id: String(value?.id),
            name: value.name,
            colour: value?.colour,
            type: value?.type,
            operator: value?.operator,
        }
    } else if (type === 'capaciousFormula' || type === 'capaciousOperator') {
        const value = data?.value as NotFinalValue
        return {
            id: String(value?.id),
            name: value.name,
            colour: value?.colour,
            type: value?.type,
            operator: value?.operator,
            value: data.children?.map(el => convertFormulaPartToDtoFormat(el))
        }
    } else if (type === 'condition') {
        const value = data?.value as NotFinalValue
        const children = data.children ? [...data.children] : []
        const reducedChildren = children.slice(1, children.length - 1)

        const firstMarkIndex = reducedChildren.findIndex(el => el.displayName === '?')
        const secondMarkIndex = reducedChildren.findIndex(el => el.displayName === ':')

        const CONDITION = reducedChildren.slice(0, firstMarkIndex).filter(el => el.type !== 'conditionSpecialOperator').map(el => convertFormulaPartToDtoFormat(el))
        const THEN = reducedChildren.slice(firstMarkIndex + 1, secondMarkIndex).filter(el => el.type !== 'conditionSpecialOperator').map(el => convertFormulaPartToDtoFormat(el))
        const ELSE = reducedChildren.slice(secondMarkIndex + 1).filter(el => el.type !== 'conditionSpecialOperator').map(el => convertFormulaPartToDtoFormat(el))

        // console.log('CONDITION: ', CONDITION)
        // console.log('THEN: ', THEN)
        // console.log('ELSE: ', ELSE)

        return {
            id: String(value?.id),
            name: value.name,
            colour: value?.colour,
            type: value?.type,
            operator: value?.operator,
            value: [CONDITION, THEN, ELSE]
        }
    } else {
        return data?.value as number
    }
}
export const convertFormulaValueToRequestDto = (value: FlattenedFormulaItem[]): CreateFormulaCalculateRequestDto['formula'] => {
    console.log('value: ', value)

    return value ? [...value].map(el => convertFormulaPartToDtoFormat(el)) : []
}


// *
// INPUT PROCESSING FUNCS
// *

export const onJobFormulaBodyAutocompleteSubmit = ({ key, setFormValue }: InputSubmitArg<JobFormulaAutocompleteKey, JobFormulaFormBody>) =>
    (data: AutocompleteSubmitData) => {
        setFormValue(key, autocompleteSubmitArg(data))
    }
export const onJobFormulaBodyAutocompleteChange = ({ formData, key, setFormValue }: InputChangeArg<JobFormulaAutocompleteKey, JobFormulaFormBody>) =>
    (label: string) => {
        if (formData[key]?.name !== label) {
            setFormValue(key, autocompleteChangeArg(label))
        }
    }


// *
// UI
// *

const refreshNestedIndexes = (data: FlattenedFormulaItem[], parentIds: UniqueIdentifier[]): FlattenedFormulaItem[] => {
    const flattenedItemsCopy = [...data]

    parentIds.forEach((parentId) => {
        const childrenByParent = flattenedItemsCopy.filter(child => child.parentId === parentId)

        childrenByParent.forEach((child, localIndex) => {
            const flatIndex = flattenedItemsCopy.findIndex(c => c?.id === child?.id)

            if (flatIndex > -1) {
                flattenedItemsCopy[flatIndex] = { ...child, index: localIndex }
            }
        })
    })

    return flattenedItemsCopy
}

const assignParentage = (data: FlattenedFormulaItem[]): FlattenedFormulaItem[] => {
    const flattenedItemsCopy = [...data]

    const capaciousIndexes = flattenedItemsCopy.reduce<number[]>((acc, item, index) => {
        if (item.type === 'capaciousFormula' || item.type === 'capaciousOperator' || item.type === 'condition') {
            return [...acc, index]
        } else {
            return acc
        }
    }, [])
    const maxPossibleDepth = capaciousIndexes.length
    const maxCapaciousIndex = Math.max(...capaciousIndexes)

    let capaciousArrayLevel = 0
    let depth = 0

    flattenedItemsCopy.forEach((el, i) => {
        if (maxPossibleDepth && isFinite(maxCapaciousIndex)) {
            const prevCapaciousIndex: number | undefined = capaciousIndexes[capaciousArrayLevel - 1]
            const currentCapaciousIndex = capaciousIndexes[capaciousArrayLevel]
            const prevCapaciousId = flattenedItemsCopy[prevCapaciousIndex]?.id
            const currentCapaciousId = flattenedItemsCopy[currentCapaciousIndex]?.id

            const isCapacious = i === currentCapaciousIndex

            if (depth === 0) flattenedItemsCopy.splice(i, 1, { ...el, depth: 0, parentId: null })
            if (depth > 0) {
                const prevElement = flattenedItemsCopy[i - 1]
                const passedLevelItems = flattenedItemsCopy.slice(prevCapaciousIndex, currentCapaciousIndex) // (0, nextCapaciousIndex) ???
                const passedLevelBracketsImbalance = bracketsImbalance(passedLevelItems)

                if (prevElement.displayName === ')' && el.type !== 'conditionSpecialOperator' && passedLevelBracketsImbalance === 0) {
                    if (depth) depth -= 1
                    flattenedItemsCopy.splice(i, 1, { ...el, depth: depth, parentId: depth ? prevCapaciousId : null })
                } else {
                    const parentId = () => {
                        if (prevCapaciousIndex) {
                            if (i > prevCapaciousIndex && i <= currentCapaciousIndex) {
                                return prevCapaciousId
                            } else {
                                return currentCapaciousId
                            }
                        } else {
                            if (i > currentCapaciousIndex) {
                                return currentCapaciousId
                            } else {
                                return null
                            }
                        }
                    }
                    flattenedItemsCopy.splice(i, 1, { ...el, depth: depth, parentId: parentId() })
                }
            }
            if (isCapacious) {
                if (depth < maxPossibleDepth) depth += 1
                if (currentCapaciousIndex < maxCapaciousIndex) capaciousArrayLevel += 1
            }
        } else {
            flattenedItemsCopy.splice(i, 1, { ...el, depth: 0, parentId: null })
        }
    })

    const capaciousIds = capaciousIndexes.reduce<UniqueIdentifier[]>((acc, item) => [...acc, flattenedItemsCopy[item]?.id], [])
    return refreshNestedIndexes(flattenedItemsCopy, capaciousIds)
}

export const buildFormulaTree = (flattenedItems: FlattenedFormulaItem[]): FlattenedFormulaItem[] => {
    const normalizedData = assignParentage(flattenedItems)
    const tree: FlattenedFormulaItem[] = []

    normalizedData.forEach(item => {

        if (item.depth > 0) {
            const parentId = item.parentId
            const parentIndex = tree.findIndex(el => el?.id === parentId)

            if (parentIndex > -1) {
                const parentChildren = tree[parentIndex]['children'] || []
                parentChildren[item.index] = item
                const newParent = { ...tree[parentIndex], children: parentChildren }
                tree[parentIndex] = newParent
            } else {
                console.log('TODO here')

                // TODO here - check if this condition is needed
                // insertNestedChild(tree, item, parentId)
            }
        } else {
            // tree.push({ ...item, children: item.children?.length ? [...item.children] : [] })

            if (item.type === 'capaciousOperator' || item.type === 'capaciousFormula' || item.type === 'condition') {
                tree.push({ ...item, children: item.children?.length ? [...item.children] : [] })
            } else {
                tree.push({ ...item, children: null })
            }
        }
    })

    let index = 0
    const indexedTree = [...tree].map(el => {
        if (el.depth === 0) {
            const result = {
                ...el,
                index,
            }

            index += 1

            return result
        } else {
            return el
        }
    })

    return indexedTree
}

export const getProjection = (
    items: FlattenedFormulaItem[],
    activeId: UniqueIdentifier,
    overId: UniqueIdentifier,
    // dragOffset: number,
    // indentationWidth: number
) => {
    const overItemIndex = items.findIndex(({ id }) => id === overId)
    const activeItemIndex = items.findIndex(({ id }) => id === activeId)
    // const activeItem = items[activeItemIndex]
    const newItems = arrayMove(items, activeItemIndex, overItemIndex)
    const previousItem = newItems[overItemIndex - 1]
    // const nextItem = newItems[overItemIndex + 1]

    const depth = getDepth()
    const parentId = getParentId()
    // const dragDepth = getDragDepth(dragOffset, indentationWidth)
    // const projectedDepth = activeItem?.depth + dragDepth
    // const maxDepth = getMaxDepth({
    //     previousItem,
    // })
    // const minDepth = getMinDepth({ nextItem })
    // let depth = projectedDepth

    // if (projectedDepth >= maxDepth) {
    //     depth = maxDepth
    // } else if (projectedDepth < minDepth) {
    //     depth = minDepth
    // }

    // console.log('dragDepth: ', dragDepth)
    // console.log('minDepth: ', minDepth)
    // console.log('maxDepth: ', maxDepth)

    // console.log('parentId: ', parentId)
    // console.log('items: ', items)
    // console.log('depth: ', depth)

    return { depth, parentId }

    function getDepth() {
        const baseDepth = 0

        if (!previousItem) {
            return baseDepth
        }

        if (previousItem.displayName === ')') {
            return previousItem.depth ? previousItem.depth - 1 : previousItem.depth
        }

        if (previousItem.displayName !== ')') {
            return previousItem.depth
        }

        return baseDepth
    }
    function getParentId() {
        if (depth === 0 || !previousItem) {
            return null
        }

        if (depth === previousItem.depth) {
            return previousItem.parentId
        }

        if (depth < previousItem.depth) {
            const [prevCapacious] = findPrevCapaciousReversely(newItems, previousItem.index)
            return prevCapacious ? prevCapacious?.parentId : null
        }

        // if (depth > previousItem.depth) {
        //     return previousItem.id
        // }

        // const newParent = newItems
        //     .slice(0, overItemIndex)
        //     .reverse()
        //     .find((item) => item.depth === depth)?.parentId

        return null
    }
}

export const flattenTree = (items: FormulaItem[]): FlattenedFormulaItem[] => flatten(items)

const flatten = (items: FormulaItem[], parentId: UniqueIdentifier | null = null, depth = 0): FlattenedFormulaItem[] =>
    items.reduce<FlattenedFormulaItem[]>((acc, item, index) => [
        ...acc,
        { ...item, parentId, depth, index },
        ...flatten(item.children || [], item.id, depth + 1),
    ], [])

const bracketsImbalance = (capaciousChildren: FlattenedFormulaItem[]): number => {
    let bracketsImbalance = 0

    if (capaciousChildren?.length) {
        for (let i = 0; i < capaciousChildren.length; i++) {
            const child = capaciousChildren[i]

            if (child.type === 'capaciousOperator' || child.type === 'capaciousFormula' || child.type === 'condition') {
                break
            } else {
                if (child.displayName === '(') {
                    bracketsImbalance -= 1
                }
                if (child.displayName === ')') {
                    bracketsImbalance += 1
                }
            }
        }
    }

    return bracketsImbalance
}

const findPrevCapaciousReversely = (data: FlattenedFormulaItem[], index: number | null): [FlattenedFormulaItem | null, number | null] => {
    if (typeof index === 'number' && index > -1) {
        const exploredArray = [...data].slice(0, index)

        for (let i = exploredArray.length - 1; i > -1; i--) {
            if (exploredArray[i].type === 'capaciousOperator' || exploredArray[i].type === 'capaciousFormula' || exploredArray[i].type === 'condition') {
                return [exploredArray[i], i]
            }
        }
    }

    return [null, null]
}

const findLastCapacious = (data: FlattenedFormulaItem[]): [FlattenedFormulaItem | null, number | null] => {
    for (let i = data.length - 1; i > 0; i--) {
        if (data[i].type === 'capaciousOperator' || data[i].type === 'capaciousFormula' || data[i].type === 'condition') {
            return [data[i], i]
        }
    }

    return [null, null]
}

export const insertNestedChild = (data: FlattenedFormulaItem[], newChild: FlattenedFormulaItem, parentId: UniqueIdentifier | null) => {
    const [grandParent, grandParentIndex] = findLastCapacious(data)
    const grandParentChildren = grandParent?.children

    if (grandParentChildren?.length && grandParentIndex) {
        const nestedParentIndex = grandParentChildren.findIndex(el => el?.id === parentId)
        const nestedParentChildren = grandParentChildren[nestedParentIndex]?.children || [] as unknown as FlattenedFormulaItem[]
        if (nestedParentChildren) {
            const nestedParentChildrenCopy = [...nestedParentChildren]
            const isChildAlreadyExist = nestedParentChildrenCopy.findIndex(child => child?.id === newChild?.id) > -1
            if (!isChildAlreadyExist) {
                nestedParentChildrenCopy.push(newChild);
                (data[grandParentIndex].children as unknown as FlattenedFormulaItem[])[nestedParentIndex].children = nestedParentChildrenCopy
            }
        } else {
            insertNestedChild(grandParentChildren as unknown as FlattenedFormulaItem[], newChild, parentId)
        }
    } else {
        throw new Error('could not find nested children')
    }
}

const calculateFormulaPart = (formulaPart: FormulaCalculateRequestDto): string | number => {

    if (isOperator(formulaPart)) {
        if (isNotFinalValue(formulaPart) && Array.isArray(formulaPart?.value)) {
            const operator = formulaPart?.name || ''
            const formattedFormValue = getSymbolsArr(formulaPart?.value)
            return String(`${formattedFormValue ? formattedFormValue : ''} ${operator}`)
        } else {
            return formulaPart?.operator ? formulaPart?.operator : ''
        }
    } else if (isNotFinalValue(formulaPart)) {
        return Array.isArray(formulaPart?.value) ? getFormulaSymbolsAmount(formulaPart?.value) : calculateFormulaPart(formulaPart?.value)
    } else if (isFinalValue(formulaPart)) {
        return formulaPart?.name
    } else {
        console.log('unprocessed type')
        return ''
    }
}


const getSymbolsArr = (value: FindFormulaResponseDto['value']) => Array.isArray(value) && value?.map((v) => {
    if (typeof v === 'number') {
        return v
    } else {
        return calculateFormulaPart(v)
    }
})

export const getFormulaSymbolsAmount = (value: FindFormulaResponseDto['value']): number => {

    const symbolsArr = getSymbolsArr(value)
    // console.log('symbolsArr')
    // console.log(symbolsArr)

    ///symbolsAmount + space after each element amount = symbolsArr.length - 1
    if (symbolsArr) {
        const amount = symbolsArr.reduce((acc: number, curr) => {

            let res = 0

            const hasNumber = /\d/

            if (typeof curr == 'string' && String(curr).includes(',') && hasNumber.test(String(curr))) {

                const stringSplitByComma = String(curr).split(',')

                ///last element contains ')'
                const lasElement = stringSplitByComma[stringSplitByComma.length - 1].replace(/\s+/g, '')

                const preLastEl = lasElement[0]

                const lastElementWithoutBracket = lasElement.slice(1)

                const arrWithAllSeparatedElements = [...stringSplitByComma].slice(0, stringSplitByComma.length - 1)

                arrWithAllSeparatedElements.push(preLastEl, lastElementWithoutBracket)

                //console.log('arrWithAllSeparatedElements: ', arrWithAllSeparatedElements)
                res = arrWithAllSeparatedElements.reduce((acc, curr) => {
                    const currStringLength = String(curr).length >= 12 ? 12 : String(curr).length
                    return acc + currStringLength
                }
                ///*2 = padding each item  + stringSplitByComma.length -1 = space after item
                , 0) + stringSplitByComma.length * 4 + Math.floor((stringSplitByComma.length - 1) / 2)

            } else {
                ///0.5 = space after item + 2 = padding each item
                const currStringLength = String(curr).length >= 12 ? 12 : String(curr).length
                res = currStringLength + 4.5

                // console.log('curr ', curr)
                // console.log('res without commas or numbers: ', res)
            }

            //console.log('res = ', res)
            return acc + res
        }, 0)
        // console.log('result: ===', result)
        // console.log('amount symbols in symbolsArr: ', amount)

        return amount
    }
    return 1


}

export const isDraggableFormulaPart = (type: any) => {
    const typedType = type as FormulaItem['type']

    if (typedType === 'condition' || typedType === 'conditionSpecialOperator') {
        return false
    } else {
        return true
    }
}

// const setProperty = <T extends keyof FormulaItem>(
//     items: FormulaItem[],
//     id: UniqueIdentifier,
//     property: T,
//     setter: (value: FormulaItem[T]) => FormulaItem[T]
// ) => {
//     for (const item of items) {
//         if (item.id === id) {
//             item[property] = setter(item[property])
//             continue
//         }

//         if (item.children?.length) {
//             item.children = setProperty(item.children, id, property, setter)
//         }
//     }

//     return [...items]
// }
