import { multiselectFieldDefault } from 'utils/inputs.tsx';
import {
    MaterialsMultiselectFilterParamKey,
    ReplaceMaterialBodyInitialState, ReplaceMaterialFormValues,
    ReplaceMaterialHeaderInitialState
} from 'components/Projects/PageBody/Correctives/CorrectivesParts/Filters/replaceMaterial.types.ts';
import { InputChangeArg, InputSubmitArg } from 'types/inputs.ts';
import { ChangedResource, ChangedResourceWithId } from 'components/Projects/PageBody/Correctives/Corrective.types.ts';
import {
    FindProjectAdjustmentListResponseDto,
    FindProjectAdjustmentResourceResponseDto,
} from 'store/api/projectAdjustments.tsx';


export const replaceMaterialHeaderInitialState: ReplaceMaterialHeaderInitialState = {
    name: '',
}

export const replaceMaterialBodyInitialState: ReplaceMaterialBodyInitialState = {
    type: multiselectFieldDefault,
    isAccountable: false,
    isChecked: false,
}


export const onMaterialTypeMultiselectChange = ({ formData, key, setFormValue }: InputChangeArg<MaterialsMultiselectFilterParamKey, ReplaceMaterialFormValues>) => (label: string) => {
    if (formData[key]?.name !== label) {
        setFormValue(`${key}.name`, label)
    }
}
export const onMAterialTypeMultiselectSubmit = ({ key, setFormValue }: InputSubmitArg<MaterialsMultiselectFilterParamKey, ReplaceMaterialFormValues>) => (valueIds: Array<string>) => {
    setFormValue(`${key}.ids`, valueIds)
}

export const replaceMaterialFormDefaultValues: ReplaceMaterialFormValues = {
    name: '',
    type: {
        name: '',
        ids: [],
        alreadySelected: [],
    },
    isAccountable: false,
    isChecked: false,
}

export  const getUniqueChangedMaterialForStore = (resource: ChangedResourceWithId, storeResourcesArr: ChangedResourceWithId[]): ChangedResourceWithId[] => {
    const idx = storeResourcesArr.findIndex((el) => {
        if (resource.materialBeforeId == '') {
            return el.materialAfterId === resource.materialAfterId && el.workId == resource.workId
        } else {
            return el.materialBeforeId === resource.materialBeforeId && el.workId == resource.workId
        }
    })
    ///if resource is in store already
    if (idx > -1) {
        return  [...storeResourcesArr.slice(0, idx), resource, ...storeResourcesArr.slice(idx + 1) ]
    } else {
        return [...storeResourcesArr, resource]
    }
}

export const getIsOriginalMaterialChanged = (changedResource: ChangedResource, adjustmentData:  FindProjectAdjustmentListResponseDto ): FindProjectAdjustmentResourceResponseDto => {

    let originalResource: FindProjectAdjustmentResourceResponseDto = {
        id: null,
        countBefore: 0,
        costBefore: 0,
        countAfter: 0,
        costAfter: 0,
        error: [],
        fromClient: false,
        isDeleted: false,
        materialBefore: null,
        materialAfter: null
    }
    // let workId = ''

    adjustmentData.rooms.forEach((room) => {
        room.works.forEach((work) => {
            work.resources.forEach((resource) => {
                if (resource?.materialBefore && resource?.materialBefore?.id === changedResource.materialBeforeId && changedResource.workId == work.id) {
                    originalResource = resource
                    //  workId = work.id
                }
            })
        })
    })

    return originalResource

}

export const getChangedItems = (notDeletedItems:  ChangedResourceWithId[], adjustmentData: FindProjectAdjustmentListResponseDto) => notDeletedItems.filter((item:  ChangedResourceWithId) => {

    const originalResource = getIsOriginalMaterialChanged(item, adjustmentData)

    if (item.fromClient !== originalResource.fromClient
            || item.isDeleted !== originalResource.isDeleted
            // || item.materialAfterId == null && originalResource.materialAfter !== null
            // || item.materialAfterId !== null && originalResource.materialAfter == null
         || originalResource.materialAfter?.id !== item.materialAfterId) {
        return item
    }
})