
import { defaultInitialState } from 'utils/defaultInitialState.ts'
import {
    InventoryChangedMaterials,
    InventoryMaterialsSliceInitialState
} from 'components/Supply/Inventories/PageBody/InventoryMaterilas.types.ts';
import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import {
    FindDocumentInventoryMaterialResponseDto,
} from 'store/api/documentInventoryMaterial.tsx';
import {
    inventoryMaterialsFormBodyInitialState, inventoryMaterialsFormHeaderInitialState
} from 'components/Supply/Inventories/PageBody/InventoryMaterials.utils.ts';
import { createFormBasedSliceReducers } from 'utils/redux.ts';
import { FindDocumentInventoryStatusResponseDto } from 'store/api/documentInventoryStatuses.tsx';
import { FindDocumentInventoryResponseDto } from 'store/api/documentInventory.tsx';



const inventoryMaterialsSliceInitialState: InventoryMaterialsSliceInitialState = {
    ...defaultInitialState,
    editableFormHeader: inventoryMaterialsFormHeaderInitialState,
    editableFormBody: inventoryMaterialsFormBodyInitialState,
    materialsForEdit: undefined,
    originalMaterialsForEdit: undefined,
    inventoryStatuses: undefined,
    changedMaterials: undefined,
    inventoryData: undefined
}

const reducers = createFormBasedSliceReducers<any>()

export const inventoryMaterialsSlice = createSlice({
    name: 'inventoryMaterials',
    initialState: inventoryMaterialsSliceInitialState,
    reducers: {
        ...reducers,
        setMaterialsForEdit: (state: Draft<InventoryMaterialsSliceInitialState>, action: PayloadAction<FindDocumentInventoryMaterialResponseDto[]>) => {
            state.materialsForEdit = action.payload
        },
        setOriginalMaterialsForEdit: (state: Draft<InventoryMaterialsSliceInitialState>, action: PayloadAction<FindDocumentInventoryMaterialResponseDto[]>) => {
            state.originalMaterialsForEdit = action.payload
        },
        setInventoryStatuses: (state: Draft<InventoryMaterialsSliceInitialState>, action: PayloadAction<FindDocumentInventoryStatusResponseDto[]>) => {
            state.inventoryStatuses = action.payload
        },
        setChangedMaterials: (state: Draft<InventoryMaterialsSliceInitialState>, action: PayloadAction<InventoryChangedMaterials[]>) => {
            state.changedMaterials = action.payload
        },
        setInventoryData:  (state: Draft<InventoryMaterialsSliceInitialState>, action: PayloadAction<FindDocumentInventoryResponseDto>) => {
            state.inventoryData = action.payload
        },
    }
}
)


export default inventoryMaterialsSlice.reducer
