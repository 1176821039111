import { createAppSlice } from 'utils/createAppSlice'
import { defaultInitialState } from 'utils/defaultInitialState.ts'
import { ProjectWarehouseInventoriesSliceInitialState } from 'components/Projects/PageBody/Warehouses/PageBody/ProjectWarehouseInventories/ProjectWarehouseInventories.types.ts'
import { projectWarehouseInventoryFormBodyInitialState, projectWarehouseInventoryFormHeaderInitialState } from 'components/Projects/PageBody/Warehouses/PageBody/ProjectWarehouseInventories/ProjectWarehouseInventories.utils.ts'

const projectWarehouseInventoriesSliceInitialState: ProjectWarehouseInventoriesSliceInitialState = {
    ...defaultInitialState,
    editableFormHeader: projectWarehouseInventoryFormHeaderInitialState,
    editableFormBody: projectWarehouseInventoryFormBodyInitialState,
    
}

export const projectWarehouseInventoriesSlice = createAppSlice<ProjectWarehouseInventoriesSliceInitialState>(
    'projectWarehouseInventories',
    projectWarehouseInventoriesSliceInitialState,
)


export default projectWarehouseInventoriesSlice.reducer
