import { JobsSliceInitialState } from 'components/Resources/Jobs/Jobs.types'
import {
    jobFormBodyInitialState,
    jobFormHeaderInitialState,
} from 'components/Resources/Jobs/Jobs.utils'
import { createAppSlice } from 'utils/createAppSlice'
import { defaultInitialState } from 'utils/defaultInitialState.ts'

const jobsGroupsInitialState: JobsSliceInitialState = {
    ...defaultInitialState,
    editableFormHeader: jobFormHeaderInitialState,
    editableFormBody: jobFormBodyInitialState,
}

export const jobsGroupsSlice = createAppSlice<JobsSliceInitialState>('jobsGroups', jobsGroupsInitialState)


export default jobsGroupsSlice.reducer
