import { Page404 } from 'components/Error/404.tsx'
import { RouteObject } from 'react-router-dom'
import React from 'react'

const Page403 = React.lazy(() => import('components/Error/403.tsx').then((module) => ({ default: module.Page403 })))

export const ERROR_ROUTES: Array<RouteObject> = [
    {
        id: '403',
        path: '/403',
        element: <Page403/>,
        loader: ({ params }) => ({ id: params.id, name: '403' }),
    },
    {
        id: '404',
        path: '/404',
        element: <Page404/>,
        loader: ({ params }) => ({ id: params.id, name: '404' }),
    },
    {
        id: 'notFound',
        path: '*',
        element: <Page404/>,
        loader: ({ params }) => ({ id: params.id, name: '404' }),
    },
]
