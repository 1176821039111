export const INTERVAL_IN_MINUTES = 30
export const MIN_INNER_DIAPASON_IN_MINUTES = 60
export const MIN_OUTER_DIAPASON_IN_MINUTES = 60
export const SECONDS_PER_HOUR = 3600
export const SECONDS_PER_DAY = SECONDS_PER_HOUR * 24

export const getInitialTimePoint = (hours: number) => Math.round(SECONDS_PER_HOUR * hours)
export const getLeftCoordinate = (time: number) => Math.round(100 * time / SECONDS_PER_DAY)
export const getRoundedTime = (time: number) => {
    const toleranceInSeconds = INTERVAL_IN_MINUTES * 60
    return toleranceInSeconds * Math.round(time / toleranceInSeconds)
}
export const getFormattedTime = (roundedTime: number) => {
    const time = String(roundedTime / SECONDS_PER_HOUR).split('.')
    time[0] = ('00' + time[0]).slice(-2)
    time[1] = ('00' + Math.round(60 * (roundedTime / SECONDS_PER_HOUR - +time[0]))).slice(-2)
    return `${time[0]}:${time[1]}`
}
export const getPointStyles = (left = '0px', down: number) => ({
    position: 'absolute' as const,
    left: left,
    zIndex: +down,
})
export const getIntervalStyles = (left = '0px', width = '0px') => ({
    position: 'absolute' as const,
    left: left,
    width: width,
    height: '100%',
    backgroundColor: '#C8D1E0',
    borderRadius: '20px',
})

export const transformWorkingTimeFromSecondsToMinutes = (arg: number[][]) => arg.map(arr => arr.map(seconds => seconds / 60))
export const transformWorkingTimeFromMinutesToSeconds = (arg: number[][]) => arg.map(arr => [arr[0] * 60, arr[1] * 60])
