import { CreateMaterialParameterRequestDto, FindMaterialParameterResponseDto } from 'store/api/materialParameters'
import { MaterialParamAutocompleteKey, MaterialParamFormGlobal, MaterialParamFormGlobalKey } from './MaterialParam.types'
import { autocompleteChangeArg, autocompleteFieldDefault, autocompleteSubmitArg } from 'utils/inputs'
import { InputSubmitArg, AutocompleteSubmitData, InputChangeArg } from 'types/inputs'

// *
// DATA CONVERT FUNCS
// *

export const convertMaterialParamDtoToFormGlobal = (param: FindMaterialParameterResponseDto): MaterialParamFormGlobal => ({
    id: param.id,
    units: param.units ? { name: param.units?.name, data: { id: param.units?.id, name: param.units?.name } } : materialParamFormGlobalInitialState.units,
    name: param.name,
    type: param.type,
    values: param.values,
    value: materialParamFormGlobalInitialState.value,
    isManual: false,
    // isManual: param.isManual,
})

export const convertMaterialParamFormToRequestDto = (data: Partial<MaterialParamFormGlobal>): CreateMaterialParameterRequestDto => ({
    unitsId: data.units?.data?.id ? data.units?.data?.id: data.units?.name || '',
    name: data?.name || materialParamFormGlobalInitialState.name,
    type: data?.value && !isNaN(+data.value) ? 'NUMBER' : 'TEXT',
    // id: data?.id || materialParamFormGlobalInitialState.id,
    // measure: data?.units || materialParamFormGlobalInitialState.measure,
    // values: data?.values || materialParamFormGlobalInitialState.values,
    // value: data?.value || materialParamFormGlobalInitialState.value,
})


// *
// STORE
// *

export const materialParamFormGlobalInitialState: MaterialParamFormGlobal = {
    units: autocompleteFieldDefault,
    name: '',
    type: 'TEXT',
    values: [],
    value: '',
    id: '',
    isManual: true
}


// *
// INPUTS PROCESSING
// *

export const onMaterialParamAutocompleteSubmit = ({ key, setFormValue }: InputSubmitArg<MaterialParamAutocompleteKey, MaterialParamFormGlobal>) =>
    (data: AutocompleteSubmitData) => {
        setFormValue(key, autocompleteSubmitArg(data))
    }
export const onMaterialParamAutocompleteChange = ({ formData, key, setFormValue }: InputChangeArg<MaterialParamAutocompleteKey, MaterialParamFormGlobal>) =>
    (label: string) => {
        if (formData[key]?.name !== label) {
            setFormValue(key, autocompleteChangeArg(label))
        }
    }



export const onMaterialValueChange = ({ key, setFormValue }: InputSubmitArg<MaterialParamFormGlobalKey, MaterialParamFormGlobal>) => (e: any) => {
    setFormValue(key, e.target.value.replace(/,/g, '.'))
}