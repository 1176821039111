import { FindTaskMaterialResponseDto } from 'store/api/taskMaterials'
import { TaskMaterialFormBody, TaskMaterialFormHeader, TaskMaterialsFormGlobal } from './TaskMaterials.types'


// *
// DATA CONVERT FUNCS
// *

export const convertTaskMaterialDtoToFormHeader = (taskMaterial: FindTaskMaterialResponseDto['material']): TaskMaterialFormHeader => ({
    name: taskMaterial?.name || taskMaterialFormHeaderInitialState.name,
})


// *
// STORE
// *

export const taskMaterialsFormGlobalDefaultValues: TaskMaterialsFormGlobal = {
    name: '',
    newTaskMaterialIds: [],
}

export const taskMaterialFormHeaderInitialState: TaskMaterialFormHeader = {
    name: '',
}

export const taskMaterialsFormBodyInitialState: TaskMaterialFormBody = {
    newTaskMaterialIds: [],
}
 