import {
    taskFormBodyInitialState,
    taskFormHeaderInitialState,
} from 'components/Resources/Jobs/NestedPages/Tasks/Tasks.utils'
import { TasksSliceInitialState } from 'components/Resources/Jobs/NestedPages/Tasks/Tasks.types'
import { createAppSlice } from 'utils/createAppSlice'
import { defaultInitialState } from 'utils/defaultInitialState.ts'

const tasksSliceInitialState: TasksSliceInitialState = {
    ...defaultInitialState,
    editableFormHeader: taskFormHeaderInitialState,
    editableFormBody: taskFormBodyInitialState,
}

export const tasksSlice = createAppSlice<TasksSliceInitialState>('tasks', tasksSliceInitialState)


export default tasksSlice.reducer
