import {
    ClientsSliceInitialState,
} from 'components/Clients/Clients.types'
import {
    clientFormHeaderInitialState,
    clientFormBodyInitialState,
} from 'components/Clients/Clients.utils'
import { createAppSlice } from 'utils/createAppSlice'
import { defaultInitialState } from 'utils/defaultInitialState.ts'

const clientInitialState: ClientsSliceInitialState = {
    ...defaultInitialState,
    editableFormHeader: clientFormHeaderInitialState,
    editableFormBody: clientFormBodyInitialState,
}

export const clientsSlice = createAppSlice<ClientsSliceInitialState>('clients', clientInitialState)

export default clientsSlice.reducer
