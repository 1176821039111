import { ViewsSliceInitialState } from 'components/Resources/Jobs/NestedPages/Views/Views.types'
import { viewFormHeaderInitialState, viewFormBodyInitialState } from 'components/Resources/Jobs/NestedPages/Views/Views.utils'
import { createAppSlice } from 'utils/createAppSlice'
import { defaultInitialState } from 'utils/defaultInitialState.ts'

const viewsSliceInitialState: ViewsSliceInitialState = {
    ...defaultInitialState,
    editableFormHeader: viewFormHeaderInitialState,
    editableFormBody: viewFormBodyInitialState,
}

export const viewsSlice = createAppSlice<ViewsSliceInitialState>('views', viewsSliceInitialState)


export default viewsSlice.reducer
