import { createSlice, Draft, type PayloadAction } from '@reduxjs/toolkit';

type BreadCrumbName = {
    breadCrumbName: string
}

const initialState: BreadCrumbName = {
    breadCrumbName: ''
}
export const breadCrumbsNameSlice = createSlice({
    name: 'breadCrumbsName',
    initialState,
    reducers: {
        setBreadCrumbNameToStore: (state: Draft<BreadCrumbName>, action: PayloadAction<string>) => {
            state.breadCrumbName = action.payload
        } }
})

export default breadCrumbsNameSlice.reducer
