import { Badge } from '@mantine/core';
import styles from 'components/AbstractComponents/UserProfile/UserProfile.module.css';


export const SpecializationBadge = ({ title }: { title: string }) => {
    if(title !== '-') return <Badge className={styles.userProfileSpecializationBadge}
        sx={(theme) => ({ color: theme.colors.natural[6], background: theme.colors.custom[0] })}>
        {title}
    </Badge>
    else return '-'
}