import { createStyles } from '@mantine/core'
import { COLOR_WHITE } from 'styles/constants'

export const useStylesCommon = createStyles((theme) => ({
    containerWidth: {
        maxWidth: '600px',
        paddingTop: '32px',
        paddingBottom: '32px',
    },
    containerUnderTab: {
        paddingTop: '32px',
        paddingBottom: '32px',
    },
    islandLightBlock: {
        backgroundColor: COLOR_WHITE,
        border: `1px solid ${ theme.colors.natural[1] }`,
        borderRadius: 8,
        padding: 24,
    },
    linkLightBlock: {
        backgroundColor: COLOR_WHITE,
        border: `1px solid ${ theme.colors.natural[1] }`,
        borderRadius: 8,
        padding: 16,
    },
    islandLightBlockStep: {
        backgroundColor: COLOR_WHITE,
        border: `1px solid ${ theme.colors.natural[1] }`,
        borderRadius: 8,
        padding: '24px 16px',
    },
    islandAccordion: {
        backgroundColor: COLOR_WHITE,
        border: `1px solid ${ theme.colors.natural[1] }`,
        borderRadius: 8,
        padding: '10px 25px',
        margin: '10px 0',
        ':hover > button': {
            borderRadius: 10,
        },
    },
    islandLightTable: {
        backgroundColor: COLOR_WHITE,
        borderRadius: 8,
        padding: 24,
    },
    numberBadge: {
        verticalAlign: 'super',
        // display: 'inline-block',
        fontSize: '12px',
        width: '20px',
        height: '20px',
        lineHeight: '20px',
        color: '#8e8e8e',
        padding: '4px',
        marginLeft: '5px',
        backgroundColor: theme.colors.natural[1],
        borderRadius: '50%',
        fontWeight: 600,
    },
    numberCell: {
        whiteSpace: 'nowrap',
        textAlign: 'right',
    },
    boxHeadingButtonsGroup: {
        justifyContent: 'flex-end',
        gap: 8,
        padding: '16px 32px',
        margin: '0 -42px',
    },
    line: {
        marginTop: '41px',
        border: `1px solid ${ theme.colors.custom[0] }`
    }
}))
