import { WarehouseInventoriesAutocompleteKey, WarehouseInventoriesFormGlobal, WarehouseInventoriesMultiselectKey, WarehouseInventoryFormBody, WarehouseInventoryFormHeader } from 'components/Supply/Warehouses/PageBody/Info/WarehouseInventories/WarehouseInventories.types.ts'
import { FindInventoryResponseDto } from 'store/api/inventory.tsx'
import { AutocompleteSubmitData, InputChangeArg, InputSubmitArg } from 'types/inputs.ts'
import { autocompleteChangeArg, autocompleteFieldDefault, autocompleteSubmitArg, multiselectFieldDefault } from 'utils/inputs.tsx'


// *
// DATA CONVERT FUNCS
// *

export const convertWarehouseInventoryDtoToFormBody = (warehouseInventory: FindInventoryResponseDto): WarehouseInventoryFormBody => ( {
    id: warehouseInventory.id || '',
    count: warehouseInventory.count || 0,
    
    inventoryTypes: multiselectFieldDefault,
    materialTypes: multiselectFieldDefault,
    statuses: multiselectFieldDefault,
    
    materialType: warehouseInventory?.material?.type?.name ? {
        name: warehouseInventory.material.type.name,
        data: {
            id: warehouseInventory.material.type.id,
            name: warehouseInventory.material.type.name,
        },
    } : autocompleteFieldDefault,
    
    units: warehouseInventory?.units ? {
        name: warehouseInventory.units.name,
        data: {
            id: warehouseInventory.units.id,
            name: warehouseInventory.units.name,
        },
    } : autocompleteFieldDefault,
    
    status: warehouseInventory?.status ? {
        name: warehouseInventory.status.name,
        data: {
            id: warehouseInventory.status.id,
            name: warehouseInventory.status.name,
        },
    } : autocompleteFieldDefault,
    
    project: warehouseInventory?.project ? {
        name: warehouseInventory.project.name,
        data: {
            id: warehouseInventory.project.id,
            name: warehouseInventory.project.name,
        },
    } : autocompleteFieldDefault,
    
    material: warehouseInventory?.material ? {
        name: warehouseInventory.material.name,
        data: {
            id: warehouseInventory.material.id,
            name: warehouseInventory.material.name,
        },
    } : autocompleteFieldDefault,
    
    inventoryType: warehouseInventory?.material?.inventoryType?.name ? {
        name: warehouseInventory.material.inventoryType.name,
        data: {
            id: warehouseInventory.material.inventoryType.id,
            name: warehouseInventory.material.inventoryType.name,
        },
    } : autocompleteFieldDefault,
} )

export const convertWarehouseInventoryDtoToFormHeader = (warehouseInventory: FindInventoryResponseDto): WarehouseInventoryFormHeader => ( {
    name: warehouseInventory?.material?.name || '',
} )

// global filter funcs
export const onWarehouseInventoriesGlobalAutocompleteSubmit = ({
    key,
    setFormValue,
}: InputSubmitArg<WarehouseInventoriesAutocompleteKey, WarehouseInventoriesFormGlobal>) => (data: AutocompleteSubmitData) => {
    setFormValue(key, autocompleteSubmitArg(data))
}
export const onWarehouseInventoriesGlobalAutocompleteChange = ({
    formData,
    key,
    setFormValue,
}: InputChangeArg<WarehouseInventoriesAutocompleteKey, WarehouseInventoriesFormGlobal>) => (label: string) => {
    if (formData[key]?.name !== label) {
        setFormValue(key, autocompleteChangeArg(label))
    }
}
export const onWarehouseInventoriesGlobalMultiselectChange = ({
    formData,
    key,
    setFormValue,
}: InputChangeArg<WarehouseInventoriesMultiselectKey, WarehouseInventoriesFormGlobal>) => (label: string) => {
    if (formData[key]?.name !== label) {
        setFormValue(`${ key }.name`, label)
    }
}
export const onWarehouseInventoriesGlobalMultiselectSubmit = ({
    key,
    setFormValue,
}: InputSubmitArg<WarehouseInventoriesMultiselectKey, WarehouseInventoriesFormGlobal>) => (valueIds: Array<string>) => {
    setFormValue(`${ key }.ids`, valueIds)
}

// *
// STORE
// *

export const warehouseInventoriesFormGlobalDefaultValues: WarehouseInventoriesFormGlobal = {
    name: '',
    id: '',
    count: 0,
    
    inventoryTypes: multiselectFieldDefault,
    materialTypes: multiselectFieldDefault,
    statuses: multiselectFieldDefault,
    
    materialType: autocompleteFieldDefault,
    inventoryType: autocompleteFieldDefault,
    material: autocompleteFieldDefault,
    project: autocompleteFieldDefault,
    status: autocompleteFieldDefault,
    units: autocompleteFieldDefault,
}
export const warehouseInventoryFormHeaderInitialState: WarehouseInventoryFormHeader = {
    name: '',
}
export const warehouseInventoryFormBodyInitialState: WarehouseInventoryFormBody = {
    id: '',
    count: 0,
    
    inventoryTypes: multiselectFieldDefault,
    materialTypes: multiselectFieldDefault,
    statuses: multiselectFieldDefault,
    
    materialType: autocompleteFieldDefault,
    inventoryType: autocompleteFieldDefault,
    material: autocompleteFieldDefault,
    project: autocompleteFieldDefault,
    status: autocompleteFieldDefault,
    units: autocompleteFieldDefault,
}

