
import { createAppSlice } from 'utils/createAppSlice'
import { defaultInitialState } from 'utils/defaultInitialState.ts'
import { InventoriesTableSliceInitialState } from 'components/Supply/Inventories/inventoriesTable.types.ts';
import {
    inventoriesTableFormBodyInitialState,
    inventoriesTableFormHeaderInitialState
} from 'components/Supply/Inventories/inventoriesTable.utils.ts';

const inventoriesTableSliceInitialState: InventoriesTableSliceInitialState = {
    ...defaultInitialState,
    editableFormHeader: inventoriesTableFormHeaderInitialState,
    editableFormBody: inventoriesTableFormBodyInitialState,
}
export const inventoriesTableSlice = createAppSlice<InventoriesTableSliceInitialState>('inventoriesTable', inventoriesTableSliceInitialState )

export default inventoriesTableSlice.reducer
