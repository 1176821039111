import { FindCategoryResponseDto, UpdateCategoryRequestDto } from 'store/api/categories'
import { AutocompleteSubmitData, InputChangeArg, InputSubmitArg } from 'types/inputs'
import { autocompleteChangeArg, autocompleteFieldDefault, autocompleteSubmitArg } from 'utils/inputs'
import { CategoriesAutocompleteKey, CategoriesFormGlobal, CategoryFormBody, CategoryFormHeader } from './Categories.types'


// *
// DATA CONVERT FUNCS
// *

export const convertCategoryDtoToFormBody = (category: FindCategoryResponseDto): CategoryFormBody => ({
    url: category?.url || categoryFormBodyInitialState.url,
    code: categoryFormBodyInitialState.code, // no code in response
    store: category?.store && { name: category.store?.name, data: { name: category.store?.name, id: category.store?.id } } || categoryFormBodyInitialState.code,
})
export const convertCategoryDtoToFormHeader = (category: FindCategoryResponseDto): CategoryFormHeader => ({
    name: category?.name || categoryFormHeaderInitialState.name,
})
export const convertCategoryFormToRequestDto = (
    data: Partial<CategoryFormBody & CategoryFormHeader>
): UpdateCategoryRequestDto => ({
    name: data?.name || categoryFormHeaderInitialState.name,
    url: data?.url || categoryFormBodyInitialState.url,
    // code: data?.code?.name && +data?.code?.name || +categoryFormBodyInitialState.code.name,
    storeId: data?.store && (data?.store?.data.id || data?.store?.name) || categoryFormBodyInitialState.store.name
})


// *
// STORE
// *

export const categoriesFormGlobalDefaultValues: CategoriesFormGlobal = {
    name: '',
    url: '',
    code: autocompleteFieldDefault,
    store: autocompleteFieldDefault,
    // imageUrl: '',
}
export const categoryFormHeaderInitialState: CategoryFormHeader = {
    name: '',
}
export const categoryFormBodyInitialState: CategoryFormBody = {
    url: '',
    code: autocompleteFieldDefault,
    store: autocompleteFieldDefault,
    // imageUrl: '',
}


// *
// INPUT PROCESSING FUNCS
// *

export const onCategoryBodyAutocompleteSubmit = ({ key, setFormValue }: InputSubmitArg<CategoriesAutocompleteKey, CategoryFormBody>) => (data: AutocompleteSubmitData) => {
    console.log('data: ', data)
    setFormValue(key, autocompleteSubmitArg(data))
}
export const onCategoryBodyAutocompleteChange = ({ formData, key, setFormValue }: InputChangeArg<CategoriesAutocompleteKey, CategoryFormBody>) => (label: string) => {
    if (formData[key]?.name!==label) {
        setFormValue(key, autocompleteChangeArg(label))
    }
}
