import { configureStore } from '@reduxjs/toolkit'
import { materialsApi } from './api/materials'
import { objectApi } from './api/object'

import rootReducer from './reducers/rootReducer'
import { emptyApi } from './api/emptyApi'
import { authenticationApi } from './api/authentication'
import { errorMiddleware } from 'store/middlewares/error.middleware.ts'

// import storage from 'redux-persist/lib/storage'
// import { PersistConfig } from 'redux-persist'
// import hardSet from 'redux-persist/lib/stateReconciler/hardSet'

// import {
//   persistReducer,
//   FLUSH,
//   REHYDRATE,
//   PAUSE,
//   PERSIST,
//   PURGE,
//   REGISTER,
// } from 'redux-persist'

// const persistConfig: PersistConfig<any> = {
//   key: 'root',
//   storage: storage,
//   stateReconciler: hardSet,
//   whitelist: ['materialsReducer'],
// }
// const persistedReducer = persistReducer(persistConfig, rootReducer)

const MODE = process.env.NODE_ENV

export const setupStore = () => configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware({
        serializableCheck: false
    }).concat([emptyApi.middleware, materialsApi.middleware, authenticationApi.middleware, objectApi.middleware, errorMiddleware]),
    devTools: MODE !== 'production',
})

export const store = setupStore()

export type RootStateType = ReturnType<typeof rootReducer>
export type AppStoreType = ReturnType<typeof setupStore>
export type AppDispatchType = AppStoreType['dispatch']
