import { ApiErrorResponse, RoleType } from 'types/auth'
import { FindRoleResponseDto } from 'store/api/authentication'

export function isApiError(error: unknown): error is ApiErrorResponse {
    return (
        typeof error === 'object' &&
        error != null &&
        'status' in error &&
        typeof error.status === 'number' &&
        typeof (error as ApiErrorResponse).data === 'object' &&
        'status' in (error as ApiErrorResponse).data
    )
}

export const getRoleType = (roles?: FindRoleResponseDto[] | null): RoleType => {
    if (!roles) {
        return RoleType.UNAUTHORIZED
    }

    const firstRole = roles.at(0)

    if (!firstRole) {
        return RoleType.UNKNOWN
    }

    const type: string = firstRole.type

    if (type) {
        return roles?.every(value => value.type === type) ? type as RoleType : RoleType.UNKNOWN
    }

    return RoleType.UNKNOWN
}