import { createAppSlice } from 'utils/createAppSlice'
import { defaultInitialState } from 'utils/defaultInitialState.ts'
import { ProjectWarehousesSliceInitialState } from 'components/Projects/PageBody/Warehouses/ProjectWarehouses.types.ts'
import { projectWarehouseFormBodyInitialState, projectWarehouseFormHeaderInitialState } from 'components/Projects/PageBody/Warehouses/ProjectWarehouses.utils.ts'

const projectWarehousesSliceInitialState: ProjectWarehousesSliceInitialState = {
    ...defaultInitialState,
    editableFormHeader: projectWarehouseFormHeaderInitialState,
    editableFormBody: projectWarehouseFormBodyInitialState,
    permanentFilterParamsArray: [
        {
            param: 'warehouse.deleted',
            rule: 'eq',
            value: false,
        },
    ],
    sortParamsArray: [
        {
            param: 'warehouse.updatedAt',
            rule: 'desc',
        },
    ],
}

export const projectWarehousesSlice = createAppSlice<ProjectWarehousesSliceInitialState>(
    'projectWarehouses',
    projectWarehousesSliceInitialState,
)


export default projectWarehousesSlice.reducer
