import { Badge, Flex } from '@mantine/core'
import { getWorkStatusDataFinance, workStatusColors } from 'components/Worker/Profile/Money/MoneyTab.utils.tsx'
import React from 'react'
import { WorkStatus } from '../Profile/Money/MoneyTab.utils.tsx'

type WorkStatusComponentProps = {
    workStatus: WorkStatus
}

export const WorkStatusComponent: React.FC<WorkStatusComponentProps> = ({ workStatus }) => {
    const status = getWorkStatusDataFinance(workStatus)
    
    return (
        <Badge size={'lg'} radius={4} px={4} py={8} fw="500" fz="xs" sx={(theme) => ({
            ...workStatusColors(workStatus, theme),
            textTransform: 'none'
        })}>
            <Flex gap={4} align={ 'center' }>
                {status.icon}
                {status.title}
            </Flex>
        </Badge>
    )
}
