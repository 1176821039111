import { CreateAccountRequestDto, FindAccountResponseDto } from 'store/api/accounts'
import { AutocompleteSubmitData, InputChangeArg, InputSubmitArg } from 'types/inputs'
import { autocompleteChangeArg, autocompleteFieldDefault, autocompleteSubmitArg } from 'utils/inputs'
import { BankAccountFormGlobal, BankAutocompleteKey, BankFormGlobal } from './BankAccountForms.types'

// TODO split BankAccounts & Banks (if needed, to be equal everywhere)

// *
// DATA CONVERT FUNCS
// *

export const convertBankAccountDtoToFormGlobal = (account: FindAccountResponseDto): BankAccountFormGlobal => ({
    id: account.id,
    number: account?.number || bankAccountFormGlobalInitialState.number,
    clientId: account?.client?.id || bankAccountFormGlobalInitialState.clientId,
    bank: {
        bik: { name: account.bank.bik, data: { id: account.bank.id, name: account.bank.bik } } || autocompleteFieldDefault, // TODO process id presence (find bank immediately)
        id: account.bank.id || bankFormGlobalInitialState.id,
        abbreviatedName: account.bank.abbreviatedName || bankFormGlobalInitialState.abbreviatedName,
        name: account.bank.name || bankFormGlobalInitialState.name,
        address: account.bank.address || bankFormGlobalInitialState.address,
        city: account.bank.city || bankFormGlobalInitialState.city,
        index: account.bank.index || bankFormGlobalInitialState.index,
        ks: account.bank.ks || bankFormGlobalInitialState.ks,
        okato: account.bank.okato || bankFormGlobalInitialState.okato,
        okpo: account.bank.okpo || bankFormGlobalInitialState.okpo,
        phone: account.bank.phone || bankFormGlobalInitialState.phone,
        regNum: account.bank.regNum || bankFormGlobalInitialState.regNum,
    }
})

export const convertBankAccountFormToRequestDto = (data: Partial<BankAccountFormGlobal>): CreateAccountRequestDto => ({
    number: data?.number || bankAccountFormGlobalInitialState.number,
    clientId: data?.clientId || bankAccountFormGlobalInitialState.clientId,
    bankId: data?.bank?.id || bankAccountFormGlobalInitialState.bank.id || '',
})


// *
// STORE
// *


export const bankFormGlobalInitialState: BankFormGlobal = {
    abbreviatedName: '',
    bik: autocompleteFieldDefault,
    id: '',
    name: '',
    address: '',
    city: '',
    index: '',
    ks: '',
    okato: '',
    okpo: '',
    phone: '',
    regNum: '',
}

export const bankAccountFormGlobalInitialState: BankAccountFormGlobal = {
    id: '',
    number: '',
    clientId: '',
    bank: bankFormGlobalInitialState
}


// *
// INPUTS PROCESSING
// *

// body
export const onBankAutocompleteSubmit = ({ key, setFormValue }: InputSubmitArg<BankAutocompleteKey, BankFormGlobal>) =>
    (data: AutocompleteSubmitData) => {
        setFormValue(key, autocompleteSubmitArg(data))
    }
export const onBankAutocompleteChange = ({ formData, key, setFormValue }: InputChangeArg<BankAutocompleteKey, BankFormGlobal>) =>
    (label: string) => {
        if (formData[key]?.name !== label) {
            setFormValue(key, autocompleteChangeArg(label))
        }
    }
