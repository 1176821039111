import BreadcrumbItem from 'components/AbstractComponents/BreadCrumbs/BreadcrumbItem'
import { createElement } from 'react'
import { Navigate, Outlet, RouteObject } from 'react-router-dom'
import { RootStateType } from 'store/store.ts'
import { UnderConstruction } from 'components/AbstractComponents/UnderConstruction/UnderConstruction.tsx'

export const ORGANIZATIONS_ROUTES: Array<RouteObject> = [
    {
        index: true,
        element: <Navigate to={ 'organization' }/>,
    },
    {
        id: 'organizationsList',
        path: 'organization',
        element: <Outlet/>,
        loader: () => ({
            name: 'Список организаций',
            path: 'organizations/organization',
        }),
        children: [
            {
                index: true,
                async lazy() {
                    const { UserOrganizationsTable } = await import('components/Organizations/UserOrganizationsTable')
                    return { element: <UserOrganizationsTable/> }
                },
            },
            {
                id: 'userOrganizationCreate',
                path: 'new',
                async lazy() {
                    const { UserOrganizationPage } = await import('components/Organizations/UserOrganizationPage')
                    return { element: <UserOrganizationPage/> }
                },
                loader: () => ({
                    name: 'Новая организация',
                    path: 'organizations/organization/new',
                }),
                children: [
                    {
                        index: true,
                        async lazy() {
                            const { UserOrganizationPageBodyEdit } = await import('components/Organizations/PageBody/UserOrganizationPageBodyEdit')
                            return { element: <UserOrganizationPageBodyEdit/> }
                        },
                    },
                ],
            },
            {
                id: 'userOrganizationById',
                path: ':organizationId',
                async lazy() {
                    const { UserOrganizationPage } = await import('components/Organizations/UserOrganizationPage')
                    return { element: <UserOrganizationPage/> }
                },
                loader: ({ params }) => ({
                    id: params.organizationId,
                    name: createElement(BreadcrumbItem((state: RootStateType) => ({
                        name: state.userOrganizationsReducer.editableFormHeader.name || '',
                    }))),
                    path: `organizations/organization/${ params.organizationId }`,
                }),
                children: [
                    {
                        index: true,
                        id: 'userOrganizationView',
                        async lazy() {
                            const { UserOrganizationPageBodyView } = await import('components/Organizations/PageBody/UserOrganizationPageBodyView')
                            return { element: <UserOrganizationPageBodyView/> }
                        },
                    },
                    {
                        id: 'userOrganizationEdit',
                        path: 'edit',
                        async lazy() {
                            const { UserOrganizationPageBodyEdit } = await import('components/Organizations/PageBody/UserOrganizationPageBodyEdit')
                            return { element: <UserOrganizationPageBodyEdit/> }
                        },
                    },
                ],
            },
        ],
    },
    {
        id: 'organizationsUsers',
        path: 'users',
        element: <Outlet/>,
        loader: () => ({
            name: 'Пользователи',
            path: 'organizations/users',
        }),
        children: [
            {
                index: true,
                async lazy() {
                    const { UsersTable } = await import('components/Organizations/NestedPages/Users/UsersTable')
                    return { element: <UsersTable/> }
                },
            },
            {
                id: 'userOrganizationsUserCreate',
                path: 'new',
                loader: () => ({
                    name: 'Новый пользователь',
                    path: 'organizations/users/new',
                }),
                async lazy() {
                    const { UserPage } = await import('components/Organizations/NestedPages/Users/UserPage')
                    return { element: <UserPage/> }
                },
                children: [
                    {
                        index: true,
                        async lazy() {
                            const { UserPageBodyEdit } = await import('components/Organizations/NestedPages/Users/PageBody/UserPageBodyEdit')
                            return { element: <UserPageBodyEdit/> }
                        },
                    },
                ],
            },
            {
                id: 'userOrganizationsUserById',
                path: ':userId',

                async lazy() {
                    const { UserPage } = await import('components/Organizations/NestedPages/Users/UserPage')
                    return { element: <UserPage/> }
                },
                loader: ({ params }) => ({
                    id: params.userId,
                    name: createElement(BreadcrumbItem((state: RootStateType) => ({
                        name: state.usersReducer.editableFormHeader.name || '',
                    }))),
                    path: `organizations/users/${ params.userId }`,
                }),
                children: [
                    {
                        index: true,
                        element: <Navigate to={ 'about' } replace />,
                    },
                    {
                        id: 'userOrganizationsUserAbout',
                        path: 'about',
                        async lazy() {
                            const { UserPageBodyView } = await import('components/Organizations/NestedPages/Users/PageBody/UserPageBodyView')
                            return { element: <UserPageBodyView/> }
                        },
                    },
                    {
                        id: 'userOrganizationsUserViewMoney',
                        path: 'money',
                        async lazy() {
                            const { MoneyTab } = await import('components/Worker/Profile/Money/MoneyTab.tsx')
                            return { element: <MoneyTab/> }
                        },
                    },
                    {
                        id: 'userOrganizationsUserViewScheduler',
                        path: 'scheduler',
                        async lazy() {
                            const { SchedulerTab } = await import('components/Worker/Profile/Scheduler/SchedulerTab.tsx')
                            return { element: <SchedulerTab/> }
                        },
                    },
                    {
                        id: 'userOrganizationsUserEdit',
                        path: 'edit',
                        async lazy() {
                            const { UserPageBodyEdit } = await import('components/Organizations/NestedPages/Users/PageBody/UserPageBodyEdit')
                            return { element: <UserPageBodyEdit/> }
                        },
                    },
                ],
            },
        ],
    },
    {
        id: 'organizationsRoles',
        path: 'roles',
        element: <Outlet/>,
        loader: () => ({
            name: 'Роли',
            path: 'organizations/roles',
        }),
        children: [
            {
                index: true,
                async lazy() {
                    const { UserRolesTable } = await import('components/Organizations/NestedPages/UserRoles/UserRolesTable')
                    return { element: <UserRolesTable/> }
                },
            },
            {
                id: 'userOrganizationsRoleCreate',
                path: 'new',
                async lazy() {
                    const { UserRolePage } = await import('components/Organizations/NestedPages/UserRoles/UserRolePage')
                    return { element: <UserRolePage/> }
                },
                loader: () => ({
                    name: 'Новая роль',
                    path: 'organizations/roles/new',
                }),
                children: [
                    {
                        index: true,
                        async lazy() {
                            const { UserRolePageBodyEdit } = await import('components/Organizations/NestedPages/UserRoles/PageBody/UserRolePageBodyEdit')
                            return { element: <UserRolePageBodyEdit/> }
                        },
                    },
                ],
            },
            {
                id: 'userOrganizationsRoleById',
                path: ':roleId',
                async lazy() {
                    const { UserRolePage } = await import('components/Organizations/NestedPages/UserRoles/UserRolePage')
                    return { element: <UserRolePage/> }
                },
                loader: ({ params }) => ({
                    id: params.roleId,
                    name: createElement(BreadcrumbItem((state: RootStateType) => ({
                        name: state.userRolesReducer.editableFormHeader.name || '',
                    }))),
                    path: `organizations/roles/${ params.roleId }`,
                }),
                children: [
                    {
                        index: true,
                        //path: 'view',
                        id: 'userOrganizationsRoleView',
                        loader: () => ({ active: 'userInfo' }),
                        async lazy() {
                            const { UserRolePageBodyView } = await import('components/Organizations/NestedPages/UserRoles/PageBody/UserRolePageBodyView')
                            return { element: <UserRolePageBodyView/> }
                        },
                    },
                    {
                        path: 'edit',
                        id: 'userOrganizationsRoleEdit',
                        async lazy() {
                            const { UserRolePageBodyEdit } = await import('components/Organizations/NestedPages/UserRoles/PageBody/UserRolePageBodyEdit')
                            return { element: <UserRolePageBodyEdit/> }
                        },
                    },
                ],
            },
        ],
    },
    {
        id: 'organizationsGroups',
        path: 'groups',
        element: <UnderConstruction/>,
        loader: () => ({
            name: 'Группы',
            path: 'organizations/groups',
        }),
    },
    {
        id: 'organizationsDepartments',
        path: 'departments',
        element: <UnderConstruction/>,
        loader: () => ({
            name: 'Подразделения',
            path: 'organizations/departments',
        }),
    },
    {
        id: 'organizationsSpecializations',
        path: 'specializations',
        element: <Outlet/>,
        loader: () => ({
            name: 'Специализации',
            path: 'organizations/specializations',
        }),
        children: [
            {
                index: true,
                async lazy() {
                    const { SpecializationsTable } = await import('components/Organizations/NestedPages/Specializations/SpecializationsTable')
                    return { element: <SpecializationsTable/> }
                },
            },
            {
                id: 'userSpecializationCreate',
                path: 'new',
                async lazy() {
                    const { SpecializationPage } = await import('components/Organizations/NestedPages/Specializations/SpecializationPage')
                    return { element: <SpecializationPage/> }
                },
                loader: () => ({
                    name: 'Новая специализация',
                    path: 'organizations/specializations/new',
                }),
                children: [
                    {
                        index: true,
                        async lazy() {
                            const { SpecializationPageBodyEdit } = await import('components/Organizations/NestedPages/Specializations/PageBody/SpecializationPageBodyEdit')
                            return { element: <SpecializationPageBodyEdit/> }
                        },
                    },
                ],
            },
            {
                id: 'userSpecializationById',
                path: ':specializationId',
                async lazy() {
                    const { SpecializationPage } = await import('components/Organizations/NestedPages/Specializations/SpecializationPage')
                    return { element: <SpecializationPage/> }
                },
                loader: ({ params }) => ({
                    id: params.specializationId,
                    name: createElement(BreadcrumbItem((state: RootStateType) => ({
                        name: state.specializationsReducer.editableFormHeader.name || '',
                    }))),
                    path: `organizations/specializations/${ params.specializationId }`,
                }),
                children: [
                    {
                        index: true,
                        id: 'userSpecializationView',
                        async lazy() {
                            const { SpecializationPageBodyView } = await import('components/Organizations/NestedPages/Specializations/PageBody/SpecializationPageBodyView')
                            return { element: <SpecializationPageBodyView/> }
                        },
                    },
                    {
                        id: 'userSpecializationEdit',
                        path: 'edit',
                        async lazy() {
                            const { SpecializationPageBodyEdit } = await import('components/Organizations/NestedPages/Specializations/PageBody/SpecializationPageBodyEdit')
                            return { element: <SpecializationPageBodyEdit/> }
                        },
                    },
                ],
            },
        ],
    },
]
