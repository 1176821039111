import { TableProps, TablePropsAbstract } from 'components/AbstractComponents/TableReusable/TableReusable.types.ts';
import { FormBasedSliceInitialState } from 'types/redux.ts';
import {
    FindJobGroupResponseDto, FindJobResponseDto,
    JobGroupsFindApiArg,
    JobGroupsFindApiResponse, JobGroupsFindByGroupApiArg, JobGroupsFindByGroupApiResponse
} from 'store/api/jobGroups.tsx';

export type ControllerPropsGroups = TableProps<
    JobGroupsFindApiResponse,
    JobGroupsFindApiArg,
    FindJobGroupResponseDto
>

export type TablePropsGroups = TablePropsAbstract & ControllerPropsGroups


// *
// STORE
// *


export const groupFormHeaderInitialState: GroupsFormHeader = {
    name: ''
}

export const groupFormBodyInitialState: GroupsFormBody = {
    jobs: []

}

export const groupFormGlobalDefaultValues: GroupsFormGlobal = {
    name: '',
    jobs: []
}
export class GroupsSliceInitialState extends FormBasedSliceInitialState {
    editableFormHeader: GroupsFormHeader = groupFormHeaderInitialState
    editableFormBody: GroupsFormBody = groupFormBodyInitialState
}

export type GroupsFormGlobal = {
    name:  string,
    jobs: FindJobResponseDto[]
}
export type GroupsFormHeader = Pick<FindJobGroupResponseDto, 'name'>
export type GroupsFormBody = {
    jobs: FindJobResponseDto[]
}

export type  GroupsFormGlobalKey = keyof GroupsFormGlobal

/*Jobs table*/

export type ControllerPropsJobsGroups = TableProps<
    JobGroupsFindByGroupApiResponse,
    JobGroupsFindByGroupApiArg,
    FindJobResponseDto
>

export type TablePropsJobsGroups = TablePropsAbstract & ControllerPropsJobsGroups



