import React from 'react'
import styles from './WorkSummary.module.css'
import { Box, Button, Divider, Flex, Grid, Title } from '@mantine/core'
import { IconPhone } from '@tabler/icons-react'
import { formatPhoneNumber } from 'utils/phone'
import dayjs from 'utils/dayjs'
import { TaskStatus } from 'components/Worker/Calendar/Nested/TaskStatus'
import { Link, useNavigate } from 'react-router-dom'
import { dateLabel } from 'components/Worker/Utils/date.label'
import { WorkSummaryProps } from 'components/Worker/Drawers/Types/WorkSummaryProps'

export const WorkSummary: React.FC<WorkSummaryProps> = ({ work, date, variant }) => {
    
    const navigate = useNavigate()

    // TODO: change to more flexible code
    let contacts: {
        id: string,
        name: string,
        phone: string | null,
    }[] | undefined = work?.project?.responsibles?.filter(responsible => responsible.type === 'FOREMAN').map(responsible => ({
        id: 'Прораб',
        name: responsible.user.name,
        phone: responsible.user.phone,
    }))

    if (work?.project?.emergencyPhoneNumber) {
        if (!contacts) {
            contacts = []
        }

        contacts.push({
            id: 'Аварийная служба',
            name: '',
            phone: work?.project?.emergencyPhoneNumber,
        })
    }
    // END TODO
    
    const openTask = (taskId: string) => {
        if (variant === 'overdue') {
            navigate(`/overdue/work/${work?.id}/task/` + taskId)
        } else {
            navigate(`/work/${work?.id}/task/` + taskId)
        }
    }
    
    return <>
        <Box className={ styles.workSummaryDate }>
            { variant === 'overdue' ? dayjs(work?.from, 'YYYY-MM-DD').format('dddd, D MMMM') : dayjs(date, 'YYYY-MM-DD').format('dddd, D MMMM') } { dateLabel(date) ? `(${ dateLabel(date) })` : '' }
        </Box>

        <Title
            order={ 3 }
            className={ styles.workSummaryName }>
            { work?.name }
        </Title>

        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 8,
            marginTop: '24px',
        }}>
            <Title order={5} fw={400}>
                { variant === 'overdue' ? 
                    work?.address : 
                    `${ work?.project?.object?.address }, кв. ${ work?.project?.object?.apartment }` }
            </Title>
            
            <Title order={5} fw={400}>{ work?.project?.name }</Title>
        </Box>

        <Box className={ styles.contactList }>
            { contacts?.map((contact) =>
                [
                    <Divider key="divider" color={ '#DDE3ED' } my="sm"/>,
                    <Grid
                        grow
                        key={ contact.id }
                        className={ styles.contactItem }
                    >
                        
                        <Grid.Col span={ 7 }>
                            <Box className={ styles.contactId }>{ contact.id }</Box>
                            <Box className={styles.contactData}>{ contact.name }</Box>
                            <Box className={styles.contactData}>{ formatPhoneNumber(contact.phone) }</Box>
                        </Grid.Col>
                        
                        <Grid.Col span={ 5 }>
                            <Flex h={'100%'} align="flex-end" justify="flex-end">
                                <Button
                                    className={ styles.callButton }
                                    leftIcon={ <IconPhone size={ '20px' }/> }
                                    size={'md'}
                                >
                                    Позвонить
                                </Button>
                            </Flex>
                        </Grid.Col>
                    </Grid>,
                ],
            ) }
        </Box>
        
        <Box className={ styles.taskList }>
            { work?.tasks?.map((task) =>
                <Box key={ task.id } className={ styles.listTask } onClick={ () => openTask(task.id) }>
                    <Grid grow key={ task.id } className={ styles.taskItem }>
                        <Grid.Col span={ 12 }>
                            <Flex
                                align="center"
                                justify="space-between"
                                gap={ 8 }
                            >
                                <Box />
                                <TaskStatus status={ task.status }/>
                            </Flex>
                        </Grid.Col>

                        <Grid.Col span={ 12 }>
                            <Link className={ styles.taskName } to={ `work/${work?.id}/task/${ task.id }` }>
                                { task.name }
                            </Link>
                        </Grid.Col>

                        <Grid.Col pt={ 0 } span={ 12 }>
                            <Box p={ 0 } className={ styles.roomName }>{ work?.room?.name }</Box>
                        </Grid.Col>
                    </Grid>
                </Box>)
            }
        </Box>
    </>
}