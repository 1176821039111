import BreadcrumbItem from 'components/AbstractComponents/BreadCrumbs/BreadcrumbItem'
import { RootStateType } from 'store/store.ts'
import { createElement } from 'react'
import { Navigate, Outlet, RouteObject } from 'react-router-dom'

export const CLIENTS_ROUTES: Array<RouteObject> = [
    {
        index: true,
        element: <Navigate to={'client'} />,
    },
    {
        id: 'clientList',
        path: 'client',
        element: <Outlet />,
        loader: () => ({
            name: 'Контрагенты',
            path: 'clients/client',
        }),
        children: [
            {
                index: true,
                async lazy() {
                    const { ClientsTable } = await import('components/Clients/ClientsTable')
                    return { element: <ClientsTable /> }
                },
            },
            {
                id: 'clientCreate',
                path: 'new',
                async lazy() {
                    const { ClientPage } = await import('components/Clients/ClientPage')
                    return { element: <ClientPage /> }
                },
                loader: () => ({
                    name: 'Новый контрагент',
                    path: 'clients/client/new',
                }),
                children: [
                    {
                        index: true,
                        async lazy() {
                            const { ClientPageBodyEdit } = await import('components/Clients/PageBody/ClientPageBodyEdit')
                            return { element: <ClientPageBodyEdit /> }
                        },
                    },
                ],
            },
            {
                id: 'clientById',
                path: ':clientId',
                async lazy() {
                    const { ClientPage } = await import('components/Clients/ClientPage')
                    return { element: <ClientPage /> }
                },
                loader: ({ params }) => ({
                    id: params.clientId,
                    name: createElement(BreadcrumbItem((state: RootStateType) => ({
                        name: state.clientsReducer.editableFormBody.fullName || '',
                    }))),
                    path: `clients/client/${params.clientId}`,
                }),
                children: [
                    {
                        index: true,
                        async lazy() {
                            const { ClientPageBodyView } = await import('components/Clients/PageBody/ClientPageBodyView')
                            return { element: <ClientPageBodyView /> }
                        },
                    },
                    {
                        path: 'edit',
                        id: 'clientEdit',
                        async lazy() {
                            const { ClientPageBodyEdit } = await import('components/Clients/PageBody/ClientPageBodyEdit')
                            return { element: <ClientPageBodyEdit /> }
                        },
                    },
                ],
            },
        ],
    },
    {
        id: 'clientsObjects',
        path: 'objects',
        element:
            <Outlet />,
        loader:
            () => ({
                name: 'Объекты',
                path: 'clients/objects',
            }),
        children:
            [
                {
                    index: true,
                    async lazy() {
                        const { ObjectsTable } = await import('components/Clients/NestedPages/Objects/ObjectsTable')
                        return { element: <ObjectsTable /> }
                    },
                },
                {
                    id: 'objectCreate',
                    path: 'new',
                    async lazy() {
                        const { ObjectPage } = await import('components/Clients/NestedPages/Objects/ObjectPage')
                        return { element: <ObjectPage /> }
                    },
                    loader: () => ({
                        name: 'Новый объект',
                        path: 'clients/objects/new',
                    }),
                    children: [
                        {
                            index: true,
                            async lazy() {
                                const { ObjectPageBodyEdit } = await import('components/Clients/NestedPages/Objects/PageBody/ObjectPageBodyEdit')
                                return { element: <ObjectPageBodyEdit /> }
                            },
                        },
                    ],
                },
                {
                    id: 'clientsObjectById',
                    path: ':objectId',
                    async lazy() {
                        const { ObjectPage } = await import('components/Clients/NestedPages/Objects/ObjectPage')
                        return { element: <ObjectPage /> }
                    },
                    loader: ({ params }) => ({
                        id: params.objectId,
                        name: createElement(BreadcrumbItem((state: RootStateType) => ({
                            name: state.objectsReducer.editableFormHeader.name || '',
                        }))),
                        path: `clients/objects/${params.objectId}`,
                    }),
                    children: [
                        {
                            index: true,
                            id: 'clientsObjectView',
                            async lazy() {
                                const { ObjectPageBodyView } = await import('components/Clients/NestedPages/Objects/PageBody/ObjectPageBodyView')
                                return { element: <ObjectPageBodyView /> }
                            },
                        },
                        {
                            path: 'edit',
                            id: 'clientsObjectEdit',
                            async lazy() {
                                const { ObjectPageBodyEdit } = await import('components/Clients/NestedPages/Objects/PageBody/ObjectPageBodyEdit')
                                return { element: <ObjectPageBodyEdit /> }
                            },
                        },
                    ],
                },
            ],
    },
    {
        id: 'clientsRooms',
        path:
            'rooms',
        element:
            <Outlet />,
        loader:
            () => ({
                name: 'Помещения',
                path: 'clients/rooms',
            }),
        children:
            [
                {
                    index: true,
                    async lazy() {
                        const { RoomsTable } = await import('components/Clients/NestedPages/Rooms/RoomsTable')
                        return { element: <RoomsTable /> }
                    },
                },
                {
                    id: 'roomCreate',
                    path: 'new',
                    async lazy() {
                        const { RoomPage } = await import('components/Clients/NestedPages/Rooms/RoomPage')
                        return { element: <RoomPage /> }
                    },
                    loader: () => ({
                        name: 'Новое помещение',
                        path: 'clients/rooms/new',
                    }),
                    children: [
                        {
                            index: true,
                            async lazy() {
                                const { RoomPageBodyEdit } = await import('components/Clients/NestedPages/Rooms/PageBody/RoomPageBodyEdit')
                                return { element: <RoomPageBodyEdit /> }
                            },
                        },
                    ],
                },
                {
                    id: 'clientsRoomById',
                    path: ':roomId',
                    async lazy() {
                        const { RoomPage } = await import('components/Clients/NestedPages/Rooms/RoomPage')
                        return { element: <RoomPage /> }
                    },
                    loader: ({ params }) => ({
                        id: params.roomId,
                        name: createElement(BreadcrumbItem((state: RootStateType) => ({
                            name: state.roomsReducer.editableFormHeader.name || '',
                        }))),
                        path: `clients/rooms/${params.roomId}`,
                    }),
                    children: [
                        {
                            index: true,
                            id: 'roomView',
                            async lazy() {
                                const { RoomPageBodyView } = await import('components/Clients/NestedPages/Rooms/PageBody/RoomPageBodyView')
                                return { element: <RoomPageBodyView /> }
                            },
                        },
                        {
                            path: 'edit',
                            id: 'roomEdit',
                            async lazy() {
                                const { RoomPageBodyEdit } = await import('components/Clients/NestedPages/Rooms/PageBody/RoomPageBodyEdit')
                                return { element: <RoomPageBodyEdit /> }
                            },
                        },
                    ],
                },
            ],
    },
]
