import React, { useEffect, useRef, useState } from 'react'
import styles from 'components/Projects/PageBody/Info/WorkingHoursTimetable/WorkingHoursTimetable.module.css'
import {
    getRoundedTime,
    getInitialTimePoint,
    MIN_OUTER_DIAPASON_IN_MINUTES,
} from './WorkingHoursTimetable.utils'
import { TimeInterval } from './TimeInterval'
import { WorkingHoursTimetableType } from './WorkingHoursTimetable.types'
import { Box, Flex } from '@mantine/core'

export const WorkingHoursTimetable: React.FC<WorkingHoursTimetableType> = ({
    initialData,
    updateParentForm,
}) => {
    
    const [timeTable, setTimetable] = useState<number[][]>(initialData)
    const intervalsBarRef = useRef<HTMLDivElement>(null)
    // const [isParentMouseLeft, setIsParentMouseLeft] = useState(true)
    
    useEffect(() => {
        setTimetable(() =>
            timeTable?.length
                ? timeTable
                : [
                    [getInitialTimePoint(9), getInitialTimePoint(13)],
                    [getInitialTimePoint(15), getInitialTimePoint(19)],
                ]
        )
    }, [])

    useEffect(() => {
        updateParentForm(timeTable)
    }, [timeTable[0]?.[0], timeTable[0]?.[1], timeTable[1]?.[0], timeTable[1]?.[1]])

    const onStop = (index: 0 | 1) => () => {
        if (index === 0) {
            setTimetable((prev) => {
                const updatedInterval: [number, number] = [
                    getRoundedTime(timeTable[0][0]),
                    getRoundedTime(timeTable[0][1]),
                ]
                return [updatedInterval, prev[1]]
            })
        } else {
            setTimetable((prev) => {
                const updatedInterval: [number, number] = [
                    getRoundedTime(timeTable[1][0]),
                    getRoundedTime(timeTable[1][1]),
                ]
                return [prev[0], updatedInterval]
            })
        }
    }

    const onChange = (index: 0 | 1) => (interval: [number, number]) => {

        if (index === 0) {
            const differenceBetweenOuterPoints = timeTable[1][0] - interval[1]
            if (differenceBetweenOuterPoints < MIN_OUTER_DIAPASON_IN_MINUTES * 60) return

            setTimetable((prev) => {
                const updatedInterval: [number, number] = [
                    interval[0] + 1 ? interval[0] : prev[0][0],
                    interval[1] + 1 ? interval[1] : prev[0][1],
                ]
                return [updatedInterval, prev[1]]
            })
        }

        if (index === 1) {
            const differenceBetweenOuterPoints = interval[0] - timeTable[0][1]
            if (differenceBetweenOuterPoints < MIN_OUTER_DIAPASON_IN_MINUTES * 60) return

            setTimetable((prev) => {
                const updatedInterval: [number, number] = [
                    interval[0] + 1 ? interval[0] : prev[1][0],
                    interval[1] + 1 ? interval[1] : prev[1][1],
                ]
                return [prev[0], updatedInterval]
            })
        }
    }

    return (
        <>
            {!timeTable?.length && <>Загрузка...</>}
            {timeTable?.length &&
                <Box
                    // onMouseLeave={() => setIsParentMouseLeft(() => true)}
                    // onMouseEnter={() => setIsParentMouseLeft(() => false)}
                    sx={{ userSelect: 'none', width: '100%', flexGrow: 1, flexBasis: 1 }}
                >
                    <Box className={styles.timetableContainer}>
                        <Flex align={'center'} justify={'center'} sx={{ userSelect: 'none' }}>00:00</Flex>
                        <Box className={styles.intervalsBox}>
                            <Box ref={intervalsBarRef} className={styles.intervalsBar}>
                                <TimeInterval
                                    id="first"
                                    index={0}
                                    onStop={onStop(0)}
                                    onChange={onChange(0)}
                                    timeTable={timeTable}
                                    parentWidth={
                                        intervalsBarRef.current &&
                                        intervalsBarRef.current.clientWidth
                                    }
                                    // isParentMouseLeft={isParentMouseLeft}
                                />

                                <TimeInterval
                                    id="second"
                                    index={1}
                                    onStop={onStop(1)}
                                    onChange={onChange(1)}
                                    timeTable={timeTable}
                                    parentWidth={
                                        intervalsBarRef.current &&
                                        intervalsBarRef.current.clientWidth
                                    }
                                    // isParentMouseLeft={isParentMouseLeft}
                                />
                            </Box>
                        </Box>
                        <Flex align={'center'} justify={'center'} styles={{ userSelect: 'none' }}>24:00</Flex>
                    </Box>
                </Box>
            }
        </>
    )
}
