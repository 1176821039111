import dayjs from 'dayjs'
import 'dayjs/locale/ru'

import customParseFormat from 'dayjs/plugin/customParseFormat'
import duration from 'dayjs/plugin/duration'
import isBetween from 'dayjs/plugin/isBetween'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import minMax from 'dayjs/plugin/minMax'

dayjs.locale('ru')

dayjs.extend(duration)
dayjs.extend(isBetween)
dayjs.extend(relativeTime)
dayjs.extend(customParseFormat)

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(minMax)

export default dayjs
