import { UsersSliceInitialState } from 'components/Organizations/NestedPages/Users/Users.types'
import {
    userFormBodyInitialState,
    userFormHeaderInitialState,
} from 'components/Organizations/NestedPages/Users/Users.utils'
import { createAppSlice } from 'utils/createAppSlice'
import { defaultInitialState } from 'utils/defaultInitialState.ts'

const usersSliceInitialState: UsersSliceInitialState = {
    ...defaultInitialState,
    editableFormHeader: userFormHeaderInitialState,
    editableFormBody: userFormBodyInitialState,
}

export const usersSlice = createAppSlice<UsersSliceInitialState>('users', usersSliceInitialState)


export default usersSlice.reducer
