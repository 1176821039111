import { createAppSlice } from 'utils/createAppSlice'
import { defaultInitialState } from 'utils/defaultInitialState.ts'
import { replaceMaterialBodyInitialState, replaceMaterialHeaderInitialState } from 'components/Supply/SupplyRequests/PageBody/List/Drawers/ReplaceMaterialDrawer/ReplaceMaterialDrawer.utils.ts';
import {
    ReplaceMaterialState
} from 'components/Supply/SupplyRequests/PageBody/List/Drawers/ReplaceMaterialDrawer/ReplaceMaterialDrawer.types.ts';

const replaceSupplyRequestMaterialInitialState: ReplaceMaterialState = {
    ...defaultInitialState,
    editableFormHeader: replaceMaterialHeaderInitialState,
    editableFormBody: replaceMaterialBodyInitialState,
}

export const replaceSupplyRequestMaterialSlice = createAppSlice<ReplaceMaterialState>('replaceSupplyRequestMaterial',  replaceSupplyRequestMaterialInitialState)

export default replaceSupplyRequestMaterialSlice.reducer
