import { FindBrandResponseDto, UpdateBrandRequestDto } from 'store/api/brands'
import { BrandFormBody, BrandFormHeader, BrandsFormGlobal } from './Brands.types'


// *
// DATA CONVERT FUNCS
// *

export const convertBrandDtoToFormBody = (brand: FindBrandResponseDto): BrandFormBody => ({
    url: brand?.url || brandFormBodyInitialState.url,
})
export const convertBrandDtoToFormHeader = (brand: FindBrandResponseDto): BrandFormHeader => ({
    name: brand?.name || brandFormHeaderInitialState.name,
})
export const convertBrandFormToRequestDto = (
    data: Partial<BrandFormBody & BrandFormHeader>
): UpdateBrandRequestDto => ({
    name: data?.name || brandFormHeaderInitialState.name,
    url: data?.url || brandFormBodyInitialState.url,
})


// *
// STORE
// *

export const brandsFormGlobalDefaultValues: BrandsFormGlobal = {
    name: '',
    url: '',
    imageUrl: '',
}
export const brandFormHeaderInitialState: BrandFormHeader = {
    name: '',
}
export const brandFormBodyInitialState: BrandFormBody = {
    url: '',
    imageUrl: '',
}


// *
// INPUT PROCESSING FUNCS
// *
