import { jobFormHeaderInitialState, jobFormBodyInitialState } from 'components/Resources/Jobs/Jobs.utils'
import { createAppSlice } from 'utils/createAppSlice'
import { defaultInitialState } from 'utils/defaultInitialState.ts'
import { ProjectGroupsSliceInitialState } from 'components/Projects/PageBody/Rooms/GroupsParts/ProjectGroups.types.ts';

const projectGroupsSliceInitialState: ProjectGroupsSliceInitialState = {
    ...defaultInitialState,
    editableFormHeader: jobFormHeaderInitialState,
    editableFormBody: jobFormBodyInitialState,
}

export const projectBodyAddGroupsSlice = createAppSlice<ProjectGroupsSliceInitialState>('projectBodyAddGroups', projectGroupsSliceInitialState)

export default projectBodyAddGroupsSlice.reducer
