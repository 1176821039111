import { TableProps, TablePropsAbstract } from 'components/AbstractComponents/TableReusable/TableReusable.types'
import { FormBasedSliceInitialState } from 'types/redux'
import {
    FindSystemFormulaResponseDto,
    SystemFormulasFindApiArg,
    SystemFormulasFindApiResponse
} from 'store/api/systemFormulas.tsx';
import { autocompleteFieldDefault } from 'utils/inputs.tsx';
import { AutocompleteField } from 'types/inputs.ts';
import { FormulaItem } from 'components/Resources/Jobs/PageBody/Formulas/Formulas.types.ts';

export const  formulasFormDefaultValues = {}
// *
// REUSABLE TABLE
// *

export type ControllerPropsFormulas = TableProps<
    SystemFormulasFindApiResponse,
    SystemFormulasFindApiArg,
    FindSystemFormulaResponseDto
>
export type TablePropsFormulas = TablePropsAbstract & ControllerPropsFormulas

export type FormulasFormHeader = Pick<FindSystemFormulaResponseDto, 'name'>
export type FormulaFormHeaderKey = keyof FormulasFormHeader
export type FormulasFormBody = Omit<FindSystemFormulaResponseDto, FormulaFormHeaderKey>

export const formulasFormHeaderInitialState: FormulasFormHeader = {
    name   : ''
}

type FormulaAutocompleteFields = {
    units: AutocompleteField
}

//
// type FormulaValueField = {
//     value: (FormulaCalculateRequestDto | number)[] | null;
// }

type JobFormulaAdditionalFields = {
    formulaItems?: FormulaItem[]
}

type FormulaGlobalImmutableFields = Pick<FindSystemFormulaResponseDto, 'name' >

export type JobFormulaAutocompleteKey = keyof FormulaAutocompleteFields


export type SystemFormulaFormGlobal = FormulaGlobalImmutableFields & FormulaAutocompleteFields & JobFormulaAdditionalFields
export type SystemFormulaFormHeader = Pick<SystemFormulaFormGlobal, 'name'>
export type SystemFormulaFormBody = SystemFormulaFormGlobal
export type SystemFormulasFormKeys = keyof SystemFormulaFormBody

export const formulasFormBodyInitialState: SystemFormulaFormGlobal = {
    name: '',
    units: autocompleteFieldDefault,
}

export const systemFormulasFormDefaultValues: SystemFormulaFormBody = {
    name: '',
    units: autocompleteFieldDefault,
    formulaItems: []
}


// *
// STORE
// *

export class FormulasSliceInitialState extends FormBasedSliceInitialState {
    editableFormHeader: SystemFormulaFormHeader = formulasFormHeaderInitialState
    editableFormBody: SystemFormulaFormBody = formulasFormBodyInitialState
}

