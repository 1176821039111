import { emptyApi as api } from './emptyApi';
export const addTagTypes = ['Object'] as const;
const injectedRtkApi = api
    .enhanceEndpoints({
        addTagTypes,
    })
    .injectEndpoints({
        endpoints: (build) => ({
            objectsFind: build.query<ObjectsFindApiResponse, ObjectsFindApiArg>({
                query: (queryArg) => ({
                    url: '/api/v1/orders/objects',
                    params: {
                        limit: queryArg.limit,
                        offset: queryArg.offset,
                        filter: queryArg.filter,
                        sort: queryArg.sort,
                    },
                }),
                providesTags: ['Object'],
            }),
            objectCreate: build.mutation<ObjectCreateApiResponse, ObjectCreateApiArg>(
                {
                    query: (queryArg) => ({
                        url: '/api/v1/orders/objects',
                        method: 'POST',
                        body: queryArg,
                    }),
                    invalidatesTags: ['Object'],
                }
            ),
            objectsFindOne: build.query<
                ObjectsFindOneApiResponse,
                ObjectsFindOneApiArg
            >({
                query: (queryArg) => ({ url: `/api/v1/orders/objects/${queryArg}` }),
                providesTags: ['Object'],
            }),
            objectUpdate: build.mutation<ObjectUpdateApiResponse, ObjectUpdateApiArg>(
                {
                    query: (queryArg) => ({
                        url: `/api/v1/orders/objects/${queryArg.id}`,
                        method: 'PUT',
                        body: queryArg.updateObjectRequestDto,
                    }),
                    invalidatesTags: ['Object'],
                }
            ),
            objectRemove: build.mutation<ObjectRemoveApiResponse, ObjectRemoveApiArg>(
                {
                    query: (queryArg) => ({
                        url: `/api/v1/orders/objects/${queryArg}`,
                        method: 'DELETE',
                    }),
                    invalidatesTags: ['Object'],
                }
            ),
        }),
        overrideExisting: false,
    });
export { injectedRtkApi as objectApi };
export type ObjectsFindApiResponse = /** status 200 ОК */ {
    metadata: {
        id: string;
        permissions: string[];
    }[];
    data: FindObjectResponseDto[];
    pagination: {
        offset: number;
        limit: number;
        total: number;
    };
};
export type ObjectsFindApiArg = {
    /** Кол-во возвращаемых элементов */
    limit?: number;
    /** Сдвиг значений */
    offset?: number;
    /** Фильтры */
    filter?: string;
    /** Сортировка */
    sort?: string;
};
export type ObjectCreateApiResponse = /** status 201  */ FindObjectResponseDto;
export type ObjectCreateApiArg = CreateObjectRequestDto;
export type ObjectsFindOneApiResponse = /** status 200  */ {
    metadata: {
        id: string;
        permissions: string[];
    }[];
    data: FindObjectResponseDto;
};
export type ObjectsFindOneApiArg = string;
export type ObjectUpdateApiResponse = /** status 200  */ FindObjectResponseDto;
export type ObjectUpdateApiArg = {
    id: string;
    updateObjectRequestDto: UpdateObjectRequestDto;
};
export type ObjectRemoveApiResponse = /** status 200  */ void;
export type ObjectRemoveApiArg = string;
export type FindClientKindResponseDto = {
    id: string;
    name: string;
};
export type FindClientTaxationSystemResponseDto = {
    id: string;
    name: string;
    description: string;
};
export type FindDepartmentResponseDto = {
    id: string;
    name: string;
};
export type FindSpecializationResponseDto = {
    id: string;
    name: string;
};
export type FindBucketResponseDto = {
    id: string;
    /** Bucket name */
    name: string;
    /** Bucket path */
    path: string;
};
export type FindFileResponseDto = {
    id: string;
    /** File name */
    name: string;
    /** File mime type */
    type: string;
    /** File size in bytes */
    size: number;
    /** File bucket */
    bucket: FindBucketResponseDto;
};
export type FindOrganizationResponseDto = {
    id: string;
    name: string;
};
export type FindRolePermissionDto = {
    id: string;
    name: string;
    description: string;
    allow: boolean;
};
export type FindRolePermissionGroupDto = {
    name: string;
    permissions: FindRolePermissionDto[];
};
export type FindRoleResponseDto = {
    id: string;
    name: string;
    enabled: boolean;
    visible: boolean;
    type: 'MANAGER' | 'WORKER';
    organization: FindOrganizationResponseDto;
    permissions: FindRolePermissionGroupDto[];
};
export type FindUserResponseDto = {
    id: string;
    createdAt: string;
    updatedAt: string;
    name: string;
    email: string;
    phone: string | null;
    job: string | null;
    chatId: number | null;
    deleted: boolean;
    type?: ('STAFF' | 'INDIVIDUAL' | 'CASH') | null;
    department: FindDepartmentResponseDto;
    specializations: FindSpecializationResponseDto[];
    avatar: FindFileResponseDto;
    roles: FindRoleResponseDto[];
    organizations: FindOrganizationResponseDto[];
    createdUser: FindUserResponseDto;
    updatedUser: FindUserResponseDto;
    workingDays: (number[][] | (string | null))[];
};
export type FindClientContactResponseDto = {
    id: string;
    firstName: string;
    lastName: string;
    middleName: string | null;
    position: string;
    phone: string | null;
    email: string | null;
    website: string | null;
    company: string | null;
    note: string | null;
};
export type FindClientBankResponseDto = {
    id: string;
    name: string;
    abbreviatedName: string;
    bik: string;
    ks?: string | null;
    index?: string | null;
    city?: string | null;
    address?: string | null;
    phone?: string | null;
    okato?: string | null;
    okpo?: string | null;
    regNum?: string | null;
};
export type FindAccountItemResponseDto = {
    id: string;
    number: string;
    bank: FindClientBankResponseDto;
};
export type FindClientTypeResponseDto = {
    id: string;
    name: string;
};
export type FindClientResponseDto = {
    id: string;
    createdAt: string;
    updatedAt: string;
    firstName: string;
    lastName: string;
    name: string;
    middleName: string | null;
    registrationAddress: string | null;
    birthDate: string | null;
    passportNumber: number | null;
    passportIssuer: string | null;
    passportIssueDate: string | null;
    passportIssuingUnitCode: string | null;
    fullName: string | null;
    abbreviatedName: string | null;
    legalAddress: string | null;
    inn: string;
    kpp: string | null;
    ogrn: string | null;
    okved: string | null;
    okpo: string | null;
    okato: string | null;
    deleted: boolean;
    kind: FindClientKindResponseDto;
    taxationSystem: FindClientTaxationSystemResponseDto;
    createdUser: FindUserResponseDto;
    updatedUser: FindUserResponseDto;
    contact: FindClientContactResponseDto;
    account: FindAccountItemResponseDto;
    types: FindClientTypeResponseDto[];
    organization: FindOrganizationResponseDto;
    contacts: FindClientContactResponseDto[];
    accounts: FindAccountItemResponseDto[];
};
export type FindObjectResponseDto = {
    id: string;
    name: string;
    deleted: boolean;
    address: string;
    fullAddress: string;
    city: string;
    street: string;
    corpus: string;
    building: string;
    house: string;
    entrance: number;
    floor: number;
    apartment: number;
    intercomCode: string;
    comment: string;
    longitude: number;
    latitude: number;
    client: FindClientResponseDto;
};
export type ApiError = {
    status: number;
    message: string[];
    error: string;
};
export type CreateObjectRequestDto = {
    name: string;
    address: string;
    city: string;
    street: string;
    corpus?: string | null;
    building?: string | null;
    house: string;
    entrance?: number | null;
    floor?: number | null;
    apartment?: number | null;
    intercomCode?: string | null;
    comment?: string | null;
    longitude?: number | null;
    latitude?: number | null;
    clientId: string;
};
export type UpdateObjectRequestDto = {
    name: string;
    address: string;
    city: string;
    street: string;
    corpus?: string | null;
    building?: string | null;
    house: string;
    entrance?: number | null;
    floor?: number | null;
    apartment?: number | null;
    intercomCode?: string | null;
    comment?: string | null;
    longitude?: number | null;
    latitude?: number | null;
    clientId: string;
    deleted?: boolean;
};
export const {
    useObjectsFindQuery,
    useLazyObjectsFindQuery,
    useObjectCreateMutation,
    useObjectsFindOneQuery,
    useLazyObjectsFindOneQuery,
    useObjectUpdateMutation,
    useObjectRemoveMutation,
} = injectedRtkApi;
