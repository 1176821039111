import { createAppSlice } from 'utils/createAppSlice'
import { defaultInitialState } from 'utils/defaultInitialState.ts'
import {
    MinStroyMaterialsSliceInitialState
} from 'components/Resources/Materials/NestedPages/MinStroyMaterials/MinStroyMaterials.types'


const minStroyMaterialsInitialState: MinStroyMaterialsSliceInitialState = { ...defaultInitialState }

export const minStroyMaterialsSlice = createAppSlice<MinStroyMaterialsSliceInitialState>('minStroyMaterials', minStroyMaterialsInitialState)

export default minStroyMaterialsSlice.reducer