import { createSlice, Draft } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { FindProjectTaskFavoriteFileResponseDto } from 'store/api/projectTaskFavoriteFiles.tsx';


export type FavoriteFileInfo = {
    fileId: string
    projectId: string
    taskId: string
}


type FavoriteFilesInitialState = {
    files: FindProjectTaskFavoriteFileResponseDto[] | null
    favoriteFileInfo: FavoriteFileInfo | null
}

const initialState: FavoriteFilesInitialState = {
    files: null,
    favoriteFileInfo: null
}

export const favoriteFilesSlice = createSlice({
    name: 'favoriteFiles',
    initialState,
    reducers: {
        setFavoriteFilesToStore: (state: Draft<FavoriteFilesInitialState>, action: PayloadAction<FindProjectTaskFavoriteFileResponseDto[]>) => {
            state.files = action.payload
        } ,
        setFavoriteFileInfoToStore:  (state: Draft<FavoriteFilesInitialState>, action: PayloadAction<FavoriteFileInfo>) => {
            state.favoriteFileInfo = action.payload
        }
    }
})

export default favoriteFilesSlice.reducer
