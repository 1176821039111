import { AutocompleteSubmitData, InputChangeArg, InputSubmitArg } from 'types/inputs'
import { StepFormBody, StepFormHeader, StepsAutocompleteKey, StepsFormGlobal } from './Steps.types'
import { FindTaskStepResponseDto, UpdateTaskStepRequestDto } from 'store/api/taskSteps'
import { autocompleteChangeArg, autocompleteFieldDefault, autocompleteSubmitArg } from 'utils/inputs'
import { EditTaskStepDtoCustom } from '../Tasks/Tasks.types'


// *
// DATA CONVERT FUNCS
// *

export const convertStepDtoToFormBody = (step: FindTaskStepResponseDto): StepFormBody => {
    console.log('step: ', step)
    return {
        task: stepFormBodyInitialState.task, // no needed data into response now
    }
}

export const convertTaskStepDtoToFormBody = (step: EditTaskStepDtoCustom): EditTaskStepDtoCustom => ({
    id: step.id,
    name: step.name,
    snippets: step.snippets,
    taskId: step.taskId,
    toValidate: step.toValidate,
    sort: step.sort,
})

export const convertStepDtoToFormHeader = (step: FindTaskStepResponseDto): StepFormHeader => ({
    name: step?.name || stepFormHeaderInitialState.name,
})

export const convertStepFormToRequestDto = (data: Partial<StepFormBody & StepFormHeader>): UpdateTaskStepRequestDto => ({
    name: data?.name || stepFormHeaderInitialState.name,
    taskId: data?.task?.data?.id || data?.task?.name || stepFormBodyInitialState.task.data.id,
    toValidate: data?.toValidate || stepFormHeaderInitialState.toValidate,
    sort: data.sort || stepsFormGlobalDefaultValues.sort,
})


// *
// STORE
// *

export const stepsFormGlobalDefaultValues: StepsFormGlobal = {
    name: '',
    task: autocompleteFieldDefault,
    toValidate: false,
    sort: 0,
}
export const stepFormHeaderInitialState: StepFormHeader = {
    name: '',
    toValidate: false,
}
export const stepFormBodyInitialState: StepFormBody = {
    task: autocompleteFieldDefault,
}


// *
// INPUT PROCESSING FUNCS
// *

export const onStepBodyAutocompleteChange = ({ formData, key, setFormValue }: InputChangeArg<StepsAutocompleteKey, StepFormBody>) => (label: string) => {
    if (formData[key]?.name!==label) {
        setFormValue(key, autocompleteChangeArg(label))
    }
}
export const onStepBodyAutocompleteSubmit = ({ key, setFormValue }: InputSubmitArg<StepsAutocompleteKey, StepFormBody>) => (data: AutocompleteSubmitData) => {
    setFormValue(key, autocompleteSubmitArg(data))
}
