import { Accordion, Anchor, Flex, Grid, RingProgress, Text, Title } from '@mantine/core';
import styles from './MobileAccordion.module.css'
import { IconClock, IconPhone } from '@tabler/icons-react';
import { formatPhoneNumber } from 'utils/phone.ts';
import { useAppSelector } from 'hooks/redux.ts';
import { getWorkFromToHours } from 'components/Worker/Utils/Worker.utils.ts';
import { FindProjectTaskResponseDto } from 'store/api/projectTasks.tsx';
import { WorkTaskTreeProps } from 'components/Worker/Work/Work.types.ts';

export const MobileTopAccordion = ({ tasksTree }: WorkTaskTreeProps) => {

    const selectedWork = useAppSelector((state) => state.activeWorkReducer.storeActiveWork)


    const tasks=  tasksTree.length > 0
        ?
        tasksTree.map((work) => {
            const job: FindProjectTaskResponseDto[] = Object.values(work).flat()
            return job[0]
        }) : []


    const getCompletedTasks = (): number => {
        const tasks = tasksTree.map((work) => {
            const job: FindProjectTaskResponseDto[] = Object.values(work).flat()
            return job[0]
        })

        const completedTasks = tasks.filter((task) => task.status.type === 'DONE')

        return completedTasks.length
    }

    const completedTasks = tasks.length > 0 ? getCompletedTasks() : 0

    const progress =  completedTasks != 0 ? Math.floor(completedTasks * 100 / tasks.length) : 0

    if (!selectedWork ) return null

    let contacts: {
        id: string,
        name: string,
        phone: string | null,
    }[] | undefined = selectedWork.project?.responsibles?.filter(responsible => responsible.type === 'FOREMAN').map(responsible => ({
        id: 'Прораб',
        name: responsible.user.name,
        phone: responsible.user.phone,
    }))

    if (selectedWork.project?.emergencyPhoneNumber) {
        if (!contacts) {
            contacts = []
        }

        contacts.push({
            id: 'Аварийная служба',
            name: '',
            phone: selectedWork.project?.emergencyPhoneNumber,
        })
    }

    const workDate =  JSON.parse(String(localStorage.getItem('workDate')))

    let from, to

    if (selectedWork.parts) {
        const { from: workFrom, to: workTo } = getWorkFromToHours(workDate, selectedWork.parts)
        from = workFrom
        to = workTo
    }


    return (
        <Accordion classNames={styles} bg={'#22252A'} >
            <Accordion.Item key={1} value={'123'} >
                <Accordion.Control />
                <Flex direction={'column'} gap={12}>
                    <Title order={3} c={'white'}>{selectedWork.project.name}</Title>
                    
                    <Grid justify="space-between" align="center" mb={6}>
                        <Grid.Col span="auto">
                            <Flex
                                gap={4}
                                align={'center'}
                                sx={() => ({
                                    color: '#A7ACB8',
                                    fontSize: '14px',
                                    fontWeight: 400,
                                    lineHeight: '20px',
                                })}
                            >
                                <IconClock width={20} height={20} />
                                {from && to && <Text size={14}>{from} : {to}</Text>}
                            </Flex>
                        </Grid.Col>
                        <Grid.Col span="auto">
                            <Flex justify={'flex-end'} pr={20}>
                                <RingProgress
                                    size={ 16 }
                                    thickness={ 1.6 }
                                    rootColor={'#545F77'}
                                    label={
                                        <Text
                                            sx={{
                                                color: '#A7ACB8',
                                                fontSize: '12px',
                                                fontWeight: 500,
                                                lineHeight: '16px',
                                                marginLeft: '20px',
                                            }}
                                        >
                                            { progress }%
                                        </Text>
                                    }
                                    sections={ [
                                        { value: progress, color: '#A7ACB8' },
                                    ] }/>
                            </Flex>
                        </Grid.Col>
                    </Grid>
                </Flex>
                
                <Accordion.Panel>
                    
                    <Flex direction={'column'} pt={16} pb={16} className={styles.panelItem}>
                        <Text className={styles.panelItemKey}>Адрес</Text>
                        <Text className={styles.panelItemData}>{selectedWork.project?.object?.address}</Text>
                    </Flex>
                    
                    {contacts?.map((contact, idx) =>
                        <Flex  pt={16} pb={16}  className={styles.panelItem} justify={'space-between'} align={'center'} key={idx}>
                            <Flex direction={'column'}>
                                <Text className={styles.panelItemKey}>{contact.id}</Text>
                                <Text className={styles.panelItemData}>{contact.name}</Text>
                                <Text className={styles.panelItemData}>{contact.phone ? formatPhoneNumber(contact.phone) : ''}</Text>
                            </Flex>
                            { contact.phone ?
                                <Flex h={'100%'} align={'flex-end'} justify={'flex-end'}>
                                    <Anchor
                                        href={`tel:${contact.phone}`}
                                        c={'white'}
                                        className={styles.phoneLink}
                                    >
                                        <IconPhone size={ '20px' } style={{ marginRight: 8 }}/>
                                        Позвонить
                                    </Anchor>
                                </Flex> :
                                null
                            }
                        </Flex>
                    )}
                
                </Accordion.Panel>
            </Accordion.Item>
        </Accordion>
    )
}
