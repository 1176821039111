import React, { useMemo } from 'react'
import styles from 'components/Worker/Dashboard.module.css'
import { Box, Flex } from '@mantine/core'
import classNames from 'classnames'
import dayjs from 'utils/dayjs.ts'
import { WorkCardProps } from 'components/Worker/Calendar/Types/WorkCardProps'
import { WorkStatusComponent } from 'components/Worker/Work/WorkStatus.tsx'
import { WorkMoneyComponent } from 'components/Worker/Work/WorkMoney'


export const WorkCardCalendar: React.FC<WorkCardProps> = ({ date, work, isActive }: WorkCardProps) => {
    
    // const isSmScreen = useMediaQuery(MEDIA_MAX.SM)
    
    const workTime= useMemo(() => {
        const partsInDay = work?.parts?.filter((part) => dayjs(date, 'YYYY-MM-DD').isSame(dayjs(part.from, 'YYYY-MM-DD HH:mm'), 'day') && dayjs(date, 'YYYY-MM-DD').isSame(dayjs(part.to, 'YYYY-MM-DD HH:mm'), 'day'))
        return  partsInDay && partsInDay.length ? `${ dayjs(partsInDay[0].from, 'YYYY-MM-DD'
            + ' HH:mm').format('HH:mm') } - ${ dayjs(partsInDay[partsInDay.length - 1].to, 'YYYY-MM-DD HH:mm').format('HH:mm') }` : 'Ошибка времени'
    }, [work])
    
    return (
        <Box className={
            classNames(styles.calendarWork, {
                [styles.calendarWorkActive]: isActive,
            })
        }>
            <Flex direction={'row'} justify={'space-between'} align={'center'}>
                
                <Box className={ styles.workTime }>
                    { workTime }
                </Box>
                
                <Flex justify="flex-end" align="center" gap={8}>
                    { work?.status && <WorkStatusComponent workStatus={work.status} /> }
                    
                    { work?.isShowMoney &&
                        <WorkMoneyComponent workStatus={work.status} workMoney={work.money} />
                    }
                </Flex>

            </Flex>
            
            <Box className={ styles.workName }>
                { work?.name }
            </Box>
            
            <Box className={ styles.workAddress }>
                { work?.project?.object?.address }, кв. { work?.project?.object?.apartment }
            </Box>
        </Box>
    )
}
