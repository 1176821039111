import { FormBasedSliceName } from 'types/inputs'
import { FormBasedSliceInitialState } from 'types/redux'
import { createFormBasedSliceReducers } from 'utils/redux'
import { createSlice } from '@reduxjs/toolkit'

export const createAppSlice = <T extends FormBasedSliceInitialState>(name: FormBasedSliceName, initialState: T) => createSlice({
    name,
    initialState,
    reducers: createFormBasedSliceReducers<T>(),
})
