// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface Number {
    /**
     * Rounds a number up like Excel ROUNDUP() or ОКРУГЛВВЕРХ()
     * ROUNDUP behaves like ROUND, except that it always rounds a number up
     *
     * @param {number} precision Max fraction digits to round
     * @param {string} locale Locale to format
     * @returns {string} Locale formatted string
     */
    roundup: (precision?: number, locale?: string) => string

    /**
     * Get number precision digits count
     *
     * @returns {number}
     */
    precision: () => number

    /**
     * Get bytes in human-readable format
     * @returns {string}
     */
    bytes: () => string
}

Number.prototype.precision = function precision(): number {
    let e = 1
    let p = 0
    while (Math.round(this.valueOf() * e) / e !== this.valueOf()) {
        e *= 10
        p++
    }
    return p
}

Number.prototype.roundup = function roundup(precision: number = 2, locale: string = 'ru-RU'): string {
    let value: number = this.valueOf()

    if (isNaN(value) || value === undefined || value === null) {
        return '0'
    }

    let fractionDigits: number = value.precision()

    if (fractionDigits > precision) {
        while (fractionDigits != precision) {
            fractionDigits--
            value = Math.ceil(value * Math.pow(10, fractionDigits)) / Math.pow(10, fractionDigits)
        }
    }

    return value.toLocaleString(locale, { maximumFractionDigits: precision })
}

Number.prototype.bytes = function bytes(): string {
    const bytes: number = this.valueOf()

    if (bytes == 0) {
        return '0.00 байт'
    }

    const e = Math.floor(Math.log(bytes) / Math.log(1024))
    return (bytes / Math.pow(1024, e)).toFixed(2) + ' ' + ' кмгтп'.charAt(e) + 'Б'
}