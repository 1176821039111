import { FindJobMaterialResponseDto } from 'store/api/jobMaterials'
import { JobMaterialFormBody, JobMaterialFormHeader, JobMaterialsFormGlobal } from './JobMaterials.types'


// *
// DATA CONVERT FUNCS
// *

export const convertJobMaterialDtoToFormHeader = (jobMaterial: FindJobMaterialResponseDto['material']): JobMaterialFormHeader => ({
    name: jobMaterial?.name || jobMaterialFormHeaderInitialState.name,
})


// *
// STORE
// *

export const jobMaterialsFormGlobalDefaultValues: JobMaterialsFormGlobal = {
    name: '',
    newJobMaterialIds: [],
}

export const jobMaterialFormHeaderInitialState: JobMaterialFormHeader = {
    name: '',
}

export const jobMaterialsFormBodyInitialState: JobMaterialFormBody = {
    newJobMaterialIds: [],
}
