import React, { ReactNode } from 'react'
import { Box, Flex, Text } from '@mantine/core'
import styles from './TaskStatus.module.css'
import { IconCircleX, IconClock, IconFlagCheck, IconPlayerPlay, IconRefreshDot } from '@tabler/icons-react'
import { FindProjectTaskStatusResponseDto } from 'store/api/projectTasks.tsx';

type StatusColorsType = {
    [key in 'PREPARATION' | 'WAIT'  | 'DONE' | 'PROBLEM' | 'REVISION' | 'CONTROL' | 'PAID']: {
        color: string;
        backgroundColor: string;
        icon: ReactNode
    };
};
export const statusColors: StatusColorsType = {
    PREPARATION: {
        color: '#545F77',
        backgroundColor: 'rgba(84, 95, 119, 0.2)',
        icon: <IconClock size={ '10px' }/>,
    },
    WAIT: {
        color: '#436CEE',
        backgroundColor: 'rgba(67, 108, 238, 0.2)',
        icon: <IconPlayerPlay size={ '10px' }/>,
    },
    PROBLEM: {
        color: '#D44332',
        backgroundColor: 'rgba(212, 67, 50, 0.2)',
        icon: <IconCircleX size={ '10px' }/>,
    },
    CONTROL: {
        color: '#EC9827',
        backgroundColor: 'rgba(253, 209, 58, 0.2)',
        icon: <IconRefreshDot size={ '10px' }/>,
    },
    REVISION: {
        color: '#EC9827',
        backgroundColor: 'rgba(253, 209, 58, 0.2)',
        icon: <IconRefreshDot size={ '10px' }/>,
    },
    DONE: {
        color: '#118F3F',
        backgroundColor: 'rgba(17, 143, 63, 0.2)',
        icon: <IconFlagCheck size={ '10px' }/>,
    },
    PAID: {
        color: '#545F77',
        backgroundColor: 'rgba(84, 95, 119, 0.2)',
        icon: <IconClock size={ '10px' }/>,
    },
}
export const TaskStatus: React.FC<{ status: FindProjectTaskStatusResponseDto }> = ({ status }) => <Flex
    gap={ 10 }
    className={ styles.taskStatus } bg={statusColors[status.type].backgroundColor}>
    <Box style={{ color: statusColors[status.type].color }}>
        { statusColors[status.type].icon }
    </Box>
    <Text c={statusColors[status.type].color}>
        { status.name }
    </Text>
</Flex>