import { StageFormBody, StageFormHeader, StagesFormGlobal } from './Stages.types'
import { FindJobStageResponseDto, UpdateJobStageRequestDto } from 'store/api/jobStages'


// *
// DATA CONVERT FUNCS
// *

export const convertStageDtoToFormBody = (stage: FindJobStageResponseDto): StageFormBody => ({
    description: stage?.description || stageFormBodyInitialState.description,
    sort: stage?.sort || stageFormBodyInitialState.sort
})
export const convertStageDtoToFormHeader = (stage: FindJobStageResponseDto): StageFormHeader => ({
    name: stage?.name || stageFormHeaderInitialState.name,
})
export const convertStageFormToRequestDto = (data: Partial<StageFormBody & StageFormHeader>): UpdateJobStageRequestDto => ({
    name: data?.name || stageFormHeaderInitialState.name,
    description: data?.description || stageFormBodyInitialState.description,
    sort: data?.sort || stageFormBodyInitialState.sort
})


// *
// STORE
// *

export const stagesFormGlobalDefaultValues: StagesFormGlobal = {
    name: '',
    description: '',
    sort: null
}
export const stageFormHeaderInitialState: StageFormHeader = {
    name: '',
}
export const stageFormBodyInitialState: StageFormBody = {
    description: '',
    sort: null
}


// *
// INPUT PROCESSING FUNCS
// *
