import { emptyApi as api } from './emptyApi';
export const addTagTypes = [
    'ProjectTaskStepResults',
    'ProjectTaskSteps',
] as const;
const injectedRtkApi = api
    .enhanceEndpoints({
        addTagTypes,
    })
    .injectEndpoints({
        endpoints: (build) => ({
            projectTaskStepResultsFind: build.query<
                ProjectTaskStepResultsFindApiResponse,
                ProjectTaskStepResultsFindApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/projects/task-step-results/${queryArg}`,
                }),
                providesTags: ['ProjectTaskStepResults'],
            }),
            projectTaskStepResultsCreate: build.mutation<
                ProjectTaskStepResultsCreateApiResponse,
                ProjectTaskStepResultsCreateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/projects/task-step-results/${queryArg.id}`,
                    method: 'POST',
                    body: queryArg.body,
                }),
                invalidatesTags: ['ProjectTaskStepResults', 'ProjectTaskSteps'],
            }),
        }),
        overrideExisting: false,
    });
export { injectedRtkApi as projectTaskStepResultsApi };
export type ProjectTaskStepResultsFindApiResponse =
  /** status 200  */ FindProjectTaskStepResultResponseDto[];
export type ProjectTaskStepResultsFindApiArg = /** Id шага задачи */ any;
export type ProjectTaskStepResultsCreateApiResponse =
  /** status 201  */ FindProjectTaskStepResultResponseDto;
export type ProjectTaskStepResultsCreateApiArg = {
    /** Id шага задачи */
    id: any;
    body: CreateProjectTaskStepResultRequestDto[];
};
export type FindProjectTaskSnippetVariantResponseDto = {
    id: string;
    label: string;
    sort: number;
    isRequired: boolean;
};
export type FindBucketResponseDto = {
    id: string;
    /** Bucket name */
    name: string;
    /** Bucket path */
    path: string;
};
export type FindFileResponseDto = {
    id: string;
    /** File name */
    name: string;
    /** File mime type */
    type: string;
    /** File size in bytes */
    size: number;
    /** File bucket */
    bucket: FindBucketResponseDto;
};
export type FindProjectTaskSnippetResponseDto = {
    id: string;
    type:
    | 'PROJECT_TEXT'
    | 'PROJECT_IMAGES'
    | 'PROJECT_CHECKBOX'
    | 'PROJECT_RADIO_BUTTON'
    | 'PROJECT_INPUT'
    | 'PROJECT_FILES'
    | 'PROJECT_MATERIAL_REPORT'
    | 'PROJECT_JOB_FILES'
    | 'PROJECT_JOB_PARAMS';
    sort: number;
    label: string | null;
    variants?: FindProjectTaskSnippetVariantResponseDto[] | null;
    header: string | null;
    placeholder: string | null;
    text: string | null;
    isRequired: boolean | null;
    isAny: boolean | null;
    minCount: number | null;
    format: string[] | null;
    images: FindFileResponseDto[] | null;
};
export type TaskStepResultMaterial = {
    materialId: string;
    remains: number;
};
export type FindProjectTaskStepResultResponseDto = {
    id: string;
    createdAt: string;
    snippet: FindProjectTaskSnippetResponseDto;
    files: FindFileResponseDto[];
    variants: FindProjectTaskSnippetVariantResponseDto[];
    value: string | string[] | TaskStepResultMaterial[];
};
export type ApiError = {
    status: number;
    message: string[];
    error: string;
};
export type ProjectTaskStepResultMessage = {
    stepId: string;
    snippetId: string;
    data: string;
};
export type ProjectTaskStepResultError = {
    status: number;
    message: ProjectTaskStepResultMessage[];
    error: string;
};
export type CreateProjectTaskStepResultRequestDto = {
    snippetId: string;
    value: string | string[] | TaskStepResultMaterial[];
};
export const {
    useProjectTaskStepResultsFindQuery,
    useLazyProjectTaskStepResultsFindQuery,
    useProjectTaskStepResultsCreateMutation,
} = injectedRtkApi;
