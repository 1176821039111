import { createAppSlice } from 'utils/createAppSlice'
import { defaultInitialState } from 'utils/defaultInitialState.ts'
import { FormBasedSliceInitialState } from 'types/redux.ts'

export class CorrectivesTableSliceInitialState extends FormBasedSliceInitialState {
    editableFormHeader = {}
    editableFormBody = {}
}

const correctivesTableInitialState: CorrectivesTableSliceInitialState = {
    ...defaultInitialState,
    editableFormBody: {},
    editableFormHeader: {},
}

export const  correctivesTableSlice = createAppSlice<CorrectivesTableSliceInitialState>('correctivesTable', correctivesTableInitialState)

export default correctivesTableSlice.reducer
