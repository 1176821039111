import { FindFileResponseDto } from 'store/api/user.tsx';
import { EditWorkerTaskStepDtoCustom } from '../../WorkerTask.types.ts'


// *
// UI
// *

export const isResultedStep = (stepStatus: EditWorkerTaskStepDtoCustom['status']) => stepStatus === 'ACCEPTED' || stepStatus === 'ERROR' || stepStatus === 'WAIT'

export const getVideos = (files: FindFileResponseDto[]) => files.filter(file => file.type.includes('video'))
export const getImages = (files: FindFileResponseDto[]) => files.filter(file => file.type.includes('image'))
export const getDocuments = (files: FindFileResponseDto[]) => files.filter(file => !file.type.includes('video') && !file.type.includes('image'))

// *
// DATA CONVERT FUNCS
// *

export const convertWorkerTaskStepDtoToFormBody = (step: EditWorkerTaskStepDtoCustom): EditWorkerTaskStepDtoCustom => ({
    id: step.id,
    name: step.name,
    snippets: step.snippets,
    // taskId: step.taskId,
    toValidate: step.toValidate,
    sort: step.sort,
    status: step.status,
})
