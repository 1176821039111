import { SpecializationsSliceInitialState } from 'components/Organizations/NestedPages/Specializations/Specializations.types'
import { specializationFormHeaderInitialState, specializationFormBodyInitialState } from 'components/Organizations/NestedPages/Specializations/Specializations.utils'
import { createAppSlice } from 'utils/createAppSlice'
import { defaultInitialState } from 'utils/defaultInitialState.ts'

const specializationsSliceInitialState: SpecializationsSliceInitialState = {
    ...defaultInitialState,
    editableFormHeader: specializationFormHeaderInitialState,
    editableFormBody: specializationFormBodyInitialState,
}

export const specializationsSlice = createAppSlice<SpecializationsSliceInitialState>('specializations', specializationsSliceInitialState)


export default specializationsSlice.reducer
