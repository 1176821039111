import { StagesSliceInitialState } from 'components/Resources/Jobs/NestedPages/Stages/Stages.types'
import { stageFormHeaderInitialState, stageFormBodyInitialState } from 'components/Resources/Jobs/NestedPages/Stages/Stages.utils'
import { createAppSlice } from 'utils/createAppSlice'
import { defaultInitialState } from 'utils/defaultInitialState.ts'

const stagesSliceInitialState: StagesSliceInitialState = {
    ...defaultInitialState,
    editableFormHeader: stageFormHeaderInitialState,
    editableFormBody: stageFormBodyInitialState,
}

export const stagesSlice = createAppSlice<StagesSliceInitialState>('stages', stagesSliceInitialState)


export default stagesSlice.reducer
