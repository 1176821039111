import React, { useCallback, useState } from 'react'
import { TimePoint } from './TimePoint'
import { TimeIntervalProps } from './WorkingHoursTimetable.types'
import {
    SECONDS_PER_DAY,
    MIN_OUTER_DIAPASON_IN_MINUTES,
    getIntervalStyles,
    getLeftCoordinate,
} from './WorkingHoursTimetable.utils'
import styles from 'components/Projects/PageBody/Info/WorkingHoursTimetable/WorkingHoursTimetable.module.css'
import { Box } from '@mantine/core'

export const TimeInterval: React.FC<TimeIntervalProps> = ({
    index,
    id,
    parentWidth,
    timeTable,
    onChange: onParentChange,
    onStop: onParentStop,
    // isParentMouseLeft,
}) => {
    const [posX, setX] = useState(0)
    const [down, setDown] = useState<0 | 1>(0)

    const onStop = () => {
        if (down) {
            setDown(0)
            onParentStop()
        }
    }

    const onMove = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (down > 0) {
            const dX = posX - event.clientX

            if (parentWidth) {
                const progressInPercent = dX / parentWidth
                const newInterval: [number, number] = [
                    Math.round(timeTable[index][0] - SECONDS_PER_DAY * progressInPercent),
                    Math.round(timeTable[index][1] - SECONDS_PER_DAY * progressInPercent),
                ]

                const difference = Math.abs(
                    index === 0
                        ? timeTable[1][0] - newInterval[1]
                        : newInterval[0] - timeTable[0][1]
                )
                if (difference < MIN_OUTER_DIAPASON_IN_MINUTES * 60) return

                setX(event.clientX)
                if (!(newInterval[0] < 0) && !(newInterval[1] > SECONDS_PER_DAY))
                    onParentChange(newInterval)
            }
        }
    }, [down, posX, parentWidth])

    return (
        <Box component="span" id={id}>
            <Box
                style={getIntervalStyles(
                    `${getLeftCoordinate(timeTable[index][0])}%`,
                    `${getLeftCoordinate(timeTable[index][1] - timeTable[index][0])}%`
                )}
                onMouseDown={(event) => {
                    setDown(1)
                    setX(event.clientX)
                }}
                onMouseLeave={onStop}
                onMouseUp={() => {
                    onStop()
                }}
                // onMouseOut={() => {
                //     onStop()
                //     // isParentMouseLeft && onStop()
                // }}
                onMouseMove={(event) => {
                    onMove(event)
                }}
            >
                <Box
                    className={`${styles.interval} ${down ? styles.movingInterval : ''}`}
                />
            </Box>

            <TimePoint
                index={0}
                intervalIndex={index}
                timeTable={timeTable}
                position="bottom"
                parentWidth={parentWidth}
                onChange={(time) => onParentChange([time, timeTable[index][1]])}
                onStop={onParentStop}
            />

            <TimePoint
                intervalIndex={index}
                index={1}
                timeTable={timeTable}
                position="top"
                parentWidth={parentWidth}
                onChange={(time) => onParentChange([timeTable[index][0], time])}
                onStop={onParentStop}
            />
        </Box>
    )
}
