import { FindJobTypeResponseDto, UpdateJobTypeRequestDto } from 'store/api/jobTypes'
import { JobTypeFormBody, JobTypeFormHeader, JobTypesFormGlobal } from './JobType.types'


// *
// DATA CONVERT FUNCS
// *

export const convertJobTypeDtoToFormBody = (jobType: FindJobTypeResponseDto): JobTypeFormBody => ({
    description: jobType?.description || jobTypeFormBodyInitialState.description,
})
export const convertJobTypeDtoToFormHeader = (jobType: FindJobTypeResponseDto): JobTypeFormHeader => ({
    name: jobType?.name || jobTypeFormHeaderInitialState.name,
})
export const convertJobTypeFormToRequestDto = (data: Partial<JobTypeFormBody & JobTypeFormHeader>): UpdateJobTypeRequestDto => ({
    name: data?.name || jobTypeFormHeaderInitialState.name,
    description: data?.description || jobTypeFormBodyInitialState.description,
})


// *
// STORE
// *

export const jobTypesFormGlobalDefaultValues: JobTypesFormGlobal = {
    name: '',
    description: '',
}
export const jobTypeFormHeaderInitialState: JobTypeFormHeader = {
    name: '',
}
export const jobTypeFormBodyInitialState: JobTypeFormBody = {
    description: '',
}


// *
// INPUT PROCESSING FUNCS
// *
