import { createAppSlice } from 'utils/createAppSlice'
import { defaultInitialState } from 'utils/defaultInitialState.ts'
import { SupplyRequestsSliceInitialState } from 'components/Supply/SupplyRequests/SupplyRequests.types.ts';

const supplyRequestsSliceInitialState: SupplyRequestsSliceInitialState = {
    ...defaultInitialState,
    sortParamsArray: [
        {
            param: 'supplyRequest.createdAt',
            rule: 'desc',
        },
    ],
}

export const supplyRequestsSlice = createAppSlice<SupplyRequestsSliceInitialState>(
    'supplyRequests',
    supplyRequestsSliceInitialState,
)


export default supplyRequestsSlice.reducer