import { defaultInitialState } from 'utils/defaultInitialState.ts.ts';
import {
    DocumentMovementChangedMaterials,
    DocumentMovementMaterialsSliceInitialState
} from 'components/Supply/DocumentMovements/PageBody/Materials/Tables/DocumentMovementMaterials.types.ts';
import {
    documentMovementMaterialsFormBodyInitialState
} from 'components/Supply/DocumentMovements/PageBody/Materials/Tables/DocumentMovementMaterials.utils.ts';
import { createFormBasedSliceReducers } from 'utils/redux.ts';
import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { FindDocumentMovementMaterialResponseDto } from 'store/api/documentMovementMaterial.tsx';
import { FindDocumentMovementResponseDto, FindDocumentMovementStatusResponseDto } from 'store/api/documentMovement.tsx';

const documentMovementMaterialsSliceInitialState: DocumentMovementMaterialsSliceInitialState = {
    ...defaultInitialState,
    editableFormBody: documentMovementMaterialsFormBodyInitialState,
    materialsForEdit: [],
    originalMaterialsForEdit: [],
    documentMovementStatuses:  undefined,
    changedMaterials: [],
    documentMovementData: undefined,
    sortParamsArray: [
        {
            param: 'material.name',
            rule: 'asc',
        },
    ],
}

const reducers = createFormBasedSliceReducers<any>()

export const documentMovementMaterialsSlice = createSlice({
    name: 'documentMovementMaterials',
    initialState: documentMovementMaterialsSliceInitialState,
    reducers: {
        ...reducers,
        setMaterialsForEdit: (state: Draft<DocumentMovementMaterialsSliceInitialState>, action: PayloadAction<FindDocumentMovementMaterialResponseDto[]>) => {
            state.materialsForEdit = action.payload
        },
        setOriginalMaterialsForEdit: (state: Draft<DocumentMovementMaterialsSliceInitialState>, action: PayloadAction<FindDocumentMovementMaterialResponseDto[]>) => {
            state.originalMaterialsForEdit = action.payload
        },

        setDocumentMovementStatuses: (state: Draft<DocumentMovementMaterialsSliceInitialState>, action: PayloadAction<FindDocumentMovementStatusResponseDto[]>) => {
            state.documentMovementStatuses = action.payload
        },
        setChangedMaterials: (state: Draft<DocumentMovementMaterialsSliceInitialState>, action: PayloadAction<DocumentMovementChangedMaterials[]>) => {
            state.changedMaterials = action.payload
        },
        setDocumentMovementData:  (state: Draft<DocumentMovementMaterialsSliceInitialState>, action: PayloadAction<FindDocumentMovementResponseDto>) => {
            state.documentMovementData = action.payload
        },
    }
}
)


export default documentMovementMaterialsSlice.reducer