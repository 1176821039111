import { FindInventoryResponseDto } from 'store/api/inventory.tsx'
import { AutocompleteSubmitData, InputChangeArg, InputSubmitArg } from 'types/inputs.ts'
import { autocompleteChangeArg, autocompleteFieldDefault, autocompleteSubmitArg } from 'utils/inputs.tsx'
import { ProjectWarehouseInventoriesAutocompleteKey, ProjectWarehouseInventoriesFormGlobal, ProjectWarehouseInventoryFormBody, ProjectWarehouseInventoryFormHeader } from 'components/Projects/PageBody/Warehouses/PageBody/ProjectWarehouseInventories/ProjectWarehouseInventories.types.ts'


// *
// DATA CONVERT FUNCS
// *

export const convertProjectWarehouseInventoryDtoToFormBody = (projectWarehouseInventory: FindInventoryResponseDto): ProjectWarehouseInventoryFormBody => ( {
    id: projectWarehouseInventory.id || '',
    count: projectWarehouseInventory.count || 0,
    
    materialType: projectWarehouseInventory?.material?.type?.name ? {
        name: projectWarehouseInventory.material.type.name,
        data: {
            id: projectWarehouseInventory.material.type.id,
            name: projectWarehouseInventory.material.type.name,
        },
    } : autocompleteFieldDefault,
    
    units: projectWarehouseInventory?.units ? {
        name: projectWarehouseInventory.units.name,
        data: {
            id: projectWarehouseInventory.units.id,
            name: projectWarehouseInventory.units.name,
        },
    } : autocompleteFieldDefault,
    
    status: projectWarehouseInventory?.status ? {
        name: projectWarehouseInventory.status.name,
        data: {
            id: projectWarehouseInventory.status.id,
            name: projectWarehouseInventory.status.name,
        },
    } : autocompleteFieldDefault,
    
    project: projectWarehouseInventory?.project ? {
        name: projectWarehouseInventory.project.name,
        data: {
            id: projectWarehouseInventory.project.id,
            name: projectWarehouseInventory.project.name,
        },
    } : autocompleteFieldDefault,
    
    material: projectWarehouseInventory?.material ? {
        name: projectWarehouseInventory.material.name,
        data: {
            id: projectWarehouseInventory.material.id,
            name: projectWarehouseInventory.material.name,
        },
    } : autocompleteFieldDefault,
    
    inventoryType: projectWarehouseInventory?.material?.inventoryType?.name ? {
        name: projectWarehouseInventory.material.inventoryType.name,
        data: {
            id: projectWarehouseInventory.material.inventoryType.id,
            name: projectWarehouseInventory.material.inventoryType.name,
        },
    } : autocompleteFieldDefault,
} )

export const convertProjectWarehouseInventoryDtoToFormHeader = (projectWarehouseInventory: FindInventoryResponseDto): ProjectWarehouseInventoryFormHeader => ( {
    name: projectWarehouseInventory?.material?.name || '',
} )

// global filter funcs
export const onProjectWarehouseInventoriesGlobalAutocompleteSubmit = ({
    key,
    setFormValue,
}: InputSubmitArg<ProjectWarehouseInventoriesAutocompleteKey, ProjectWarehouseInventoriesFormGlobal>) => (data: AutocompleteSubmitData) => {
    setFormValue(key, autocompleteSubmitArg(data))
}
export const onProjectWarehouseInventoriesGlobalAutocompleteChange = ({
    formData,
    key,
    setFormValue,
}: InputChangeArg<ProjectWarehouseInventoriesAutocompleteKey, ProjectWarehouseInventoriesFormGlobal>) => (label: string) => {
    if (formData[key]?.name !== label) {
        setFormValue(key, autocompleteChangeArg(label))
    }
}


// *
// STORE
// *

export const projectWarehouseInventoriesFormGlobalDefaultValues: ProjectWarehouseInventoriesFormGlobal = {
    name: '',
    id: '',
    count: 0,
    
    materialType: autocompleteFieldDefault,
    inventoryType: autocompleteFieldDefault,
    material: autocompleteFieldDefault,
    project: autocompleteFieldDefault,
    status: autocompleteFieldDefault,
    units: autocompleteFieldDefault,
}
export const projectWarehouseInventoryFormHeaderInitialState: ProjectWarehouseInventoryFormHeader = {
    name: '',
}
export const projectWarehouseInventoryFormBodyInitialState: ProjectWarehouseInventoryFormBody = {
    id: '',
    count: 0,
    
    materialType: autocompleteFieldDefault,
    inventoryType: autocompleteFieldDefault,
    material: autocompleteFieldDefault,
    project: autocompleteFieldDefault,
    status: autocompleteFieldDefault,
    units: autocompleteFieldDefault,
}

