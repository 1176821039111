import { createAppSlice } from 'utils/createAppSlice'
import { defaultInitialState } from 'utils/defaultInitialState.ts'
import {
    InventoryAddMaterialsSliceInitialState
} from 'components/Supply/Inventories/PageBody/InventoryAddMaterialsTable/InventoryAddMaterials.types.ts';
import {
    materialFormHeaderInitialState,
    materialsFormBodyInitialState
} from 'components/Resources/Materials/Materials.utils.ts';

const inventoryAddMaterialsInitialState: InventoryAddMaterialsSliceInitialState = {
    ...defaultInitialState,
    editableFormHeader: materialFormHeaderInitialState,
    editableFormBody: materialsFormBodyInitialState,
}

export const inventoryAddMaterialsSlice = createAppSlice<InventoryAddMaterialsSliceInitialState>('inventoryAddMaterials',  inventoryAddMaterialsInitialState)

export default inventoryAddMaterialsSlice.reducer
