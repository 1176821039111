import { FindJobResponseDto } from 'store/api/jobGroups.tsx';
import { FormBasedSliceInitialState } from 'types/redux.ts';
import { JobsFindApiArg, JobsFindApiResponse } from 'store/api/jobs.tsx';
import { TableProps, TablePropsAbstract } from 'components/AbstractComponents/TableReusable/TableReusable.types.ts';


export type NewAddedJobs = {
    newAddedJobs: FindJobResponseDto[]
}

export const newAddedJobsInitialState: NewAddedJobs = {
    newAddedJobs: [],
}

export class GroupsAddJobsInitialState extends FormBasedSliceInitialState {
    editableFormBody: NewAddedJobs = newAddedJobsInitialState
}


/*Jobs table*/

export type ControllerPropsAddJobs = TableProps<
    JobsFindApiResponse,
    JobsFindApiArg,
    FindJobResponseDto
>

export type TablePropsAddJobs = TablePropsAbstract & ControllerPropsAddJobs


