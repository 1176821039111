import { autocompleteFieldDefault } from 'utils/inputs.tsx';
import {
    DocumentMovementChangedMaterials,
    DocumentMovementMaterialsFormBody
} from './DocumentMovementMaterials.types.ts';
import {
    FindDocumentMovementMaterialResponseDto, UpdateDocumentMovementMaterialRequestDto
} from 'store/api/documentMovementMaterial.tsx';

// *
// HELPERS
// *

const getMethod = (editPath: 'delete' | 'update' | 'create'): 'POST' | 'PUT' | 'DELETE'  => {
    if(editPath === 'delete') return 'DELETE';
    if(editPath === 'update') return 'PUT';
    else return 'POST'

}

// *
// STORE
// *

export const documentMovementMaterialsFormBodyInitialState: DocumentMovementMaterialsFormBody = {
    source: autocompleteFieldDefault,
    destination: autocompleteFieldDefault
}

export const convertDocumentMovementMaterialForStore = (material: FindDocumentMovementMaterialResponseDto, editPath: DocumentMovementChangedMaterials['editPath']): DocumentMovementChangedMaterials => ({
    ...material, editPath
})

export const convertDocumentMovementMaterialsForUpdate = (materials: DocumentMovementChangedMaterials[], movementId?: string): UpdateDocumentMovementMaterialRequestDto[] => materials.map((material) => ({
    id: material.id.includes('new') ? null : material.id,
    count: material?.move || null,
    cost: material?.material?.price,
    projectId: material?.project?.id,
    unitsId: material.units?.id ? material.units?.id : null,
    method: getMethod(material.editPath),
    movementId: material.id.includes('new') ?  movementId : null,
    materialId: material.id.includes('new') ? material.material.id : null,
    inventoryId: material.id.includes('new') ? material.id.split('new_')[1] : null
}))