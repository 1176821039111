import { createAppSlice } from 'utils/createAppSlice'
import { defaultInitialState } from 'utils/defaultInitialState.ts'
import { warehouseInventoryFormBodyInitialState, warehouseInventoryFormHeaderInitialState } from 'components/Supply/Warehouses/PageBody/Info/WarehouseInventories/WarehouseInventories.utils.ts'
import { WarehouseInventoriesSliceInitialState } from 'components/Supply/Warehouses/PageBody/Info/WarehouseInventories/WarehouseInventories.types.ts'

const warehouseInventoriesSliceInitialState: WarehouseInventoriesSliceInitialState = {
    ...defaultInitialState,
    editableFormHeader: warehouseInventoryFormHeaderInitialState,
    editableFormBody: warehouseInventoryFormBodyInitialState,
    
}

export const warehouseInventoriesSlice = createAppSlice<WarehouseInventoriesSliceInitialState>(
    'warehouseInventories',
    warehouseInventoriesSliceInitialState,
)


export default warehouseInventoriesSlice.reducer
