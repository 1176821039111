import { CSSObject, MantineTheme } from '@mantine/core'
import { COLOR_WHITE } from './constants'

export const inputLabelOptions = (theme: MantineTheme): CSSObject => ({
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '0em',
    color: theme.colors.natural[9],
    // textAlign: 'left',
})

export const dropdownOptions = (): CSSObject => ({
    maxHeight: '300px',
    minWidth: '200px',
    overflowY: 'scroll',
    zIndex: 3,
})

export const inputOptions = (theme: MantineTheme): CSSObject => ({
    // padding: '0 0 0 16px',
    border: `1px solid ${theme.colors.natural[1]}`,
    color: theme.colors.natural[9],
    borderRadius: '4px',
    boxShadow: 'none',
    '&:hover': {
        border: `1px solid ${theme.colors.gray[7]}`,
    },
    '&[disabled]': {
        backgroundColor: theme.colors.gray[0],
    },
    '&[data-invalid="true"]': {
        border: `1px solid ${theme.colors.red[4]}`,
    },
    '&:focus': {
        border: `1px solid ${theme.colors.primary[3]}`,
    },
    '&::placeholder': {
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '20px',
    },
})

export const selectStyles = (theme: MantineTheme) => ({
    input: { ...inputOptions(theme), minWidth: '91px' },
    label: inputLabelOptions(theme),
    dropdown: dropdownOptions(),
    itemsWrapper: {
        border: `2px solid ${theme.colors.primary[3]}`,
    },
    item: {
        '&[data-selected]': {
            '&, &:hover': {
                backgroundColor: theme.colors.gray[0],
                color: theme.colors.blue[5],
            },
        },
        '&[data-hovered]': {
            backgroundColor: theme.colors.gray[0],
            color: theme.colors.blue[5],
        },
    },
    searchInput: {
        height: '100% !important'
    },
})

export const viewTextarea = () => ({
    input: {
        color: '#000000 !important',
        border: 'none !important',
        padding: '8px 0 !important',
        resize: 'none' as const,
        minHeight: 0,
    },
    label: {
        fontFamily: 'Inter',
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '24px',
        letterSpacing: '0em',
    }
})

export const inputVariants = () => ({
    filter: (theme: MantineTheme) => ({
        input: {
            display: 'flex',
            alignItems: 'center',
            height: '40px',
            padding: '0 12px',
            borderRadius: '4px !important',
            backgroundColor: COLOR_WHITE,
            borderColor: `${theme.colors.natural[1]} !important`,
            'input': {
                height: '100%',
            },
        },
        searchInput: {
            height: '100% !important',
            display: 'flex',
        },
    }),
    page: (theme: MantineTheme) => ({
        input: {
            display: 'flex',
            alignItems: 'center',
            height: '48px',
            padding: '0 12px',
            borderRadius: '8px !important',
            backgroundColor: COLOR_WHITE,
            borderColor: `${theme.colors.natural[3]} !important`,
            'input': {
                height: '100%',
            },
            '&:disabled': {
                backgroundColor: 'transparent !important',
                color: `${theme.colors.natural[9]} !important`,
                opacity: 1,
                border: 'none !important',
            }
        },
    }),
    pageSnippet: (theme: MantineTheme) => ({
        input: {
            display: 'flex',
            alignItems: 'center',
            height: '48px',
            padding: '0 12px',
            borderRadius: '8px !important',
            backgroundColor: theme.colors.natural[0],
            border: 'none !important',
            outline: 'none',

            fontSize: '20px !important',
            fontWeight: 400,
            lineHeight: '24px !important',

            '&::placeholder': {
                fontSize: '20px !important',
                fontWeight: 400,
                lineHeight: '24px !important',
            },
        },
    }),
    pageFormulaSnippet: (theme: MantineTheme) => ({
        input: {
            display: 'flex',
            alignItems: 'center',
            height: 'auto',
            padding: 0,
            // padding: '0 12px',
            // borderRadius: '8px !important',
            backgroundColor: theme.colors.natural[0],
            border: 'none !important',
            outline: 'none',

            fontSize: '14px !important',
            fontWeight: 400,
            lineHeight: '20px !important',

            '&::placeholder': {
                fontSize: '14px !important',
                fontWeight: 400,
                lineHeight: '20px !important',
            },
        },
        wrapper: {
            maxWidth: '32px',
        },
        root: {
            maxWidth: '32px',
        },
    }),
    pageTable: (theme: MantineTheme) => ({
        input: {
            display: 'flex',
            alignItems: 'center',
            height: '32px',
            padding: '0 12px',
            borderRadius: '4px !important',
            backgroundColor: COLOR_WHITE,
            borderColor: `${theme.colors.natural[3]} !important`,

            'input': {
                height: '100%',
            }
        },
    }),
    pageCard: (theme: MantineTheme) => ({
        input: {
            display: 'flex',
            alignItems: 'center',
            height: '32px',
            padding: '0 12px',
            borderRadius: '4px !important',
            backgroundColor: COLOR_WHITE,
            borderColor: `${theme.colors.natural[3]} !important`,

            'input': {
                height: '100%',
            }
        },
    }),
    view: (theme: MantineTheme) => ({
        // TODO change label UI
        input: {
            display: 'flex',
            alignItems: 'center',
            height: '48px',
            padding: '0',
            borderRadius: '8px !important',
            border: 'none !important',
            fontSize: '18px',

            // '&label[for^="mantine-"]': {
            //     color: 'red !important', 
            //     fontSize: '8px !important',
            // },

            'input': {
                height: '100%',
            },
            '&[data-disabled]': {
                backgroundColor: `${COLOR_WHITE} !important`,
                color: theme.colors.natural[8],
                opacity: 0.8,
            },
        },
    }),
    viewTable: (theme: MantineTheme) => ({
        input: {
            display: 'flex',
            alignItems: 'center',
            height: '32px',
            padding: '0',
            border: 'none !important',

            fontSize: '14px !important',
            fontWeight: 400,
            lineHeight: '20px !important',
            color: theme.colors.natural[9],

            'input': {
                height: '100%',
            },
            '&[data-disabled]': {
                backgroundColor: 'transparent !important',
                color: `${theme.colors.natural[9]} !important`,
                opacity: 1,

                '&:hover': {
                    backgroundColor: 'transparent !important',
                },
            },
        },
    }),
    viewCard: (theme: MantineTheme) => ({
        input: {
            display: 'flex',
            alignItems: 'center',
            height: '32px',
            padding: '0',
            border: 'none !important',

            fontSize: '14px !important',
            fontWeight: 400,
            lineHeight: '20px !important',
            color: theme.colors.natural[9],

            'input': {
                height: '100%',
            },
            '&[data-disabled]': {
                backgroundColor: `${COLOR_WHITE} !important`,
                color: `${theme.colors.natural[9]} !important`,
                opacity: 1,
            },
        },
    }),
})
