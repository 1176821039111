
import { defaultInitialState } from 'utils/defaultInitialState.ts'
import { createAppSlice } from 'utils/createAppSlice'
import { ProjectsSliceInitialState } from 'components/Projects/Projects.types'
import { projectFormHeaderInitialState, projectsFormBodyInitialState } from 'components/Projects/Projects.utils'

const projectsInitialState: ProjectsSliceInitialState = {
    ...defaultInitialState,
    editableFormHeader: projectFormHeaderInitialState,
    editableFormBody: projectsFormBodyInitialState,
    sortParamsArray: [
        {
            param: 'project.createdAt',
            rule: 'desc',
        },
    ],
}

export const projectsSlice = createAppSlice<ProjectsSliceInitialState>('projects', projectsInitialState)

export default projectsSlice.reducer
