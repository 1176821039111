import {
    MinstroyMaterialsKSRSliceInitialState
} from 'components/Resources/Materials/NestedPages/MinstroyMaterialsKSRMaterials/MinstroyMaterialsKSR.types.ts'
import { defaultInitialState } from 'utils/defaultInitialState.ts'
import { createAppSlice } from 'utils/createAppSlice'
import {
    minstroyMaterialsKSRFormBodyInitialState,
    minstroyMaterialsKSRFormHeaderInitialState
} from 'components/Resources/Materials/NestedPages/MinstroyMaterialsKSRMaterials/MinstroyMaterialsKSR.utils.ts';

const minstroyMaterialsKSRInitialState: MinstroyMaterialsKSRSliceInitialState = {
    ...defaultInitialState,
    editableFormHeader: minstroyMaterialsKSRFormHeaderInitialState,
    editableFormBody: minstroyMaterialsKSRFormBodyInitialState
}


export const minstroyMaterialsKSRSlice = createAppSlice<MinstroyMaterialsKSRSliceInitialState>('minstroyMaterialsKSR', minstroyMaterialsKSRInitialState)

export default minstroyMaterialsKSRSlice.reducer