import { createAppSlice } from 'utils/createAppSlice'
import { defaultInitialState } from 'utils/defaultInitialState.ts'
import { FormBasedSliceInitialState } from 'types/redux.ts'

export class ProjectReportsSliceInitialState extends FormBasedSliceInitialState {
    editableFormHeader = {}
    editableFormBody = {}
}

const projectReportsInitialState: ProjectReportsSliceInitialState = {
    ...defaultInitialState,
    editableFormBody: {},
    editableFormHeader: {},
}

export const projectReportsSlice = createAppSlice<ProjectReportsSliceInitialState>('projectReports', projectReportsInitialState)

export default projectReportsSlice.reducer
