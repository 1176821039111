import { combineReducers } from 'redux'
import { authenticationApi } from '../api/authentication'
import authReducer from '../slices/authSlice'
import layoutReducer from '../slices/layoutSlice'
import materialsReducer from '../slices/materialsSlice'
import jobsReducer from '../slices/jobsSlice'
import jobBodyViewMaterialsReducer from '../slices/jobBodyViewMaterialsSlice'
import jobBodyAddMaterialsReducer from '../slices/jobBodyAddMaterialsSlice'
import taskBodyViewMaterialsReducer from '../slices/taskBodyViewMaterialsSlice'
import taskBodyAddMaterialsReducer from '../slices/taskBodyAddMaterialsSlice'
import projectBodyAddWorksReducer from '../slices/projectBodyAddWorksSlice'
import projectBodyAddRoomsReducer from '../slices/projectBodyAddRoomsSlice'
import tasksReducer from '../slices/tasksSlice'
import objectsReducer from '../slices/objectsSlice'
import roomsReducer from '../slices/roomsSlice'
import brandsReducer from '../slices/brandsSlice'
import storesReducer from '../slices/storesSlice'
import categoriesReducer from '../slices/categoriesSlice'
import minStroyMaterialsReducer from '../slices/minStroyMaterialsSlice'
import stagesReducer from '../slices/stagesSlice'
import viewsReducer from '../slices/viewsSlice'
import packagesReducer from '../slices/packagesSlice'
import jobTypesReducer from '../slices/jobTypesSlice'
import stepsReducer from '../slices/stepsSlice'
import userOrganizationsReducer from '../slices/userOrganizationsSlice'
import userRolesReducer from '../slices/userRolesSlice'
import usersReducer from '../slices/usersSlice'
import warehousesReducer from '../slices/warehousesSlice'
import warehouseInventoriesReducer from '../slices/warehouseInventoriesSlice'
import projectWarehousesReducer from '../slices/projectWarehousesSlice'
import projectWarehouseInventoriesReducer from '../slices/projectWarehouseInventoriesSlice'
import clientsReducer from '../slices/clientsSlice'
import specializationsReducer from '../slices/specializationsSlice'
import projectsReducer from '../slices/projectsSlice'
import projectPlanningWorksReducer from '../slices/projectPlanningWorksSlice'
import jobBodyFormulaReducer from '../slices/jobBodyFormulaSlice'
import errorReducer from 'store/slices/errorSlice.ts'
import outlaysReducer from 'store/slices/outlaysSlice.ts'
import activeWorkReducer from 'store/slices/activeWorkSlice.ts'
import workerTaskReducer from '../slices/workerTaskSlice'
import selectedOutlayReducer from 'store/slices/selectedOutlaySlice.ts'
import journalReducer from '../slices/journalSlice.ts'
import notificationsReducer from '../slices/notificationsSlice.ts'
import adjustmentReducer from '../slices/adjustmentSlice.ts'
import favoriteFilesReducer from '../slices/favoriteFiles.ts'
import minstroyMaterialsKSRReducer from '../slices/minstroyMaterialsKSRSlice.ts'
import projectReportsReducer from '../slices/projectReports.ts'
import minstroyJobsReducer from '../slices/minstroyJobsSlice.ts'
import correctivesTableReducer from '../slices/correctivesTable.ts'
import replaceCorrectionMaterialReducer from '../slices/replaceCorrectionMaterial.ts'
import materialUsageReducer from '../slices/materialUsageSlice.ts'
import formulasReducer from '../slices/formulasSlice.ts'
import groupsReducer from '../slices/groupsSlice.ts'
import jobsGroupsReducer from '../slices/jobsGroups.ts'
import groupsAddJobsReducer from '../slices/groupsAddJobs.ts'
import  projectBodyAddGroupsReducer  from '../slices/projectBodyAddGroupsSlice.ts';
import inventoryMaterialsReducer from '../slices/inventoryMaterials.ts';
import  inventoriesTableReducer  from '../slices/inventoriesTableSlice.ts';
import inventoryAddMaterialsReducer  from 'store/slices/inventoryAddMaterialsSlice.ts';
import documentMovementsReducer from '../slices/documentMovementsSlice.ts'
import documentMovementMaterialsReducer from '../slices/documentMovementMaterialsSlice.ts'
import warehouseInventoriesTableReducer from '../slices/warehouseInventoriesTable.ts'
import ordersReducer from '../slices/ordersSlice.ts'
import orderMaterialsReducer from '../slices/orderMaterialsSlice.ts'
import resourceStatementReducer from 'store/slices/resourceStatement.ts';
import replaceSupplyRequestMaterialReducer from 'store/slices/replaceSupplyRequestMaterialSlice.ts'
import breadCrumbsNameReducer from 'store/slices/breadCrumbsNameSlice.ts'
import docsReducer from '../slices/docsSlice.ts'
import projectDocumentsReducer from 'store/slices/projectDocuments.ts';
import supplyRequestsReducer from '../slices/supplyRequestsSlice.ts'
import supplyRequestMaterialsReducer from '../slices/supplyRequestMaterialsSlice.ts'

export default combineReducers({
    projectDocumentsReducer,
    breadCrumbsNameReducer,
    docsReducer,
    resourceStatementReducer,
    warehouseInventoriesTableReducer,
    inventoryAddMaterialsReducer,
    inventoriesTableReducer,
    inventoryMaterialsReducer,
    projectBodyAddGroupsReducer,
    groupsAddJobsReducer,
    jobsGroupsReducer,
    groupsReducer,
    formulasReducer,
    materialUsageReducer,
    replaceCorrectionMaterialReducer,
    correctivesTableReducer,
    authReducer,
    favoriteFilesReducer,
    layoutReducer,
    materialsReducer,
    notificationsReducer,
    jobsReducer,
    jobBodyViewMaterialsReducer,
    jobBodyAddMaterialsReducer,
    journalReducer,
    taskBodyViewMaterialsReducer,
    taskBodyAddMaterialsReducer,
    tasksReducer,
    objectsReducer,
    roomsReducer,
    brandsReducer,
    storesReducer,
    categoriesReducer,
    stagesReducer,
    viewsReducer,
    packagesReducer,
    jobTypesReducer,
    stepsReducer,
    userOrganizationsReducer,
    userRolesReducer,
    usersReducer,
    warehousesReducer,
    warehouseInventoriesReducer,
    projectWarehousesReducer,
    projectWarehouseInventoriesReducer,
    clientsReducer,
    specializationsReducer,
    projectsReducer,
    jobBodyFormulaReducer,
    projectBodyAddWorksReducer,
    projectBodyAddRoomsReducer,
    errorReducer,
    outlaysReducer,
    activeWorkReducer,
    workerTaskReducer,
    selectedOutlayReducer,
    adjustmentReducer,
    minStroyMaterialsReducer,
    minstroyMaterialsKSRReducer,
    projectReportsReducer,
    minstroyJobsReducer,
    projectPlanningWorksReducer,
    documentMovementsReducer,
    documentMovementMaterialsReducer,
    ordersReducer,
    orderMaterialsReducer,
    replaceSupplyRequestMaterialReducer,
    supplyRequestsReducer,
    supplyRequestMaterialsReducer,

    [authenticationApi.reducerPath]: authenticationApi.reducer,
})
