import styles from 'components/Worker/Dashboard.module.css'
import { Box } from '@mantine/core'

type EmptyCalendarDateProps = {
    string: string
}

export const EmptyCalendarDate = ({ string }: EmptyCalendarDateProps) => 
    <Box className={ styles.calendarEmpty }>
        {string}
    </Box>
