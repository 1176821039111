import { createSlice, Draft } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { FindProjectFolderResponseDto } from 'store/api/projectFolders.tsx';
import { FindProjectDocumentResponseDto } from 'store/api/projectDocuments.tsx';

type ProjectDocumentsSliceInitialState = {
    projectDocumentsFolders:  FindProjectFolderResponseDto[] | null ,
    foldersDocuments: FindProjectDocumentResponseDto[] | null ,
    uploadProgressSlotId: string | null,
}

const initialState: ProjectDocumentsSliceInitialState = {
    projectDocumentsFolders: null,
    foldersDocuments: null,
    uploadProgressSlotId: null,
}

export const projectDocumentsSlice = createSlice({
    name: 'projectDocuments',
    initialState,
    reducers: {
        setProjectDocumentsFoldersToStore: (state: Draft<ProjectDocumentsSliceInitialState>, action: PayloadAction<FindProjectFolderResponseDto[] >) => {
            state.projectDocumentsFolders = action.payload
        },
        setFoldersDocumentsToStore: (state: Draft<ProjectDocumentsSliceInitialState>, action: PayloadAction<FindProjectDocumentResponseDto[] >) => {
            state.foldersDocuments = action.payload
        },
        setUploadProgressSlotId: (state: Draft<ProjectDocumentsSliceInitialState>, action: PayloadAction<string | null>) => {
            state.uploadProgressSlotId = action.payload
        }
    }
})

export default projectDocumentsSlice.reducer
