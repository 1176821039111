import { createAppSlice } from 'utils/createAppSlice'
import { defaultInitialState } from 'utils/defaultInitialState.ts'
import { FormBasedSliceInitialState } from 'types/redux.ts'

export class JournalSliceInitialState extends FormBasedSliceInitialState {
    editableFormHeader = {}
    editableFormBody = {}
}

const journalSliceInitialState: JournalSliceInitialState = {
    ...defaultInitialState,
    editableFormBody: {},
    editableFormHeader: {},
}

export const journalSlice = createAppSlice<JournalSliceInitialState>('journal', journalSliceInitialState)

export default journalSlice.reducer
