import { createElement, Suspense } from 'react'
import { Outlet, RouteObject } from 'react-router-dom'
import { REDIRECT_ROUTES } from 'routes/redirect/routes'
import { ERROR_ROUTES } from '../error/routes'
import { CLIENTS_ROUTES } from './clientsRoutes.tsx'
import { EMPLOYEE_ROUTES } from './employeeRoutes'
import { ORGANIZATIONS_ROUTES } from './organizationsRoutes.tsx'
import { PROJECTS_ROUTES } from './projectsRoutes.tsx'
import { RESOURCES_ROUTES } from './resourcesRoutes.tsx'
import { STORE_ROUTES } from './storeRoutes'
import { SUPPLY_ROUTES } from './supplyRoutes.tsx'
import { ADMIN_ROUTES } from 'routes/tenant/adminRoutes.tsx'
import { UserProfileLayout } from 'components/Worker/User/UserProfileLayout.tsx';
import BreadcrumbItem from 'components/AbstractComponents/BreadCrumbs/BreadcrumbItem.tsx';
import { RootStateType } from 'store/store.ts';

export const TENANT_ROUTES: Array<RouteObject> = [
    {
        id: 'home',
        path: '/',
        async lazy() {
            const { LayoutTenant } = await import('components/AbstractComponents/LayoutTenant/LayoutTenant')

            return { element: <Suspense fallback=""><LayoutTenant/></Suspense> }
        },
        loader: ({ params }) => ({ id: params.id, name: 'Главная', path: '/' }),
        children: [
            ...ERROR_ROUTES,
            ...REDIRECT_ROUTES,
            {
                index: true,
                async lazy() {
                    const { HomePage } = await import('components/Home/HomePage.tsx')
                    return { element: <HomePage/> }
                },
            },
            {
                id: 'projects',
                path: '/projects',
                element: <Suspense fallback=""><Outlet/></Suspense>,
                loader: ({ params }) => ({ id: params.id, name: 'Проекты', path: '/projects' }),
                children: PROJECTS_ROUTES,
            },
            {
                id: 'clients',
                path: '/clients',
                element: <Suspense fallback=""><Outlet/></Suspense>,
                loader: ({ params }) => ({ id: params.id, name: 'Клиенты и расчёты', path: '/clients' }),
                children: CLIENTS_ROUTES,
            },
            {
                id: 'organizations',
                path: '/organizations',
                element: <Suspense fallback=""><Outlet/></Suspense>,
                loader: ({ params }) => ({ id: params.id, name: 'Организации', path: '/organizations' }),
                children: ORGANIZATIONS_ROUTES,
            },
            {
                id: 'employee',
                path: '/employee',
                element: <Suspense fallback=""><Outlet/></Suspense>,
                loader: ({ params }) => ({ id: params.id, name: 'Сотрудники и группы', path: '/employee' }),
                children: EMPLOYEE_ROUTES,
            },
            {
                id: 'resource',
                path: '/resources',
                element: <Suspense fallback=""><Outlet/></Suspense>,
                loader: ({ params }) => ({ id: params.id, name: 'Справочник', path: '/resources' }),
                children: RESOURCES_ROUTES,
            },
            {
                id: 'supply',
                path: '/supply',
                element: <Suspense fallback=""><Outlet/></Suspense>,
                loader: ({ params }) => ({ id: params.id, name: 'Снабжение', path: '/supply' }),
                children: SUPPLY_ROUTES,
            },
            {
                id: 'docs',
                path: '/docs',
                element: <Suspense fallback=""><Outlet/></Suspense>,
                loader: ({ params }) => ({ id: params.id, name: 'База знаний', path: '/docs' }),
                children: [
                    {
                        id: 'docsList',
                        path: '',
                        async lazy() {
                            const { DocsPage } = await import('components/Docs/DocsPage.tsx')
                            return { element: <DocsPage/> }
                        },

                    },
                    {
                        id: 'docById',
                        path: ':docId',
                        async lazy() {
                            const { ArticlePageWrapper } = await import('components/Docs/ArticlePageWrapper.tsx')
                            return { element: <ArticlePageWrapper/> }
                        },
                        loader: ({ params }) => ( {
                            id: params.docId,
                            name: createElement(BreadcrumbItem((state: RootStateType) => ( {
                                name: typeof state.docsReducer.article?.title == 'string' ?  state.docsReducer.article.title : '',
                            } ))),
                        } ),
                        children: [
                            {
                                id: 'articleById',
                                index: true,
                                path: '',
                                async lazy() {
                                    const { ArticlePage } = await import('components/Docs/ArticlePage.tsx')
                                    return { element: <ArticlePage/> }
                                },

                            }
                        ]
                    }
                ],
            },
            {
                id: 'store',
                path: '/store',
                element: <Suspense fallback=""><Outlet/></Suspense>,
                loader: ({ params }) => ({ id: params.id, name: 'Склад', path: '/store' }),
                children: STORE_ROUTES,
            },
            {
                id: 'admin',
                path: '/admin',
                element: <Suspense fallback=""><Outlet/></Suspense>,
                loader: () => ({ name: 'Администратор', path: '/admin' }),
                children: ADMIN_ROUTES,
            },
            {
                id: 'tenantNotifications',
                path: '/notifications',
                async lazy() {
                    const { NotificationPage } = await import('components/AbstractComponents/Notifications/NotificationPage.tsx')
                    return { element: <NotificationPage/> }
                }
            },
            {
                id: 'user',
                path: 'user/:userId',
                element: <Outlet />,

                loader: ({ params }) => ({
                    id: params.id,
                    name: 'Профиль',
                    path: '/',
                }),
                children: [
                    {
                        id: 'userId',
                        path: '',
                        element: <UserProfileLayout />,
                    },
                ]
            },
        ],
    },
]
