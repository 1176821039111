import { Box, Flex, Text, Title } from '@mantine/core'
import { TaskStatus } from 'components/Worker/Calendar/Nested/TaskStatus.tsx';
import { useNavigate } from 'react-router-dom';
import { TaskItemProps, WorkTaskTreeProps } from 'components/Worker/Work/Work.types.ts';


const TaskItem = ({ task }: TaskItemProps) => {

    const navigate = useNavigate()

    return (
        <Flex
            direction={'column'}
            gap={12}
            mt={8}
            onClick={ () => navigate('task/' + task.id) }
            sx={{
                border: '1px solid #E0E3E7',
                borderRadius: '8px',
                padding: '16px',
                backgroundColor: '#FFFFFF',
            }}
        >
            <Flex justify={'space-between'}>
                <Box />
                { task?.status ? <TaskStatus status={task.status}/> : null}
            </Flex>
            
            <Flex direction={'column'} gap={4}>
                <Title order={4}>{task.name}</Title>
                <Text 
                    sx={{
                        fontSize: '14px',
                        fontWeight: 400,
                        lineHeight: '20px',
                        color: '#3F4759',
                    }}
                >
                    {task.work?.room.name}
                </Text>
            </Flex>
        </Flex>
    )
}


export const WorkTaskTree = ({ tasksTree }: WorkTaskTreeProps) =>
    <Box
        style={ {
            overflowY: 'scroll',
            height: '110vh',
            padding: '8px',
        } }
    >
        { tasksTree.flat().map((task) => <TaskItem task={ task } key={ task.id } />) }
    </Box>
