import {
    MaterialsSliceInitialState,
} from 'components/Resources/Materials/Materials.types'
import {
    materialsFormBodyInitialState,
    materialFormHeaderInitialState,
} from 'components/Resources/Materials/Materials.utils'
import { defaultInitialState } from 'utils/defaultInitialState.ts'
import { createAppSlice } from 'utils/createAppSlice'

const materialsInitialState: MaterialsSliceInitialState = {
    ...defaultInitialState,
    editableFormHeader: materialFormHeaderInitialState,
    editableFormBody: materialsFormBodyInitialState,
}

export const materialsSlice = createAppSlice<MaterialsSliceInitialState>('materials', materialsInitialState)


export default materialsSlice.reducer
