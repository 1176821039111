import { RoomsSliceInitialState } from 'components/Clients/NestedPages/Rooms/Rooms.types'
import { roomFormHeaderInitialState, roomFormBodyInitialState } from 'components/Clients/NestedPages/Rooms/Rooms.utils'
import { createAppSlice } from 'utils/createAppSlice'
import { defaultInitialState } from 'utils/defaultInitialState.ts'

const roomsSliceInitialState: RoomsSliceInitialState = {
    ...defaultInitialState,
    editableFormHeader: roomFormHeaderInitialState,
    editableFormBody: roomFormBodyInitialState,
}


export const roomsSlice = createAppSlice<RoomsSliceInitialState>('rooms', roomsSliceInitialState)


export default roomsSlice.reducer
