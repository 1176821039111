import {
    autocompleteChangeArg,
    autocompleteFieldDefault,
    autocompleteSubmitArg,
    multiselectFieldDefault
} from 'utils/inputs.tsx'
import { ProjectWarehouseFormBody, ProjectWarehouseFormHeader, ProjectWarehousesAutocompleteKey, ProjectWarehousesFormGlobal, ProjectWarehousesMultiselectKey } from './ProjectWarehouses.types.ts'
import { AutocompleteSubmitData, InputChangeArg, InputSubmitArg } from 'types/inputs.ts'
import { FindWarehouseResponseDto, UpdateWarehouseRequestDto } from 'store/api/warehouse.tsx'


// *
// DATA CONVERT FUNCS
// *

export const convertProjectWarehouseDtoToFormBody = (projectWarehouse: FindWarehouseResponseDto): ProjectWarehouseFormBody => ( {
    address: projectWarehouse?.address || projectWarehouseFormBodyInitialState.address,
    description: projectWarehouse?.description || projectWarehouseFormBodyInitialState.description,
    type: projectWarehouse?.type && {
        name: projectWarehouse.type.name,
        data: {
            id: projectWarehouse.type.id,
            name: projectWarehouse.type.name,
        },
    } || projectWarehouseFormBodyInitialState.object,
    types: multiselectFieldDefault,
    object: projectWarehouse?.object && {
        name: projectWarehouse.object.name,
        data: {
            id: projectWarehouse.object.id,
            name: projectWarehouse.object.name,
        },
    } || projectWarehouseFormBodyInitialState.object,
    organization: projectWarehouse?.organization && {
        name: projectWarehouse.organization.name,
        data: {
            id: projectWarehouse.organization.id,
            name: projectWarehouse.organization.name,
        },
    } || projectWarehouseFormBodyInitialState.organization,
    deleted: projectWarehouse?.deleted || false,
} )
export const convertProjectWarehouseDtoToFormHeader = (projectWarehouse: FindWarehouseResponseDto): ProjectWarehouseFormHeader => ( {
    name: projectWarehouse?.name || projectWarehouseFormHeaderInitialState.name,
} )
export const convertProjectWarehouseFormToRequestDto = (data: Partial<ProjectWarehouseFormBody & ProjectWarehouseFormHeader>): UpdateWarehouseRequestDto => ( {
    name: data?.name || projectWarehouseFormHeaderInitialState.name,
    // address: data?.address || projectWarehouseFormBodyInitialState.address,
    description: data?.description || projectWarehouseFormBodyInitialState.description,
    typeId: data?.type && ( data?.type?.data.id || data?.type?.name )
        || projectWarehouseFormBodyInitialState.type.name,
    objectId: data?.object && ( data?.object?.data.id || data?.object?.name )
        || projectWarehouseFormBodyInitialState.object.name,
    deleted: data?.deleted || false,
} )

// *
// STORE
// *

export const projectWarehousesFormGlobalDefaultValues: ProjectWarehousesFormGlobal = {
    name: '',
    address: '',
    description: '',
    object: autocompleteFieldDefault,
    organization: autocompleteFieldDefault,
    type: autocompleteFieldDefault,
    types: multiselectFieldDefault,
    deleted: false,
}
export const projectWarehouseFormHeaderInitialState: ProjectWarehouseFormHeader = {
    name: '',
}
export const projectWarehouseFormBodyInitialState: ProjectWarehouseFormBody = {
    address: '',
    description: '',
    object: autocompleteFieldDefault,
    organization: autocompleteFieldDefault,
    type: autocompleteFieldDefault,
    types: multiselectFieldDefault,
    deleted: false,
}


// *
// INPUT PROCESSING FUNCS
// *

// global

type ProjectWarehousesObjectAutocompleteSubmitData = AutocompleteSubmitData & { label: string }

export const onProjectWarehousesGlobalAutocompleteSubmit = ({
    key,
    setFormValue,
}: InputSubmitArg<ProjectWarehousesAutocompleteKey, ProjectWarehousesFormGlobal>) =>
    (data: ProjectWarehousesObjectAutocompleteSubmitData) => {
        if(data.value && data.label) {
            setFormValue(key, autocompleteSubmitArg({ id: data.value, value: data.label }))
            return
        }
        setFormValue(key, autocompleteSubmitArg(data))
    }

export const onProjectWarehousesGlobalAutocompleteChange = ({
    formData,
    key,
    setFormValue,
}: InputChangeArg<ProjectWarehousesAutocompleteKey, ProjectWarehousesFormGlobal>) =>
    (label: string) => {
        if (formData[key]?.name !== label) {
            setFormValue(key, autocompleteChangeArg(label))
        }
    }
export const onProjectWarehousesGlobalMultiselectChange = ({
    formData,
    key,
    setFormValue,
}: InputChangeArg<ProjectWarehousesMultiselectKey, ProjectWarehousesFormGlobal>) => (label: string) => {
    if (formData[key]?.name !== label) {
        setFormValue(`${ key }.name`, label)
    }
}
export const onProjectWarehousesGlobalMultiselectSubmit = ({
    key,
    setFormValue,
}: InputSubmitArg<ProjectWarehousesMultiselectKey, ProjectWarehousesFormGlobal>) => (valueIds: Array<string>) => {
    setFormValue(`${ key }.ids`, valueIds)
}

// body
export const onProjectWarehouseBodyAutocompleteSubmit = ({
    key,
    setFormValue,
}: InputSubmitArg<ProjectWarehousesAutocompleteKey, ProjectWarehouseFormBody>) =>
    (data: AutocompleteSubmitData) => {
        setFormValue(key, autocompleteSubmitArg(data))
    }
export const onProjectWarehouseBodyAutocompleteChange = ({
    formData,
    key,
    setFormValue,
}: InputChangeArg<ProjectWarehousesAutocompleteKey, ProjectWarehouseFormBody>) =>
    (label: string) => {
        if (formData[key]?.name !== label) {
            setFormValue(key, autocompleteChangeArg(label))
        }
    }
