import { JobMaterialsSliceInitialState } from 'components/Resources/Jobs/PageBody/Materials/JobMaterials.types'
import { jobMaterialFormHeaderInitialState, jobMaterialsFormBodyInitialState } from 'components/Resources/Jobs/PageBody/Materials/JobMaterials.utils'
import { createAppSlice } from 'utils/createAppSlice'
import { defaultInitialState } from 'utils/defaultInitialState.ts'

const jobsMaterialInitialState: JobMaterialsSliceInitialState = {
    ...defaultInitialState,
    editableFormHeader: jobMaterialFormHeaderInitialState,
    editableFormBody: jobMaterialsFormBodyInitialState,
}

export const jobBodyViewMaterialsSlice = createAppSlice<JobMaterialsSliceInitialState>('jobBodyViewMaterials', jobsMaterialInitialState)

export default jobBodyViewMaterialsSlice.reducer
