import { Navigate, RouteObject } from 'react-router-dom'

export const REDIRECT_ROUTES: Array<RouteObject> = [
    {
        id: 'login',
        path: 'login',
        element: <Navigate to={'/'}/>,
    },
    {
        id: 'register',
        path: 'register',
        element: <Navigate to={'/'}/>,
    },
    {
        id: 'reset',
        path: 'reset',
        element: <Navigate to={'/'}/>,
    },
]
