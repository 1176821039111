// import { setupListeners } from '@reduxjs/toolkit/query'
import React, { ReactNode } from 'react'
import { Provider } from 'react-redux'
import { store } from 'store/store'

type Props = {
    children: ReactNode
}

export const ProviderRedux: React.FC<Props> = ({ children }) => 
    <Provider store={store}>{children}</Provider>


