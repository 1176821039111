import { createAppSlice } from 'utils/createAppSlice'
import { defaultInitialState } from 'utils/defaultInitialState.ts'
import {
    formulasFormBodyInitialState,
    formulasFormHeaderInitialState,
    FormulasSliceInitialState
} from 'components/Resources/Jobs/NestedPages/Formulas/SystemFormulas.types.ts';


const formulasSliceInitialState: FormulasSliceInitialState = {
    ...defaultInitialState,
    editableFormHeader: formulasFormHeaderInitialState,
    editableFormBody: formulasFormBodyInitialState,
}

export const formulasSlice = createAppSlice<FormulasSliceInitialState>('formulas', formulasSliceInitialState )

export default formulasSlice.reducer
