import { FindWorkPartResponseDto } from 'store/api/projectTasks.tsx'
import dayjs from 'utils/dayjs.ts'

export const getWorkFromToHours = (date: string, parts: FindWorkPartResponseDto[]) => {

    const workParts = parts.filter((part) => dayjs(part.from).format('YYYY-MM-DD') == date)

    const from = workParts[0]?.from.split(' ')[1]
    const to = workParts[0]?.to.split(' ')[1]

    return { from, to }

}
