import { SpecializationFormBody, SpecializationFormHeader, SpecializationsFormGlobal } from './Specializations.types'
import { FindSpecializationResponseDto, UpdateSpecializationRequestDto } from 'store/api/userSpecialization'


// *
// DATA CONVERT FUNCS
// *

export const convertSpecializationDtoToFormBody = (specialization: FindSpecializationResponseDto): SpecializationFormBody => {
    console.log(specialization)
    return {}
}
export const convertSpecializationDtoToFormHeader = (specialization: FindSpecializationResponseDto): SpecializationFormHeader => ({
    name: specialization?.name || specializationFormHeaderInitialState.name,
})
export const convertSpecializationFormToRequestDto = (data: Partial<SpecializationFormBody & SpecializationFormHeader>): UpdateSpecializationRequestDto => ({
    name: data?.name || specializationFormHeaderInitialState.name,
})


// *
// STORE
// *

export const specializationsFormGlobalDefaultValues: SpecializationsFormGlobal = {
    name: '',
}
export const specializationFormHeaderInitialState: SpecializationFormHeader = {
    name: '',
}
export const specializationFormBodyInitialState: SpecializationFormBody = {
    // description: '',
}


// *
// INPUT PROCESSING FUNCS
// *
