import {
    ObjectsSliceInitialState,
} from 'components/Clients/NestedPages/Objects/Objects.types'
import {
    objectFormHeaderInitialState,
    objectFormBodyInitialState,
} from 'components/Clients/NestedPages/Objects/Objects.utils'
import { createAppSlice } from 'utils/createAppSlice'
import { defaultInitialState } from 'utils/defaultInitialState.ts'

const objectsSliceInitialState: ObjectsSliceInitialState = {
    ...defaultInitialState,
    editableFormHeader: objectFormHeaderInitialState,
    editableFormBody: objectFormBodyInitialState,
    permanentFilterParamsArray: [
        {
            param: 'object.deleted',
            rule: 'eq',
            value: false,
        },
    ],
}

export const objectsSlice = createAppSlice<ObjectsSliceInitialState>('objects', objectsSliceInitialState)


export default objectsSlice.reducer
